import React, {useEffect, useState} from 'react'
import useLocalization from '../../hooks/useLocalization'
import {Redirect, useParams} from 'react-router-dom'
import useServices from '../../hooks/useServices'
import Text from '../../components/Text'
import Loader from '../../components/Loader'
import InnerScroll from '../../components/InnerScroll'
import Page from '../../components/Platform/Onboarding/Page'
import Box from '../../components/Box'
import OfferQuickSignInForm from '../../components/Platform/Onboarding/OfferQuickSignInForm'
import Grid from '../../components/Grid'
import {IOfferInvite} from '../../domain/IOffer'
import useProfile from 'hooks/useProfile'
import {StyledReloadingButton} from 'components/Platform/Onboarding/Layout/FormComponents'

interface ParamTypes {
  accessCode: string
}

const OfferInvite = () => {
  const {accessCode} = useParams<ParamTypes>()
  const {translate} = useLocalization()
  const {contentService} = useServices()
  const {user, isLoaded: isUserLoaded} = useProfile()
  const [offerInvite, setOfferInvite] = useState<IOfferInvite>({} as IOfferInvite)
  const [error, setError] = useState<string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    contentService
      .getUserFromOfferInvite(accessCode)
      .then(offerInvite => {
        setOfferInvite(offerInvite)
      })
      .catch(err => {
        setError(translate('Invite link does not exist, already used or has expired. Please contact an administrator.'))
      })
      .finally(() => {
        setIsLoading(false)
      })
    //eslint-disable-next-line
  }, [accessCode])

  if (!isUserLoaded) {
    return (
      <Page>
        <Loader />
      </Page>
    )
  }

  if (user) {
    return (
      <Page>
        <Box>
          <Text bold size="mlarge">
            You are already logged in as {user.email}.
          </Text>
          <Box justify="flex-end" margin={{top: 2}}>
            <StyledReloadingButton>{translate('Log out & continue with the invite')}</StyledReloadingButton>
          </Box>
        </Box>
      </Page>
    )
  }

  if (offerInvite.user && offerInvite.user.activated && offerInvite.offer?.id) {
    return <Redirect to={`/consumer/buy/offers/${offerInvite.offer.id}?modal.offerpdf`} />
  }

  return (
    <Page hideNavigation={!error}>
      <InnerScroll>
        {(() => {
          if (isLoading) {
            return <Loader />
          }

          if (error) {
            return <Text size={'xlarge'}>{error}</Text>
          }

          return (
            <Grid>
              <div>
                <div>
                  <Text size={'xlarge'} bold>
                    {translate('Hi, %s!', offerInvite.user.email)}
                  </Text>
                </div>
                <Box margin={{bottom: 2}}>
                  <Text size="mlarge">
                    {translate(
                      'Thank you for accepting the invite. Your account is prepared and you only need to create a password so you can log in.',
                    ) +
                      ' ' +
                      translate('You can change your first and last name.')}
                  </Text>
                </Box>
                <OfferQuickSignInForm accessCode={accessCode} user={offerInvite.user} />
              </div>
            </Grid>
          )
        })()}
      </InnerScroll>
    </Page>
  )
}

export default OfferInvite
