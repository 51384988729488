import React, {useEffect} from 'react'
import Select from '../Select'
import useLocalization from '../../hooks/useLocalization'
import useStore from '../../hooks/useStore'
import {Interval} from '../../services/IContentService'
import Label from '../Label'
import {getChangeEventValue} from '../../helpers/misc'
import {useAtom} from 'jotai'
import {intervalAtom} from 'atoms/general'

export interface IProps extends React.PropsWithChildren {
  hideYearly?: boolean
  hideDaily?: boolean
  hideMonthly?: boolean
}

const SelectTimescale: React.FC<IProps> = ({hideYearly, hideDaily, hideMonthly}) => {
  const {translate} = useLocalization()
  const [interval, setInterval] = useAtom(intervalAtom)

  const handleChange = event => {
    const value = getChangeEventValue(event) as Interval
    setInterval(value)
  }

  useEffect(() => {
    if (hideYearly && interval === Interval.YEARLY) {
      setInterval(Interval.MONTHLY)
    }

    // eslint-disable-next-line
  }, [interval, hideYearly])

  return (
    <Label text={translate('Select timescale')}>
      <Select
        margin={0}
        value={interval}
        options={[
          !hideYearly && {value: Interval.YEARLY, label: translate('Full period')},
          !hideMonthly && {value: Interval.MONTHLY, label: translate('Months')},
          {value: Interval.HOURLY, label: translate('Hours')},
          !hideDaily && {value: Interval.DAILY, label: translate('Days')},
        ].filter(Boolean)}
        onChange={handleChange}
      />
    </Label>
  )
}

export default SelectTimescale
