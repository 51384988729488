import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import ITheme from '../theme/ITheme'
import SimpleBarReact from 'simplebar-react'
import 'simplebar/src/simplebar.css'
import useOS, {OperatingSystem} from '../hooks/useOS'

interface IProps extends React.PropsWithChildren {
  padded?: boolean
  noRightPad?: boolean
}

const Container = styled(Box)<IProps>`
  overflow: hidden;
  flex-grow: 1;
  max-height: 100%;
  ${props => props.padded && `margin-left: ${-(props.theme as ITheme).tileSize}px;`}
`

const Scrollable: React.FC<React.PropsWithChildren> = ({children}) => {
  const os: OperatingSystem = useOS()
  const overrideScrollbarForOperatingSystems = [OperatingSystem.Linux, OperatingSystem.Windows]

  if (overrideScrollbarForOperatingSystems.includes(os)) {
    return <SimpleBarReact style={{maxHeight: '100vh', height: '100%'}}>{children}</SimpleBarReact>
  }

  return <div style={{height: '100%', overflow: 'auto'}}>{children}</div>
}

const InnerScroll: React.FC<IProps> = ({children, padded, noRightPad}) => {
  return (
    <Container margin={padded ? {left: -1} : null}>
      <Scrollable>
        <Box
          pad={{
            vertical: padded ? 1 : 0,
            left: padded ? 1 : 0,
            right: noRightPad ? 0 : 3,
          }}
        >
          {children}
        </Box>
      </Scrollable>
    </Container>
  )
}

export default InnerScroll
