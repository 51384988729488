import styled from 'styled-components'
import spacing, {ISpacingProps} from './styleProps/spacing'
import ITheme from 'theme/ITheme'

export type ISize =
  | 'xsmall'
  | 'medium'
  | 'small'
  | 'lsmall'
  | 'large'
  | 'slarge'
  | 'xlarge'
  | 'msmall'
  | 'xxlarge'
  | 'xmlarge'

interface IProps extends React.PropsWithChildren, ISpacingProps {
  children: any
  size?: ISize
  lineHeight?: ISize
  color?: string
  transform?: string
  font?: string
  weight?: string
}

const sizes = {
  xxlarge: 49,
  xmlarge: 38,
  xlarge: 32,
  slarge: 30,
  large: 24,
  medium: 20,
  lsmall: 18,
  msmall: 16,
  small: 14,
  xsmall: 12,
}

const lineHeights = {
  xxlarge: 56,
  xlarge: 48,
  slarge: 44,
  large: 40,
  medium: 36,
  lsmall: 32,
  msmall: 28,
  small: 21,
  xsmall: 16,
}

const Heading = styled.div<IProps>`
  font-weight: ${props => (props.weight ? props.weight : 500)};
  margin: 0;
  ${props => props.color && `color: ${props.color};`}
  ${props => props.transform && `text-transform: ${props.transform};`}
  ${(props: IProps & {theme: ITheme}) => `font-family: ${props.font || props.theme.font.headings};`}
  font-size: ${({size = 'msmall'}: IProps) => sizes[size]}px;
  line-height: ${({lineHeight, size}: IProps) => lineHeights[lineHeight || size || 'small']}px;
  ${spacing}
`

export default Heading
