import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'

interface IProps extends React.PropsWithChildren {}

const TableSkeleton: React.FC<IProps> = () => {
  return (
    <>
      <Box direction="column" pad={{top: 3}} gap={3}>
        <Box direction="column" gap={1}>
          {[...Array(15)].map((_, index) => (
            <React.Fragment key={index}>
              <Skeleton height={45} width="100%" variant="rectangular" />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default TableSkeleton
