import {subscriptionIdAtom} from 'atoms/subscription'
import {EventSourcePolyfill} from 'event-source-polyfill'
import {useAtomValue, useSetAtom} from 'jotai'
import {useEffect, useState} from 'react'
import {partyAtom} from 'atoms/party'
import {rootServiceAtom} from 'atoms/general'
import env from '@beam-australia/react-env'
import useIsTabActive from './useIsTabActive'
import useStore from 'hooks/useStore'
import {ISubscriptionPlan} from 'domain/IParty'
import useNetworkStatus from 'hooks/useNetworkStatus'

const EventSource = EventSourcePolyfill

enum ServerSentEvents {
  SUBSCRIPTION_PLAN_UPGRADED = 'SUBSCRIPTION_PLAN_UPGRADED',
  SUBSCRIPTION_PLAN_DOWNGRADED = 'SUBSCRIPTION_PLAN_DOWNGRADED',
}

function useSSE() {
  const isTabVisible = useIsTabActive()
  const isOnline = useNetworkStatus()
  const {profileStore} = useStore()
  const setSubscription = useSetAtom(subscriptionIdAtom)
  const party = useAtomValue(partyAtom)
  const [authToken, setAuthToken] = useState(null)
  const [error, setError] = useState(false)
  const rootService = useAtomValue(rootServiceAtom)

  const refreshToken = async () => {
    const token = await rootService?.authenticationService?.refreshToken()

    setAuthToken(token)
  }

  const updatePartiesPlan = (partyId: number, plan: ISubscriptionPlan) => {
    const partyMember = profileStore.getPartyMemberByPartyId(partyId)
    profileStore.setPartyMember({
      ...partyMember,
      party: {
        ...partyMember?.party,
        subscriptionPlan: plan,
      },
    })
  }

  useEffect(() => {
    const fetchToken = async () => {
      const token = await rootService?.authenticationService?.getToken()

      setAuthToken(token)
    }

    if (party) {
      fetchToken()
    }
  }, [party])

  // It is workaround for browser becoming stale and killing the connection, this way we restart it with fresh token
  useEffect(() => {
    if (isTabVisible && error && isOnline) {
      refreshToken()
    }
  }, [isTabVisible, error, isOnline])

  useEffect(() => {
    let source

    if (authToken) {
      const eventSourceInitDict = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }

      source = new EventSource(`${env('CORE_V2_URL')}/v2/api/events`, eventSourceInitDict)

      source.onopen = () => {
        setError(false)
      }

      source.onmessage = event => {
        const jsonEvent = JSON.parse(event.data)
        const partyId = jsonEvent?.payload?.partyId
        if (jsonEvent?.kind === ServerSentEvents.SUBSCRIPTION_PLAN_UPGRADED) {
          updatePartiesPlan(partyId, jsonEvent?.payload?.newPlan)
        }
        if (jsonEvent?.kind === ServerSentEvents.SUBSCRIPTION_PLAN_DOWNGRADED) {
          if (partyId === party?.id) {
            setSubscription(jsonEvent?.payload?.subscriptionId)
          }

          updatePartiesPlan(partyId, jsonEvent?.payload?.newPlan)
        }
      }

      source.onerror = e => {
        source?.close() // Locally this will cause runtime error, bad lib implementation
        setError(true)
      }

      return () => {
        source?.close()
      }
    }
  }, [authToken])
}

export default useSSE
