import React from 'react'
import Box from 'components/Box'
import HorizStack from 'components/HorizStack'
import Text from 'components/Text'
import VerticalStack from 'components/VerticalStack'
import UserNotConfirmedError from 'exceptions/UserNotConfirmedError'
import {useHistory, useLocation} from 'react-router'
import {queryStringToNestedObject} from '../../../helpers/misc'
import useLocalization from '../../../hooks/useLocalization'
import useServices from '../../../hooks/useServices'
import useStore from '../../../hooks/useStore'
import Form from '../../Form'
import {StyledFormButton, StyledLink} from './Layout/FormComponents'
import FormInput from 'components/Form/FormInput'

const SignInForm = () => {
  const {authenticationService} = useServices()
  const {profileStore, alertStore} = useStore()
  const {translate} = useLocalization()
  const location = useLocation()
  const history = useHistory()
  const query: {return_to?: string} = location.search ? queryStringToNestedObject(location.search) : {}

  const handleSubmit = async ({email, password}) => {
    try {
      await authenticationService.signIn(email, password)
      await profileStore.load()
    } catch (error) {
      alertStore.addError(error.message, error?.correlationId)
      if (error instanceof UserNotConfirmedError) {
        history.push(`/on-boarding/email-verification/${email}`)
      }
    }

    if (query.return_to) {
      history.replace(query.return_to)
    }
  }

  return (
    <VerticalStack>
      <Form onSubmit={handleSubmit} submissionFeedback={null}>
        <FormInput
          large
          defaultValue={''}
          label={translate('Your email')}
          name="email"
          data-cy="email"
          placeholder={translate('Insert email')}
          required
        />
        <FormInput
          large
          label={translate('Your password')}
          defaultValue={''}
          name="password"
          data-cy="password"
          type="password"
          placeholder="********************"
          required
          margin={{bottom: 1}}
        />

        <HorizStack justify="space-between" align="center" pad={{top: 0.5}}>
          <Box pad={{left: 2.5}}>
            <StyledLink decoration="none" to="/on-boarding/forgot-password">
              {translate('Forgot password?')}
            </StyledLink>
          </Box>
          <StyledFormButton variant="primary" data-cy="login">
            {translate('Login')}
          </StyledFormButton>
        </HorizStack>
      </Form>
    </VerticalStack>
  )
}

export default SignInForm
