import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import {IProps as ITextAreaProps} from '../TextArea'
import TextEditor from '../TextEditor'

export type IProps = IWrapperProps<ITextAreaProps>

const FormTextEditor: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <TextEditor />
    </FormInputWrapper>
  )
}

export default FormTextEditor
