import Loader from 'components/Loader'
import {TextDocumentType} from 'domain/IDocumentType'
import {unescapeJsonString} from 'helpers/textDocument'
import useServices from 'hooks/useServices'
import useStoreData from 'hooks/useStoreData'
import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'

const PrivacyPolicy: React.FC<React.PropsWithChildren> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState<string>(null)
  const locale = useStoreData(store => store.localizationStore.locale)
  const {contentService} = useServices()

  useEffect(() => {
    async function loadContent() {
      const textDocument = await contentService.getTextDocument(TextDocumentType.PRIVACY_POLICY, locale)
      const formattedContent = unescapeJsonString(textDocument)

      setContent(formattedContent)

      setIsLoading(false)
    }

    loadContent()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <Loader></Loader>
  }

  return <ReactMarkdown>{content}</ReactMarkdown>
}

export default PrivacyPolicy
