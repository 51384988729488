import {action, observable} from 'mobx'

export default class UIStore {
  @observable private hoveredItem: string

  @action
  public setHoveredItem(key: string) {
    this.hoveredItem = key
  }

  @action
  public clearHoveredItem() {
    this.hoveredItem = null
  }

  public isHovered(key: string) {
    return this.hoveredItem === key
  }
}
