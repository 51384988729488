import {SiteProductionTechnology} from './ISite'

export enum ExcessStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export interface IRecommendedExcessPrice {
  currency: string
  price: number
}

export default interface ISellExcessRequest {
  id: number
  technologyKind: SiteProductionTechnology
  status: ExcessStatus
  party: {
    id: number
    name: string
  }
  quantity: number
  certPrice: number
}
