import NotificationBar, {GlobalNotificationType} from 'components/GlobalNotification'
import PaywallDialog from 'components/PaywallDialog'
import PoweredBy from 'components/PoweredBy'
import Text from 'components/Text'
import TextLink from 'components/TextLink'
import {getPartyLink} from 'helpers/party'
import {humanReadablePlan} from 'helpers/subscription'
import useProfile from 'hooks/useProfile'
import useStore from 'hooks/useStore'
import useStoreData from 'hooks/useStoreData'
import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components'
import useLocalization from '../../hooks/useLocalization'
import {ScreenBreakpoint} from '../../hooks/useScreenSize'
import ITheme from '../../theme/ITheme'
import Box from '../Box'
import ErrorBoundary from '../ErrorBoundary'
import Logo from '../Logo'
import {ModalType} from '../Modal/IModal'
import Alerts from './Alerts'
import Footer, {FooterLink} from './Footer'
import {PartyRole} from 'domain/IParty'

export const Spacer = styled.div`
  flex-grow: 1;
`

export const Line = styled.div`
  border-bottom: 1px solid #f1f2f5;
`

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
`

const Padding = styled.div`
  height: ${props => (props.theme as ITheme).tileSize * 4}px;

  background-color: ${props =>
    props.theme.colorBindings.headerBackground &&
    (props.theme as ITheme).colors[props.theme.colorBindings.headerBackground]};
`

const Corner = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => (props.theme as ITheme).tileSize * 2}px;
  padding-bottom: 0;
  background-color: ${props =>
    props.theme.colorBindings.headerBackground &&
    (props.theme as ITheme).colors[props.theme.colorBindings.headerBackground]};
`

const Main = styled.div`
  background-color: ${props => (props.theme as ITheme).colors.surface};
  box-shadow: 0 8px 16px rgba(39, 40, 51, 0.16);
  max-height: 100%;
  overflow: hidden;
`

export const TOP_NAVBAR_HEIGHT = 64
export const PADDING_HEIGHT = 96

const Container = styled.div<{screenSize?: ScreenBreakpoint}>`
  background-color: ${props =>
    props.theme.colorBindings.globalBackground &&
    (props.theme as ITheme).colors[props.theme.colorBindings.globalBackground]};
  min-width: 1330px;
  display: grid;
  grid-template-columns: minmax(200px, auto) 1fr;
  grid-template-rows: ${TOP_NAVBAR_HEIGHT}px auto;
  height: 100vh;

  ${props =>
    props.screenSize < ScreenBreakpoint.Large &&
    `
    grid-template-columns: auto;
    grid-template-rows: auto;
  `}
`

interface IProps extends React.PropsWithChildren {
  top?: React.ReactNode
  aside?: React.ReactNode
}

const Layout: React.FC<IProps> = ({top, aside, children}) => {
  const {translate} = useLocalization()
  const {user, party, isLoaded} = useProfile()
  const {profileStore} = useStore()
  const showNotVerifiedNotification = useStoreData(store => store.profileStore.showPhoneNotVerifiedNotification)
  const hasPartyRole = isLoaded && party && party.partyRole
  const theme = useTheme()

  return (
    <Container>
      {showNotVerifiedNotification && hasPartyRole && (
        <NotificationBar
          type={GlobalNotificationType.WARNING}
          onClose={() => profileStore.setShowPhoneNotVerifiedNotification(false)}
        >
          {!user?.phoneNumber && (
            <Box>
              <Text margin={{right: 1}} inline>
                {/* todo translate */}
                {translate('Please add your phone number and verify it. Go to')}
              </Text>
              {/* todo translate */}
              <TextLink to={`/${getPartyLink(party?.partyRole)}/company/personal-profile`}>
                {translate('Account settings')}
              </TextLink>
            </Box>
          )}
          {user?.phoneNumber && !user?.phoneNumberVerified && (
            <Box>
              <Text margin={{right: 1}} inline>
                {/* todo translate */}
                {translate('Your phone number is not verified') + '.'}
              </Text>
              <TextLink to={`/${getPartyLink(party?.partyRole)}/company/personal-profile`}>
                {/* todo translate */}
                {translate('Verify Phone')}
              </TextLink>
            </Box>
          )}
        </NotificationBar>
      )}
      <Box direction="column">
        <Corner>
          <Logo to="/" />
        </Corner>
        {party?.partyRole === PartyRole.BUYER && (
          <Box justify="start" pad={{left: 12}}>
            <Text
              inline
              size="msmall"
              lineHeight="small"
              color={theme.colors.primaryDark}
              font={theme.font.headings}
              light
            >
              {humanReadablePlan(party?.subscriptionPlan)}
            </Text>
          </Box>
        )}
      </Box>

      <div>{top}</div>

      <Aside>
        <Padding />
        {aside}
        <Spacer />
        <PoweredBy />
        <Footer>
          <FooterLink modal={ModalType.TERMS}>{translate('Terms and conditions')}</FooterLink>
          <FooterLink modal={ModalType.COOKIES}>{translate('Cookie policy')}</FooterLink>
          <FooterLink modal={ModalType.PRIVACY}>{translate('Privacy policy')}</FooterLink>
          <FooterLink modal={ModalType.SECURITY}>{translate('Security')}</FooterLink>
          <FooterLink as="a" onClick={() => window?.HelpCrunch('openChat')} data-cy="footer-support-link">
            {translate('Support')}
          </FooterLink>
        </Footer>
      </Aside>

      <Main>
        <Alerts />
        <PaywallDialog />
        <ErrorBoundary>{children}</ErrorBoundary>
      </Main>
    </Container>
  )
}

export default Layout
