import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import styled from 'styled-components'

const ListEntrySkeleton = () => {
  return <Skeleton height={55}></Skeleton>
}

const StyledBox = styled(Box)`
  width: 10%;
  min-width: 100px;
  max-width: 140px;
`

const ResourceSkeleton = styled(Skeleton)`
  width: 33%;
`

const OverviewSkeleton: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Box direction={'row'} height={'128px'} pad={{top: 1, bottom: 1}}>
        <ResourceSkeleton></ResourceSkeleton>
        <Box width={'10px'}></Box>
        <ResourceSkeleton></ResourceSkeleton>
        <Box width={'10px'}></Box>
        <ResourceSkeleton></ResourceSkeleton>
      </Box>

      <Box direction={'row'} height={'256px'}>
        <Box width={'90%'}>
          <Skeleton height={256} variant={'rectangular'}></Skeleton>
        </Box>
        <StyledBox height={256} pad={{top: 6}} margin={{left: 1}}>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
        </StyledBox>
      </Box>

      <Box height={'256px'}>
        <ListEntrySkeleton></ListEntrySkeleton>
        <ListEntrySkeleton></ListEntrySkeleton>
        <ListEntrySkeleton></ListEntrySkeleton>
      </Box>
    </>
  )
}

export default OverviewSkeleton
