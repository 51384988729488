import React from 'react'
import {autoConvertEnergy, convertEnergy, EnergyUnit} from '../../../../helpers/conversion'
import ResourceSummaryItem, {IProps as IItemProps} from './ResourceSummaryItem'

interface IProps extends React.PropsWithChildren, Omit<IItemProps, 'unit'> {
  sourceUnit?: string
  unitSuffix?: string
}

const EnergySummaryItem: React.FC<IProps> = ({sourceUnit, value, max, unitSuffix, ...props}) => {
  const [convertedValue, convertedUnit] = autoConvertEnergy(value, sourceUnit, 10)
  const convertedMax = max ? convertEnergy(max, sourceUnit, convertedUnit) : null

  return (
    <ResourceSummaryItem
      max={convertedMax}
      value={convertedValue}
      unit={`${convertedUnit}${unitSuffix || ''}`}
      {...props}
    />
  )
}

EnergySummaryItem.defaultProps = {
  sourceUnit: EnergyUnit.MWh,
}

export default EnergySummaryItem
