import React, {useEffect} from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import useLocalization from '../../hooks/useLocalization'
import useStore from 'hooks/useStore'
import {getPartyLink, needsOnboarding} from 'helpers/party'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

const OnboardingGuard: React.FC<React.PropsWithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const party = useAtomValue(partyAtom)
  const {alertStore} = useStore()
  const {translate} = useLocalization()
  const isOnboarding = needsOnboarding(party)
  const onboardingPath = `/${getPartyLink(party?.partyRole)}/onboarding`

  useEffect(() => {
    if (
      party &&
      isOnboarding &&
      !pathname.includes(onboardingPath) &&
      pathname !== `/${getPartyLink(party?.partyRole)}`
    ) {
      alertStore.addInfo(translate('Please complete the onboarding process first'))
    }
  }, [pathname])

  if (party && isOnboarding && !pathname.includes(onboardingPath)) {
    return <Redirect to={onboardingPath} />
  }

  return <>{children}</>
}

export default OnboardingGuard
