import React, {useState} from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading from 'components/Heading'
import KeyFeature from 'components/Platform/KeyFeature'
import useLocalization from 'hooks/useLocalization'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import {SiteProductionTechnology} from 'domain/ISite'
import Link from 'components/Link'
import {ModalType} from 'components/Modal/IModal'
import ModalLink from 'components/Modal/ModalLink'
import useTheme from 'hooks/useTheme'
import {formatDecimal} from 'helpers/format'
import {Tooltip} from '@mui/material'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {useHistory} from 'react-router-dom'

interface IProps extends React.PropsWithChildren {
  action: string
  tecnology?: SiteProductionTechnology
  quantity: number
  unit: string
  title: string
  to?: string
  modal?: ModalType
  modalValue?: string
  disabled?: boolean
  confirmDialogText?: string
}

const CertificateSummaryItem: React.FC<IProps> = ({
  title,
  action,
  tecnology,
  quantity,
  unit,
  to,
  modal,
  modalValue,
  disabled,
  confirmDialogText,
}) => {
  const {translate} = useLocalization()
  const [dialogOpen, setDialogOpen] = useState(false)
  const history = useHistory()

  const handleLinkClick = e => {
    if (confirmDialogText) {
      e.preventDefault()
      setDialogOpen(true)
    }
  }

  return (
    <Box border round direction="column" justify="space-between" align="center" width={40}>
      <Box direction="column" pad={2} gap={1}>
        <Heading style={{textAlign: 'center', minHeight: 42}}>{title}</Heading>
        <Box direction="row" gap={0} align="center" justify="space-around" width="100%">
          <KeyFeature title={translate('Quantity')} unit={unit} value={formatDecimal(quantity, 0)} />

          {tecnology && (
            <KeyFeature
              title={translate('Technology')}
              value={
                <Box margin={0.8}>
                  <TechnologyIconAndName technology={tecnology} />
                </Box>
              }
            />
          )}
        </Box>
      </Box>
      <Box margin={{bottom: 2}}>
        {to ? (
          <Link to={to} onClick={handleLinkClick}>
            <Button height={4}>{action}</Button>
          </Link>
        ) : (
          <Tooltip title={disabled && translate('You cannot trade excess energy from Delivered PPA')} arrow>
            <div>
              <ModalLink modal={modal} value={modalValue}>
                <Button disabled={disabled} height={3}>
                  {action}
                </Button>
              </ModalLink>
            </div>
          </Tooltip>
        )}
      </Box>
      <ConfirmationDialog
        title={confirmDialogText}
        acceptText="Continue"
        declineText="Back"
        onAccept={() => {
          history.push(to)
          setDialogOpen(false)
        }}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </Box>
  )
}

export default CertificateSummaryItem
