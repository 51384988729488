import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import TextArea, {IProps as ITextAreaProps} from '../TextArea'

export type IProps = IWrapperProps<ITextAreaProps>

const FormTextArea: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <TextArea />
    </FormInputWrapper>
  )
}

export default FormTextArea
