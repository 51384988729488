import ILocation from './ILocation'
import {OnboardingStatus} from './IParty'
import {CurrencyCode} from './IPrice'
import IProduct from './IProduct'
import {CertificateTech} from './IRegoCertificate'
import ISite, {SiteState} from './ISite'

export interface IOnboardProduct {
  name: string
  description: string
  technology: string
  price: number
  firstCertificate: string
  lastCertificate: string
  currency: CurrencyCode
}

export interface ISellerOnboardSite {
  name: string
  technology: CertificateTech
  siteState: SiteState
  capacityMwac: number
  operationStart: Date
  lifetimeYears: number
  meterReference: string
  location: ILocation
  generatedCertificates: number
  profileInterval: {
    year: number
    month: string
  }
}

export type ISellerOnboardState =
  | {onboardingStatus: OnboardingStatus.NOT_STARTED}
  | {onboardingStatus: OnboardingStatus.SELLER_STARTED}
  | {onboardingStatus: OnboardingStatus.SELLER_SITE_CREATED; site: ISellerOnboardSite}
  | {onboardingStatus: OnboardingStatus.SELLER_PRODUCT_CREATED; site: ISellerOnboardSite; product: IOnboardProduct}
  | {onboardingStatus: OnboardingStatus.COMPLETED; site: ISellerOnboardSite; product: IOnboardProduct}

export const getOnboardSite = (state: ISellerOnboardState): ISellerOnboardSite | null => {
  switch (state.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
      return null
    case OnboardingStatus.SELLER_STARTED:
      return null
    case OnboardingStatus.SELLER_SITE_CREATED:
      return state.site
    case OnboardingStatus.SELLER_PRODUCT_CREATED:
      return state.site
    case OnboardingStatus.COMPLETED:
      return state.site
  }
}

export const getOnboardProduct = (state: ISellerOnboardState): IOnboardProduct | null => {
  switch (state.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
      return null
    case OnboardingStatus.SELLER_STARTED:
      return null
    case OnboardingStatus.SELLER_SITE_CREATED:
      return null
    case OnboardingStatus.SELLER_PRODUCT_CREATED:
      return state.product
    case OnboardingStatus.COMPLETED:
      return state.product
  }
}
