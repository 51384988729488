import {Atom, PrimitiveAtom, useAtomValue, useSetAtom} from 'jotai'
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

interface Filter {
  single?: Record<string, PrimitiveAtom<any>>
  multi?: Record<string, PrimitiveAtom<any>>
}

const useFilters = ({single, multi}: Filter) => {
  const location = useLocation()
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(location.search)
  const singleParams = Object.keys(single).map(name => ({
    urlValue: urlSearchParams.get(name),
    name: name,
    setter: useSetAtom(single[name]),
    value: useAtomValue(single[name]),
  }))
  const multiParams = Object.keys(multi).map(name => ({
    urlValue: urlSearchParams.getAll(name),
    name: name,
    setter: useSetAtom(multi[name]),
    value: useAtomValue(multi[name]),
  }))

  const all = [...singleParams, ...multiParams]
  const deps = all.map(({value}) => value)

  useEffect(() => {
    all.forEach(({urlValue, name, setter}) => {
      if (Array.isArray(urlValue) && urlValue.filter(Boolean).length > 0) {
        setter(urlValue)
      }
      if (!Array.isArray(urlValue) && urlValue) {
        setter(isNumeric(urlValue) ? +urlValue : urlValue)
      }
    })
  }, [])

  useEffect(() => {
    all.forEach(({name, value}) => {
      if (Array.isArray(value) && value.length > 0) {
        urlSearchParams.delete(name)
        value.forEach(v => urlSearchParams.append(name, v))
      } else {
        urlSearchParams.set(name, `${value}`)
      }
    })

    history.push({search: urlSearchParams.toString()})
  }, deps)
}

export default useFilters
