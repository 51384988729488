import styled from 'styled-components'
import ITheme from '../../theme/ITheme'
import {NavLink} from 'react-router-dom'
import React from 'react'

const Wrap = styled.div`
  display: flex;
  width: 100%;
  padding-right: ${props => (props.theme as ITheme).tileSize * 2}px;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  background-color: ${props =>
    props.theme.colorBindings.globalBackground &&
    (props.theme as ITheme).colors[props.theme.colorBindings.globalBackground]};
`

export const TopNavigationLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => (props.theme as ITheme).font.secondaryFont};
  line-height: 18px;
  color: ${props => (props.theme as ITheme).colors.clay};
  padding: 6px 12px;
  margin-right: 4px;
  font-size: ${props => (props.theme as ITheme).topNavigationFontSize}px;

  &.active {
    color: ${props => (props.theme as ITheme).colors.secondary};
    border-bottom: 2px solid black;
  }

  &:hover {
    color: ${props => (props.theme as ITheme).colors.secondary};
  }
`

const TopNavigation = ({children}) => {
  return <Wrap>{children}</Wrap>
}

export default TopNavigation
