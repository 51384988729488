import React from 'react'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import IProduct from 'domain/IProduct'
import useLocalization from 'hooks/useLocalization'
import ListItem, {Badge} from 'components/ListItem'
import {getHoverKey} from 'helpers/product'
import useServices from 'hooks/useServices'
import ConnectedHoverable from 'components/ConnectedHoverable'
import useTheme from 'hooks/useTheme'
import {getProductCountry} from 'helpers/location'

interface IProductItem {
  product: IProduct
  to?: string
  disabled?: boolean
}

const SimpleProductItem: React.FC<IProductItem> = ({product, to, disabled}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()

  return (
    <ConnectedHoverable hoverKey={getHoverKey(product)}>
      <ListItem
        key={product.id}
        minWidth="350px"
        coverHeight={170}
        to={to || ''}
        title={product.name}
        noHover={disabled}
        noPanel
        onClick={e => disabled && e.preventDefault()}
        defaultCoverColor={theme.colors.technology[product.site.productionTechnology]}
        country={getProductCountry(product, translate)}
        coverContent={
          <>
            <Badge>
              <TechnologyIconAndName technology={product.site.productionTechnology} />
            </Badge>
            {product.seller && <Badge>{product.seller.name}</Badge>}
          </>
        }
        coverImage={contentService.getAttachmentURL(product.image)}
      ></ListItem>
    </ConnectedHoverable>
  )
}

export default SimpleProductItem
