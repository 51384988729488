import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import ITheme from '../theme/ITheme'

const Container = styled(Link)<{size: number; src: string; color?: string}>`
  width: ${props => (props.theme as ITheme).tileSize * props.size}px;
  height: ${props => (props.theme as ITheme).tileSize * props.size}px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${props => (props.theme as ITheme).colors.light1};
  position: relative;
  background-position: center center;
  background-size: cover;
  ${props => props.src && `background-image: url("${props.src}");`}
  ${props => props.color && `background-color: ${props.color};`}
`

interface IProps extends React.PropsWithChildren {
  linkTo?: string
  image: string
  size?: number
  fallbackColor?: string
}

const Thumbnail: React.FC<IProps> = ({linkTo, image, size, fallbackColor}) => {
  return <Container to={linkTo} size={size} src={image} color={fallbackColor} />
}

Thumbnail.defaultProps = {
  size: 16,
}

export default Thumbnail
