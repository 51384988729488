import React from 'react'
import useLocalization from 'hooks/useLocalization'
import ModalHeader from 'components/Modal/ModalHeader'
import Box from 'components/Box'
import Button from 'components/Button'
import ModalCloser from 'components/Modal/ModalCloser'
import useScreenSize, {ScreenBreakpoint} from 'hooks/useScreenSize'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Heading from 'components/Heading'
import ContractDownloadLinks from 'components/Platform/Portfolio/ContractDownloadLinks'
import ContractHtmlPreview from 'components/Platform/Portfolio/ContractHtmlPreview'
import OfferProduct from 'components/Platform/Portfolio/Offer/OfferDesignerCertificate/OfferProduct'
import Text from 'components/Text'
import {OfferState} from 'domain/IOffer'
import {ContractState} from 'domain/IContract'
import ICertificateOffer from 'domain/ICertificateOffer'
import Label from 'components/Label'
import ValueWithUnit from 'components/ValueWithUnit'

interface IProps extends React.PropsWithChildren {
  offer: ICertificateOffer
  startSigning(): void
}

const Preview: React.FC<IProps> = ({offer, startSigning}) => {
  const {translate} = useLocalization()
  const screenSize = useScreenSize()
  const signable = offer?.state === OfferState.CONTRACT_SENT && offer?.contract?.state === ContractState.OFFER

  return (
    <>
      <ModalHeader
        title={signable ? translate('Sign the contract') : translate('Contract')}
        description={
          signable
            ? translate('Please review and accept the contract')
            : translate('Please review the details of solution contract')
        }
      >
        <Box direction="row" gap={2}>
          <ModalCloser>
            <Button variant="secondary" type="button">
              {translate('Cancel')}
            </Button>
          </ModalCloser>
          {signable && (
            <Button onClick={startSigning} type="button">
              {translate('I agree with the contract and I sign it')}
            </Button>
          )}
        </Box>
      </ModalHeader>

      <ResponsiveGrid
        gap={3}
        templateColumns={{
          [ScreenBreakpoint.Small]: '1fr',
          [ScreenBreakpoint.Large]: '1fr 2.5fr',
        }}
      >
        <Box pad={2} round border direction="column" gap={2} $maxHeight="128px">
          <Label text={translate('Name')}>
            <Text size="large" semibold lineHeight="small">
              {offer.name}
            </Text>
          </Label>
          <Label text={translate('Estimated month volume')}>
            <ValueWithUnit value={offer?.estimatedVolume} unit="CERTIFICATES" />
          </Label>
        </Box>

        <Box>
          <OfferProduct product={offer.product} currency={offer.currency} />
        </Box>

        <Box pad={2} border round style={screenSize >= ScreenBreakpoint.Large ? {gridColumn: 'span 2'} : null}>
          <Heading margin={{bottom: 2}}>{translate('Agreement')}</Heading>
          <ContractDownloadLinks contract={offer?.contract} />
          <ContractHtmlPreview id={offer?.contract?.id} />
        </Box>
      </ResponsiveGrid>
    </>
  )
}

export default Preview
