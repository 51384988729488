import React, {useContext, useEffect, useState} from 'react'

export enum ScreenBreakpoint {
  Small = 0,
  Medium = 768,
  Large = 1366,
  MLarge = 1665,
  XLarge = 1965,
}

const getCurrentBreakpoint = (): ScreenBreakpoint => {
  for (const breakpoint of [
    ScreenBreakpoint.XLarge,
    ScreenBreakpoint.MLarge,
    ScreenBreakpoint.Large,
    ScreenBreakpoint.Medium,
    ScreenBreakpoint.Small,
  ]) {
    if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
      return breakpoint
    }
  }

  return ScreenBreakpoint.Large
}

const ScreenSizeContext = React.createContext(ScreenBreakpoint.Large)

export const ScreenSizeProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [current, setCurrent] = useState<ScreenBreakpoint>(getCurrentBreakpoint())

  const detectCurrent = () => {
    setCurrent(getCurrentBreakpoint())
  }

  useEffect(() => {
    window.addEventListener('resize', detectCurrent, false)

    return () => window.removeEventListener('resize', detectCurrent)
  }, [])

  return <ScreenSizeContext.Provider value={current}>{children}</ScreenSizeContext.Provider>
}

const useScreenSize = (): ScreenBreakpoint => {
  return useContext(ScreenSizeContext)
}

export default useScreenSize
