import {useContext} from 'react'
import {MobXProviderContext} from 'mobx-react'
import RootStore from '../store'

const useStore = (): RootStore => {
  const store = useContext(MobXProviderContext)

  if (!store) {
    throw new Error('Store was not loaded')
  }

  return store as RootStore
}

export default useStore
