import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 10%;
  min-width: 100px;
  max-width: 140px;
`

interface IProps extends React.PropsWithChildren {
  withoutFilters?: boolean
}

const LoadingSkeleton: React.FC<IProps> = ({withoutFilters}) => {
  return (
    <>
      {!withoutFilters && (
        <Box direction="row" height={26.5} margin={{top: -5, bottom: -3}}>
          <Skeleton width="90%" />
        </Box>
      )}

      <Box direction="column" gap={1} margin={{top: withoutFilters ? 3 : 0}}>
        {[...Array(5)].map((_, index) => (
          <Box direction="row" gap={2} key={index}>
            <Skeleton height={withoutFilters ? 170 : 293} width="33%" variant="rounded" />
            <Skeleton height={withoutFilters ? 170 : 293} width="33%" variant="rounded" />
            <Skeleton height={withoutFilters ? 170 : 293} width="33%" variant="rounded" />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default LoadingSkeleton
