import React, {useEffect, useState} from 'react'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import useLocalization from 'hooks/useLocalization'
import Input from 'components/Input'
import {createTheme, Grid, TextField, ThemeProvider} from '@mui/material'
import Select from './Select'
import {autoCeilNumber, autoFloorNumber, getChangeEventValue} from 'helpers/misc'
import Chart, {ChartType, ILine, StackType, getMaxValue, getMinValue} from 'components/Chart'
import {round} from 'lodash-es'
import {formatNumber} from 'helpers/format'
import Label from './Label'
import ValueWithUnit from './ValueWithUnit'
import {getCurrentYear, getMonthDays, getMonthNumber, getPrevMonth} from 'helpers/date'
import useFormContext from 'hooks/useFormContext'
import useScreenSize, {ScreenBreakpoint} from 'hooks/useScreenSize'

const ConsumptionChart: React.FC<{totalConsumption: object; production?: boolean; contrast?: boolean}> = ({
  totalConsumption,
  production,
  contrast,
}) => {
  const theme = useTheme()

  if (!totalConsumption) {
    return null
  }

  const labels = Object.keys(totalConsumption).map(name => name) || []

  const lines: ILine[] = []

  lines.push({
    key: production ? 'production' : 'consumption',
    label: production ? 'Production' : 'Consumption',
    color: contrast ? theme.colors.info : theme.colors.green1,
    data: Object.values(totalConsumption).map(value => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: true,
  })

  return (
    <Chart
      toolbar={{vertical: true}}
      labels={labels}
      lines={lines}
      dark
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

enum ConsumptionShapeType {
  OFFICE = 'Office',
  OTHER = 'Data center, Factory, Other',
}

enum ProductionShapeType {
  SOLAR = 'Solar',
  OTHER = 'Wind, Hydro, Other',
}

// Generated with chatgpt
const PARABOLA_RATIOS = [
  0.0, 0.5, 1.0, 1.75, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.25, 8.5, 8.5, 8.25, 7.5, 6.5, 5.5, 4.5, 3.5, 2.5, 1.75, 1.0, 0.5,
  0.0,
]

const generateValuesByShape = (
  totalConsumption: number,
  shape: ConsumptionShapeType | ProductionShapeType,
): Consumption => {
  const arrayOfHours = Array.from({length: 24}, (v, k) => k)

  if (shape === ConsumptionShapeType.OFFICE || shape === ProductionShapeType.SOLAR) {
    return arrayOfHours.reduce((acc, cur) => {
      acc[cur] = formatNumber(totalConsumption * (PARABOLA_RATIOS[cur] / 100))
      return acc
    }, {})
  }

  return arrayOfHours.reduce((acc, cur) => {
    acc[cur] = formatNumber(totalConsumption / 24)
    return acc
  }, {})
}

type Consumption = {
  [key: number]: number // from 0 to 23
}

type ConsumptionProfile = {
  hourlyVolumes: {
    hour: number
    volumeMwh: number
  }[]
}

interface IProps extends React.PropsWithChildren {
  onChange?: (value: ConsumptionProfile) => void
  production?: boolean
  onboarding?: boolean
}

const DEFAULT_PARABOLA = 200

const ProfileBuilder: React.FC<IProps> = ({onChange, production, onboarding}) => {
  const {watch} = useFormContext() || {}
  const screenSize = useScreenSize()
  const [totalConsumption, setTotalConsumption] = useState(DEFAULT_PARABOLA)
  const defaultShape = production ? ProductionShapeType.SOLAR : ConsumptionShapeType.OFFICE
  const shapeToUse = production ? ProductionShapeType : ConsumptionShapeType
  const [shape, setShape] = useState(defaultShape)
  const [consumption, setConsumption] = useState<Consumption>(generateValuesByShape(DEFAULT_PARABOLA, defaultShape))
  const theme = useTheme()
  const {translate} = useLocalization()

  const getTotalConsumption = () => {
    const interval = watch ? watch('interval') : undefined

    if (interval) {
      const month = interval?.month
      const year = interval?.year

      return getMonthDays(getMonthNumber(month), year) * totalConsumption
    } else {
      return getMonthDays(getMonthNumber(getPrevMonth()), getCurrentYear()) * totalConsumption
    }
  }

  const onboardingStyles = {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    border: `1px solid ${theme.colors.secondary}`,
    borderRadius: 4,
  }

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.colors.primary,
      },
      secondary: {
        main: theme.colors.secondary,
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: onboarding && theme.colors.secondary,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: onboarding && theme.colors.secondary,
          },
        },
      },
    },
  })

  const handleInputChange = (e, key) => {
    const value = round(+getChangeEventValue(e), 2)
    setConsumption({...consumption, [key]: value})
    setTotalConsumption(round(totalConsumption + value - consumption[key], 2))
  }

  const handletotalConsumptionChange = e => {
    const value = +getChangeEventValue(e)
    setTotalConsumption(value)
    setConsumption(generateValuesByShape(value, shape))
  }

  const handleShapeChange = e => {
    const value = getChangeEventValue(e)
    const shape = shapeToUse[value]
    setShape(shape)
    setConsumption(generateValuesByShape(totalConsumption, shape))
  }

  useEffect(() => {
    onChange &&
      onChange({
        hourlyVolumes: Object.keys(consumption).map(key => ({hour: +key, volumeMwh: +consumption[key]})),
      })
  }, [consumption])

  return (
    <ThemeProvider theme={muiTheme}>
      <Box direction="column">
        <Box direction="row" gap={2}>
          <Box width="50%">
            <Input
              type="number"
              value={totalConsumption}
              onChange={handletotalConsumptionChange}
              label={translate(`Total ${production ? 'production' : 'consumption'} mWh for each day`)}
              style={onboarding && onboardingStyles}
            />
          </Box>
          <Box width="50%">
            <Select
              label={production ? translate('Production technology') : translate('Consumption shape')}
              options={Object.keys(shapeToUse).map(option => ({
                label: shapeToUse[option],
                value: option,
              }))}
              onChange={handleShapeChange}
              style={onboarding && onboardingStyles}
            />
          </Box>
        </Box>
        <Box margin={{bottom: 2}}>
          <Label text={translate('Total production for the interval')}>
            <ValueWithUnit size="large" value={getTotalConsumption()} />
          </Label>
        </Box>
        <ConsumptionChart totalConsumption={consumption} production={production} contrast={onboarding} />

        <Grid container spacing={2} mt={0.5} columns={24}>
          {Array.from({length: 24}, (v, k) => k).map(day => (
            <Grid item key={day} xs={onboarding ? 6 : screenSize > ScreenBreakpoint.Large ? 4 : 3}>
              <TextField
                type="number"
                size="small"
                color={onboarding ? 'secondary' : 'primary'}
                value={consumption[day]}
                label={`${day}:00`.padStart(5, '0')}
                onChange={e => handleInputChange(e, day)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default ProfileBuilder
