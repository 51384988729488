import useTheme from 'hooks/useTheme'
import React, {useState} from 'react'
import styled from 'styled-components'
import CloseButton from './CloseButton'

export const NotificationContainer = styled.div<{color: string}>`
  z-index: 1;
  position: fixed;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  padding: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 -1px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledCloseButton = styled(CloseButton)`
  margin-left: auto;
`

const generalColor = '##c4def3'
const warningColor = '#F6D5D5'

export enum GlobalNotificationType {
  GENERAL = 'GENERAL',
  WARNING = 'WARNING',
}

interface IProps extends React.PropsWithChildren {
  type: GlobalNotificationType
  onClose: () => void
}

const GlobalNotificationBar: React.FC<IProps> = ({children, type, onClose}) => {
  const theme = useTheme()
  const [isActive, setIsActive] = useState(true)
  const color = type === GlobalNotificationType.GENERAL ? generalColor : warningColor

  const handleClose = () => {
    onClose()
    setIsActive(false)
  }

  if (!isActive) {
    return null
  }

  return (
    <NotificationContainer color={color}>
      {children}
      <StyledCloseButton onClick={handleClose}></StyledCloseButton>
    </NotificationContainer>
  )
}

export default GlobalNotificationBar
