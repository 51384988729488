import React from 'react'
import useLocalization from '../../../../../hooks/useLocalization'
import ResponsiveGrid from '../../../../ResponsiveGrid'
import {ScreenBreakpoint} from '../../../../../hooks/useScreenSize'
import Heading from '../../../../Heading'
import FormInput from '../../../../Form/FormInput'
import SiteStateSelect from './SiteStateSelect'
import SiteShapeSelect from './SiteShapeSelect'
import ProductionTechnologySelect from './ProductionTechnologySelect'
import FormTextArea from '../../../../Form/FormTextArea'
import ISite, {SiteModel, SiteType} from '../../../../../domain/ISite'
import Box from 'components/Box'
import SiteProfileSourceData from './SiteProfileSourceData'
import FormTextEditor from '../../../../Form/FormTextEditor'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  siteType?: SiteType
  site: ISite
}

const SiteFormContentNew: React.FC<IProps> = ({siteType, site}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const isConsumptionSite = siteType === SiteType.CONSUMPTION

  return (
    <ResponsiveGrid gap={2} columns={{[ScreenBreakpoint.Small]: 1, [ScreenBreakpoint.Large]: 2}}>
      <Box round border pad={2}>
        <Heading margin={{bottom: 2}}>{translate('General site data')}</Heading>
        <FormInput name="siteType" type="hidden" defaultValue={siteType} />
        <FormInput name="siteModel" type="hidden" defaultValue={SiteModel.PHYSICAL} />
        <FormInput name="name" label={translate('Name')} required defaultValue={null} />
        {site?.siteNmi && <FormInput name="siteNmi" label={translate('NMI')} />}
        {isConsumptionSite ? (
          <SiteShapeSelect name="siteShape" required />
        ) : (
          <ProductionTechnologySelect name="productionTechnology" required />
        )}
        <SiteStateSelect name="state" required />
        <FormTextEditor name="description" label={translate('Description')} />
        <FormInput type="date" name="operationsStart" label={translate('Operations start')} defaultValue={null} />
        <FormInput type="date" name="operationsEnd" label={translate('Operations end')} defaultValue={null} />
        <FormTextArea name="location.details" label={translate('Location details')} />
      </Box>
      <Box round border pad={2}>
        <Box>
          <Heading margin={{bottom: 2}}>
            {translate(`${isConsumptionSite ? 'Consumption' : 'Production'} data`)}
          </Heading>
          <SiteProfileSourceData isConsumptionSite={isConsumptionSite} siteType={siteType} site={site} />
        </Box>
      </Box>
    </ResponsiveGrid>
  )
}

SiteFormContentNew.defaultProps = {
  siteType: SiteType.PRODUCTION,
}

export default SiteFormContentNew
