import React from 'react'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import Button from 'components/Button'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import {Container} from '../Layout'
import {useAtom, useAtomValue} from 'jotai'
import VerticalStack from 'components/VerticalStack'
import {partyAtom} from 'atoms/party'
import {buyerOnboardingAtom, buyerSiteDashboardAtom} from 'atoms/onboarding'
import {OnboardingStatus} from 'domain/IParty'
import Heading from 'components/Heading'
import DashboardLoadingSkeleton from 'components/Platform/Portfolio/Site/DashboardLoadingSkeleton'
import useStore from 'hooks/useStore'
import useServices from 'hooks/useServices'
import {useHistory} from 'react-router-dom'
import {getHumanReadableState} from 'helpers/site'
import SiteSummaryChart from '../SIteSummaryChart'
import {formatDecimal} from 'helpers/format'
import CenteredLoader from 'components/CenteredLoader'

interface IProps extends React.PropsWithChildren {
  onSuccess?: () => void
}

const HeroContainer: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <Container>
      <Box justify="center" align="center" pad={{top: 15}}>
        {children}
      </Box>
    </Container>
  )
}

const ReviewOnboarding: React.FC<IProps> = () => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const [party, setParty] = useAtom(partyAtom)
  const {alertStore} = useStore()
  const {v2ContentService} = useServices()
  const history = useHistory()
  const {data: onboardResult, loading: onbLoading, error: onbError} = useAtomValue(buyerOnboardingAtom)
  const {data: siteDashboard, loading: siteDashbdLoading, error: siteDashbError} = useAtomValue(buyerSiteDashboardAtom)

  const handleComplete = async () => {
    try {
      const res = await v2ContentService.completeBuyerOnboarding(party.id)
      const updated = {
        ...party,
        onboardingStatus: res.onboardingStatus,
      }
      setParty(updated)
      history.push('/consumer/portfolio/overview')
    } catch (ex) {
      alertStore.addError('Failed to complete onboarding', ex?.correlationId, ex?.message)
    }
  }

  if (onbError) {
    return (
      <HeroContainer>
        <Heading size="xlarge">{translate('Something went wrong')}</Heading>
      </HeroContainer>
    )
  }

  if (onbLoading) {
    return (
      <HeroContainer>
        <CenteredLoader size="medium" color={theme.colors.common.white} />
      </HeroContainer>
    )
  }

  if (onboardResult.onboardingStatus !== OnboardingStatus.BUYER_PROFILE_UPLOADED) {
    if (onboardResult.onboardingStatus === OnboardingStatus.COMPLETED) {
      return (
        <HeroContainer>
          <Heading size="xlarge">{translate('Onboarding already completed')}</Heading>
        </HeroContainer>
      )
    }
    console.error(`Invalid onboarding state ${onboardResult.onboardingStatus}`)
    // TODO: redirect to correct screen for the given status?
    return (
      <HeroContainer>
        <Heading size="xlarge">{translate('You need to complete the other steps first')}</Heading>
      </HeroContainer>
    )
  }

  const site = onboardResult.site
  const volume = onboardResult?.uploadedProfile?.volumeMwh

  return (
    <Container>
      <Text size="xxxlarge" color={theme.colors.common.black} margin={{bottom: 2}}>
        3. {translate('Review and complete')}
      </Text>
      <HorizStack justify="space-between">
        <VerticalStack margin={{top: 1}}>
          <Text uppercase> {translate('Site name')} </Text>
          <Text bold data-cy="name">
            {site.name}
          </Text>
        </VerticalStack>
        <VerticalStack margin={{top: 1}}>
          <Text uppercase> {translate('Status')} </Text>
          <Text bold data-cy="status">
            {getHumanReadableState(site.state, translate)}
          </Text>
        </VerticalStack>

        <VerticalStack margin={{top: 1}}>
          <Text uppercase> {translate('Volume')} </Text>
          <Text bold data-cy="volume">
            {formatDecimal(volume)}
          </Text>
        </VerticalStack>
      </HorizStack>
      <VerticalStack margin={{top: 1}}>
        <Text uppercase> {translate('Site description')} </Text>
        <Text bold data-cy="description">
          {site.description}
        </Text>
      </VerticalStack>

      <Box
        margin={{top: 3}}
        pad={2}
        round={2}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
        }}
      >
        {siteDashbdLoading ? (
          <DashboardLoadingSkeleton />
        ) : siteDashbError ? (
          <Heading size="medium">Failed to load site summary chart</Heading>
        ) : (
          <SiteSummaryChart timeseries={siteDashboard.timeseries} siteName={siteDashboard.siteName} />
        )}
      </Box>

      <HorizStack justify="flex-end" margin={{top: 4}}>
        {/* <CancelButton type="button" onClick={() => history.push('/consumer/onboarding/upload-consumption')}>
          {translate('Back')}
        </CancelButton> */}
        <Button onClick={handleComplete} variant="outlined" large contrast data-cy="complete">
          <Text color={theme.colors.common.black} semibold uppercase font={theme.font.secondaryFont}>
            {translate('Complete')}
          </Text>
        </Button>
      </HorizStack>
    </Container>
  )
}

export default ReviewOnboarding
