import React from 'react'
import Marker, {IProps as IMarkerProps} from './Marker'
import {SiteProductionTechnology} from '../../domain/ISite'
import bioenergyMarker from './markers/bioenergy.svg'
import geothermalMarker from './markers/geothermal.svg'
import hydroMarker from './markers/hydro.svg'
import hydrogenMarker from './markers/hydrogen.svg'
import solarMarker from './markers/solar.svg'
import waveMarker from './markers/wave.svg'
import windMarker from './markers/wind.svg'
import gasMarker from './markers/gas.svg'
import batteryMarker from './markers/gas.svg'

export interface IProps extends React.PropsWithChildren, Omit<IMarkerProps, 'image'> {
  technology: SiteProductionTechnology
}

const TECHNOLOGY_MARKERS: {[key in SiteProductionTechnology]: string} = {
  BIOENERGY: bioenergyMarker,
  GEOTHERMAL: geothermalMarker,
  HYDRO: hydroMarker,
  HYDROGEN: hydrogenMarker,
  SOLAR: solarMarker,
  WAVE: waveMarker,
  WIND: windMarker,
  GAS: gasMarker,
  BATTERY: batteryMarker,
}

const TechnologyMarker: React.FC<IProps> = ({technology, ...props}) => {
  return <Marker {...props} image={TECHNOLOGY_MARKERS[technology] || solarMarker} />
}

export default TechnologyMarker
