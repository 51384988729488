import {useLocation} from 'react-router-dom'

const useTabs = (slug?: string) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const tabSlug = urlSearchParams.get(slug)

  return {
    currentTab: tabSlug,
  }
}

export default useTabs
