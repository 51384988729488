import {atom} from 'jotai'
import {atomWithCompare, loadable} from './utils'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import dayjs from 'dayjs'
import {getMonthNumber, lastDayOfMonth} from 'helpers/date'
import {atomWithRefresh} from 'jotai/utils'
import {ISubscriptionPlan} from 'domain/IParty'

const defaultDate = dayjs().subtract(1, 'month')

export const kpiIntervalAtom = atomWithCompare({
  month: defaultDate.format('MMMM'),
  year: defaultDate.year(),
})

export const greenCertsDateFilterAtom = atomWithCompare({
  month: defaultDate.format('MMMM'),
  year: defaultDate.year(),
  dayOfMonth: undefined,
})

export const contractedVolumesDateFilterAtom = atomWithCompare({
  month: defaultDate.format('MMMM'),
  year: defaultDate.year(),
})

export const performanceFilterAtom = atomWithCompare({
  startMonth: defaultDate.format('MMMM'),
  startYear: defaultDate.year(),
  endMonth: defaultDate.format('MMMM'),
  endYear: defaultDate.year(),
})

export const greenCertificatesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const dateFilter = get(greenCertsDateFilterAtom)

    const data = await services.v2ContentService.getGreenCertificatesData(
      party?.id,
      dateFilter.year,
      dateFilter.month,
      dateFilter.dayOfMonth,
    )

    return data
  }),
)

export const contractedVolumesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)
    const dateFilter = get(contractedVolumesDateFilterAtom)

    const data = await services.v2ContentService.getPorfolioContractedVolumes(
      party?.id,
      interval,
      dateFilter.year,
      dateFilter.month,
    )

    return data
  }),
)

export const coverageOverviewAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getCoverageOverview(party?.id, interval)

    return data
  }),
)

export const performanceAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const interval = get(intervalAtom)
    const party = get(partyAtom)
    const filter = get(performanceFilterAtom)
    const startDate = `${filter.startYear}-${getMonthNumber(filter.startMonth) + 1}-01`
    const endDate = `${filter.endYear}-${getMonthNumber(filter.endMonth) + 1}-${lastDayOfMonth(
      getMonthNumber(filter.endMonth) + 1,
      filter.endYear,
    )}`

    const data = await services.v2ContentService.getFinancialPerformance(party?.id, startDate, endDate, interval)

    return data
  }),
)

export const kpiAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const interval = get(kpiIntervalAtom)
    const party = get(partyAtom)

    const data =
      party?.subscriptionPlan === ISubscriptionPlan.BASIC
        ? await services.v2ContentService.getBuyerPartialKPIs(party?.id, interval.month, interval.year)
        : await services.v2ContentService.getBuyerKPIs(party?.id, interval.month, interval.year)

    return data
  }),
)

export const kpiTargetsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getKPITargets(party?.id)

    return data
  }),
)
