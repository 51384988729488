import React, {PropsWithChildren} from 'react'
import greenEnergyIcon from '../assets/icons/green-energy.svg'
import Box from './Box'
import Heading from './Heading'
import Text from './Text'
import Helmet from './Helmet'

const Error: React.FC<PropsWithChildren<{title?: string}>> = ({title, children}) => {
  return (
    <Helmet title={title}>
      <Box pad={4} direction="column" align="center">
        <img src={greenEnergyIcon} style={{maxWidth: 200}} alt="Green energy icon" />

        {title && <Heading margin={{top: 4}}>{title}</Heading>}
        <Text>{children}</Text>
      </Box>
    </Helmet>
  )
}

export default Error
