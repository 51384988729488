import React, {useContext} from 'react'

export const FormFieldNamePrefixContext = React.createContext<string>('')

export function useFormFieldName(name: string) {
  const prefix = useContext(FormFieldNamePrefixContext)

  return `${prefix}${name}`
}

const FormFieldNamePrefixProvider: React.FC<React.PropsWithChildren<{value: string}>> = ({value, children}) => {
  const newValue = useFormFieldName(value)

  return <FormFieldNamePrefixContext.Provider value={newValue}>{children}</FormFieldNamePrefixContext.Provider>
}

export default FormFieldNamePrefixProvider
