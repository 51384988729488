import React from 'react'
import Table, {TableValue} from 'components/Table'
import {IPerformanceContract} from 'domain/IContract'
import useLocalization from 'hooks/useLocalization'
import {LinkButton} from 'components/Button'
import Link from 'components/Link'
import {CurrencyCode} from 'domain/IPrice'

interface IProps extends React.PropsWithChildren {
  contracts: IPerformanceContract[]
  currency: CurrencyCode
}

const PerformanceTable: React.FC<IProps> = ({contracts, currency}) => {
  const {translate} = useLocalization()

  return (
    <Table<IPerformanceContract>
      tableName={translate('Contract performance')}
      highlightOnHover
      data={contracts}
      linkTo={contract => `/consumer/portfolio/external-contracts/${contract.id}`}
      columns={[
        {
          title: translate('Name'),
          accessor: contract => contract.name,
          render: (name: string) => (
            <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
              {name}
            </LinkButton>
          ),
        },
        {
          title: translate('PPA price'),
          accessor: (contract: IPerformanceContract) => contract.price,
          render: (price: number) => <TableValue value={price} currency={currency} unit="mwh" />,
        },
        {
          title: translate('Avg. market price'),
          accessor: (contract: IPerformanceContract) => contract.averageMarketPrice,
          render: (averageMarketPrice: number) => (
            <TableValue nowrap value={averageMarketPrice} currency={currency} unit="mwh" />
          ),
        },
        {
          title: translate('Total price'),
          accessor: (contract: IPerformanceContract) => contract.totalPrice,
          render: (totalPrice: number) => <TableValue nowrap value={totalPrice} currency={currency} />,
        },
        {
          title: translate('Total profit'),
          accessor: (contract: IPerformanceContract) => contract.totalProfit,
          render: (totalProfit: number) => <TableValue nowrap value={totalProfit} currency={currency} />,
        },
        {
          title: translate('Profit'),
          accessor: (contract: IPerformanceContract) => contract.averageProfit,
          render: (averageProfit: number) => <TableValue value={averageProfit} currency={currency} unit="mwh" />,
        },
        {
          title: translate('Total volume'),
          accessor: (contract: IPerformanceContract) => contract.volumeMwh,
          render: (volumeMwh: number) => <TableValue value={volumeMwh} unit="mwh" />,
        },
      ]}
    />
  )
}

export default PerformanceTable
