import React, {useEffect} from 'react'
import FullscreenLoader from '../components/FullscreenLoader'
import useLocalization from '../hooks/useLocalization'
import useServices from '../hooks/useServices'

const Logout = () => {
  const {authenticationService} = useServices()
  const {translate} = useLocalization()

  useEffect(() => {
    authenticationService
      .signOut()
      .catch(() => null)
      .then(() => {
        window.location.href = '/'
      })
  })

  return <FullscreenLoader>{translate('Logging out..')}</FullscreenLoader>
}

export default Logout
