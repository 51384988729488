import styled from 'styled-components'
import React from 'react'
import Text from '../Text'
import useTheme from 'hooks/useTheme'
import {Tooltip} from '@mui/material'

const Bubble = styled.div<{color: string; vertical?: boolean}>`
  min-width: ${props => (props.vertical ? '8px' : '12px')};
  height: ${props => (props.vertical ? '8px' : '12px')};
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: ${props => (props.vertical ? '4px' : '6px')};
  margin-left: ${props => (props.vertical ? '0px' : '4px')};
`

const Container = styled.div<{clickable?: boolean; selected?: boolean; vertical?: boolean}>`
  margin-right: 1px;
  font-family: ${props => props.theme.font.secondaryFont};
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 100px;
  min-width: 100px;
  font-size: 12px;
  min-height: 18px;
  border: ${props => `1px solid ${props.theme.colors.lightGrey}`};
  color: ${props => props.theme.colors.accent};
  border-radius: 16px;
  white-space: nowrap;
  border-color: ${props => props.selected && props.theme.colors.secondary};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  height: ${props => !props.vertical && '30px'};

  &:hover {
    border-color: ${props => props.clickable && props.theme.colors.primary};
    border-color: ${props => props.selected && props.theme.colors.secondary};
  }
`

interface IProps extends React.PropsWithChildren {
  color: string
  onClick?: () => void
  selected?: boolean
  vertical?: boolean
}

const LegendItem: React.FC<IProps> = ({children, color, onClick, selected, vertical}) => {
  const theme = useTheme()

  return (
    <Tooltip title={children}>
      <Container onClick={onClick} clickable={!!onClick} selected={selected} vertical={vertical}>
        <Bubble color={color} vertical={vertical} />
        <Text uppercase size={vertical ? 'small' : 'msmall'} overflowEllipsis font={theme.font.secondaryFont}>
          {children}
        </Text>
      </Container>
    </Tooltip>
  )
}

export default LegendItem
