import useLocalization from '../../../../hooks/useLocalization'
import React from 'react'
import Page from '../../../Page'
import InnerScroll from '../../../InnerScroll'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import CustomerCertOfferTable from './CustomerCertOfferTable'
import Box from 'components/Box'
import {useAtom, useAtomValue} from 'jotai'
import {buyerProposalsAtom, pageAtom, pageSizeAtom} from 'atoms/proposals'
import ErrorInfo from 'components/ErrorInfo'

const RESULT_PER_PAGE_OPTIONS = [25, 50, 100]

const CustomerOfferListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const {data: paginatedOffers, loading, error} = useAtomValue(buyerProposalsAtom)

  return (
    <Page
      title={translate('Offers')}
      description={translate('Review the offers sent to you')}
      error={error && <ErrorInfo title={translate('Failed to load proposals')} error={error} />}
    >
      <InnerScroll>
        <CustomerCertOfferTable offers={paginatedOffers?.data} isLoading={loading} />
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={paginatedOffers?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default CustomerOfferListPage
