import React from 'react'
import Marker, {IProps as IMarkerProps} from './Marker'
import {SiteShape} from '../../domain/ISite'
import commercialMarker from './markers/site/commercial.svg'
import industrialMarker from './markers/site/industrial.svg'
import miningMarker from './markers/site/mining.svg'
import dataWarehouseMarker from './markers/site/data-warehouse.svg'
import portMarker from './markers/site/port.svg'
import realEstateMarker from './markers/site/real-estate.svg'

export interface IProps extends React.PropsWithChildren, Omit<IMarkerProps, 'image'> {
  shape: SiteShape
}

const SHAPE_MARKERS: {[key in SiteShape]: string} = {
  COMMERCIAL: commercialMarker,
  MINING: miningMarker,
  INDUSTRIAL: industrialMarker,
  DATA_WAREHOUSE: dataWarehouseMarker,
  PORT: portMarker,
  REAL_ESTATE: realEstateMarker,
}

const SiteShapeMarker: React.FC<IProps> = ({shape, ...props}) => {
  return <Marker {...props} image={SHAPE_MARKERS[shape] || commercialMarker} />
}

export default SiteShapeMarker
