import React from 'react'
import IconButton, {IProps as IIconButtonProps} from './index'
import dotsIcon from '../../assets/icons/dots.svg'
import useLocalization from 'hooks/useLocalization'

type IProps = Omit<IIconButtonProps, 'src'>

const DottedIconButton: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return <IconButton src={dotsIcon} size={4} alt={translate('more actions')} {...props} />
}

export default DottedIconButton
