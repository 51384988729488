import IOffer, {OfferState} from '../domain/IOffer'
import ITranslate from '../interfaces/ITranslate'
import {isNil, meanBy, mean, sum} from 'lodash-es'

export function getPriceRange(minPrice: number, maxPrice: number): string {
  const getPrice = (price: number) => (isNil(price) ? '...' : price.toFixed(2))
  return `${getPrice(minPrice)} - ${getPrice(maxPrice)}`
}

export function getReasonWhyPriceCouldNotBeCalculated(offer: IOffer, translate: ITranslate): string {
  const erroneousFields = []

  if (offer.contract?.volumeMwh <= 0) {
    erroneousFields.push(translate('estimated annual volume'))
  }
  if (!offer.numberOfNmis || offer.numberOfNmis <= 0) {
    erroneousFields.push(translate('number of NMIs'))
  }
  if (!offer.customer?.id) {
    erroneousFields.push(translate('customer'))
  }
  if (!offer.contract?.validFrom) {
    erroneousFields.push(translate('contract start'))
  }
  if (!offer.contract?.validTo) {
    erroneousFields.push(translate('contract end'))
  }
  if (!offer.contract?.contractType?.id) {
    erroneousFields.push(translate('contract type'))
  }
  if (offer.contract?.contractItems?.length <= 0) {
    erroneousFields.push(translate('products'))
  }

  return erroneousFields.length > 0 ? translate('Missing %s', erroneousFields.join(', ')) : null
}

export function getOffersAveragePrice(offers: IOffer[]): number {
  return meanBy(
    offers,
    offer => sum([offer.minPrice, offer.maxPrice, offer.lgcMinPrice, offer.lgcMaxPrice].filter(Boolean)) / 2,
  )
}

export function getOffersAverageElectricityPrice(offers: IOffer[]): number {
  return meanBy(offers, offer => mean([offer.minPrice, offer.maxPrice]))
}

export function brokerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.CONTRACT_SENT:
      return translate('Contract sent')

    case OfferState.CONTRACT_SIGNED:
      return translate('Contract signed')

    case OfferState.CONFIG_SENT_FOR_SELLER_REVIEW:
      return translate('Purchase in review')

    case OfferState.CONFIG_IN_PROGRESS:
      return translate('Purchase in draft')

    case OfferState.SELLER_DECLINED_CONFIG:
      return translate('Purchase declined')
  }

  return null
}

export function customerOfferStateToReadableName(state: OfferState, translate: ITranslate): string {
  switch (state) {
    case OfferState.CONTRACT_SENT:
      return translate('Contract draft')

    case OfferState.CONTRACT_SIGNED:
      return translate('Contract signed')

    case OfferState.CONFIG_SENT_FOR_SELLER_REVIEW:
      return translate('Purchase in review')

    case OfferState.CONFIG_IN_PROGRESS:
      return translate('Purchase in draft')

    case OfferState.SELLER_DECLINED_CONFIG:
      return translate('Purchase declined')
  }

  return null
}
