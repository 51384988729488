import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import CompanyInformationForm from '../../../components/Platform/Account/CompanyInformationForm'
import AccountPage from '../../../components/Platform/Account/AccountPage'

const CompanyInformation = () => {
  const {translate} = useLocalization()

  return (
    <AccountPage title={translate('General')} description={translate('Manage company information')}>
      <CompanyInformationForm />
    </AccountPage>
  )
}

export default CompanyInformation
