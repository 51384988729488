import React from 'react'
import Loader from './Loader'
import styled from 'styled-components'

const Container = styled.div<IFullscreenLoader>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.height ? props.height : '100vh')};
  flex-direction: column;
`

interface IFullscreenLoader extends React.PropsWithChildren {
  height?: number | string
}

const FullscreenLoader: React.FC<IFullscreenLoader> = ({children, height}) => {
  return (
    <Container height={height}>
      <Loader size="medium" margin={2} />
      {children}
    </Container>
  )
}

export default FullscreenLoader
