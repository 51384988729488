import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'

const InputError = styled.div`
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  right: -1px;
  display: block;
  padding: 0 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  color: ${props => (props.theme as ITheme).colors.error};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

const InputErrorComponent = ({children, ...rest}) => {
  return (
    <InputError {...rest} data-cy="input-error">
      {children}
    </InputError>
  )
}

export default InputErrorComponent
