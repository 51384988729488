import HorizStack from 'components/HorizStack'
import ModalLink from 'components/Modal/ModalLink'
import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import useLocalization from '../../../hooks/useLocalization'
import Box from '../../Box'
import NavigationItem from './NavigationItem'

interface IProps extends React.PropsWithChildren {
  hideNavigation?: boolean
}

const StyledLogo = styled.img`
  max-width: 100%;
  height: 32px;
  margin-bottom: 44px;
`

export const FooterLink = styled(ModalLink)`
  display: block;
  color: ${props => (props.theme as ITheme).colors.primaryDark};
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`

const Page: React.FC<IProps> = ({children, hideNavigation}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  return (
    <>
      <Box style={{flex: 1}}>
        <StyledLogo src={theme.logo.logo} alt="logo" />
        {!hideNavigation && (
          <HorizStack margin={{bottom: 4}} gap={1}>
            <Box>
              <NavigationItem to="/" exact title={translate('Login')} />
            </Box>
            <Box>
              <NavigationItem to="/register" exact title={translate('Create an account')} />
            </Box>
          </HorizStack>
        )}
        {children}
      </Box>
    </>
  )
}

export default Page
