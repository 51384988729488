import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import PrivacyPolicy from './PrivacyPolicy'
import Box from '../Box'
import useTheme from '../../hooks/useTheme'
import Grid from '../Grid'
import styled from 'styled-components'
import InnerScroll from '../InnerScroll'
import RouterModal from '../Modal/RouterModal'
import ModalHeader from '../Modal/ModalHeader'
import {ModalType} from '../Modal/IModal'

const StyledGrid = styled(Grid)`
  overflow: hidden;
`

const PrivacyModal: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <RouterModal name={ModalType.PRIVACY} full>
      <ModalHeader title={translate('Privacy policy')} description={translate('Overview of the privacy policy')} />
      <StyledGrid>
        <InnerScroll noRightPad>
          <Box pad={2} border round>
            <PrivacyPolicy />
          </Box>
        </InnerScroll>
      </StyledGrid>
    </RouterModal>
  )
}

export default PrivacyModal
