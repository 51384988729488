import React, {useEffect, useRef, useState} from 'react'
import Page from 'components/Page'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import VerticalStack from 'components/VerticalStack'
import {Heading, SubHeading} from './Heading'
import {Redirect, Route, Switch} from 'react-router-dom'
import NotFound from 'components/NotFound'
import SetupSitesForm from './Buyer/SetupSitesForm'
import {OnboardingStatus} from 'domain/IParty'
import InnerScroll from 'components/InnerScroll'
import {PADDING_HEIGHT, TOP_NAVBAR_HEIGHT} from 'components/Layout'
import {HeroImageContainer, OnboardingContainer} from './SellerOnboardingWizard'
import OnboardingWelcome from './OnboardingWelcome'
import BuyerOnboardingAside from './Buyer/BuyerOnboardingAside'
import UploadConsumption from './Buyer/UploadConsumption'
import ReviewOnboarding from './Buyer/ReviewOnboarding'
import Box from 'components/Box'
import Loader from 'components/Loader'
import useTheme from 'hooks/useTheme'

export interface IProps extends React.PropsWithChildren {
  title: string
  description?: string
  disabled?: boolean
  ref: any
}

const ROUTE_PREFIX = '/consumer/onboarding'

const BuyerOnboardingWizard: React.FC<IProps> = ({title, description}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const party = useAtomValue(partyAtom)
  const [contentHeight, setContentHeight] = useState(0)
  const elementRef = useRef(null)

  const updateContentHeight = () => {
    if (elementRef?.current) {
      const {clientHeight} = elementRef.current
      setContentHeight(clientHeight + TOP_NAVBAR_HEIGHT + PADDING_HEIGHT)
    }
  }

  useEffect(() => {
    updateContentHeight()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateContentHeight)
  }, [])

  if (!party) {
    return null
  }

  return (
    <Page
      title={title}
      description={description}
      aside={<BuyerOnboardingAside />}
      asideColor={theme.colors.globalBackground}
      gridTemplateColStyle="auto 325px"
      mainPad={{top: 0, right: 0, bottom: 0, left: 0}}
      noHeader
    >
      <InnerScroll noRightPad={true}>
        <VerticalStack>
          <div ref={elementRef}>
            <VerticalStack style={{padding: 16}}>
              <Heading>{translate('Onboarding wizard')}</Heading>
              <SubHeading>{translate('Follow the steps and get going in no time')}</SubHeading>
            </VerticalStack>
          </div>
          <HeroImageContainer>
            <OnboardingContainer>
              <InnerScroll noRightPad>
                <Box height={`calc(100vh - ${contentHeight}px)`} pad={{horizontal: 6}}>
                  <Switch>
                    <Route
                      exact
                      path="/consumer/onboarding"
                      component={() => {
                        switch (party.onboardingStatus) {
                          case OnboardingStatus.NOT_STARTED:
                            return <Redirect to={`${ROUTE_PREFIX}/welcome`} />

                          case OnboardingStatus.BUYER_STARTED:
                            return <Redirect to={`${ROUTE_PREFIX}/setup-site`} />

                          case OnboardingStatus.BUYER_SITE_CREATED:
                            return <Redirect to={`${ROUTE_PREFIX}/upload-consumption`} />

                          case OnboardingStatus.BUYER_PROFILE_UPLOADED:
                            return <Redirect to={`${ROUTE_PREFIX}/review`} />

                          case OnboardingStatus.COMPLETED:
                            console.warn('Completed onboarding, but still in onboarding flow')
                            return <Redirect to={`/consumer/buy/marketplace/products`} />
                        }
                      }}
                    />
                    <Route
                      exact
                      path={`${ROUTE_PREFIX}/welcome`}
                      render={() => {
                        if (party?.onboardingStatus === OnboardingStatus.NOT_STARTED) {
                          return <OnboardingWelcome buyer />
                        }
                        return <Redirect to={`${ROUTE_PREFIX}`} />
                      }}
                    />
                    <Route
                      exact
                      path={`${ROUTE_PREFIX}/setup-site`}
                      component={() => {
                        if (party?.onboardingStatus === OnboardingStatus.BUYER_STARTED) {
                          return <SetupSitesForm />
                        }
                        return <Redirect to={`${ROUTE_PREFIX}`} />
                      }}
                    />
                    <Route
                      exact
                      path={`${ROUTE_PREFIX}/upload-consumption`}
                      component={() => {
                        if (party?.onboardingStatus === OnboardingStatus.BUYER_SITE_CREATED) {
                          return <UploadConsumption />
                        }
                        return <Redirect to={`${ROUTE_PREFIX}`} />
                      }}
                    />
                    <Route
                      exact
                      path={`${ROUTE_PREFIX}/review`}
                      component={() => {
                        if (party?.onboardingStatus === OnboardingStatus.BUYER_PROFILE_UPLOADED) {
                          return <ReviewOnboarding />
                        }
                        return <Redirect to={`${ROUTE_PREFIX}`} />
                      }}
                    />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Box>
              </InnerScroll>
            </OnboardingContainer>
          </HeroImageContainer>
        </VerticalStack>
      </InnerScroll>
    </Page>
  )
}

export default BuyerOnboardingWizard
