import React from 'react'
import Box from '../../../Box'
import contractIcon from './contractIcon.svg'
import Button from '../../../Button'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
`

interface IProps extends React.PropsWithChildren {
  buttonText: string
  buttonLink: string
}

const NoContract: React.FC<IProps> = ({buttonText, buttonLink}) => {
  return (
    <Box justify="center">
      <Box direction="column" align="center" margin={6} width={60} gap={6}>
        <img src={contractIcon} alt="" />
        <StyledLink to={buttonLink}>
          <Button fullWidth>{buttonText}</Button>
        </StyledLink>
      </Box>
    </Box>
  )
}

export default NoContract
