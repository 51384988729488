import React from 'react'
import Box from 'components/Box'
import Label from 'components/Label'
import {generateYears, getCurrentYear} from 'helpers/date'
import {getChangeEventValue} from 'helpers/misc'
import {useEffect} from 'react'
import {customPeriodAtom, MONTHS} from './periodSelect'
import styled from 'styled-components'
import Select from 'components/Select'
import {useAtom} from 'jotai'

const StyledSelect = styled(Select)`
  height: 32px;
  padding: 0px 12px;
  margin: 0;
`

const CustomPeriod = ({setValue}) => {
  const years = generateYears(getCurrentYear() + 2, 7)
  const [dateFilter, setDateFilter] = useAtom(customPeriodAtom)

  const handleDateChange = (event, key) => {
    const value = getChangeEventValue(event)

    setDateFilter({...dateFilter, [key]: value})
  }

  useEffect(() => {
    setValue(dateFilter)
  }, [dateFilter])

  return (
    <Box gap={2}>
      <Label text="Start date">
        <Box direction="row" gap={1}>
          <StyledSelect
            value={dateFilter.startMonth}
            options={MONTHS.map(month => ({value: month, label: month}))}
            onChange={e => handleDateChange(e, 'startMonth')}
          />
          <StyledSelect
            value={dateFilter.startYear}
            options={years.map(year => ({value: year, label: `${year}`}))}
            onChange={e => handleDateChange(e, 'startYear')}
          />
        </Box>
      </Label>

      <Label text="End date">
        <Box direction="row" gap={1}>
          <StyledSelect
            value={dateFilter.endMonth}
            options={MONTHS.map(month => ({value: month, label: month}))}
            onChange={e => handleDateChange(e, 'endMonth')}
          />
          <StyledSelect
            value={dateFilter.endYear}
            options={years.map(year => ({value: year, label: `${year}`}))}
            onChange={e => handleDateChange(e, 'endYear')}
          />
        </Box>
      </Label>
    </Box>
  )
}

export default CustomPeriod
