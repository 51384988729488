import useLocalization from '../../../../hooks/useLocalization'
import React from 'react'
import Page from '../../../Page'
import Link from '../../../Link'
import Button from '../../../Button'
import InnerScroll from '../../../InnerScroll'
import Box from '../../../Box'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import {useAtom, useAtomValue} from 'jotai'
import ErrorInfo from 'components/ErrorInfo'
import {marketListingsAtom, pageAtom, pageSizeAtom} from 'atoms/marketListings'
import MarketListingsTable from './MarketListingsTable'
import TableSkeleton from 'components/TableSkeleton'

const RESULT_PER_PAGE_OPTIONS = [25, 50]

const MarketplaceListingsPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {data: listings, loading, error: error} = useAtomValue(marketListingsAtom)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)

  if (loading) {
    return (
      <Page title={translate('Marketplace listings')} description={translate('View your listings')}>
        <TableSkeleton />
      </Page>
    )
  }

  return (
    <Page
      title={translate('Marketplace listings')}
      description={translate('View your listings')}
      corner={
        <Box gap={1}>
          <Link to={`marketplace-listings/add`}>
            <Button data-cy="add-listing-button">{translate('Add new listings')}</Button>
          </Link>
        </Box>
      }
      error={error && <ErrorInfo error={error} title={translate('Failed to load contracts')} />}
    >
      <InnerScroll>
        <MarketListingsTable listings={listings.data} />
      </InnerScroll>

      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={listings?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default MarketplaceListingsPage
