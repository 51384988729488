import React, {useEffect, useState} from 'react'
import useServices from '../../../hooks/useServices'
import Loader from '../../Loader'
import useLocalization from '../../../hooks/useLocalization'
import useStore from '../../../hooks/useStore'

const ContractHtmlPreview: React.FC<{id: any}> = ({id}) => {
  const {translate} = useLocalization()
  const {alertStore} = useStore()
  const [html, setHtml] = useState(null)
  const {contentService} = useServices()

  const toHtml = text => {
    text = text.toString()
    text = text.replace(/&lt;/g, '<')
    text = text.replace(/&gt;/g, '>')
    text = text.replace(/&amp;nbsp;/g, '')
    text = text.replace(/&amp;rsquo;/g, "'")
    text = text.replace(/&amp;ldquo;/g, '"')
    text = text.replace(/&amp;rdquo;/g, '"')
    return text
  }

  useEffect(() => {
    contentService
      .getContractHtml(id)
      .then(html => setHtml(html))
      .catch(error => {
        console.error(error)
        alertStore.addError(translate('Failed to retrieve contract preview'), error?.correlationId, error?.message)
        setHtml('')
      })
    // eslint-disable-next-line
  }, [id])

  if (html === null) {
    return <Loader margin={3} />
  }

  return <div dangerouslySetInnerHTML={{__html: toHtml(html)}} />
}

export default ContractHtmlPreview
