import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import Form from '../../Form'
import useServices from '../../../hooks/useServices'
import ICreatedUser from '../../../domain/ICreatedUser'
import {StyledFormButton, StyledFormCheckbox} from './Layout/FormComponents'
import Box from 'components/Box'
import Link from 'components/Link'
import styled from 'styled-components'
import FormInput from 'components/Form/FormInput'

interface IProps extends React.PropsWithChildren {
  onSuccess?: (data: ICreatedUser) => void
}

const StyledLink = styled(Link)`
  font-weight: 300;
  text-transform: uppercase;
  color: ${({theme}) => `${theme.colors.blue7}`};

  &:hover {
    text-decoration: underline;
  }
  font-family: ${({theme}) => `${theme.font.headings}`};
  color: ${({theme}) => `${theme.colors.primaryDark}`};
`

const CreateAccountForm: React.FC<IProps> = ({onSuccess}) => {
  const {translate} = useLocalization()
  const {authenticationService} = useServices()

  const handleSubmit = async ({firstName, lastName, email, password}) => {
    await authenticationService.signUp(email, password, firstName, lastName)

    onSuccess && onSuccess({firstName, lastName, email, password})
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormInput large defaultValue={''} name="firstName" label={translate('First name')} required data-cy="name" />
      <FormInput large defaultValue={''} name="lastName" label={translate('Surname')} required data-cy="lastName" />
      <FormInput
        large
        defaultValue={''}
        name="email"
        label={translate('Email address')}
        pattern={{
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: translate('Invalid email address'),
        }}
        required
        data-cy="email"
      />
      <FormInput
        large
        defaultValue={''}
        name="password"
        label={translate('Password')}
        type="password"
        validate={(value: string): string | true => {
          if (value.length < 8) {
            return translate('Must be at least 8 characters.')
          }

          if (!/[a-z]/.test(value)) {
            return translate('Must contain at least one lowercase character.')
          }

          if (!/[0-9]/.test(value)) {
            return translate('Must contain at least one number.')
          }

          if (!/[A-Z]/.test(value)) {
            return translate('Must contain at least one uppercase character.')
          }

          return true
        }}
        required
        data-cy="pass1"
      />
      <FormInput
        large
        defaultValue={''}
        name="password2"
        label={translate('Confirm your password')}
        type="password"
        required
        validate={(value, getValues) => value === getValues().password || translate('Passwords do not match')}
        data-cy="pass2"
      />

      <StyledFormCheckbox
        name="agree"
        label={
          <StyledLink to="?modal.terms">
            {translate('I agree to the privacy policy and terms of the platform')}
          </StyledLink>
        }
        required
        data-cy="agree"
      />
      <StyledFormCheckbox
        name="cookies"
        label={<StyledLink to="?modal.cookies">{translate('I accept the cookie policy of the platform')}</StyledLink>}
        required
        data-cy="cookies"
      />
      <Box justify="flex-end" pad={{top: 2}}>
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default CreateAccountForm
