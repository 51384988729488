import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'
import {autoCeilNumber, autoFloorNumber} from '../../../../helpers/misc'
import useTheme from 'hooks/useTheme'
import {IPerformanceContractTimeseries} from 'domain/IContract'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  data: IPerformanceContractTimeseries[]
  namesById: Record<string, string>
}

const PerformanceChart: React.FC<IProps> = ({data, namesById}) => {
  const theme = useTheme()
  const [selectedLine, setSelectedLine] = React.useState<string | null>(null)
  const {getNext} = useNewChartColors()

  if (!data) {
    return null
  }
  const allTimeseriesByName = data.reduce(
    (acc, {contractId, timeseries}) => ({...acc, [namesById[contractId]]: timeseries.series}),
    {},
  )

  const labels = data[0].timeseries.series.map(({name}) => name) || []

  const lines: ILine[] = []

  lines.push(
    ...Object.keys(allTimeseriesByName).map((key, index) => ({
      key: key,
      label: key,
      color: getNext(),
      data: allTimeseriesByName[key].map(({value}) => value),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
      fill: true,
      onClick: () => setSelectedLine(selectedLine === key ? null : key),
    })),
  )

  const filteredLines = lines.map(line => {
    if (selectedLine && line.key !== selectedLine) {
      return {...line, data: []}
    }

    return line
  })

  return (
    <Chart
      toolbar={{vertical: false, selectedLegend: selectedLine, timescale: {hideMonthly: true, hideYearly: true}}}
      labels={labels}
      lines={filteredLines}
      typeLabels={{
        [StackType.DEFAULT]: `Profit/Loss, ${data[0].timeseries.currency}`,
      }}
      dark
      maxValue={autoCeilNumber(getMaxValue(filteredLines))}
      minValue={autoFloorNumber(getMinValue(filteredLines))}
    />
  )
}

export default PerformanceChart
