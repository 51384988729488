import {SiteProductionTechnology} from '../../../../domain/ISite'
import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
import {getHumanReadableTechnologyName} from '../../../../helpers/site'
import ResourceSummary from '../Summary/ResourceSummary'
import TechnologyIcon from '../../TechnologyIcon'
import IContract from '../../../../domain/IContract'
import {getContractsVolumeMwhByTechnology, getContractYearlyVolumeMwh} from '../../../../helpers/contract'
import EnergySummaryItem from '../Summary/EnergySummaryItem'
import IPurchaseContract from 'domain/IPurchaseContract'
import {Skeleton} from '@mui/material'
import Box from 'components/Box'

interface IProps extends React.PropsWithChildren {
  contracts: (IContract | IPurchaseContract)[]
  loading?: boolean
}

const ContractsSummaryByTechnology: React.FC<IProps> = ({contracts, loading}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const volumeMwhByTechnology = getContractsVolumeMwhByTechnology(contracts)
  const total: number = contracts.reduce((acc, current) => acc + getContractYearlyVolumeMwh(current), 0)
  const unitSuffix = `/${translate('year')}`

  if (loading) {
    return (
      <Box direction="row" margin={{top: 3}} gap={4}>
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
        <Skeleton variant="rectangular" height="96px" width="100%" />
      </Box>
    )
  }

  return (
    <ResourceSummary>
      <EnergySummaryItem
        title={translate('Total volume')}
        value={total}
        color={theme.colors.light4}
        max={total}
        unitSuffix={unitSuffix}
      />
      {Object.keys(volumeMwhByTechnology).map((technology: SiteProductionTechnology) => (
        <EnergySummaryItem
          key={technology}
          icon={<TechnologyIcon technology={technology} />}
          title={translate('%s volume', getHumanReadableTechnologyName(technology, translate))}
          value={volumeMwhByTechnology[technology]}
          max={total}
          color={theme.colors.technology[technology]}
          unitSuffix={unitSuffix}
        />
      ))}
    </ResourceSummary>
  )
}

export default ContractsSummaryByTechnology
