import {action, computed, observable, toJS} from 'mobx'
import {isEqual, omit} from 'lodash-es'
import {ResourceId} from '../types'

export enum RelationType {
  ManyToOne,
  OneToOne,
  OneToMany,
  ManyToMany,
}

export interface IRelation<T, K extends {id: ResourceId}> {
  getStore: () => ResourceStore<K>
  key: keyof T
  inversedBy?: keyof K
  type: RelationType
}

export default abstract class ResourceStore<T extends {id: ResourceId}> {
  @observable public isLoading = false
  @observable private byId: {[key: string]: T} = {}
  @observable private idsByKey: {[key: string]: Array<string | number>} = {}

  private readonly relations: IRelation<T, any>[]

  public constructor(relations: IRelation<T, any>[] = []) {
    this.relations = relations
  }

  @action
  public async setManyEventually(promise: Promise<T[]>, key?: string): Promise<void> {
    this.isLoading = true

    try {
      const items = await promise

      this.setItems(items)
      if (key) {
        this.idsByKey[key] = items.map(item => item.id)
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      throw e
    }
  }

  @action
  public setManyInstantly(items: T[], key?: string): void {
    this.isLoading = true

    try {
      this.setItems(items)
      if (key) {
        this.idsByKey[key] = items.map(item => item.id)
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
      throw e
    }
  }

  @action
  public async clearItems(): Promise<void> {
    this.byId = {}
  }

  @action
  public async setOneEventually(promise: Promise<T>): Promise<T> {
    this.isLoading = true

    try {
      const item = await promise

      this.setItem(item)
      this.isLoading = false

      return item
    } catch (e) {
      this.isLoading = false
      throw e
    }
  }

  @action
  public setItems(items: T[]) {
    items.forEach(item => {
      this.setItem(item)
    })
  }

  @action
  public setItem(item: T, ignore?: Array<keyof T>) {
    if (!item?.id) {
      console.error(`Unable to store item`, item)
      return
    }

    const currentValue = toJS(this.byId[item.id.toString()])
    const newValue = this.prepareForStoring(item)

    // Lazily loaded arrays are valued with null - do not overwrite!
    if (currentValue) {
      Object.keys(currentValue).forEach(key => {
        if (Array.isArray(currentValue[key]) && newValue[key] === null) {
          delete newValue[key]
        }
      })
    }

    const parsed = {...currentValue, ...newValue}

    if (!isEqual(currentValue, parsed)) {
      this.byId[item.id.toString()] = parsed
    }
  }

  @computed
  public get items(): T[] {
    return Object.values(this.byId)
      .map(item => this.prepareForAccessing(item))
      .sort((a, b) => b.id - a.id)
  }

  public getItem(id: ResourceId, ignore?: Array<keyof T>): T {
    const item = this.byId[id]

    if (!item) {
      return null
    }

    return this.prepareForAccessing(ignore ? (omit(item, ignore) as unknown as T) : item)
  }

  public getItemsByKey(key: string): T[] {
    return this.idsByKey[key] && this.getItemsByIds(this.idsByKey[key])
  }

  public getItemsByIds(ids: ResourceId[], ignore?: Array<keyof T>): T[] {
    return ids.map(id => this.getItem(id, ignore)).filter(Boolean)
  }

  public removeItem(id: ResourceId): T {
    const item = this.byId[id]

    // Returns null if nothing was deleted
    if (!item) {
      return null
    }

    delete this.byId[id]

    // Returns deleted item on success
    return item
  }

  protected prepareForStoring(item: T): T {
    const relations: any = {}

    this.relations.forEach(({key, getStore, inversedBy, type}) => {
      const value: any = item[key]

      if (!value) {
        delete item[key]
        return
      }

      if (typeof value !== 'object') {
        return
      }

      const store = getStore()

      if (type === RelationType.ManyToMany) {
        store.setItems(value)
        relations[key] = value.map(v => v.id)
      } else if (type === RelationType.ManyToOne) {
        const current = store.getItem(value.id) || {}

        if (!inversedBy || (current && current[inversedBy] && current[inversedBy].find(i => i.id === item.id))) {
          store.setItem(value)
        } else if (!current) {
          store.setItem({...value, [inversedBy]: [item]})
        } else {
          store.setItem({
            ...value,
            [inversedBy]: [...(current[inversedBy] || []).filter(i => i.id !== item.id), item],
          })
        }

        relations[key] = value.id
      } else if (type === RelationType.OneToOne) {
        store.setItem(inversedBy ? {...value, [inversedBy]: item.id} : value)
        relations[key] = value.id
      } else if (type === RelationType.OneToMany) {
        store.setItems(value.map(v => (inversedBy ? {...v, [inversedBy]: item.id} : v)))
        relations[key] = value.map(v => v.id)
      }
    })

    return {...item, ...relations}
  }

  protected prepareForAccessing(item: T): T {
    const relations: any = {}

    this.relations.forEach(({key, getStore, inversedBy}) => {
      const value = item[key]

      if (!value) {
        return
      }

      const ignore = inversedBy ? [inversedBy] : []

      if (Array.isArray(value)) {
        relations[key] = getStore().getItemsByIds(value, ignore)
      } else {
        relations[key] = getStore().getItem(value, ignore)
      }
    })

    return {
      ...item,
      ...relations,
    }
  }
}
