import {CurrencyCode} from './IPrice'
import {SiteProductionTechnology, SiteState, TimeseriesKind} from './ISite'

interface Certificates {
  start: string
  end: string
}

export enum MarketListingKind {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export interface INewMarketListing {
  product: QuickProduct
  listingDetails: IListingDetails
}

export interface IMarketListingTimeseries {
  kind: TimeseriesKind
  payload: {
    attachmentId?: number
    hourlyVolumes?: {
      hour: number
      volumeMwh: number
    }[]
  }
}

export type QuickProduct =
  | {kind: MarketListingKind.NEW; payload: INewQuickProduct}
  | {kind: MarketListingKind.EXISTING; payload: {id: number}}

interface INewQuickProduct {
  name: string
  description: string

  imgAttachmentId: number
  site:
    | {kind: MarketListingKind.NEW; payload: INewQuickSite}
    | {kind: MarketListingKind.EXISTING; payload: {id: number}}
}

export interface INewQuickSite {
  name: string
  technology: SiteProductionTechnology
  state: SiteState
  prodCapacityMwac: number
  operationsStart: string
  lifetimeYears: number
  meterReference: string
  placeId: string
}

export interface IListingDetails {
  profileAttachmentId: number
  price: number
  firstCertificate: string
  lastCertificate: string
  timeseries: IMarketListingTimeseries
  interval: {
    year: number
    month: string
  }
}

export interface IMarketListing {
  id: number
  productId: number
  productName: string
  certificateId: number
  startDate: string
  certPrice: number
  currency: CurrencyCode
  totalQuantity: number
  soldQuantity: number
  reservedQuantity: number
  availableQuantity: number
}

export interface IMarketListingReport {
  hourly: {
    contracts: {
      id: number
      certs: Certificates
    }[]
    gap: Certificates
    total: Certificates
    timestamp: string
  }[]
}

export interface IMarketListingUpdatePayload {
  certPrice: number
}

export interface IGenerateTestCertsPayload {
  product:
    | {
        kind: MarketListingKind.NEW
        payload: {
          site:
            | {kind: MarketListingKind.NEW; payload: {technology: SiteProductionTechnology}}
            | {kind: MarketListingKind.EXISTING; payload: {id: number}}
        }
      }
    | {kind: MarketListingKind.EXISTING; payload: {id: number}}
  interval: {
    year: number
    month: string
  }
  timeseries: IMarketListingTimeseries
}
