import React from 'react'
import Box from './Box'
import useTheme from '../hooks/useTheme'

const NoResults: React.FC<React.PropsWithChildren> = ({children}) => {
  const theme = useTheme()

  return (
    <Box margin={{vertical: 4}} round={3} pad={{horizontal: 3, vertical: 1.25}} color={theme.colors.light3}>
      {children}
    </Box>
  )
}

export default NoResults
