import IContentService from '../services/IContentService'
import {action, observable, computed} from 'mobx'
import RootStore from './index'
import IWarehouseCertificates from 'domain/IWarehouseCertificates'
import ISellExcessRequest from 'domain/ISellExcessRequest'
import IParty from 'domain/IParty'
import IV2ContentService from 'services/IV2ContentService'

export default class AdminStore {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService
  private readonly v2ContentService: IV2ContentService

  @observable public certificatesData: IWarehouseCertificates
  @observable public sellRequests: ISellExcessRequest[]
  @observable public loading = true

  public constructor(rootStore: RootStore, contentService: IContentService, v2ContentService: IV2ContentService) {
    this.rootStore = rootStore
    this.contentService = contentService
    this.v2ContentService = v2ContentService
  }

  @action
  public async loadCertificates(): Promise<void> {
    try {
      this.certificatesData = await this.v2ContentService.getWarehouseCertificates()
    } finally {
      this.loading = false
    }
  }

  @action
  public async loadAdminSellRequests(): Promise<void> {
    try {
      this.loading = true
      const {data} = await this.v2ContentService.getAdminExcessSellRequests()

      this.sellRequests = data
    } finally {
      this.loading = false
    }
  }

  @action
  public async loadPublicSellRequests(): Promise<void> {
    try {
      this.loading = true
      const {data} = await this.contentService.getPublicExcessSellRequests(this.party.id)

      this.sellRequests = data
    } finally {
      this.loading = false
    }
  }

  @action
  public async acceptSellRequest(requestId: number): Promise<boolean> {
    try {
      await this.contentService.acceptExcessSellRequest(requestId)

      return true
    } catch {
      return false
    }
  }

  @action
  public async declineSellRequest(requestId: number): Promise<boolean> {
    try {
      await this.contentService.declineExcexsSellRequest(requestId)

      return true
    } catch {
      return false
    }
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
