import IRoute from '../interfaces/IRoute'
import {set, uniqBy, zipWith, sum, isNumber} from 'lodash-es'
import {WithMeta} from '../types'

export function objectToFormData(object: Record<string, any>): FormData {
  const formData = new FormData()

  for (const key in object) {
    // eslint-disable-next-line
    if (object.hasOwnProperty(key)) {
      formData.append(key, object[key])
    }
  }

  return formData
}

export function downloadFile(fileName: string, content: Blob) {
  try {
    const objectUrl = window.URL.createObjectURL(content).toString()

    const link = document.createElement('a')
    link.href = objectUrl
    link.setAttribute('download', fileName)
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}))

    window.URL.revokeObjectURL(objectUrl)
  } catch (error) {
    throw new Error('Download failed: ' + error.message)
  }
}

export function addPrefixToRoutes(routes: IRoute[], prefix: string): IRoute[] {
  return routes.map(route => {
    return {
      ...route,
      path: prefix + route.path,
      children: route.children && addPrefixToRoutes(route.children, prefix),
    }
  })
}

export function flattenObject(object: Record<string, any>): Record<string, any> {
  const newObject = {}

  for (const key in object) {
    // eslint-disable-next-line
    if (!object.hasOwnProperty(key)) continue

    if (typeof object[key] === 'object') {
      const subObject = flattenObject(object[key])
      Object.assign(
        newObject,
        ...Object.keys(subObject).map(subKey => ({
          [[key, subKey].join('.')]: subObject[subKey],
        })),
      )
    } else {
      newObject[key] = object[key]
    }
  }

  return newObject
}

export function getChangeEventValue(event: any) {
  return event?.target ? event?.target?.value : event
}

export function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  }).then((result: string) => result.substr(result.indexOf('base64,') + 'base64,'.length))
}

// Count number of digits - returns negative number for every zero after decimal point
// E.g:
// 13 => 2
// 0.1 => 0
// 0.01 => -1
// 0.0089 => -2
export function getNumberOfDigits(value: number) {
  if (value >= 1) {
    return Math.max(Math.floor(Math.log10(Math.abs(value))), 0) + 1
  }

  if (value <= 0) {
    return 0
  }

  if (value < 1) {
    return Math.floor(Math.log(value) / Math.log(10) + 1)
  }
}

// Returns wheter the value is a number, and its not -Infinity, NaN, etc.
export function isFiniteNumber(value: any): boolean {
  return isNumber(value) && isFinite(value)
}

// Returns wheter the value is in the specified range(inclusive).
export function isInRange(value: number, low: number, high: number): boolean {
  return value >= low && value <= high
}

export function autoCeilNumber(value: number): number {
  if (value === 0) {
    return 0.1
  }

  const pow = Math.pow(10, getNumberOfDigits(value))

  let result = Math.ceil(value / pow) * pow

  const tenth = result / 10

  for (let i = 0; i < 10; i++) {
    const minusFifth = result - tenth

    if (minusFifth > value) {
      result = minusFifth
    } else {
      break
    }
  }

  return +result.toPrecision(12)
}

export function autoFloorNumber(value: number): number {
  if (value >= 0) {
    return 0
  }

  if (value < 0) {
    value = -value
  }

  const pow = Math.pow(10, getNumberOfDigits(value))

  let result = Math.ceil(value / pow) * pow

  const tenth = result / 10

  for (let i = 0; i < 10; i++) {
    const minusFifth = result - tenth

    if (minusFifth > value) {
      result = minusFifth
    } else {
      break
    }
  }

  return -+result.toPrecision(12)
}

export function queryStringToNestedObject<T extends Record<string, any>>(query: string): T {
  const result = {}
  const searchParams = new URLSearchParams(query)

  // eslint-disable-next-line
  // @ts-ignore
  for (const [key, value] of searchParams.entries()) {
    set(result, key, value)
  }

  return result as T
}

export function mergeArrayOfArrays(arrayOfArrays: any[]): any[] {
  return [].concat(...arrayOfArrays.map(item => item[0]))
}

export function filterRepeatingObjectsOutOfArrayOfObjectsByKey(array: Record<string, any>[], key: string): any[] {
  return uniqBy(array, element => {
    return element[key]
  })
}

export function reduceArrayAndFilterByKey(array: Record<string, unknown>[], key: string): any[] {
  const flattenArray = mergeArrayOfArrays(array)

  return filterRepeatingObjectsOutOfArrayOfObjectsByKey(flattenArray, key)
}

export function sumArrayOfArrays(arrays: number[][]): number[] {
  return zipWith(...arrays, (...values: number[]) => {
    return sum(values)
  })
}

/*
 * Sets __key property to object to identify it by some kind of identifier
 */
export function withMeta<T extends Record<string, any>, K extends Record<string, any>>(
  object: T | WithMeta<T, K>,
  meta: K,
): WithMeta<T, K> {
  return {
    ...object,
    __meta: {
      ...(object as WithMeta<T, K>).__meta,
      ...meta,
    },
  }
}

/*
 * Checks whether all specified internal keys exist in object
 */
export function hasMeta<T extends Record<string, any>, K extends Record<string, any>>(
  object: T | WithMeta<T, K>,
  predicate: (meta: K) => boolean,
): boolean {
  return predicate((object as WithMeta<T, K>).__meta || ({} as K))
}

export function HexToRGB(hex: string): number[] {
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }
  const aRgbHex = hex.match(/.{1,2}/g)
  const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)]
  return aRgb
}

export function HexToRGBWithOpacity(hex: string, opacity: number): number[] {
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }
  const aRgbHex = hex.match(/.{1,2}/g)
  const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16), opacity]
  return aRgb
}

export function getRandomNumber(min: number = 100, max: number = 1000): string {
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString()
}
