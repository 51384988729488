import React, {useContext} from 'react'
import RouterModal from '../../../Modal/RouterModal'
import useLocalization from 'hooks/useLocalization'
import ModalHeader from 'components/Modal/ModalHeader'
import Box from 'components/Box'
import Button from 'components/Button'
import {ModalContext} from 'components/Modal'

export const MODAL_NAME = 'modal.certificateRequest'

const ModalBody = ({onSend}) => {
  const {translate} = useLocalization()
  const {handleClose} = useContext(ModalContext)

  const handleSend = () => {
    onSend()
    handleClose()
  }

  return (
    <Box direction="column">
      <ModalHeader title={translate('Send purchase configuration for review?')} />
      <Box gap={2} justify="center">
        <Button variant="secondary" fullWidth onClick={handleClose}>
          {translate('Cancel')}
        </Button>
        <Button onClick={handleSend} fullWidth>
          {translate('Send')}
        </Button>
      </Box>
    </Box>
  )
}

interface IProps extends React.PropsWithChildren {
  onSend: () => void
}

const RequestModal: React.FC<IProps> = ({onSend}) => {
  return (
    <RouterModal style={{width: 600}} name={MODAL_NAME}>
      <ModalBody onSend={onSend} />
    </RouterModal>
  )
}

export default RequestModal
