import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import spacing, {ISpacingProps} from './styleProps/spacing'

export interface IProps extends React.PropsWithChildren, ISpacingProps {
  columns?: number
  gap?:
    | number
    | {
        row?: number
        column?: number
      }
  templateColumns?: string
  templateRows?: string
  inline?: boolean
  alignItems?: string
}

const gapToPx = (gap: number, theme: ITheme) => (gap ? `${gap * theme.tileSize}px` : 0)

const Grid = styled.div<IProps>`
  display: ${props => (props.inline ? 'inline-grid' : 'grid')};
  grid-template-columns: ${props => props.templateColumns || `repeat(${props.columns || 1}, 1fr)`};
  ${props => props.templateRows && `grid-template-rows: ${props.templateRows};`}
  ${props =>
    props.gap && typeof props.gap === 'object'
      ? `grid-row-gap: ${gapToPx(props.gap.row, props.theme)};
    grid-column-gap: ${gapToPx(props.gap.column, props.theme)};`
      : typeof props.gap === 'number' && `grid-gap: ${gapToPx(props.gap, props.theme)};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${spacing}
`

export default Grid
