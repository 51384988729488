import React from 'react'
import {useAtomValue, useAtom} from 'jotai'
import {externalContractsAtom, pageAtom, pageSizeAtom} from 'atoms/externalContracts'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import Table, {TableValue} from 'components/Table'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import Box from 'components/Box'
import Link from 'components/Link'
import Button, {LinkButton} from 'components/Button'
import InnerScroll from 'components/InnerScroll'
import IExternalContract from 'domain/IExternalContract'
import ErrorInfo from 'components/ErrorInfo'
import {DateFormat, formatDate} from 'helpers/date'
import TableSkeleton from 'components/TableSkeleton'
import {usePaywall} from 'hooks/usePaywall'
import {ISubscriptionPlan, PartyRole} from 'domain/IParty'
import {partyAtom} from 'atoms/party'

const RESULT_PER_PAGE_OPTIONS = [25, 50]

const ExternalContractsPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {data: paginatedContracts, loading, error} = useAtomValue(externalContractsAtom)
  const {data: contracts} = paginatedContracts || {}
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const party = useAtomValue(partyAtom)
  const {openPaywall} = usePaywall()

  if (loading) {
    return (
      <Page title={translate('External Contracts')} description={translate('Manage your contracts')}>
        <TableSkeleton />
      </Page>
    )
  }

  return (
    <Page
      title={translate('External Contracts')}
      description={translate('Manage your contracts')}
      corner={
        <Box gap={1}>
          {party?.subscriptionPlan === ISubscriptionPlan.BASIC &&
          contracts?.length >= 1 &&
          party.partyRole === PartyRole.BUYER ? (
            <Button onClick={() => openPaywall()}>{translate('Add new contract')}</Button>
          ) : (
            <Link to={`external-contracts/add`}>
              <Button>{translate('Add new contract')}</Button>
            </Link>
          )}
        </Box>
      }
      error={error && <ErrorInfo error={error} title={translate('Failed to load contracts')} />}
    >
      <InnerScroll>
        <Table<IExternalContract>
          tableName={translate('External Contracts')}
          data={contracts as IExternalContract[]}
          linkTo={contract => `external-contracts/${contract.id}`}
          columns={[
            {
              title: translate('Name'),
              accessor: (contract: IExternalContract) => contract.name,
              render: (name: string) => (
                <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} fullWidth>
                  {name}
                </LinkButton>
              ),
            },
            {
              title: translate('Volume %'),
              accessor: (contract: IExternalContract) => contract.volumePercent,
              render: (volumePercent: number) => <TableValue rounded value={volumePercent} />,
            },
            {
              title: translate('Price per Mwh'),
              accessor: (contract: IExternalContract) => contract.pricePerMwh,
              render: (pricePerMwh: number) => <TableValue value={pricePerMwh} />,
            },
            {
              title: translate('Start date'),
              accessor: (contract: IExternalContract) => formatDate(contract.startDate, DateFormat.MONTH_DAY_YEAR),
              render: (date: string) => <TableValue text={date} />,
            },
            {
              title: translate('End date'),
              accessor: (contract: IExternalContract) => formatDate(contract.endDate, DateFormat.MONTH_DAY_YEAR),
              render: (date: string) => <TableValue text={date} />,
            },
          ]}
        />
      </InnerScroll>

      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={paginatedContracts?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default ExternalContractsPage
