import React, {useCallback, useEffect} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import ITheme from '../../theme/ITheme'

export const ModalContext = React.createContext<{handleClose: () => void}>(null)

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 48, 78, 0.5);
`

const ModalContainer = styled.div<{full: boolean; maxHeight: number; maxWidth: number; allowOverflow?: boolean}>`
  position: relative;
  max-height: ${props => props.maxHeight}vh;
  max-width: ${props => props.maxWidth}vw;
  overflow: ${props => (props.allowOverflow ? 'initial' : 'auto')};
  background-color: ${props => (props.theme as ITheme).colors.common.white};
  padding: ${props => (props.theme as ITheme).tileSize * 4}px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  flex-direction: column;
  ${props =>
    props.full &&
    `
    width: 100%;
    height: 100%;
  `}
`

export interface IProps extends React.PropsWithChildren {
  onClose: () => void
  full?: boolean
  style?: React.CSSProperties
  maxSize?: 'large' | 'medium'
  allowOverflow?: boolean
}

const sizes = {
  large: [84, 94],
  medium: [75, 75],
}

const Modal: React.FC<IProps> = ({onClose, children, full, style, maxSize, allowOverflow}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Esc':
        case 'Escape':
          return onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.body.style.overflow = 'initial'
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleKeyDown])

  const [maxWidth, maxHeight] = sizes[maxSize]

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{handleClose: onClose}}>
      <Wrapper data-c="Modal">
        <Background onClick={onClose} />
        <ModalContainer
          full={full}
          style={style}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          allowOverflow={allowOverflow}
        >
          {children}
        </ModalContainer>
      </Wrapper>
    </ModalContext.Provider>,
    document.body,
  )
}

Modal.defaultProps = {
  maxSize: 'large',
}

export default Modal
