import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import AutocompleteSelect, {IProps as ISelectProps} from '../AutocompleteSelect'

export type IProps = IWrapperProps<ISelectProps>

const FormAutocompleteSelect: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <AutocompleteSelect />
    </FormInputWrapper>
  )
}

export default FormAutocompleteSelect
