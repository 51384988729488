import React from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'
import useTheme from '../hooks/useTheme'

const ThemeFavicon: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()

  if (!theme.favicon) {
    return null
  }

  return (
    <ReactHelmet>
      <link rel="icon" type="image/png" href={theme.favicon} sizes="32x32" />
    </ReactHelmet>
  )
}

export default ThemeFavicon
