import IAttachment from '../../domain/IAttachment'

const mockAttachments: IAttachment[] = [
  {
    id: 1,
    name: 'image.png',
    fileName: 'image.png',
    size: 150332,
    mimeType: 'image/png',
  },
  {
    id: 2,
    name: 'name.csv',
    fileName: 'fileName.csv',
    size: 12334,
    mimeType: 'text/csv',
  },
  {
    id: 3,
    name: 'name.pdf',
    fileName: 'fileName.pdf',
    size: 42352,
    mimeType: 'application/pdf',
  },
]

export default mockAttachments
