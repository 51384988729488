import React from 'react'
import Link, {IProps as ILinkProps} from '../Link'
import {ModalType} from './IModal'

interface IProps extends React.PropsWithChildren, Omit<ILinkProps, 'to'> {
  modal: ModalType
  value?: string | number
}

const ModalLink: React.FC<IProps> = ({modal, value, ...props}) => (
  <Link to={`?${modal}${value ? `=${value}` : ''}`} {...props} />
)

export default ModalLink
