import IProductType, {ProductTypeBehaviour, VolumeType} from '../../domain/IProductType'

export const mockUserSelectProductType: IProductType = {
  id: 1,
  name: 'USER_SELECT_1',
  volumeType: VolumeType.USER_SELECT,
  priceVisibleToCustomer: true,
  behaviour: ProductTypeBehaviour.ELECTRICITY,
}

export const mockStandaloneUserSelectProductType: IProductType = {
  id: 2,
  name: 'STANDALONE_USER_SELECT_2',
  volumeType: VolumeType.STANDALONE_USER_SELECT,
  behaviour: ProductTypeBehaviour.LGC,
}

export const mockAutofillProductType: IProductType = {
  id: 3,
  name: 'AUTO_FILL_3',
  volumeType: VolumeType.AUTO_FILL,
  behaviour: ProductTypeBehaviour.BALANCING,
}

export const mockNoneProductType: IProductType = {
  id: 4,
  name: 'NONE_4',
  volumeType: VolumeType.NONE,
}

export default [
  mockUserSelectProductType,
  mockStandaloneUserSelectProductType,
  mockAutofillProductType,
  mockNoneProductType,
] as IProductType[]
