import {atom} from 'jotai'
import {intervalAtom, rootServiceAtom} from './general'
import {partyAtom} from './party'
import {loadable} from './utils'

export const sellerOnboardingAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getSellerOnboardingState(party?.id)

    return data
  }),
)

export const buyerOnboardingAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getBuyerOnboardingState(party?.id)

    return data
  }),
)

export const buyerSiteDashboardAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const interval = get(intervalAtom)

    const data = await services.v2ContentService.getBuyerOnbSiteSummary(party?.id, interval)

    return data
  }),
)

export const sellerSiteDashboardAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const interval = get(intervalAtom)

    const data = await services.v2ContentService.getSellerOnbSiteSummary(party?.id, interval)

    return data
  }),
)
