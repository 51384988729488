export default class LocalStorageDataService<T> {
  private static KEY_PREFIX = 'renewabl/'
  private readonly key: string

  constructor(key: string) {
    this.key = key
  }

  public setItem(value: any): void {
    localStorage.setItem(this.fullKey, JSON.stringify(value))
  }

  public getItem(): T {
    const result = localStorage.getItem(this.fullKey)

    return result ? JSON.parse(result) : null
  }

  public removeItem(): void {
    localStorage.removeItem(this.fullKey)
  }

  private get fullKey(): string {
    return LocalStorageDataService.KEY_PREFIX + this.key
  }
}
