import React, {useState, useRef} from 'react'
import Table, {TableValue} from 'components/Table'
import {DateFormat, formatDate} from 'helpers/date'
import useLocalization from 'hooks/useLocalization'
import {ITenderRespond} from 'domain/ITenderRespond'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import {SiteProductionTechnology, SiteState} from 'domain/ISite'
import {getHumanReadableState} from 'helpers/site'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import Button from 'components/Button'
import {rootServiceAtom} from 'atoms/general'
import {CurrencyCode, PriceUnit} from 'domain/IPrice'

interface IProps extends React.PropsWithChildren {
  responds: ITenderRespond[]
}

const TenderRespondsTable: React.FC<IProps> = ({responds}) => {
  const {translate} = useLocalization()
  const [url, setUrl] = useState('')
  const party = useAtomValue(partyAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <>
      <Table<ITenderRespond>
        tableName={translate('Tender responses')}
        data={responds}
        linkTo={(respond: ITenderRespond) => `/broker/sell/tender-responses/${respond.id}`}
        columns={[
          {
            title: translate('Site name'),
            accessor: (respond: ITenderRespond) => respond.siteName,
          },
          {
            title: translate('Technology'),
            accessor: (respond: ITenderRespond) => respond.technology,
            render: (technology: SiteProductionTechnology) => <TechnologyIconAndName technology={technology} />,
          },
          {
            title: translate('Status'),
            accessor: (respond: ITenderRespond) => respond.state,
            render: (state: SiteState) => getHumanReadableState(state, translate),
          },
          {
            title: translate('Price'),
            accessor: (respond: ITenderRespond) => respond.price,
            render: (price: number) => <TableValue value={price} unit={CurrencyCode.EUR} />,
          },
          {
            title: translate('Nameplate capacity, MW'),
            accessor: (respond: ITenderRespond) => respond.capacityMwac,
            render: (capacity: number) => <TableValue rounded value={capacity} />,
          },
          {
            title: translate('Operations start'),
            accessor: (respond: ITenderRespond) => respond.operationsStart,
            render: (operationsStart: string) => formatDate(operationsStart, DateFormat.MONTH_DAY_YEAR),
          },
          {
            title: translate('Year'),
            accessor: (respond: ITenderRespond) => respond.year,
            render: (year: number) => <TableValue rounded value={year} />,
          },
          {
            title: translate('Profile'),
            disableLink: true,
            accessor: (respond: ITenderRespond) => respond.attachmentId,
            render: (attachmentId: string) => (
              <Button
                variant="link"
                pad={{left: 0}}
                style={{display: 'block', textAlign: 'left'}}
                onClick={async () => {
                  const url = await rootService.v2ContentService.downloadAttachment(party.id, attachmentId)
                  setUrl(url)
                  linkRef.current?.click()
                }}
              >
                {translate('Download')}
              </Button>
            ),
          },
        ].filter(Boolean)}
      />
      <a
        href={url}
        download="profile.xlsx"
        target="_blank"
        rel="noopener noreferrer"
        style={{display: 'none'}}
        ref={linkRef}
      />
    </>
  )
}

export default TenderRespondsTable
