import React from 'react'
import Page from '../../../Page'
import useLocalization from '../../../../hooks/useLocalization'
import NoContract from './index'
import useStore from '../../../../hooks/useStore'
import {getPartyLink} from '../../../../helpers/party'

const NoSignedContract: React.FC<React.PropsWithChildren> = () => {
  const {profileStore} = useStore()
  const {translate} = useLocalization()
  const {partyRole} = profileStore.party

  return (
    <Page
      title={translate('You don’t have any active contract at the moment')}
      description={translate('You can create the contract in contract designer')}
    >
      <NoContract
        buttonText={translate('Go to marketplace')}
        buttonLink={`/${getPartyLink(partyRole)}/buy/marketplace/products`}
      />
    </Page>
  )
}

export default NoSignedContract
