import React, {ReactElement} from 'react'
import useStore from '../hooks/useStore'
import useStoreData from '../hooks/useStoreData'

interface IProps extends React.PropsWithChildren {
  hoverKey: string
}

const ConnectedHoverable: React.FC<IProps> = ({hoverKey, children}) => {
  const {uiStore} = useStore()
  const hasMouseOver = useStoreData(store => store.uiStore.isHovered(hoverKey))

  return (
    <>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null
        }

        return React.cloneElement(child as ReactElement<any>, {
          hasMouseOver,
          onMouseOver: () => uiStore.setHoveredItem(hoverKey),
          onMouseOut: () => uiStore.clearHoveredItem(),
        })
      })}
    </>
  )
}

export default ConnectedHoverable
