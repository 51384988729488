import LocalStorageDataService from './LocalStorageDataService'

enum RelationType {
  ManyToOne,
  OneToOne,
  OneToMany,
  ManyToMany,
}

interface IRelation<T, K extends {id: any}> {
  service: LocalStorageRelationalDataService<K>
  type: RelationType
  localKey: keyof T
  inversedBy: keyof K
}

export default class LocalStorageRelationalDataService<T extends {id: any}> extends LocalStorageDataService<T> {
  private relations: Array<IRelation<T, any>> = []

  public addOneToManyRelation<K extends {id: any}>(
    service: LocalStorageRelationalDataService<K>,
    localKey: keyof T,
    inversedBy?: keyof K,
  ) {
    this.addRelation(RelationType.OneToMany, service, localKey, inversedBy)
  }

  public addManyToOneRelation<K extends {id: any}>(
    service: LocalStorageRelationalDataService<K>,
    localKey: keyof T,
    inversedBy?: keyof K,
  ) {
    this.addRelation(RelationType.ManyToOne, service, localKey, inversedBy)
  }

  public addOneToOne<K extends {id: any}>(
    service: LocalStorageRelationalDataService<K>,
    localKey: keyof T,
    inversedBy?: keyof K,
  ) {
    this.addRelation(RelationType.OneToOne, service, localKey, inversedBy)
  }

  public addManyToMany<K extends {id: any}>(
    service: LocalStorageRelationalDataService<K>,
    localKey: keyof T,
    inversedBy?: keyof K,
  ) {
    this.addRelation(RelationType.ManyToMany, service, localKey, inversedBy)
  }

  private addRelation<K extends {id: any}>(
    type: RelationType,
    service: LocalStorageRelationalDataService<K>,
    localKey: keyof T,
    inversedBy?: keyof K,
  ) {
    // Most likely recursively back here
    if (this.relations.find(r => r.localKey === localKey)) {
      return
    }

    this.relations.push({
      service,
      localKey,
      inversedBy,
      type,
    })

    if (inversedBy) {
      switch (type) {
        case RelationType.ManyToMany:
          service.addManyToMany<T>(this, inversedBy, localKey)
          break

        case RelationType.OneToOne:
          service.addOneToOne<T>(this, inversedBy, localKey)
          break

        case RelationType.OneToMany:
          service.addManyToOneRelation<T>(this, inversedBy, localKey)
          break

        case RelationType.ManyToOne:
          service.addOneToManyRelation<T>(this, inversedBy, localKey)
          break
      }
    }
  }

  public setItems(items: T[]): void {
    return super.setItems(
      items.map(item => {
        const relations: any = {}

        this.relations.forEach(relation => {
          const value: any = item[relation.localKey]

          if (
            !value ||
            typeof value !== 'object' ||
            (Array.isArray(value) && (!value.length || typeof value[0] !== 'object'))
          ) {
            return
          }

          switch (relation.type) {
            case RelationType.OneToMany:
              value.forEach(v => {
                relation.service.saveItem({
                  ...v,
                  [relation.inversedBy]: item.id,
                })
              })
              relations[relation.localKey] = value.map(({id}) => id)
              break

            case RelationType.ManyToMany:
              // eslint-disable-next-line
              const relatedItems = relation.service.getItems()
              value.forEach(v => {
                const relatedItem = relatedItems.find(i => i.id.toString() === v.id.toString())
                if (relatedItem) {
                  const existing = (relatedItem[relation.inversedBy] || []).map(({id}) => id)
                  if (!existing.includes(item.id)) {
                    relation.service.saveItem({
                      ...v,
                      [relation.inversedBy]: [...existing, item.id],
                    })
                  }
                } else {
                  relation.service.saveItem({
                    ...v,
                    [relation.inversedBy]: [item.id],
                  })
                }
              })
              relations[relation.localKey] = value.map(({id}) => id)
              break

            case RelationType.ManyToOne:
              // eslint-disable-next-line
              const relatedItem = relation.service.getItems().find(i => i.id?.toString() === value.id?.toString())
              if (relation.inversedBy) {
                if (relatedItem) {
                  const existing = (relatedItem[relation.inversedBy] || []).map(({id}) => id)
                  if (!existing.includes(item.id)) {
                    relation.service.saveItem({
                      ...value,
                      [relation.inversedBy]: [...existing, item.id],
                    })
                  }
                } else {
                  relation.service.saveItem({
                    ...value,
                    [relation.inversedBy]: [item.id],
                  })
                }
              } else {
                relation.service.saveItem({...value})
              }
              relations[relation.localKey] = value.id
              break

            case RelationType.OneToOne:
              relation.service.saveItem({
                ...value,
                [relation.inversedBy]: item.id,
              })
              relations[relation.localKey] = value.id
              break
          }
        })

        return {...item, ...relations}
      }),
    )
  }

  public getItems(ignore?: string[]): T[] {
    return super.getItems().map(item => {
      const relations: any = {}

      this.relations.forEach(relation => {
        if (ignore && ignore.includes(relation.localKey as string)) {
          delete item[relation.localKey]
          return
        }

        const value: any = item[relation.localKey]

        if (!value) {
          return
        }

        const relatedItems = relation.service.getItems([relation.inversedBy as string])

        switch (relation.type) {
          case RelationType.OneToMany:
          case RelationType.ManyToMany:
            relations[relation.localKey] = value
              .map(id => relatedItems.find(i => i.id?.toString() === id?.toString()))
              .filter(Boolean)
            break
          case RelationType.ManyToOne:
          case RelationType.OneToOne:
            relations[relation.localKey] = relatedItems.find(i => i.id?.toString() === value.toString())
            break
        }
      })

      return {...item, ...relations}
    })
  }
}
