import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import useProfile from '../../hooks/useProfile'
import Page from '../../components/Platform/Onboarding/Page'
import InnerScroll from '../../components/InnerScroll'
import OnboardingSteps, {Step} from '../../components/Platform/Onboarding/OnboardingSteps'
import EmailVerificationForm from '../../components/Platform/Onboarding/EmailVerificationForm'
import IUser from '../../domain/IUser'
import ICreatedUser from '../../domain/ICreatedUser'
import OnboardingCompleted from '../../components/Platform/Onboarding/OnboardingCompleted'
import IParty from '../../domain/IParty'
import CreateAccountForm from 'components/Platform/Onboarding/CreateAccountForm'
import ContactInfoForm from 'components/Platform/Onboarding/ContactInfoForm'
import VerticalStack from 'components/VerticalStack'

const getCurrentStep = (user: IUser, createdUser: ICreatedUser, party: IParty): Step => {
  if (!user && !createdUser) {
    return Step.LOGIN_INFO
  } else if (!user) {
    return Step.EMAIL_VERIFICATION
  } else if (!party) {
    return Step.CONTACT_INFO
  } else if (!party.partyRole) {
    return Step.PARTY_VERIFICATION
  } else {
    return Step.COMPLETED
  }
}

const CreateAccount = () => {
  const {user, party, isLoaded} = useProfile()
  const [createdUser, setCreatedUser] = useState<ICreatedUser>(null)
  const [currentStep, setCurrentStep] = useState<Step>(getCurrentStep(user, createdUser, party))

  useEffect(() => {
    setCurrentStep(getCurrentStep(user, createdUser, party))
  }, [user, createdUser, party])

  if (!isLoaded) {
    return null
  }

  return (
    <Page>
      <VerticalStack height="90%">
        <InnerScroll noRightPad>
          <OnboardingSteps current={currentStep} />
          {currentStep === Step.LOGIN_INFO && <CreateAccountForm onSuccess={(c: ICreatedUser) => setCreatedUser(c)} />}
          {currentStep === Step.EMAIL_VERIFICATION && <EmailVerificationForm createdUser={createdUser} />}
          {currentStep === Step.CONTACT_INFO && <ContactInfoForm />}
          {currentStep === Step.PARTY_VERIFICATION && <OnboardingCompleted />}
          {currentStep === Step.COMPLETED && <Redirect to="/" />}
        </InnerScroll>
      </VerticalStack>
    </Page>
  )
}
export default CreateAccount
