import {useEffect, useState, useRef} from 'react'

const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState<boolean>(true)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const updateNetworkStatus = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // Set a timeout to update the network status after 1 second, as the navigator reports online too early
    timeoutRef.current = setTimeout(() => {
      setOnline(navigator.onLine)
    }, 1000)
  }

  useEffect(() => {
    window.addEventListener('load', updateNetworkStatus)
    window.addEventListener('online', updateNetworkStatus)
    window.addEventListener('offline', updateNetworkStatus)

    return () => {
      window.removeEventListener('load', updateNetworkStatus)
      window.removeEventListener('online', updateNetworkStatus)
      window.removeEventListener('offline', updateNetworkStatus)
    }
  }, [navigator.onLine])

  return isOnline
}

export default useNetworkStatus
