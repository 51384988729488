import {PrimitiveAtom, atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const productIdAtom = atom<string>(null) as PrimitiveAtom<string>

export const marketListingsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    const data = await services.v2ContentService.getMarketListings(party?.id, page, pageSize)

    return data
  }),
)

export const basicProductsAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getBasicProducts(party?.id)

    return data
  }),
)

export const basicSitesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const data = await services.v2ContentService.getBasicSites(party?.id)

    return data
  }),
)

export const marketListingsByProductAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)
    const productId = get(productIdAtom)

    if (!productId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingsByProduct(party?.id, productId, page, pageSize)

    return data
  }),
  {nullIsLoading: true},
)

export const marketListingIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const marketListingAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const marketListingId = get(marketListingIdAtom)

    if (!marketListingId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingById(party?.id, marketListingId)

    return data
  }),
  {nullIsLoading: true},
)

export const marketListingReportAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const marketListingId = get(marketListingIdAtom)

    if (!marketListingId) {
      return null
    }

    const data = await services.v2ContentService.getMarketListingCertReport(party?.id, marketListingId)

    return data
  }),
  {nullIsLoading: true},
)
