import ISite, {SiteProductionTechnology} from './ISite'
import IAttachment from './IAttachment'
import IPrice from './IPrice'
import IProductType from './IProductType'
import IContract from './IContract'
import IParty from './IParty'
import {IRegoCertificate} from './IRegoCertificate'

export enum ProductState {
  DRAFT = 'DRAFT',
  IN_OPERATION = 'IN_OPERATION',
  RETIRED = 'RETIRED',
}

export interface ICertificateProduct {
  id: string
  name: string
  description?: string
  technology: SiteProductionTechnology
  quantity: number
  certificatePrice: string
  image: IAttachment
}

export interface IBasicProduct {
  id: number
  name: string
  description: string
}

export interface IUpdateProduct {
  name: string
  description: string
  imgAttachmentId: string
}

interface ISeller {
  id: string
  name: string
  logoUrl?: string
}

export default interface IProduct {
  id: any
  name: string
  state: ProductState
  factSheet?: IAttachment
  image?: IAttachment
  description: string
  salesStart: string
  salesEnd: string
  seller?: ISeller
  site?: ISite
  purchaseContract?: IContract
  totalVolumeForSaleMwh?: number
  totalContractedVolumeMwh?: number
  totalOfferedVolumeMwh?: number
  price: IPrice
  productType?: IProductType
  attachments?: IAttachment[]
  visibleToParties?: IParty[]
  emissionFactor: number
  certificate?: IRegoCertificate
  quantity?: number
  maxQuantity?: number
  availableQuantity?: number
  soldQuantity?: number
  reservedQuantity?: number
  totalQuantity?: number
}
