import {action, computed, observable} from 'mobx'

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

class Alert {
  @observable public message: string = null
  @observable public details: string = null
  @observable public type: AlertType = null
  @observable public dismissed = false
  @observable public correlationId = null

  constructor(
    message: string,
    type: AlertType,
    details: string = null,
    timeout: number = null,
    correlationId: string = null,
  ) {
    this.message = message
    this.type = type
    this.correlationId = correlationId
    this.details = details

    if (timeout) {
      setTimeout(() => this.dismiss(), timeout)
    }
  }

  @action
  public dismiss() {
    this.dismissed = true
  }
}

const DEFAULT_TIMEOUT_MS = 5000
export default class AlertStore {
  @observable private _alerts: Alert[] = []

  public addSuccess(message: string, details: string = null) {
    this.addAlert(message, details, AlertType.Success)
  }

  public addError(message: string, correlationId?: string, details: string = null) {
    this.addAlert(message || 'Something went wrong', details, AlertType.Error, DEFAULT_TIMEOUT_MS, correlationId)
  }

  public addInfo(message: string, correlationId?: string, details: string = null) {
    this.addAlert(message, details, AlertType.Info, 2000)
  }

  @action
  private addAlert(
    message: string,
    details: string = null,
    type: AlertType,
    timeout = DEFAULT_TIMEOUT_MS,
    correlationId?: string,
  ) {
    this._alerts.push(new Alert(message, type, details, timeout, correlationId))
  }

  @computed
  public get alerts() {
    return this._alerts.filter(alert => !alert.dismissed)
  }
}
