import React, {useEffect, useState} from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import DownloadExampleLink from '../Site/Form/DownloadExampleLink'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import FormRange from 'components/Form/FormRange'
import Label from 'components/Label'
import Text from 'components/Text'
import ValueWithUnit from 'components/ValueWithUnit'
import FormCertInput from 'components/Form/FormCertInput'
import {getAccreditationNum, getNumCertificates} from 'helpers/certificates'
import useFormContext from 'hooks/useFormContext'
import useTheme from 'hooks/useTheme'
import Heading from 'components/Heading'
import {FormControlLabel, RadioGroup, Tooltip} from '@mui/material'
import Radio from 'components/Radio'
import {getChangeEventValue} from 'helpers/misc'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import FormSelect from 'components/Form/FormSelect'
import {FeatureFlags} from 'components/FeatureFlagProvider'
import useFeatureFlags from 'hooks/useFeatureFlags'
import {useAtomValue} from 'jotai'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import useStore from 'hooks/useStore'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Button from 'components/Button'
import {MarketListingKind} from 'domain/IMarketListing'
import {getCurrentYear, getMonths, getPastYears, getPrevMonth} from 'helpers/date'

interface IProps extends React.PropsWithChildren {
  newProduct: boolean
  uploadType: TimeseriesKind
  setUploadType: (type: TimeseriesKind) => void
}

const ProductListingForm: React.FC<IProps> = ({newProduct, uploadType, setUploadType}) => {
  const {getFeatureFlag} = useFeatureFlags()
  const {translate} = useLocalization()
  const {getValues} = useFormContext()
  const {alertStore} = useStore()
  const [disabledCerts, setDisabledCerts] = useState(false)
  const theme = useTheme()
  const [volume, setVolume] = useState(100)
  const [staticStartCert, setStaticStartCert] = useState('')
  const [staticEndCert, setStaticEndCert] = useState('')
  const rootService = useAtomValue(rootServiceAtom)
  const party = useAtomValue(partyAtom)

  const allowGenerateCert = getFeatureFlag(FeatureFlags.ENABLE_CERT_GENERATION)
  const {product, listing, interval, site} = getValues({nest: true})
  const {technology, id: siteId} = site || {}
  const {id: productId, name: productName} = product || {}
  const {firstCertificate: startCert, lastCertificate: endCert, profileAttachment, builder} = listing || {}
  const certVolume = getNumCertificates(startCert, endCert)
  const validCerts = certVolume !== null
  const invalidAccNum = validCerts ? getAccreditationNum(startCert) !== getAccreditationNum(endCert) : false
  const months = getMonths()
  const years = getPastYears(2)
  const hasTimeseries = uploadType === TimeseriesKind.BUILDER || profileAttachment?.id
  const hasProduct = !newProduct && productId
  const hasSite = newProduct && (technology || siteId)
  const disableCertGenButton = !allowGenerateCert || !hasTimeseries || !(hasProduct || hasSite)

  const handleGenerateCertificates = async () => {
    try {
      const sitePayload = {
        kind: siteId ? MarketListingKind.EXISTING : MarketListingKind.NEW,
        payload: {
          technology: siteId ? undefined : technology,
          id: siteId ? +siteId : undefined,
        },
      }
      const payload = {
        product: {
          kind: newProduct ? MarketListingKind.NEW : MarketListingKind.EXISTING,
          payload: {
            site: newProduct ? sitePayload : undefined,
            id: newProduct ? undefined : +productId,
          },
        },
        volumePercent: listing.volumePercent,
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        timeseries: {
          kind: uploadType,
          payload: {
            hourlyVolumes: uploadType === TimeseriesKind.BUILDER ? builder?.hourlyVolumes : undefined,
            attachmentId: uploadType === TimeseriesKind.FILE_UPLOAD ? profileAttachment?.id : undefined,
          },
        },
      }
      const certs = await rootService.v2ContentService.generateMarketListingTestCertificates(party.id, payload)
      setDisabledCerts(true)
      setStaticStartCert(certs.start)
      setStaticEndCert(certs.end)
    } catch (ex) {
      alertStore.addError('Failed to generate test certificates', ex?.correlationId, ex?.message)
    }
  }

  useEffect(() => {
    setStaticStartCert('')
    setStaticEndCert('')
  }, [interval?.year, interval?.month, volume, productId, productName, siteId, technology, listing?.builder])

  return (
    <Box direction="column" gap={2}>
      <Box pad={2} round border>
        <Heading margin={{bottom: 1}} size="msmall">
          {translate('2. Upload generation')}
        </Heading>
        <RadioGroup row value={uploadType} onChange={e => setUploadType(getChangeEventValue(e))}>
          <FormControlLabel value={TimeseriesKind.BUILDER} control={<Radio />} label={translate('Build')} />
          <FormControlLabel
            data-cy="radio-upload"
            value={TimeseriesKind.FILE_UPLOAD}
            control={<Radio />}
            label={translate('Upload')}
          />
        </RadioGroup>
        <Label text={translate('Interval')}>
          <Box direction="row" gap={1} pad={{bottom: 2}} width="calc(50% - 8px)">
            <FormSelect
              style={{width: '100%'}}
              required
              name="interval.month"
              margin={0}
              options={months.map((month, index) => ({value: month, label: month}))}
              placeholder="Month"
              defaultValue={getPrevMonth()}
            />
            <FormSelect
              style={{width: '100%'}}
              width="100%"
              required
              name="interval.year"
              margin={0}
              options={years.map(year => ({value: year, label: `${year}`}))}
              placeholder="Year"
              defaultValue={getCurrentYear()}
            />
          </Box>
        </Label>
        {uploadType === TimeseriesKind.BUILDER && <FormProfileBuilder production name="listing.builder" />}
        {uploadType === TimeseriesKind.FILE_UPLOAD && (
          <>
            <FormAttachmentUpload
              label={translate('Upload profile')}
              name="listing.profileAttachment"
              removable
              required
            />

            <Box margin={{vertical: 2}}>
              <DownloadExampleLink interval={interval} siteType={SiteType.CONSUMPTION} />
            </Box>
          </>
        )}

        <Box gap={1} margin={{top: 2}} direction="column" width="50%">
          <FormRange
            label={translate('Generation volume dedicated to the marketplace')}
            name="listing.volumePercent"
            min={1}
            max={100}
            step={1}
            defaultValue={100}
            onChange={value => setVolume(+value)}
            data-cy="listing-volume"
          />

          <Label text={translate('Volume')}>
            <ValueWithUnit size="large" value={volume} unit={'%'} />
          </Label>
        </Box>
      </Box>

      <Box pad={2} round border>
        <Heading margin={{bottom: 2}} size="msmall">
          {translate('3. Add certificates and price')}
        </Heading>
        <FormInput
          name="listing.price"
          label={translate('Price')}
          type="number"
          defaultValue={''}
          placeholder={translate('Insert price as number with max two decimals')}
          data-cy="listing-price"
          required
        />

        <Box justify="end" margin={{top: 2, bottom: -4}}>
          <Button
            style={{zIndex: 99}}
            small
            type="button"
            variant="primary"
            onClick={handleGenerateCertificates}
            data-cy="generate-certs"
            disabled={disableCertGenButton}
          >
            <Tooltip
              title={disableCertGenButton ? translate('Previous steps should be completed') : undefined}
              arrow
              placement="bottom"
            >
              <div>
                <Box direction="row" gap={1}>
                  <AutoAwesomeIcon />
                  <Text>{translate('Generate test certificates')}</Text>
                  <AutoAwesomeIcon />
                </Box>
              </div>
            </Tooltip>
          </Button>
        </Box>

        <FormCertInput
          disabled={disabledCerts}
          required
          name="listing.firstCertificate"
          staticValue={disabledCerts && staticStartCert}
          label={translate('Start certificate number')}
        />
        <FormCertInput
          disabled={disabledCerts}
          staticValue={disabledCerts && staticEndCert}
          required
          name="listing.lastCertificate"
          label={translate('End certificate number')}
        />
        {validCerts && invalidAccNum && (
          <Text size="mlarge" color={theme.colors.error}>
            {translate('The accreditation number of start and end do not match')}
          </Text>
        )}

        <Box margin={{vertical: 2}}>
          <Label text={translate('Certificate volume')}>
            <ValueWithUnit size="large" value={certVolume || '-'} unit={certVolume ? 'EACs' : ''} />
          </Label>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductListingForm
