import {useEffect} from 'react'
import RootStore from '../store'
import useStoreData from './useStoreData'
import useStore from './useStore'

const useResource = <T>(get: (store: RootStore) => T, load?: (store: RootStore) => any, deps?: any): T => {
  const store = useStore()

  useEffect(() => {
    if (load) {
      load(store)
    }
    // eslint-disable-next-line
  }, [deps])

  return useStoreData(get)
}

export default useResource
