import React from 'react'
import Chart, {ChartType, ILine, StackType, getMaxValue, getMinValue} from 'components/Chart'
import {autoCeilNumber, autoFloorNumber} from 'helpers/misc'
import IWarehouseCertificates from 'domain/IWarehouseCertificates'
import {IChoice} from 'components/Filter'
import useTheme from 'hooks/useTheme'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  certs: IWarehouseCertificates
  filter: IChoice
}

const ALL_FILTER = 'ALL'

const WarehouseCertificatesChart: React.FC<IProps> = ({certs, filter}) => {
  const chartColors = useNewChartColors()
  const theme = useTheme()

  if (!certs) {
    return null
  }

  const {missing, mixed, wind, solar} = certs
  const labels = missing.map(({name}) => name) || []

  const lines: ILine[] = []
  lines.push({
    key: 'missing',
    label: 'Missing',
    color: theme.colors.common.black,
    data: missing.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.SECONDARY,
    showPoint: true,
    fill: false,
  })

  const data = {mixed, wind, solar}
  lines.push(
    ...Object.keys(data).map(key => ({
      key: key,
      label: key,
      color: chartColors.getNext(),
      data: data[key].map(({value}) => value),
      type: ChartType.LINE,
      stackType: StackType.STACKED,
      fill: true,
    })),
  )
  const filteredLines = lines.filter(line => line.key.toUpperCase() === filter?.slug || filter?.slug === ALL_FILTER)

  return (
    <Chart
      toolbar={{vertical: true}}
      labels={labels}
      lines={filteredLines}
      dark
      maxValue={autoCeilNumber(getMaxValue(filteredLines))}
      minValue={autoFloorNumber(getMinValue(filteredLines))}
    />
  )
}

export default WarehouseCertificatesChart
