import IContractType from '../../domain/IContractType'
import mockAttachments from './mockAttachments'

export const mockContractType1: IContractType = {
  id: 1,
  isDefault: true,
  name: 'CONTRACT_TYPE_1',
  termsAndConditions: mockAttachments[2],
}

const mockContractTypes: IContractType[] = [mockContractType1]

export default mockContractTypes
