import ProfileStore from './ProfileStore'
import RootService from '../services'
import NewTimeseriesStore from './NewTimeseriesStore'
import LocalizationStore from './LocalizationStore'
import AlertStore from './AlertStore'
import ProductStore from './ProductStore'
import SiteStore from './SiteStore'
import UIStore from './UIStore'
import ProductTypeStore from './ProductTypeStore'
import PartyStore from './PartyStore'
import PurchaseContractStore from './PurchaseContractStore'
import AdminStore from './AdminStore'

export default class RootStore {
  public adminStore: AdminStore
  public alertStore: AlertStore
  public profileStore: ProfileStore
  public newTimeseriesStore: NewTimeseriesStore
  public localizationStore: LocalizationStore
  public productStore: ProductStore
  public productTypeStore: ProductTypeStore
  public siteStore: SiteStore
  public uiStore: UIStore
  public partyStore: PartyStore
  public purchaseContractStore: PurchaseContractStore

  public constructor(rootService: RootService) {
    this.adminStore = new AdminStore(this, rootService.contentService, rootService.v2ContentService)
    this.alertStore = new AlertStore()
    this.localizationStore = new LocalizationStore()
    this.profileStore = new ProfileStore(
      rootService.authenticationService,
      rootService.contentService,
      rootService.v2ContentService,
      this.localizationStore,
    )
    this.newTimeseriesStore = new NewTimeseriesStore(rootService.contentService, rootService.v2ContentService)
    this.uiStore = new UIStore()
    this.siteStore = new SiteStore(this, rootService.contentService)
    this.productStore = new ProductStore(this, rootService.contentService)
    this.productTypeStore = new ProductTypeStore(this, rootService.contentService)
    this.partyStore = new PartyStore(this, rootService.contentService)
    this.purchaseContractStore = new PurchaseContractStore(this, rootService.contentService)
  }
}
