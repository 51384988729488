import React from 'react'
import Range, {IProps as IRangeProps} from '../Range'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'

type IProps = IWrapperProps<IRangeProps>

const FormRange: React.FC<IProps> = props => {
  const {min, max, contrast} = props

  return (
    <FormInputWrapper {...props}>
      <Range min={min} max={max} contrast={contrast} />
    </FormInputWrapper>
  )
}

export default FormRange
