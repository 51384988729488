import IOffer, {OfferKind} from '../../domain/IOffer'
import {mockOfferContract1, mockOfferContract2} from './mockContracts'
import {mockBrokerParty, mockBuyerParty1} from './mockParties'

export const mockOffer1: IOffer = {
  id: 1,
  name: 'SEED_OFFER_1',
  contract: mockOfferContract1,
  customer: mockBuyerParty1,
  managedByParty: mockBrokerParty,
  lgcMinPrice: 0,
  lgcMaxPrice: 0,
  minPrice: 51,
  maxPrice: 55.232,
  totalPrice: 100,
  numberOfNmis: 2,
  operationalFocus: 2,
  riskAppetite: 4,
  kind: OfferKind.CERTIFICATE_TRADE,
}

export const mockOffer2: IOffer = {
  id: 2,
  name: 'SEED_OFFER_2',
  contract: mockOfferContract2,
  customer: mockBuyerParty1,
  lgcMinPrice: 2.23,
  lgcMaxPrice: 9,
  minPrice: 51,
  maxPrice: 55.232,
  totalPrice: 100,
  numberOfNmis: 2,
  operationalFocus: 2,
  riskAppetite: 4,
  kind: OfferKind.CERTIFICATE_TRADE,
}

export default [mockOffer1, mockOffer2]
