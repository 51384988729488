import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import OfferListPage from '../../../components/Platform/Portfolio/Offer/OfferListPage'
import OfferDesignerCertificatePage from 'components/Platform/Portfolio/Offer/OfferDesignerCertificatePage'

const Offers = () => {
  return (
    <ResourceGateway
      single={routeProps => <OfferDesignerCertificatePage id={routeProps.match.params.id} />}
      list={() => <OfferListPage />}
    />
  )
}

export default Offers
