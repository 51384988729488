import React from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import Checkbox, {IProps as ICheckboxProps} from '../Checkbox'

type IProps = IWrapperProps<ICheckboxProps>

const FormCheckbox: React.FC<IProps> = ({label, ...props}) => {
  return (
    <FormInputWrapper {...props}>
      <Checkbox label={label} />
    </FormInputWrapper>
  )
}

export default FormCheckbox
