import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import Label from './Label'
import InputError from './InputError'
import Box, {IProps as IBoxProps} from './Box'

export interface IOption {
  label: string
  value: string | number
  disabled?: boolean
  hidden?: boolean
}

export interface IProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    Pick<IBoxProps, 'margin'> {
  label?: string
  error?: string
  options?: IOption[]
  color?: string
  placeholder?: string
}

const toPx = (value: string | number) => (typeof value === 'number' ? `${value}px` : value)
const renderBorderStyle = (value: string | number | undefined, theme: ITheme) => {
  if (value === undefined) {
    return `1px solid ${theme.colors.lightGrey}`
  }

  return typeof value === 'number' ? `${value}px solid ${theme.colors.lightGrey}` : value
}

const Container = styled(Box)`
  position: relative;
`

const StyledSelect = styled.select<{
  hasError?: boolean
  borderRadius: string | number
  border: string | number
  backgroundColor: string
  isPlaceholder: boolean
}>`
  height: 40px;
  font-size: 14px;
  border: ${props => renderBorderStyle(props.border, props.theme)};
  border-radius: ${props => (props.borderRadius ? toPx(props.borderRadius) : '4px')};

  min-width: 120px;
  color: ${props => (props.theme as ITheme).colors.secondary};
  background-color: ${props => props.backgroundColor || (props.theme as ITheme).colors.surface};
  font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
  font-weight: 200;
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  outline: none;

  &:focus {
    border-color: ${props => (props.theme as ITheme).colors.primary};
  }

  &:hover {
    border-color: ${props =>
      props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.secondary};
  }

  ${props =>
    props.isPlaceholder &&
    `
    && {
      font-size: 14px;
      color: #999;
    }
  `}

  ${props =>
    props.hasError &&
    `
    && {
      border-color: ${(props.theme as ITheme).colors.error};
    }
  `}
`

// eslint-disable-next-line react/display-name
const Select: React.FC<IProps> = React.forwardRef(
  ({error, label, margin, value, options, style, placeholder, ...props}, ref: React.Ref<any>) => {
    const allOptions = placeholder ? [{label: placeholder, value: '', disabled: true}, ...(options || [])] : options

    const content = (
      <Container margin={margin} errorMargin={!!error && 1} style={{...style, border: undefined}}>
        <StyledSelect
          {...props}
          value={value}
          ref={ref}
          hasError={!!error}
          // the bg color is already applied to the parent container
          // (which makes opacity apply twice if we don't override it)
          backgroundColor={style?.backgroundColor ? 'transparent' : undefined}
          border={style?.border}
          borderRadius={style?.borderRadius}
          isPlaceholder={placeholder && !value}
        >
          {(allOptions || []).map(({value, label, disabled, hidden}) => (
            <option key={`${label}/${value}`} value={value || ''} disabled={disabled}>
              {label}
            </option>
          ))}
        </StyledSelect>
        {error && <InputError>{error}</InputError>}
      </Container>
    )

    if (label) {
      return <Label text={label}>{content}</Label>
    }

    return content
  },
)

Select.defaultProps = {
  margin: {
    bottom: 2,
  },
}

export default Select
