import {SiteShape} from '../../../../../domain/ISite'
import {getHumanReadableShape} from '../../../../../helpers/site'
import FormSelect, {IProps} from '../../../../Form/FormSelect'
import React from 'react'
import useLocalization from '../../../../../hooks/useLocalization'

const SiteShapeSelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return (
    <FormSelect
      label={translate('Profile')}
      {...props}
      options={Object.keys(SiteShape).map(shape => ({
        value: shape,
        label: getHumanReadableShape(shape as SiteShape, translate),
      }))}
    />
  )
}

export default SiteShapeSelect
