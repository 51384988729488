import React from 'react'
import greenEnergyIcon from '../assets/icons/green-energy.svg'
import Box from './Box'
import Heading from './Heading'
import Text from './Text'
import Helmet from './Helmet'
import {CorrelationIdMessage} from './Layout/Alerts'
import {showAdminMessage} from 'helpers/user'
import ApiError from 'exceptions/ApiError'
import useProfile from 'hooks/useProfile'
import useLocalization from 'hooks/useLocalization'

const ErrorInfo: React.FC<React.PropsWithChildren<{title?: string; error?: ApiError}>> = ({title, error, children}) => {
  const {user} = useProfile()
  const {translate} = useLocalization()
  const {correlationId, message} = error || {}

  return (
    <Helmet title={title}>
      <Box pad={4} direction="column" align="center">
        <img src={greenEnergyIcon} style={{maxWidth: 200}} alt="Green energy icon" />

        {title && <Heading margin={{top: 4}}>{title}</Heading>}
        {!title && !children && <Heading margin={{top: 4}}>{translate('Something went wrong')}</Heading>}
        <Text>{children}</Text>
        {showAdminMessage(user) && message && <Text>Admin: {message}</Text>}
        {correlationId && <CorrelationIdMessage correlationId={correlationId} black />}
      </Box>
    </Helmet>
  )
}

export default ErrorInfo
