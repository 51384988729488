import React from 'react'
import {Link as RouterLink, LinkProps} from 'react-router-dom'

export type IProps = Pick<LinkProps, 'to'> & React.HTMLAttributes<HTMLSpanElement>

const Link: React.FC<IProps> = ({to, ...rest}) => {
  if (to) {
    return <RouterLink to={to} {...rest} />
  }

  return <span {...rest} />
}

export default Link
