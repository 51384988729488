export enum TransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export default interface IContractParty {
  id: number
  partyId: number
  contractId: number
  transactionType: TransactionType
}
