import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import TermsAndConditions from './TermsAndConditions'
import Box from '../Box'
import useTheme from '../../hooks/useTheme'
import Grid from '../Grid'
import styled from 'styled-components'
import InnerScroll from '../InnerScroll'
import RouterModal from '../Modal/RouterModal'
import ModalHeader from '../Modal/ModalHeader'
import {ModalType} from '../Modal/IModal'

const StyledGrid = styled(Grid)`
  overflow: hidden;
`

const TermsModal: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <RouterModal name={ModalType.TERMS} full>
      <ModalHeader
        title={translate('Terms and conditions')}
        description={translate('Overview of terms and conditions')}
      />
      <StyledGrid>
        <InnerScroll noRightPad>
          <Box>
            <iframe src="/files/rulebook.pdf" style={{height: '100vh', width: '100%', border: 0}} />
          </Box>
        </InnerScroll>
      </StyledGrid>
    </RouterModal>
  )
}

export default TermsModal
