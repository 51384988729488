import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 10%;
  min-width: 100px;
  max-width: 140px;
`

const PerformanceLoadingSkeleton: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Box direction={'row'} height="32px" margin={{top: -1, bottom: 1}}>
        <Skeleton width={365} height={54} />
        <Box width={'16px'}></Box>
        <Skeleton width={315} height={54} />
      </Box>
      <Box direction="row" height={15} justify="space-between">
        <Skeleton width="32%" />
        <Skeleton width="32%" />
        <Skeleton width="32%" />
      </Box>

      <Box direction="row" height="256px">
        <Box width="87%">
          <Skeleton height={256} variant="rectangular"></Skeleton>
        </Box>
        <StyledBox pad={{top: 2}} margin={{left: 3}}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </StyledBox>
      </Box>

      <Box direction="column" pad={{top: 3}} gap={3}>
        <Skeleton height={55} width="15%" />
        <Box direction="column" gap={1}>
          <Skeleton height={40} width="100%" variant="rectangular" />
          <Skeleton height={40} width="100%" variant="rectangular" />
        </Box>
      </Box>
    </>
  )
}

export default PerformanceLoadingSkeleton
