import {ISubscriptionPlan} from 'domain/IParty'
import {SubscriptionStatus} from 'domain/ISubscription'

export const humanReadablePlan = (kind: ISubscriptionPlan): string => {
  switch (kind) {
    case ISubscriptionPlan.BASIC:
      return 'Basic'
    case ISubscriptionPlan.PRO:
      return 'Pro'
    case ISubscriptionPlan.ENTERPRISE:
      return 'Enterprise'
  }
}

export const humanReadableSubscriptionStatus = (status: SubscriptionStatus): string => {
  switch (status) {
    case SubscriptionStatus.Active:
      return 'Active'
    case SubscriptionStatus.Canceled:
      return 'Canceled'
    case SubscriptionStatus.PendingCancellation:
      return 'Pending cancellation'
    default:
      return 'Ended'
  }
}
