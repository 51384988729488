import {SvgIcon} from '@mui/material'
import React from 'react'

const EpsilonIcon = () => {
  return (
    <SvgIcon fontSize="small">
      <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" fill="#000">
        <path d="M523.85-480 284.54-722.62q-2.92-2.92-3.73-5.93t-.81-6.23v-9.24q0-6.83 4.64-11.4 4.65-4.58 11.51-4.58h359.23q10.26 0 17.44 7.21 7.18 7.2 7.18 17.5 0 10.29-7.18 17.79t-17.44 7.5h-291.3l202.46 207.15q9.15 9.4 9.15 22.7 0 13.3-9.15 23L364.08-249.23h291.3q10.26 0 17.44 7.2 7.18 7.21 7.18 17.51 0 10.29-7.18 17.4-7.18 7.12-17.44 7.12H291.31q-4.68 0-8-3.31-3.31-3.32-3.31-8v-16.74q0-2.64.8-4.44.81-1.8 2.82-3.43L523.85-480Z"></path>
      </svg>
    </SvgIcon>
  )
}

export default EpsilonIcon
