import React from 'react'
import Page, {IProps as IPageProps} from '../../Page'
import Grid from '../../Grid'
import InnerScroll from '../../InnerScroll'

interface IProps extends React.PropsWithChildren, IPageProps {
  aside?: React.ReactNode
  verticalGap?: number
}

const AccountPage: React.FC<IProps> = ({children, aside, verticalGap = 5, ...pageProps}) => {
  return (
    <Page {...pageProps}>
      <InnerScroll>
        <Grid columns={2} gap={5}>
          <Grid columns={1} gap={verticalGap}>
            {children}
          </Grid>
          <aside>{aside}</aside>
        </Grid>
      </InnerScroll>
    </Page>
  )
}

export default AccountPage
