import React from 'react'
import Box from '../../../Box'
import Grid from '../../../Grid'

interface IProps extends React.PropsWithChildren {
  noPad?: boolean
}

const ResourceSummary: React.FC<IProps> = ({children, noPad}) => {
  return (
    <Box pad={{vertical: noPad ? 0 : 2}} data-c="ResourceSummary">
      <Grid columns={Math.min(React.Children.count(children), 6)} gap={4} alignItems="end">
        {children}
      </Grid>
    </Box>
  )
}

export default ResourceSummary
