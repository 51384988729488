import React from 'react'
import useTheme from '../hooks/useTheme'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import spacing from './styleProps/spacing'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'

interface IProps extends React.PropsWithChildren {
  to?: string
}

const StyledLogoImage = styled.img`
  height: ${props => (props.theme as ITheme).tileSize * props.theme.logo.height}px;
  ${spacing}
  display: block;
  filter: contrast(0.9);
`

const Logo: React.FC<IProps> = ({to}) => {
  const theme = useTheme()
  const party = useAtomValue(partyAtom)

  if (!party) {
    return null
  }

  const logo = <StyledLogoImage src={theme.logo.logo} alt="logo" />

  if (to) {
    return <Link to={to}>{logo}</Link>
  }

  return logo
}

export default Logo
