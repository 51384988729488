import React, {memo} from 'react'
import IProduct from '../../../domain/IProduct'
import ProductMapMarker from './ProductMapMarker'
import MapWithHeader, {IProps as IMapProps} from '../../Map/MapWithHeader'

interface IProps extends React.PropsWithChildren, IMapProps {
  title?: string
  description?: string
  products: IProduct[]
  urlPrefix?: string
}

const ProductMap: React.FC<IProps> = ({title, description, products, urlPrefix, ...mapProps}) => {
  return (
    <MapWithHeader title={title} description={description} {...mapProps}>
      {(products || []).map(product => {
        const location = product.site?.location

        if (!location || !location.coordinatesLat || !location.coordinatesLon) {
          return null
        }

        return (
          <ProductMapMarker
            urlPrefix={urlPrefix}
            product={product}
            key={product.id}
            lat={+location.coordinatesLat}
            lng={+location.coordinatesLon}
          />
        )
      })}
    </MapWithHeader>
  )
}

export default memo(ProductMap)
