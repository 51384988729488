import ITheme from '../../theme/ITheme'

export interface IRoundProps {
  round?: boolean | number | string
}

const round = (props: IRoundProps & {theme: ITheme}): string => {
  if (!props.round) {
    return null
  }

  let value: string

  if (typeof props.round === 'number') {
    value = `${props.round * props.theme.tileSize}px`
  } else if (props.round === true) {
    value = `${props.theme.tileSize * props.theme.roundness}px`
  } else {
    value = props.round
  }

  return `
    border-radius: ${value};
    overflow: hidden;
  `
}

export default round
