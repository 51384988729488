import React, {useRef} from 'react'
import ConfirmationDialog from 'components/ConfirmationDialog'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {kpiAtom, kpiTargetsAtom} from 'atoms/portfolioOverview'
import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import useStore from 'hooks/useStore'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'

const GoalsModal = ({setModalType}) => {
  const {alertStore} = useStore()
  const formRef = useRef<HTMLFormElement>(null)
  const {translate} = useLocalization()
  const [{data}, refetchTargets] = useAtom(kpiTargetsAtom)
  const rootService = useAtomValue(rootServiceAtom)
  const refetchKpi = useSetAtom(kpiAtom)
  const party = useAtomValue(partyAtom)
  const {hourlyPercent, monthlyPercent, yearlyPercent} = data?.targets || {}
  const targetsExist = !!data?.targets

  const handleAccept = () => {
    formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
  }

  const handleSave = async data => {
    const payload = {
      hourlyPercent: data.hourly ? +data.hourly : null,
      monthlyPercent: data.monthly ? +data.monthly : null,
      yearlyPercent: data.yearly ? +data.yearly : null,
    }

    try {
      if (targetsExist) {
        await rootService.v2ContentService.updateKPITargets(party?.id, payload)
      } else {
        await rootService.v2ContentService.createKPITargets(party?.id, payload)
      }
      refetchKpi()
      refetchTargets()

      alertStore.addSuccess(translate('Goals successfully set'))
    } catch (e) {
      alertStore.addError(translate('Failed to set goals'), e?.correlationId, e?.message)
    }
    setModalType(undefined)
  }

  return (
    <Form
      onSubmit={handleSave}
      submissionFeedback={null}
      ref={formRef}
      defaultValues={{hourly: hourlyPercent || '', monthly: monthlyPercent || '', yearly: yearlyPercent || ''}}
    >
      <ConfirmationDialog
        title={translate('Set your sustainability goals for current year')}
        content={
          <Box direction="column">
            <FormInput
              min={1}
              max={100}
              type="number"
              placeholder="Enter a number between 1 and 100"
              label="Hourly coverage %"
              name="hourly"
              data-cy="goals-hourly"
            />
            <FormInput
              min={1}
              max={100}
              type="number"
              placeholder="Enter a number between 1 and 100"
              label="Monthly coverage %"
              name="monthly"
              data-cy="goals-monthly"
            />
            <FormInput
              min={1}
              max={100}
              type="number"
              placeholder="Enter a number between 1 and 100"
              label="Yearly coverage %"
              name="yearly"
              data-cy="goals-yearly"
            />
          </Box>
        }
        open
        setOpen={() => setModalType(undefined)}
        acceptText={translate('Set')}
        declineText={translate('Close')}
        onAccept={handleAccept}
      />
    </Form>
  )
}

export default GoalsModal
