import React, {useEffect} from 'react'
import ActionBox from '../../ActionBox'
import useLocalization from 'hooks/useLocalization'
import Heading from 'components/Heading'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import useResource from 'hooks/useResource'
import OfferProduct from '../Offer/OfferDesignerCertificate/OfferProduct'
import ContractDownloadLinks from '../ContractDownloadLinks'
import ContractHtmlPreview from '../ContractHtmlPreview'
import useScreenSize, {ScreenBreakpoint} from 'hooks/useScreenSize'
import ProposalDetailsInfo from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsInfo'
import ProposalDetailsChart from 'components/Platform/Buy/CertificateDesigner/ProposalDetailsChart'
import Page from 'components/Page'
import InnerScroll from 'components/InnerScroll'
import Text from 'components/Text'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Label from 'components/Label'
import ValueWithUnit from 'components/ValueWithUnit'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MatchedCertsTable from './MatchedCertsTable'
import {useAtomValue, useSetAtom} from 'jotai'
import {buyerContractAtom, offerIdAtom} from 'atoms/contracts'

interface IProps extends React.PropsWithChildren {
  offerId: number
}

const CertificateContractPage: React.FC<IProps> = ({offerId}) => {
  const {translate} = useLocalization()
  const screenSize = useScreenSize()
  const theme = useTheme()
  const {data: offer, loading} = useAtomValue(buyerContractAtom)
  const setOfferId = useSetAtom(offerIdAtom)
  const {name, estimatedVolume, totalPrice, averagePrice, greenCoverage, timeseries, currency} = offer || {}

  useEffect(() => {
    setOfferId(offerId)
  }, [offerId])

  return (
    <Page isLoading={loading} noHeader mainPad={{top: 2, right: 2, bottom: 2, left: 2}}>
      <InnerScroll noRightPad>
        <Box direction="column" gap={2}>
          <ResponsiveGrid
            gap={2}
            templateColumns={{[ScreenBreakpoint.Medium]: '1fr', [ScreenBreakpoint.Large]: '1fr 3fr'}}
          >
            <ActionBox
              title={translate('Contract summary')}
              description={translate('Overview of your contract key facts')}
              fullWidth
            >
              <Box gap={2} direction="column">
                <Label text={translate('Name')}>
                  <Text size="large" semibold lineHeight="small" data-cy="contract-name">
                    {name}
                  </Text>
                </Label>
                <Label text={translate('Estimated month volume')}>
                  <ValueWithUnit value={estimatedVolume} unit="CERTIFICATES" />
                </Label>
              </Box>
            </ActionBox>
            <ActionBox title={translate('Contract details')} fullWidth>
              <Box direction="column" gap={3} width="100%">
                <ProposalDetailsInfo
                  hasConsumption
                  proposalDetails={{totalPrice, averagePrice, greenCoverage, timeseries}}
                  draft={false}
                />
                <ProposalDetailsChart timeseries={timeseries} hideMissing />
              </Box>
            </ActionBox>
          </ResponsiveGrid>

          <Box direction="column">
            <OfferProduct product={offer?.product} currency={currency} />

            <Accordion
              sx={{
                padding: 1,
                mt: 0,
                mb: 2,
                border: `1px solid ${theme.colors.lightGrey}`,
                boxShadow: 'none',
                borderRadius: 2,
              }}
              slotProps={{transition: {timeout: 100}}}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Heading>{translate('Matched EACs')}</Heading>
              </AccordionSummary>
              <AccordionDetails>
                <MatchedCertsTable data={offer?.matchedCerts} />
              </AccordionDetails>
            </Accordion>

            <Box pad={2} border round style={screenSize >= ScreenBreakpoint.Large ? {gridColumn: 'span 2'} : null}>
              <Heading margin={{bottom: 2}}>{translate('Agreement')}</Heading>
              {offer?.contract && (
                <>
                  <ContractDownloadLinks contract={offer?.contract} />
                  <ContractHtmlPreview id={offer?.contract?.id} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </InnerScroll>
    </Page>
  )
}

export default CertificateContractPage
