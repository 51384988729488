import env from '@beam-australia/react-env'
import React, {useState, createContext} from 'react'

interface FeatureFlagsContext {
  features: Record<FeatureFlags, boolean>
  getFeatureFlag: (name: FeatureFlags) => boolean
}

export const FeatureFlagsContext = createContext<FeatureFlagsContext>(null)

export const enum FeatureFlags {
  ENABLE_CERT_GENERATION = 'ENABLE_CERT_GENERATION',
}

export const FeatureFlagProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [features, setFeatures] = useState({
    ENABLE_CERT_GENERATION: env('ENABLE_CERT_GENERATION') === 'true',
  })

  const getFeatureFlag = (name: FeatureFlags) => features[name]

  return <FeatureFlagsContext.Provider value={{features, getFeatureFlag}}>{children}</FeatureFlagsContext.Provider>
}

export default FeatureFlagProvider
