import useStore from './useStore'
import useStoreData from './useStoreData'
import useTheme from './useTheme'

interface IUseLocalization {
  locale: string
  translate(text: string, ...modifiers: Array<string | number>): string
}

const useLocalization = (): IUseLocalization => {
  const {localizationStore} = useStore()
  const locale = useStoreData(store => store.localizationStore.locale)
  const theme = useTheme()

  return {
    locale,
    translate: (text, ...modifiers) => localizationStore.translate(text, theme, ...modifiers),
  }
}

export default useLocalization
