import React from 'react'
import Heading from '../../../Heading'
import useLocalization from '../../../../hooks/useLocalization'
import Box from '../../../Box'
import Text from '../../../Text'
import styled from 'styled-components'
import useTheme from '../../../../hooks/useTheme'
import {ModalType} from 'components/Modal/IModal'
import ModalLink from 'components/Modal/ModalLink'
import ITheme from 'theme/ITheme'
import CheckIcon from '@mui/icons-material/Check'

const Container = styled(Box)`
  max-height: none;
  flex-direction: column;
  display: flex;
  align-self: center;
  color: ${props => props.theme.colors.common.white};
`

const StyledLogo = styled.img`
  height: 12px;
  filter: invert(1);
  opacity: 0.5;
  margin-bottom: 4px;
`

export const FooterLink = styled(ModalLink)`
  display: block;
  color: ${props => (props.theme as ITheme).colors.primaryDark};
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`

const SubText: React.FC<{text: string}> = ({text}) => {
  return (
    <Box direction="row" align="center" gap={1}>
      <CheckIcon />
      <Text size="large">{text}</Text>
    </Box>
  )
}

const Aside = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <>
      <Container style={{flex: 1}}>
        <Box height={'50%'} />
        <Box style={{maxWidth: '600px'}} justify="center" direction="column" margin={{left: 3}}>
          <Text semibold size="xxxxlarge" font={theme.font.headings} pad={{top: 3}} style={{lineHeight: '1.2'}}>
            {translate('Powering your path to net zero')}
          </Text>
          <SubText text={translate('For a seamless transition to renewable energy.')} />
          <SubText text={translate('For data-driven sustainability leaders.')} />
          <SubText text={translate('For pioneering energy procurement.')} />
        </Box>
      </Container>
      <Container width="100%" margin={{top: 2}}>
        <Box justify="space-between" direction="column" align="start" gap={0.5} pad={{bottom: 3}} data-c="Footer">
          <StyledLogo src={theme.logo.logo} alt="logo" />
          <FooterLink modal={ModalType.TERMS}>{translate('Terms and conditions')}</FooterLink>
          <FooterLink modal={ModalType.COOKIES}>{translate('Cookie policy')}</FooterLink>
          <FooterLink modal={ModalType.PRIVACY}>{translate('Privacy policy')}</FooterLink>
          <FooterLink modal={ModalType.SECURITY}>{translate('Security')}</FooterLink>
          <FooterLink as="a" href="mailto:support@renewabl.com" data-cy="footer-support-link">
            {translate('Support')}
          </FooterLink>
        </Box>
      </Container>
    </>
  )
}

export default Aside
