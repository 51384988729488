import IParty from './IParty'
import IUser from './IUser'
import {PartyMemberRole} from './PartyMemberRole'

export enum RepresentationBasis {
  CI_CUSTOMER_MANAGER = 'CI_CUSTOMER_MANAGER',
  MEMBER_OF_BOARD = 'MEMBER_OF_BOARD',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}

export default interface IPartyMember {
  id: number
  representationBasis: RepresentationBasis
  party: IParty
  user?: IUser
  partyMemberRoles: PartyMemberRole[]
}
