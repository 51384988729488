export enum VolumeType {
  USER_SELECT = 'USER_SELECT',
  AUTO_FILL = 'AUTO_FILL',
  NONE = 'NONE',
  STANDALONE_USER_SELECT = 'STANDALONE_USER_SELECT',
}

export enum ProductTypeBehaviour {
  ELECTRICITY = 'ELECTRICITY',
  LGC = 'LGC',
  BALANCING = 'BALANCING',
  DUTCH_COO = 'DUTCH_COO',
}

export default interface IProductType {
  id: any
  name: string
  volumeType: VolumeType
  behaviour?: ProductTypeBehaviour
  priceVisibleToCustomer?: boolean
}
