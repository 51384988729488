import {isString, round, replace} from 'lodash-es'
import ITheme from 'theme/ITheme'

export function formatNumber(value: number, digits = 2): string {
  const formattedValue = isString(value) ? Number(value) : value

  return formattedValue && formattedValue.toFixed(digits)
}

export function formatDecimal(value: number | string, digits = 2): string {
  const formattedValue = isString(value) ? Number(value) : value

  return formattedValue && formattedValue.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export function formatEnergy(value: number, theme?: ITheme): number {
  const formattedValue = formatDecimal(value, theme?.numberDecimals)

  return Number(formattedValue ? formattedValue.replace(/\s/g, '') : formattedValue)
}

export function formatPercentage(value: number, theme?: ITheme): number {
  return Number(formatDecimal(value * 100, theme?.numberDecimals)?.replace(' ', ''))
}

export function formatMoney(value: number | string, theme?: ITheme): string {
  const formattedValue = isString(value) ? Number(value) : value

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  }).format(formattedValue)
}

export function formatPriceToAbbrevations(value: number): string {
  const formattedValue = isString(value) ? Number(value) : value

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(formattedValue)
}

export function formatPrice(value: number) {
  return round(value, 2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
