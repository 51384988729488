import React, {useContext} from 'react'
import {ModalContext} from './index'

export const ModalCloser: React.FC<React.PropsWithChildren> = ({children}) => {
  const {handleClose} = useContext(ModalContext)

  return (
    <div onClick={handleClose} data-cy="modal-closer">
      {children}
    </div>
  )
}

export default ModalCloser
