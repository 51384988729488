import VerticalStack from 'components/VerticalStack'
import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components'
import {getAccountType, getFullName} from 'helpers/user'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import ITheme from 'theme/ITheme'
import Avatar from 'components/Avatar'
import Box from 'components/Box'
import Text from 'components/Text'

const Container = styled.div`
  height: 72px;
  padding: 15px 16px;
  background-color: ${({theme}: {theme: ITheme}) => theme.colors.primary};
  box-sizing: border-box;
  display: flex;
  align-items: center;
`

const UserInfo = styled.div`
  color: ${({theme}: {theme: ITheme}) => theme.colors[theme.colorBindings.textOnBrandedBackground]};
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${props => (props.theme as ITheme).tileSize}px;
`

interface IProps extends React.PropsWithChildren {
  name: string
  company?: string
  role?: string
  image?: string
}

const CoverIcon = () => {
  const theme = useTheme()

  return (
    <svg
      style={{position: 'absolute', left: 0}}
      width="200"
      height="72"
      viewBox="0 0 200 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_110)">
        <g opacity="0.68" clipPath="url(#clip1_5_110)">
          <path
            d="M39.6667 29.9646V-6.48095C39.6667 -9.23651 37.3545 -11.5 34.5062 -11.5H19.4603C16.612 -11.5 14.3333 -9.23651 14.3333 -6.48095V29.9646C14.3333 32.7529 16.6455 34.9836 19.4603 34.9836H28.1729C31.0212 34.9836 33.2998 37.2471 33.2998 40.0026V76.4482C33.2998 79.2365 35.612 81.4672 38.4268 81.4672H53.4727C56.321 81.4672 58.5997 79.2037 58.5997 76.4482V40.0355C58.5997 37.2471 56.2875 35.0164 53.4727 35.0164H44.7602C41.9453 34.9836 39.6667 32.7529 39.6667 29.9646Z"
            fill={theme.colors.globalBackground}
          />
        </g>
      </g>
    </svg>
  )
}

const UserBadge = ({name, company, role: role, image}: IProps) => {
  const theme = useTheme()
  return (
    <Container>
      <CoverIcon />
      <Avatar name={name} image={image} colored />
      <VerticalStack margin={{left: 0.5}}>
        <UserInfo>
          {company && (
            <Box justify="start" style={{maxWidth: 135}}>
              <Text size="medium" lineHeight="msmall" inline overflowEllipsis font={theme.font.secondaryFont} light>
                {company}
              </Text>
            </Box>
          )}
          {role && (
            <Box justify="start">
              <Text
                inline
                size="msmall"
                uppercase
                lineHeight="small"
                color={theme.colors.grey7}
                font={theme.font.secondaryFont}
                light
              >
                {role}
              </Text>
            </Box>
          )}
        </UserInfo>
      </VerticalStack>
    </Container>
  )
}

const ConnectedUserBadge = () => {
  const {translate} = useLocalization()
  const {user, party} = useProfile()
  const {contentService} = useServices()
  const theme = useTheme()

  return (
    <UserBadge
      name={user ? getFullName(user) : '-'}
      image={user?.picture || contentService.getAttachmentURL(party?.image)}
      company={party?.name}
      role={getAccountType(party, translate, theme)}
    />
  )
}

export default ConnectedUserBadge
