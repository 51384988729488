import {LederhosenApiErrorType} from 'services/LederhosenApiErrorMapper'

export default class ApiError extends Error {
  correlationId?: string
  cause?: Error
  type?: LederhosenApiErrorType

  public constructor(message: string, correlationId?: string, cause?: Error, type?: LederhosenApiErrorType) {
    super(message)

    this.correlationId = correlationId
    this.cause = cause
    this.type = type
  }
}
