export enum OperatingSystem {
  Linux,
  Windows,
  Mac,
  IOs,
  Android,
}

const useOS = (): OperatingSystem => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform

  if (/Mac/.test(userAgent)) {
    return OperatingSystem.Mac
  } else if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1) {
    return OperatingSystem.IOs
  } else if (/Win/.test(userAgent)) {
    return OperatingSystem.Windows
  } else if (/Android/.test(userAgent)) {
    return OperatingSystem.Android
  } else if (/Linux/.test(platform)) {
    return OperatingSystem.Linux
  }

  return null
}

export default useOS
