import IPurchaseContract from 'domain/IPurchaseContract'
import {ContractState, ContractVolumeProfile} from '../../domain/IContract'
import {mockContractType1} from './mockContractTypes'

export const mockPurchaseContract3: IPurchaseContract = {
  contractItems: [],
  contractParties: [],
  contractType: mockContractType1,
  name: 'SEED_PURCHASE_CONTRACT_3',
  id: 3,
  signingDate: null,
  state: ContractState.SIGNED,
  summary: null,
  validFrom: '2025-01-01T22:00:00Z',
  validTo: '2025-12-31T23:59:00Z',
  volumeMwh: 125,
  totalPrice: 1000,
  volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
}

export const mockPurchaseContract4: IPurchaseContract = {
  contractItems: [],
  contractParties: [],
  contractType: mockContractType1,
  name: 'SEED_PURCHASE_CONTRACT_4',
  id: 4,
  signingDate: '2020-01-01T22:00:00Z',
  state: ContractState.SIGNED,
  summary: null,
  validFrom: '2020-01-01T22:00:00Z',
  validTo: '2030-12-31T23:59:00Z',
  volumeMwh: 500,
  totalPrice: 1000,
  volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
}

export default [mockPurchaseContract3, mockPurchaseContract4]
