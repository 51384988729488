import React, {createContext, useState, useContext} from 'react'

const DialogContext = React.createContext<{
  isOpen: boolean
  openPaywall: () => void
  closePaywall: () => void
}>(null)

export const PaywallProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openPaywall = () => {
    setIsOpen(true)
  }

  const closePaywall = () => {
    setIsOpen(false)
  }

  return <DialogContext.Provider value={{isOpen, openPaywall, closePaywall}}>{children}</DialogContext.Provider>
}

export const usePaywall = () => {
  return useContext(DialogContext)
}
