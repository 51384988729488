import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import useTheme from 'hooks/useTheme'
import ITheme from 'theme/ITheme'

export const Heading = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  font-family: ${props => (props.theme as ITheme).font.headings};
`

export const SubHeading: React.FC<{black?: boolean; children: React.ReactNode}> = ({black, children}) => {
  const theme = useTheme()
  return (
    <Text
      uppercase
      size="small"
      color={black ? theme.colors.common.black : theme.colors.accent}
      font={theme.font.secondaryFont}
    >
      {children}
    </Text>
  )
}
