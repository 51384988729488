import React from 'react'
import ResourceGateway from '../../../components/ResourceGateway'
import SalesContractListPage from '../../../components/Platform/Portfolio/SalesContract/SalesContractListPage'
import CertificateContractPage from 'components/Platform/Portfolio/CertificateContractPage/CertificateContractPage'

const SalesContracts: React.FC<React.PropsWithChildren> = () => {
  return (
    <ResourceGateway
      single={routeProps => <CertificateContractPage offerId={routeProps.match.params.id} />}
      list={() => <SalesContractListPage />}
    />
  )
}

export default SalesContracts
