import Big from 'big.js'
import {isNil, isFinite} from 'lodash-es'

const DEFAULT_MIN_VALUE = 1

interface IUnitsProperties {
  [name: string]: {
    ratio: number
  }
}

type ValueWithUnit = [number, string]

export enum EnergyUnit {
  Wh = 'Wh',
  kWh = 'kWh',
  MWh = 'MWh',
  GWh = 'GWh',
  TWh = 'TWh',
}

const conversions: {energy: IUnitsProperties} = {
  energy: {
    Wh: {
      ratio: 0.000001,
    },
    kWh: {
      ratio: 0.001,
    },
    MWh: {
      ratio: 1,
    },
    GWh: {
      ratio: 1000,
    },
    TWh: {
      ratio: 1000000,
    },
  },
}

function convertToUnit(
  value: number,
  sourceUnit: string,
  targetUnit: string,
  unitsProperties: IUnitsProperties,
): ValueWithUnit {
  if (isNil(value) || !isFinite(value)) {
    return null
  }

  return [+Big(unitsProperties[sourceUnit].ratio).div(unitsProperties[targetUnit].ratio).mul(value), targetUnit]
}

function autoConvert(
  value: number,
  unit: string,
  unitsProperties: IUnitsProperties,
  minValue: number = DEFAULT_MIN_VALUE,
): ValueWithUnit {
  if (!value) {
    return [0, unit]
  }

  const sourceUnitProperties = unitsProperties[unit]

  if (!sourceUnitProperties) {
    throw new Error(`Invalid unit ${unit}`)
  }

  return Object.keys(unitsProperties)
    .map(targetUnit => {
      if (targetUnit === unit) {
        return [value, unit]
      }

      return convertToUnit(value, unit, targetUnit, unitsProperties)
    })
    .reduce((previous: ValueWithUnit, current: ValueWithUnit) => {
      if (!previous) {
        return current
      }

      return current[0] >= minValue && current[0] - minValue < previous[0] - minValue ? current : previous
    }) as ValueWithUnit
}

export function convertEnergy(value: number, sourceUnit: string, targetUnit: string): number {
  if (isNil(value) || !isFinite(value)) {
    return null
  }

  return convertToUnit(value, sourceUnit, targetUnit, conversions.energy)[0]
}

export function convertMwh(value: number, targetUnit: string): number {
  return convertEnergy(value, EnergyUnit.MWh, targetUnit)
}

export function autoConvertEnergy(
  value: number,
  unit: string = EnergyUnit.MWh,
  minValue: number = DEFAULT_MIN_VALUE,
): ValueWithUnit {
  return autoConvert(value, unit, conversions.energy, minValue)
}

export function detectBestPossibleEnergyUnit(values: number[], unit: string = EnergyUnit.MWh): string {
  const max = Math.max(0, ...values.filter(v => !isNil(v) && isFinite(v)))

  if (!max) {
    return unit
  }

  const [, targetUnit] = autoConvertEnergy(max, unit)

  return targetUnit
}

export function autoConvertEnergies(values: number[], unit: string = EnergyUnit.MWh): [number[], string] {
  const targetUnit = detectBestPossibleEnergyUnit(values, unit)

  return [values.map(v => convertEnergy(v, unit, targetUnit)), targetUnit]
}
