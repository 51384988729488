import React from 'react'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import FormSelect from 'components/Form/FormSelect'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import {SiteType, TimeseriesKind} from 'domain/ISite'
import dayjs from 'dayjs'
import FormProfileBuilder from 'components/Form/FormProfileBuilder'
import {FormControlLabel, RadioGroup} from '@mui/material'
import Radio from 'components/Radio'
import {getChangeEventValue} from 'helpers/misc'
import DownloadExampleLink from '../Site/Form/DownloadExampleLink'
import ResponsiveGrid from 'components/ResponsiveGrid'
import Heading from 'components/Heading'
import {ScreenBreakpoint} from 'hooks/useScreenSize'
import {getCurrentYear, getMonths, getPastYears, getPrevMonth} from 'helpers/date'
import useFormContext from 'hooks/useFormContext'

interface IProps extends React.PropsWithChildren {
  uploadExpectedType: TimeseriesKind
  setUploadExpectedType: (value: TimeseriesKind) => void
  uploadActualType: TimeseriesKind
  setUploadActualType: (value: TimeseriesKind) => void
}

const ExternalContractUpload: React.FC<IProps> = ({
  uploadExpectedType,
  setUploadExpectedType,
  uploadActualType,
  setUploadActualType,
  children,
}) => {
  const {translate} = useLocalization()
  const {watch} = useFormContext()
  const months = getMonths()
  const years = getPastYears(2)
  const interval = watch('interval')

  return (
    <Box pad={{bottom: 2}}>
      <Box pad={2} border round margin={{bottom: 2}}>
        <Heading margin={{bottom: 2}} size="msmall">
          {translate('Interval')}
        </Heading>
        <Box direction="row" gap={2} pad={{bottom: 2}} width="calc(50% - 8px)">
          <FormSelect
            required
            style={{width: '100%'}}
            name="interval.month"
            margin={0}
            defaultValue={getPrevMonth()}
            options={months.map((month, index) => ({value: month, label: month}))}
            placeholder="Month"
          />
          <FormSelect
            required
            style={{width: '100%'}}
            name="interval.year"
            margin={0}
            defaultValue={getCurrentYear()}
            options={years.map(year => ({value: year, label: `${year}`}))}
            placeholder="Year"
          />
        </Box>
      </Box>
      <ResponsiveGrid gap={2} templateColumns={{[ScreenBreakpoint.Large]: '1fr', [ScreenBreakpoint.MLarge]: '1fr 1fr'}}>
        <Box pad={2} border round>
          <Heading margin={{bottom: 1}} size="msmall">
            {translate('Expected generation under contract')}
          </Heading>

          <RadioGroup
            sx={{m: 2, ml: 1}}
            row
            value={uploadExpectedType}
            onChange={e => setUploadExpectedType(getChangeEventValue(e))}
          >
            <FormControlLabel value={TimeseriesKind.BUILDER} control={<Radio />} label={translate('Build')} />
            <FormControlLabel value={TimeseriesKind.FILE_UPLOAD} control={<Radio />} label={translate('Upload')} />
          </RadioGroup>
          {uploadExpectedType === TimeseriesKind.BUILDER && <FormProfileBuilder production name="expectedBuilder" />}
          {uploadExpectedType === TimeseriesKind.FILE_UPLOAD && (
            <>
              <FormAttachmentUpload
                label={translate('Upload consumption profile')}
                name="expectedFile"
                removable
                required
              />
              <Box margin={{vertical: 2}}>
                <DownloadExampleLink interval={interval} siteType={SiteType.CONSUMPTION} />
              </Box>
            </>
          )}
          {children}
        </Box>
        <Box pad={2} border round>
          <Heading margin={{bottom: 1}} size="msmall">
            {translate('Actual generation under contract')}
          </Heading>

          <RadioGroup
            sx={{m: 2, ml: 1}}
            row
            value={uploadActualType}
            onChange={e => setUploadActualType(getChangeEventValue(e))}
          >
            <FormControlLabel value={TimeseriesKind.BUILDER} control={<Radio />} label={translate('Build')} />
            <FormControlLabel value={TimeseriesKind.FILE_UPLOAD} control={<Radio />} label={translate('Upload')} />
          </RadioGroup>
          {uploadActualType === TimeseriesKind.BUILDER && <FormProfileBuilder production name="actualBuilder" />}
          {uploadActualType === TimeseriesKind.FILE_UPLOAD && (
            <>
              <FormAttachmentUpload
                label={translate('Upload consumption profile')}
                name="actualFile"
                removable
                required
              />
              <Box margin={{vertical: 2}}>
                <DownloadExampleLink interval={interval} siteType={SiteType.CONSUMPTION} />
              </Box>
            </>
          )}
        </Box>
      </ResponsiveGrid>
    </Box>
  )
}

export default ExternalContractUpload
