import {atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

export const companyInfoAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    return await services.v2ContentService.getCompanyInformation(party.id)
  }),
)
