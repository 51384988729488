import React from 'react'
import Input, {IProps as IInputProps} from '../Input'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'

export type IProps = IWrapperProps<IInputProps>

const FormInput: React.FC<IProps> = props => {
  return (
    <FormInputWrapper {...props}>
      <Input />
    </FormInputWrapper>
  )
}

export default FormInput
