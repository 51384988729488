import React from 'react'
import Chart, {ChartType, getMaxValue, getMinValue, ILine, StackType} from '../../../Chart'
import {autoCeilNumber, autoFloorNumber} from '../../../../helpers/misc'
import useTheme from 'hooks/useTheme'
import {IContractedVolumeTimeseries, ITimeseriesInterval} from 'domain/Portfolio'
import useNewChartColors from 'hooks/useNewChartColors'

interface IProps extends React.PropsWithChildren {
  consumption: ITimeseriesInterval[]
  contractsTimeseries: (IContractedVolumeTimeseries & {external?: boolean})[]
}

const ContractedVolumesChart: React.FC<IProps> = ({consumption, contractsTimeseries}) => {
  const chartColors = useNewChartColors()
  const theme = useTheme()

  if (!consumption) {
    return null
  }

  const labels = consumption.map(({name}) => name) || []

  const lines: ILine[] = []

  lines.push({
    key: 'consumption',
    label: 'My consumption',
    color: theme.colors.blue7,
    data: consumption.map(({value}) => value),
    type: ChartType.LINE,
    stackType: StackType.DEFAULT,
    fill: false,
    showPoint: true,
  })

  contractsTimeseries.forEach(({timeseries, contractId, external}) => {
    lines.push({
      key: contractId,
      label: external ? `External-#${contractId}` : `#${contractId}`,
      color: chartColors.getNext(),
      data: timeseries.map(({value}) => value),
      type: ChartType.BAR,
      stackType: StackType.STACKED,
    })
  })

  return (
    <Chart
      toolbar={{vertical: true, timescale: {hideDaily: true}}}
      labels={labels}
      lines={lines}
      dark
      typeLabels={{
        [StackType.DEFAULT]: 'EACs',
      }}
      maxValue={autoCeilNumber(getMaxValue(lines))}
      minValue={autoFloorNumber(getMinValue(lines))}
    />
  )
}

export default ContractedVolumesChart
