import {IOption} from '../components/Select'
import {ILanguages} from '../theme/ITheme'

export function getLanguageOptions(listOfLanguage: ILanguages[], translate: (a: string) => string): IOption[] {
  const options = [{label: translate('English'), value: 'en'}]

  listOfLanguage.forEach(lang => {
    if (lang === 'nl') {
      options.push({label: translate('Dutch'), value: 'nl'})
    }
  })

  return options
}
