import {atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const buyerProposalsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getBuyerProposals(party.id, page, pageSize)
  }),
)

export const sellerProposalsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    return await services.v2ContentService.getSellerProposals(party.id, page, pageSize)
  }),
)
