import ResourceStore from './ResourceStore'
import IContentService from '../services/IContentService'
import RootStore from './index'
import {computed} from 'mobx'
import IParty, {PartyRole} from '../domain/IParty'
import {ResourceId} from '../types'

export default class PartyStore extends ResourceStore<IParty> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super()
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadManagedParties() {
    await this.clearItems()
    const currentParty = this.rootStore.profileStore.party
    await this.setManyEventually(this.contentService.getManagedParties(currentParty.id))
  }

  public async loadManagedParty(id: any) {
    const currentParty = this.rootStore.profileStore.party
    await this.setOneEventually(this.contentService.getManagedParty(currentParty.id, id))
  }

  public async setParty(party: IParty) {
    await this.setOneEventually(this.storeParty(party))
  }

  private async loadSite(newSiteId: ResourceId) {
    const newSite = newSiteId ? this.rootStore.siteStore.getItem(newSiteId) : null

    if (newSiteId && !newSite) {
      throw new Error('Something went wrong')
    }

    let siteName = null

    if (newSite) {
      siteName = newSite.name
    }

    return {siteName, newSite}
  }

  public async saveParty(party: IParty, newSiteId?: ResourceId): Promise<IParty> {
    const {newSite} = await this.loadSite(newSiteId)

    const savedParty = await this.storeParty({
      ...party,
      timeseriesId: newSite ? newSite.timeseriesId : party.timeseriesId, // No change
    })

    this.loadManagedParty(savedParty.id)

    return savedParty
  }

  public async saveConsumption(partyId: number, consumptionFile?: File, newSiteId?: ResourceId): Promise<void> {
    const {siteName} = await this.loadSite(newSiteId)

    if (consumptionFile) {
      try {
        await this.contentService.uploadConsumptionProfile(partyId, consumptionFile, siteName)
      } catch (error) {
        throw new Error('Failed to upload consumption profile')
      }
    }
  }

  public async deleteParty(partyId: ResourceId): Promise<void> {
    await this.contentService.softDeleteManagedParty(partyId)

    this.removeItem(partyId)
  }

  private storeParty(party: IParty): Promise<IParty> {
    const asPartyId = this.rootStore.profileStore.party.id

    return party.id
      ? this.contentService.updateManagedParty(asPartyId, party)
      : this.contentService.createManagedParty(asPartyId, party)
  }

  @computed
  get buyerParties(): IParty[] {
    return this.items.filter(party => party.partyRole === PartyRole.BUYER)
  }
}
