import React from 'react'
import ISite, {SiteType} from '../../../../domain/ISite'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import InnerScroll from '../../../InnerScroll'
import Grid from '../../../Grid'
import Heading from '../../../Heading'
import GeneralSiteData from './GeneralSiteData'
import SiteMap from '../../Map/SiteMap'
import {getStringifiedSiteLocation} from '../../../../helpers/site'
import {ScreenBreakpoint} from '../../../../hooks/useScreenSize'
import ResponsiveGrid from '../../../ResponsiveGrid'
import Box from 'components/Box'
import Button from '../../../Button'
import Link from '../../../Link'
import {useRouteMatch} from 'react-router'

interface IProps extends React.PropsWithChildren {
  site?: ISite
  suffix?: string
}

const getEditLink = (site: ISite, suffix: string, url) => {
  if (!suffix) {
    return `${url.replace('/sites', '/sites/edit')}`
  } else {
    return `${url.replace(site.id, 'edit/' + site.id + suffix)}`
  }
}

const SiteViewPage: React.FC<IProps> = ({site, suffix}) => {
  const {translate} = useLocalization()
  const {url} = useRouteMatch()

  return (
    <Page
      title={site.name}
      description={translate('Site details')}
      corner={
        <Box gap={2}>
          <Link to={getEditLink(site, suffix, url)}>
            <Button>{translate('Edit')}</Button>
          </Link>
        </Box>
      }
      aside={
        <SiteMap
          title={translate('Address')}
          description={getStringifiedSiteLocation(site, translate)}
          sites={[site]}
        />
      }
    >
      <InnerScroll>
        <Grid columns={1} gap={5}>
          <Heading>
            {translate(site.siteType === SiteType.CONSUMPTION ? 'Consumption profile' : 'Production profile')}
          </Heading>
          <ResponsiveGrid
            gap={5}
            columns={{
              [ScreenBreakpoint.Small]: 1,
              [ScreenBreakpoint.Large]: site.siteType === SiteType.CONSUMPTION ? 2 : 1,
            }}
          >
            <GeneralSiteData site={site} />
          </ResponsiveGrid>
        </Grid>
      </InnerScroll>
    </Page>
  )
}

export default SiteViewPage
