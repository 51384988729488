import React from 'react'
import {InputWrap, IWrapProps} from './Input'
import {BlockEditor} from 'simple-text-editor-rcl'

export interface IProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    IWrapProps {
  className?: string
}

// eslint-disable-next-line react/display-name
const TextEditor = React.forwardRef((props: IProps, ref: React.Ref<any>) => {
  const {label, error = null, height, value, isSubmitted, errorType, ...inputProps} = props

  const component = () => <textarea value={value || undefined} {...inputProps} ref={ref} />

  return (
    <InputWrap error={error} label={label} height={height}>
      <BlockEditor component={component} />
    </InputWrap>
  )
})

TextEditor.defaultProps = {
  height: 10,
}

export default TextEditor
