import React from 'react'
import Box from './Box'
import Text, {IProps as ITextProps} from './Text'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  value: string | number
  unit?: string
  currency?: string
  size?: 'small' | 'medium' | 'mlarge' | 'large'
  dataCy?: string
}

const ValueWithUnit: React.FC<IProps> = ({value, unit, currency, size, dataCy}) => {
  const theme = useTheme()
  const valueProps: ITextProps = {}
  const unitProps: ITextProps = {
    margin: {
      left: 0.5,
    },
  }
  const currencyProps: ITextProps = {
    margin: {
      right: 0.5,
    },
  }

  if (['mlarge', 'large'].includes(size)) {
    valueProps.size = size === 'large' ? 'xlarge' : 'large'
    valueProps.semibold = true
    valueProps.lineHeight = 'medium'
    unitProps.size = 'medium'
    unitProps.semibold = true
  } else if (size === 'small') {
    valueProps.semibold = true
    unitProps.size = 'small'
  } else {
    valueProps.semibold = true
    unitProps.semibold = true
  }

  return (
    <Box align="center" data-cy={dataCy}>
      {currency && <Text {...currencyProps}>{currency}</Text>}
      <Text font={theme.font.numbers} {...valueProps}>
        {value}
      </Text>
      {unit && <Text {...unitProps}>{unit}</Text>}
    </Box>
  )
}

ValueWithUnit.defaultProps = {
  size: 'medium',
}

export default ValueWithUnit
