import React from 'react'
import Box from '../Box'
import Heading from '../Heading'
import Text from '../Text'
import ModalCloser from './ModalCloser'
import CloseButton from '../CloseButton'

export interface IProps extends React.PropsWithChildren {
  title?: string
  description?: string
  error?: string
}

const ModalHeader: React.FC<IProps> = ({title, description, children, error}) => (
  <Box justify="space-between" align="center" margin={{bottom: 4}}>
    <div>
      <Heading size="large">{title}</Heading>
      {description && (
        <Text size="small" uppercase>
          {description}
        </Text>
      )}
      {error && (
        <Text size="medium" color="red">
          {error}
        </Text>
      )}
    </div>
    {children || (
      <ModalCloser>
        <CloseButton />
      </ModalCloser>
    )}
  </Box>
)

export default ModalHeader
