import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import IPurchaseContract from 'domain/IPurchaseContract'
import {
  IRecommendedExcessPrice,
  default as ISellExcessRequest,
  default as SellExcessRequest,
} from 'domain/ISellExcessRequest'
import ITextDocument from 'domain/ITextDocument'
import IUser from 'domain/IUser'
import {ITimeseriesOverview} from 'domain/Portfolio'
import {EnergyDataType} from '../domain/EnergyDataType'
import IAttachment from '../domain/IAttachment'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IParty from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import {IPasswordFromOfferInvite} from '../domain/IPasswordFromOfferInvite'
import IProduct from '../domain/IProduct'
import IProductType from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'

export enum Interval {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export default interface IContentService {
  getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument>

  sendContractVerificationCode(contractId: number)

  confirmContractVerificationCode(contractId: number, code: string)

  updateProfile(updatedUser: IUser)

  confirmUserPhoneNumber(code: string): Promise<void>

  sendUserPhoneVerification(phone: string): Promise<void>

  createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember>

  savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember>

  uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void>

  getPorfolioOverview(partyId: number, interval: Interval, start: Date, end: Date): Promise<ITimeseriesOverview>

  getConsumptionData(partyId: any, interval: Interval, energyDataType: EnergyDataType): Promise<ITimeseriesItem[]>

  sellCertExcessRequest(partyId: any, payload: SellExcessPayload): Promise<SellExcessRequest>

  uploadTimeseriesData(siteId: any, csv: File): Promise<ISite>

  cloneTimeseriesData(siteId: any, cloneSiteId: any): Promise<ISite>

  getProductsVisibleToParty(partyId: any): Promise<IProduct[]>

  getProductVisibleToParty(partyId: any, id: any): Promise<IProduct>

  getProductManagedByParty(partyId: any, id: any): Promise<IProduct>

  editCertificateOffer(partyId: any, offerId: any, payload: IProposalDetailsBody): Promise<IOffer>

  getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>>

  getRecommendedExcessPrice(partyId: any, technologyKind: SiteProductionTechnology): Promise<IRecommendedExcessPrice>

  acceptExcessSellRequest(sellRequestId: any): Promise<void>

  declineExcexsSellRequest(sellRequestId: any): Promise<void>

  getProductTypes(partyId: any): Promise<IProductType[]>

  getSites(partyId: any): Promise<ISite[]>

  createSite(partyId: any, site: ISite): Promise<ISite>

  updateSite(partyId: any, site: ISite): Promise<ISite>

  softDeleteSite(partyId: any, siteId: any): Promise<ISite>

  getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob>

  getContractContractTemplates(contractId: any, partyId: any): Promise<any>

  getContractHtml(contractId: any): Promise<string>

  getManagedParties(asPartyId: any): Promise<IParty[]>

  getManagedParty(asPartyId: any, partyId: any): Promise<IParty>

  getPartyMembers(asPartyId: any): Promise<IPartyMember[]>

  getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void>

  setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void>

  setPasswordFromOfferInvite(accessCode: string, user: IPasswordFromOfferInvite): Promise<IOffer>

  getUserFromInvite(accessCode: string): Promise<IUser>

  getUserFromOfferInvite(accessCode: string): Promise<IOfferInvite>

  createPartyMemberUser(asPartyId: number, partyMember: IAdminCreatePartyMember): Promise<IPartyMember>

  updatePartyMember(asPartyId: any, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember>

  disablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  enablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember>

  createManagedParty(asPartyId: any, party: IParty): Promise<IParty>

  updateManagedParty(asPartyId: any, party: IParty): Promise<IParty>

  softDeleteManagedParty(partyId: any): Promise<IParty>

  getAttachmentURL(attachment: IAttachment): string

  getSalesContracts(partyId: any): Promise<IPurchaseContract[]>
}
