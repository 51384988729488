import Box from 'components/Box'
import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Logo from 'components/Logo'
import Text from 'components/Text'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import React from 'react'

interface IFormData {
  phoneNumber: string
}

interface IProps extends React.PropsWithChildren {
  onSubmit: (formData: IFormData) => Promise<void>
}

const PhoneNumberForm: React.FC<IProps> = ({onSubmit}) => {
  const {translate} = useLocalization()
  const {user} = useProfile()

  return (
    <Box justify="center" direction="column">
      <Box justify="center">
        <Logo />
      </Box>
      <Form onSubmit={onSubmit} submissionFeedback={null}>
        <Text size="xlarge" align="center">
          Enter your phone number
        </Text>
        <FormInput
          label={translate('Phone number')}
          name="phoneNumber"
          defaultValue={user.phoneNumber}
          pattern={/^\+[1-9]\d{1,14}$/}
          placeholder={'e.g. +XXXXXXXXXX'}
        />
        <Text margin={{top: 3, bottom: 3}} size="large">
          {translate('No spaces or hyphens allowed')}
        </Text>
        <Box margin={{bottom: 2}} justify="center">
          <FormSubmitButton>Save</FormSubmitButton>
        </Box>
      </Form>
    </Box>
  )
}

export default PhoneNumberForm
