import React, {useContext} from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'

interface IProps extends React.PropsWithChildren {
  title: string
}

const TitleContext = React.createContext('')

const Helmet: React.FC<IProps> = ({title, children}) => {
  const parentTitle = useContext(TitleContext)
  const concatenatedTitle = [title, parentTitle].filter(Boolean).join(' - ')

  return (
    <>
      <ReactHelmet>
        <title>{concatenatedTitle}</title>
      </ReactHelmet>

      <TitleContext.Provider value={concatenatedTitle}>{children}</TitleContext.Provider>
    </>
  )
}

export default Helmet
