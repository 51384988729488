import React from 'react'
import styled from 'styled-components'
import Box from '../Box'
import Heading from '../Heading'
import Text from '../Text'
import Helmet from '../Helmet'
import backgroundImage from './page-bg.svg'
import {ISpacing} from '../styleProps/spacing'
import FullscreenLoader from 'components/FullscreenLoader'
import ApiError from 'exceptions/ApiError'
import useTheme from 'hooks/useTheme'

export interface IProps extends React.PropsWithChildren {
  title?: string
  description?: string | React.ReactNode
  descriptionAside?: boolean
  aside?: any
  isLoading?: boolean
  corner?: any
  cornerMargin?: ISpacing
  withBackground?: boolean
  noHeader?: boolean
  gridTemplateColStyle?: string
  asideBorder?: boolean
  mainPad?: ISpacing
  error?: React.ReactNode
  asideColor?: string
}

const Container = styled.div<{withAside?: boolean; withBackground?: boolean; gridTemplateColStyle?: string}>`
  display: grid;
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
  ${props => props.withAside && ` grid-template-columns: ${props.gridTemplateColStyle || 'auto 320px;'}`}
  ${props =>
    props.withBackground &&
    `
    background-image: url('${backgroundImage}');
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
  `}
`

const Main = styled(Box)`
  display: grid;
  grid-template-rows: min-content auto;
`

const Aside = styled.aside<{withBorder?: boolean; asideColor?: string}>`
  min-height: 100%;
  background-color: ${props => props.asideColor || props.theme.colors.surface};

  ${props => props.withBorder && `border-left: 1px solid ${props.theme.colors.outline};`}
`

const Header = styled.div<{$noHeader?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${props => (props.$noHeader ? '0' : '64px')};
`

const Page: React.FC<IProps> = ({
  title,
  description,
  descriptionAside,
  aside,
  isLoading,
  corner,
  cornerMargin = {right: 1},
  noHeader,
  mainPad = {top: 0, right: 2, bottom: 0, left: 2},
  withBackground,
  gridTemplateColStyle,
  asideBorder,
  asideColor,
  error,
  children,
}) => {
  const theme = useTheme()

  return (
    <Helmet title={title}>
      <Container
        data-cy="page"
        withAside={!!aside}
        withBackground={withBackground}
        gridTemplateColStyle={gridTemplateColStyle}
      >
        <Main pad={mainPad}>
          <Header $noHeader={noHeader}>
            <Box
              direction={descriptionAside ? 'row' : 'column'}
              gap={descriptionAside ? 2 : null}
              align={descriptionAside ? 'center' : null}
            >
              {title && <Heading color={theme.colors.secondary}>{title}</Heading>}
              {description && (
                <Text
                  data-cy="page-description"
                  size="msmall"
                  uppercase
                  color={theme.colors.accent}
                  font={theme.font.secondaryFont}
                >
                  {description}
                </Text>
              )}
            </Box>
            {corner && <Box margin={cornerMargin}>{corner}</Box>}
          </Header>
          {isLoading && <FullscreenLoader height="50vh" />}
          {error}
          {!isLoading && !error && children}
        </Main>
        {aside && (
          <Aside withBorder={asideBorder} asideColor={asideColor}>
            {aside}
          </Aside>
        )}
      </Container>
    </Helmet>
  )
}

export default Page
