import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import useProfile from '../../hooks/useProfile'
import {getPartyLink, getPlatformUrl} from 'helpers/party'
import useTheme from 'hooks/useTheme'

const AuthGuard: React.FC<React.PropsWithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const theme = useTheme()
  const partyRoute = pathname.split('/')[1]
  const {user, isLoaded, party} = useProfile()

  if (!user && isLoaded) {
    return <Redirect to={`/?return_to=${encodeURIComponent(pathname)}`} />
  }

  if (user && !party && isLoaded) {
    return <Redirect to="/no-associated" />
  }

  if (user && party && partyRoute !== getPartyLink(party.partyRole)) {
    return <Redirect to={getPlatformUrl(party?.partyRole, theme)} />
  }

  return <>{children}</>
}

export default AuthGuard
