import React from 'react'
import styled from 'styled-components'
import useLocalization from '../../hooks/useLocalization'

const Container = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
`

const FormSubmitted: React.FC<React.PropsWithChildren> = ({children}) => {
  const {translate} = useLocalization()

  return <Container>{children || translate('Thank you! Your submission has been received!')}</Container>
}

export default FormSubmitted
