import useLocalization from '../../../../hooks/useLocalization'
import React from 'react'
import Page from '../../../Page'
import OfferTable from './OfferTable'
import InnerScroll from '../../../InnerScroll'
import Box from '../../../Box'
import OffersSummary from './OffersSummary'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import {useAtom, useAtomValue} from 'jotai'
import {pageAtom, pageSizeAtom, sellerProposalsAtom} from 'atoms/proposals'
import ErrorInfo from 'components/ErrorInfo'
import OffersSkeleton from './OffersSkeleton'

const RESULT_PER_PAGE_OPTIONS = [25, 50, 100]

const OfferListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const [sellerProposal, refreshSellerProposal] = useAtom(sellerProposalsAtom)

  const {data: paginatedOffers, loading, error} = sellerProposal

  return (
    <Page
      title={translate('Offers')}
      description={translate('Create and manage solution offers for your customers')}
      error={error && <ErrorInfo title={translate('Failed to load proposals')} error={error} />}
    >
      {loading ? (
        <OffersSkeleton />
      ) : (
        <>
          <InnerScroll>
            {paginatedOffers?.data?.length > 0 && (
              <Box>
                <OffersSummary {...paginatedOffers?.summary} />
              </Box>
            )}
            {/* reversing offer order here because reversing them in OfferStore did not work */}
            <OfferTable offers={paginatedOffers?.data} loading={loading} updateTable={() => refreshSellerProposal()} />
          </InnerScroll>
          <Box align="flex-end" justify="center">
            <PaginateWithSelection
              activePage={page}
              resultsPerPage={pageSize}
              resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
              totalPages={paginatedOffers?.totalPages || 1}
              handlePageChange={value => setPage(value)}
              handleResultsPerPageChange={value => {
                setPageSize(value)
                setPage(1)
              }}
            />
          </Box>
        </>
      )}
    </Page>
  )
}

export default OfferListPage
