import React from 'react'
import Box from 'components/Box'
import rainSvg from 'assets/icons/rain.svg'

const Rain: React.FC<React.PropsWithChildren> = () => (
  <Box justify="center" margin={{vertical: 3}}>
    <img src={rainSvg} alt="" />
  </Box>
)

export default Rain
