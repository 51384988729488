import React from 'react'
import {InputWrap, IWrapProps} from './Input'

export interface IProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    IWrapProps {
  className?: string
}

// eslint-disable-next-line react/display-name
const TextArea = React.forwardRef((props: IProps, ref: React.Ref<any>) => {
  const {label, error = null, height, value, isSubmitted, errorType, ...inputProps} = props

  return (
    <InputWrap error={error} label={label} height={height}>
      <textarea value={value || undefined} {...inputProps} ref={ref} />
    </InputWrap>
  )
})

TextArea.defaultProps = {
  height: 10,
}

export default TextArea
