import ResourceGateway from 'components/ResourceGateway'
import React from 'react'
import CreateSubscription from './CreateSubscription'
import SubscriptionListPage from './SubscriptionListPage'
import SubscriptionView from './SubscriptionView'

const SubscriptionGateway: React.FC<React.PropsWithChildren> = () => {
  return (
    <ResourceGateway
      create={() => <CreateSubscription />}
      single={routeProps => {
        return <SubscriptionView subscriptionId={routeProps.match.params.id} />
      }}
      list={() => <SubscriptionListPage />}
    />
  )
}

export default SubscriptionGateway
