import React from 'react'
import ResourceGateway from 'components/ResourceGateway'
import AddTenderRespondPage from 'components/Platform/Sell/TenderRespond/AddTenderRespondPage'
import TenderRespondPage from 'components/Platform/Sell/TenderRespond/TenderRespondPage'
import TenderRespondsPage from 'components/Platform/Sell/TenderRespond/TenderRespondsPage'

const TenderRespond = () => {
  return (
    <ResourceGateway
      create={() => <AddTenderRespondPage />}
      single={routeProps => <TenderRespondPage id={routeProps.match.params.id} />}
      list={() => <TenderRespondsPage />}
    />
  )
}

export default TenderRespond
