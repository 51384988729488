import React, {memo, useEffect, useMemo} from 'react'
import useResource from '../../../hooks/useResource'
import ISite from '../../../domain/ISite'
import {ResourceId} from '../../../types'
import NotFound from '../../NotFound'
import Loader from 'components/Loader'
import useStore from 'hooks/useStore'

interface IProps extends React.PropsWithChildren {
  id: ResourceId
  suffix?: string
  component: React.FC<{site: ISite; suffix?: string}>
}

const SiteResolver: React.FC<IProps> = ({id, suffix, component: Component, ...props}) => {
  const {siteStore} = useStore()
  const {site, isLoading, partyLoaded} = useResource(store => ({
    site: store.siteStore.getItem(id),
    isLoading: store.siteStore.siteLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))

  useEffect(() => {
    if (partyLoaded) {
      siteStore.loadSite(id)
    }
  }, [partyLoaded])

  if (id && !site && !isLoading) {
    return <NotFound />
  }

  if (isLoading) {
    return <Loader />
  }

  return <Component site={site} {...props} suffix={suffix} />
}

export default SiteResolver
