import React from 'react'
import MuiRadio from '@mui/material/Radio'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  noColor?: boolean
}

const Radio: React.FC<IProps> = ({noColor, ...props}) => {
  const theme = useTheme()

  return (
    <MuiRadio
      {...props}
      sx={{
        color: noColor ? theme.colors.blue7 : theme.colors.primary,
        '&.Mui-checked': {
          color: noColor ? theme.colors.blue7 : theme.colors.primary,
        },
      }}
    />
  )
}

export default Radio
