import React from 'react'
import styled from 'styled-components'
import {NavLink, NavLinkProps} from 'react-router-dom'
import ITheme from '../../../theme/ITheme'
import Text from '../../Text'
import useTheme from '../../../hooks/useTheme'

const Container = styled(NavLink)`
  display: inline-block;
  font-family: ${props => (props.theme as ITheme).font.secondaryFont};

  padding: 0px 12px;
  color: ${props => (props.theme as ITheme).colors.secondary};

  &:hover {
    border-color: unset;
    color: ${props => (props.theme as ITheme).colors.clay};
  }

  &.active {
    border-bottom: 2px solid ${props => (props.theme as ITheme).colors.primary};
    color: ${props => (props.theme as ITheme).colors.accent};
  }
`

interface IProps extends React.PropsWithChildren, NavLinkProps {
  title: string
  description?: string
}

const NavigationItem: React.FC<IProps> = ({title, description, ...navlinkProps}) => {
  const theme = useTheme()

  return (
    <Container {...navlinkProps}>
      <Text font={theme.font.headings} size="large">
        {title}
      </Text>
      {description && (
        <Text color={theme.colors.secondary} uppercase size="small">
          {description}
        </Text>
      )}
    </Container>
  )
}

export default NavigationItem
