import {Country} from './ILocation'
import {SiteProductionTechnology} from './ISite'

export enum VolumePrefKind {
  CUSTOM = 'CUSTOM',
  PRODUCED = 'PAY_AS_PRODUCED',
}

export default interface ITenderRequest {
  countries: Array<Country>
  maxAgeYears: number
  deliveryStartsOn: string
  deliveryEndsOn: string
  technologies: Array<SiteProductionTechnology>
  volumePreferences:
    | {kind: VolumePrefKind.PRODUCED; payload: {annualVolumeMwh: number}}
    | {
        kind: VolumePrefKind.CUSTOM
        payload: {
          monthlyCertificates: Array<{year: number; month: number; quantity: number}>
        }
      }
}
