import React from 'react'
import Box, {IProps as IBoxProps} from './Box'

type IProps = Omit<IBoxProps, 'direction'>

const HorizStack: React.FC<IProps> = ({children, ...props}) => {
  return (
    <Box direction="row" {...props}>
      {children}
    </Box>
  )
}

export default HorizStack
