import React from 'react'
import Error from './Error'
import useLocalization from 'hooks/useLocalization'

interface IWithTranslateFunctionHOCProps extends React.PropsWithChildren {
  translate: (text: string, ...modifiers: (string | number)[]) => string
}

const withTranslateFunctionHOC = (Component: any) => {
  return (props: any) => {
    const {translate} = useLocalization()

    return <Component translate={translate} {...props} />
  }
}
class ErrorBoundary extends React.Component<IWithTranslateFunctionHOCProps, {hasError: boolean}> {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    console.error(error) // TODO: report this error

    window.addEventListener(
      'click',
      () => {
        this.setState({hasError: false})
      },
      {once: true},
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error title={this.props.translate('Something went wrong')}>{this.props.translate('Please try again')}</Error>
      )
    }

    return this.props.children
  }
}

export default withTranslateFunctionHOC(ErrorBoundary)
