import React, {ReactElement} from 'react'
import styled from 'styled-components'
import ITheme from '../../theme/ITheme'
import Box from '../Box'
import {Switch, useLocation} from 'react-router'
import {ISpacingProps} from 'components/styleProps/spacing'

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: ${props => (props.theme as ITheme).tileSize * 2}px;
`

interface IProps extends React.PropsWithChildren, ISpacingProps {
  name: string
}

const Tabs: React.FC<IProps> = ({children, name, ...props}) => {
  const location = useLocation()
  const activeSlug = new URLSearchParams(location.search).get(name) || ''

  return (
    <Box margin={{top: 2}} {...props}>
      <TabsContainer>
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return null
          }

          return React.cloneElement(child as ReactElement, {name})
        })}
      </TabsContainer>
      <Switch>
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return null
          }

          if (child.props.slug !== activeSlug) {
            return null
          }

          return <React.Fragment key={child.props.slug}>{child.props.children}</React.Fragment>
        })}
      </Switch>
    </Box>
  )
}

export default Tabs
