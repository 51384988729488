import React, {useEffect} from 'react'
import useStore from '../../../../hooks/useStore'
import IProduct from '../../../../domain/IProduct'
import ProductForm from './ProductForm'
import useLocalization from '../../../../hooks/useLocalization'
import {useHistory} from 'react-router'
import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import Page from 'components/Page'
import Form from 'components/Form'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Box from 'components/Box'
import useServices from 'hooks/useServices'
import {partyAtom} from 'atoms/party'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import MarketListingsTable from '../MarketplaceListing/MarketListingsTable'
import InnerScroll from 'components/InnerScroll'
import {marketListingsByProductAtom, pageAtom, pageSizeAtom, productIdAtom} from 'atoms/marketListings'
import {useLocation} from 'react-router-dom'
import TableSkeleton from 'components/TableSkeleton'
import Button from 'components/Button'
import Link from 'components/Link'
import {productsAtom} from 'atoms/portfolioProducts'

const TAB_SLUG = 'product'
const MARKETPLACE_TAB_SLUG = 'marketplace'
const RESULT_PER_PAGE_OPTIONS = [25, 50]

export interface IProps extends React.PropsWithChildren {
  product?: IProduct
}

const ProductEditPage: React.FC<IProps> = ({product}) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const tabSlug = urlSearchParams.get(TAB_SLUG)
  const {alertStore} = useStore()
  const party = useAtomValue(partyAtom)
  const {v2ContentService} = useServices()
  const {translate} = useLocalization()
  const history = useHistory()
  const refetchProducts = useSetAtom(productsAtom)
  const {data: listings, loading, error: error} = useAtomValue(marketListingsByProductAtom)
  const [page, setPage] = useAtom(pageAtom)
  const setProductId = useSetAtom(productIdAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)

  useEffect(() => {
    if (product) {
      setProductId(product.id)
    }
  }, [product])

  const handleSubmit = async data => {
    const {image, ...productData} = data

    await v2ContentService.updateProduct(party.id, product.id, {...productData, imgAttachmentId: image?.id})
    refetchProducts()

    alertStore.addSuccess(translate('Successfully saved %s', productData.name))
    history.push('/broker/portfolio/products')
  }

  if (tabSlug === MARKETPLACE_TAB_SLUG && (loading || !product)) {
    return (
      <Page title={translate('Edit product')} description={translate('Manage information about the product')}>
        <Box pad={{top: 5}}>
          <TableSkeleton />
        </Box>
      </Page>
    )
  }

  return (
    <Form
      onSubmit={handleSubmit}
      fillHeight
      defaultValues={JSON.parse(JSON.stringify(product || {}))}
      submissionFeedback={null}
    >
      <Page
        title={translate('Edit product')}
        description={translate('Manage information about the product')}
        corner={
          tabSlug === MARKETPLACE_TAB_SLUG ? (
            <Link to={`/broker/portfolio/marketplace-listings/add?productId=${product?.id}`}>
              <Button>{translate('Add new listing')}</Button>
            </Link>
          ) : (
            <FormSubmitButton>{translate('Save')}</FormSubmitButton>
          )
        }
      >
        <InnerScroll noRightPad>
          <Box margin={{top: -2}}>
            <Tabs name={TAB_SLUG}>
              <Tab label={translate('General')} slug="">
                <ProductForm />
              </Tab>
              <Tab label={translate('Marketplace listings')} slug={MARKETPLACE_TAB_SLUG}>
                <MarketListingsTable listings={listings?.data} />
              </Tab>
            </Tabs>
          </Box>
        </InnerScroll>
        {tabSlug === MARKETPLACE_TAB_SLUG && (
          <Box align="flex-end" justify="center">
            <PaginateWithSelection
              activePage={page}
              resultsPerPage={pageSize}
              resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
              totalPages={listings?.totalPages || 1}
              handlePageChange={value => setPage(value)}
              handleResultsPerPageChange={value => {
                setPageSize(value)
                setPage(1)
              }}
            />
          </Box>
        )}
      </Page>
    </Form>
  )
}

export default ProductEditPage
