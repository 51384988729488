import React from 'react'
import Grid, {IProps as IGridProps} from './Grid'
import useScreenSize, {ScreenBreakpoint} from '../hooks/useScreenSize'

interface IProps extends React.PropsWithChildren, Omit<IGridProps, 'templateColumns' | 'columns'> {
  templateColumns?: {
    [key in ScreenBreakpoint]?: string
  }
  columns?: {
    [key in ScreenBreakpoint]?: number
  }
}

const ResponsiveGrid: React.FC<IProps> = ({templateColumns, columns, ...props}) => {
  let screenSize = useScreenSize()

  let foundTemplateColumns: string = null
  while (templateColumns && !foundTemplateColumns && screenSize >= 0) {
    foundTemplateColumns = templateColumns[screenSize--]
  }

  let foundColumns: number = null
  while (columns && !foundColumns && screenSize >= 0) {
    foundColumns = columns[screenSize--]
  }

  return <Grid templateColumns={foundTemplateColumns} columns={foundColumns} {...props} />
}

export default ResponsiveGrid
