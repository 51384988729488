import React from 'react'
import styled from 'styled-components'
import ITheme, {IColors} from '../theme/ITheme'
import spacing, {ISpacingProps} from './styleProps/spacing'
import round, {IRoundProps} from './styleProps/round'

export interface IProps
  extends React.PropsWithChildren,
    ISpacingProps,
    IRoundProps,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'wrap'> {
  className?: string
  align?: 'start' | 'end' | 'center' | 'baseline' | 'flex-end'
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'flex-end' | 'flex-start'
  direction?: 'row' | 'column'
  gap?: number
  wrap?: boolean
  fillHeight?: boolean
  grow?: boolean
  noShrink?: boolean
  width?: string | number
  height?: string | number
  color?: string | keyof IColors
  hoverColor?: string | keyof IColors
  border?: boolean
  borderColor?: string | keyof IColors
  overflow?: 'auto' | 'hidden' | 'inherit' | 'initial' | 'overlay' | 'scroll' | 'unset' | 'visible'
  cursor?: 'pointer' | 'default'
  $disablescrollbar?: boolean
  $maxHeight?: string | number
  shadow?: boolean
}

const BaseDiv: React.FC<IProps> = ({
  round,
  margin,
  pad,
  wrap,
  align,
  justify,
  fillHeight,
  grow,
  noShrink,
  width,
  direction,
  shadow,
  errorMargin,
  hoverColor,
  border,
  borderColor,
  ...props
}) => {
  return <div {...props} />
}

const StyledBox = styled(BaseDiv)<IProps>`
  box-sizing: border-box;
  max-height: 100%;
  ${props => (props.align || props.justify || props.direction || props.wrap || props.gap) && `display: flex;`}
  ${props => props.align && `align-items: ${props.align};`}
  ${props => props.justify && `justify-content: ${props.justify};`}
  ${props => props.direction && `flex-direction: ${props.direction};`}
  ${props => props.wrap && `flex-wrap: wrap;`}
  ${props => props.fillHeight && `height: 100%;`}
  ${props =>
    props.height &&
    `height: ${
      typeof props.height === 'number' ? `${props.height * (props.theme as ITheme).tileSize}px` : props.height
    };`}
  ${props =>
    props.width &&
    `width: ${typeof props.width === 'number' ? `${props.width * (props.theme as ITheme).tileSize}px` : props.width};`}
  ${props => props.grow && 'flex-grow: 1;'}
  ${props => props.noShrink && 'flex-shrink: 0;'}
  ${(props: IProps & {theme: ITheme}) =>
    props.color && `background: ${props.theme.colors[props.color] || props.color};`}
  ${(props: IProps & {theme: ITheme}) =>
    props.border && `border: 1px solid ${props.borderColor ? props.borderColor : props.theme.colors.lightGrey};`}
  ${spacing}
  ${round}
  ${props => props.overflow && `overflow: ${props.overflow};`}
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props =>
    props.$maxHeight &&
    `max-height: ${
      typeof props.$maxHeight === 'number'
        ? `${props.$maxHeight * (props.theme as ITheme).tileSize}px`
        : props.$maxHeight
    };`}
  ${props =>
    props.shadow &&
    `box-shadow: 0 2px 2px rgba(0, 0, 0, .2), 0 3px 1px -2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);`}
  
  &:hover {
    ${(props: IProps & {theme: ITheme}) =>
      props.hoverColor && `background-color: ${props.theme.colors[props.hoverColor] || props.hoverColor};`}
  }

  ${props =>
    props.$disablescrollbar &&
    `
    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    `}
`

function flattenChildren(children) {
  const flattened = []

  React.Children.forEach(children, child => {
    if (child && child.type === React.Fragment) {
      flattened.push(child.props.children)
    } else if (child) {
      // Ignore empty elements to prevent multiple gap boxes
      flattened.push(child)
    }
  })

  return flattened
}

const Box: React.FC<IProps> = ({gap, children, ...props}) => (
  <StyledBox gap={gap} {...props}>
    {gap
      ? flattenChildren(children).map((child, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <StyledBox
                margin={{
                  top: props.direction === 'column' ? gap : 0,
                  left: !props.direction || props.direction === 'row' ? gap : 0,
                }}
              />
            )}
            {child}
          </React.Fragment>
        ))
      : children}
  </StyledBox>
)
export default Box
