import ITheme from '../../theme/ITheme'

export type ISpacing =
  | number
  | {
      horizontal?: number
      vertical?: number
      top?: number
      right?: number
      bottom?: number
      left?: number
    }

export interface ISpacingProps {
  margin?: ISpacing
  errorMargin?: number
  pad?: ISpacing
}

const spacingToStyle = (spacing: ISpacing, theme: ITheme, errorMargin: number): string => {
  const result = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  if (typeof spacing !== 'object') {
    result.top = result.right = result.bottom = result.left = spacing
  } else {
    result.top = spacing.top !== undefined ? spacing.top : spacing.vertical
    result.right = spacing.right !== undefined ? spacing.right : spacing.horizontal
    result.bottom = spacing.bottom !== undefined ? spacing.bottom + errorMargin : spacing.vertical + errorMargin
    result.left = spacing.left !== undefined ? spacing.left : spacing.horizontal
  }

  for (const r in result) {
    result[r] = `${theme.tileSize * (result[r] || 0)}px`
  }

  return `${result.top} ${result.right} ${result.bottom} ${result.left}`
}

const spacing = (props: ISpacingProps & {theme: ITheme}): string =>
  `
  ${props.margin ? `margin: ${spacingToStyle(props.margin, props.theme, props.errorMargin || 0)};` : ''}
  ${props.pad ? `padding: ${spacingToStyle(props.pad, props.theme, props.errorMargin || 0)};` : ''}
`

export default spacing
