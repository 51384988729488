import IParty, {OnboardingStatus, PartyRole, PriceCalculationMethod} from '../domain/IParty'
import ISite from '../domain/ISite'
import ITheme from '../theme/ITheme'

export function getPlatformUrl(partyRole: PartyRole, theme: ITheme): string {
  const ROUTES = {
    [PartyRole.BUYER]: '/consumer',
    [PartyRole.PRODUCER]: '/producer',
    [PartyRole.BROKER]: '/broker',
    ...theme.loginRedirectPaths,
  }

  return ROUTES[partyRole] || '/incompatible-party'
}

export function getPartySite(party: IParty): ISite | null {
  if (!party?.sites?.length) {
    return null
  }

  return party.sites[0]
}

export function isPriceCalculationMethodSimple(party: IParty): boolean {
  return party.priceCalculationMethod === PriceCalculationMethod.SIMPLE
}

export function isPriceCalculationMethodDefault(party: IParty): boolean {
  return party.priceCalculationMethod === PriceCalculationMethod.DEFAULT
}

export function getPartyLink(role): string {
  if (role === PartyRole.BUYER) {
    return 'consumer'
  } else if (role === PartyRole.BROKER) {
    return 'broker'
  } else if (role === PartyRole.PRODUCER) {
    return 'producer'
  } else {
    return role ? role.toLowerCase() : 'incompatible-party'
  }
}

export const needsOnboarding = (party: IParty) => {
  if (!party) {
    return false
  }

  switch (party.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
    case OnboardingStatus.SELLER_STARTED:
    case OnboardingStatus.SELLER_SITE_CREATED:
    case OnboardingStatus.SELLER_PRODUCT_CREATED:
    case OnboardingStatus.BUYER_STARTED:
    case OnboardingStatus.BUYER_SITE_CREATED:
    case OnboardingStatus.BUYER_PROFILE_UPLOADED:
      return true
    case OnboardingStatus.COMPLETED:
    case OnboardingStatus.SELLER_SKIPPED:
      return false
  }
}
