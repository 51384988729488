import React from 'react'
import styled from 'styled-components'
import FormInput from 'components/Form/FormInput'
import TextLink from 'components/TextLink'
import Text from 'components/Text'
import checkIcon from 'assets/icons/basic-check.svg'
import arrowIcon from 'assets/icons/basic-arrow.svg'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import FormCheckbox from 'components/Form/FormCheckbox'
import HorizStack from 'components/HorizStack'
import LocationSearch from 'components/Platform/Portfolio/Site/LocationSearch'
import {ReloadingLogoutButton} from 'components/LogoutButton'
import useTheme from 'hooks/useTheme'

export const StyledFormInput = styled(FormInput)<{$small?: boolean}>`
  height: ${({$small}) => ($small ? '40px' : '56px')};
  width: 100%;
  background-color: ${({theme}) => `${theme.colors.contrastBackground}`};
  border: 1px solid ${({theme}) => `${theme.colors.secondary}`};
  input::placeholder {
    color: ${({theme}) => `${theme.colors.clay}`};
  }
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }
`

export const StyledLink = styled(TextLink)`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  color: black;

  &:hover {
    color: ${({theme}) => `${theme.colors.clay}`};
  }
`

export const StyledFormButton = ({children, ...props}) => {
  const theme = useTheme()

  return (
    <FormSubmitButton contrast large variant="outlined" {...props}>
      <HorizStack>
        <Text
          color={theme.colors.common.black}
          style={{fontSize: '13px'}}
          semibold
          uppercase
          font={theme.font.secondaryFont}
        >
          {children}
        </Text>
        <ArrowIcon />
      </HorizStack>
    </FormSubmitButton>
  )
}

export const StyledReloadingButton = styled(ReloadingLogoutButton)`
  font-size: 13px;
  line-height: 48px;
  min-height: 48px;
  border-radius: 24px;
  border: 1px solid black;
  background-color: transparent;
  font-weight: 500;
  color: black;

  &:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  button:focus {
    background-color: rgba(119, 249, 111, 0.38);
    border-color: black;
  }
`

export const StyledFormCheckbox = styled(FormCheckbox)`
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 8px;
`

export const StyledLocationSearch = styled(LocationSearch)<{$small?: boolean}>`
  .wp__control {
    height: ${({$small}) => ($small ? '40px' : '56px')};
    background-color: rgba(255, 255, 255, 0.5);
    border-color: ${({theme}) => `${theme.colors.secondary}`};
  }
`

export const StyledArrowImage = styled.img<{width?: string}>`
  width: 16px;
  margin-left: 4px;
  margin-right: -8px;
`

export const StyledCheckImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const StyledSmallArrowImage = styled.div<{width?: string}>`
  width: 12px;
  height: 12px;
  background-image: url(${arrowIcon});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 8px;
`

export const ArrowIcon = () => {
  return <StyledArrowImage src={arrowIcon} alt="arrow" />
}

export const CheckIcon = () => {
  return <StyledCheckImage src={checkIcon} alt="check" />
}
