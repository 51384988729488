import React, {useContext} from 'react'
import RootService from '../services'

export const ServicesContext = React.createContext(RootService.create())

const useServices = (): RootService => {
  return useContext(ServicesContext)
}

export default useServices
