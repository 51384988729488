import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react'
import styles from './mapStyles'
import theme from '../../theme'
import {isFiniteNumber, isInRange} from 'helpers/misc'
import env from '@beam-australia/react-env'

interface IMapOptions {
  streetViewControl?: boolean
  scaleControl?: boolean
  mapTypeControl?: boolean
  panControl?: boolean
  zoomControl?: boolean
  rotateControl?: boolean
  fullscreenControl?: boolean
  styles?: any[]
}

const createMapOptions = (overrides: Partial<IMapOptions> = {}): IMapOptions => {
  return {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    panControl: false,
    zoomControl: true,
    rotateControl: false,
    fullscreenControl: false,
    styles,
    ...overrides,
  }
}

export interface IProps extends React.PropsWithChildren, IMapOptions {
  defaultCenter?: [number, number]
  defaultZoom?: number
  maxZoom?: number
  setLoaded?: (loaded: boolean) => void
}

const LATITUDE_MIN_VAL = -90
const LATITUDE_MAX_VAL = 90

const LONGTITUDE_MIN_VAL = -180
const LONGTITUDE_MAX_VAL = 180

export const Map: React.FC<IProps> = ({children, setLoaded, defaultCenter, defaultZoom, maxZoom, ...options}) => {
  const [googleApi, setGoogleApi] = useState(null)
  const [center, setCenter] = useState<[number, number]>(defaultCenter || theme.map.defaultCenter)

  useEffect(() => {
    if (googleApi) {
      const markerPositions: Array<{lat: number; lng: number}> = (
        React.Children.map(children, (child: any) => ({
          lat: child?.props?.lat,
          lng: child?.props?.lng,
        })) || []
      ).filter(
        ({lat, lng}) =>
          isFiniteNumber(lat) &&
          isFiniteNumber(lng) &&
          isInRange(lat, LATITUDE_MIN_VAL, LATITUDE_MAX_VAL) &&
          isInRange(lng, LONGTITUDE_MIN_VAL, LONGTITUDE_MAX_VAL),
      )

      const bounds = new googleApi.maps.LatLngBounds()

      if (markerPositions.length) {
        markerPositions.forEach(position => bounds.extend(position))

        googleApi.maps.event.addListenerOnce(googleApi.map, 'bounds_changed', () => {
          googleApi.map.setZoom(Math.min(maxZoom, googleApi.map.getZoom()))
        })

        googleApi.map.fitBounds(bounds)
      }
    }
  }, [googleApi, children, maxZoom])

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: env('GOOGLE_API_KEY'),
        version: 'weekly',
        id: '__googleMapsScriptId',
      }}
      center={center}
      defaultZoom={defaultZoom}
      options={() => createMapOptions(options)}
      onChildClick={(key, {lat, lng}) => {
        setCenter([lat, lng])
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={setGoogleApi}
      onTilesLoaded={() => setLoaded && setLoaded(true)}
    >
      {children}
    </GoogleMapReact>
  )
}

Map.defaultProps = {
  defaultCenter: theme.map.defaultCenter,
  maxZoom: theme.map.maxZoom,
  defaultZoom: theme.map.defaultZoom,
}

export default Map
