import React, {useState} from 'react'
import useTheme from 'hooks/useTheme'
import Box from '../Box'
import Text from '../Text'
import useLocalization from 'hooks/useLocalization'
import {useAtom} from 'jotai'
import {IValue, PeriodType, periodAtom, YEARS, yearAtom, MONTHS} from './periodSelect'
import CustomPeriod from './CustomPeriod'
import YearChip from './YearChip'
import Period from './Period'

const getValueByPeriod = (period: PeriodType, year: number, value?: string): IValue => {
  if (period === PeriodType.YEAR) {
    return {startYear: year, startMonth: MONTHS[0], endYear: year, endMonth: MONTHS[11]}
  }
  if (period === PeriodType.MONTH) {
    return {startYear: year, startMonth: value, endYear: year, endMonth: value}
  }
  if (period === PeriodType.QUARTER) {
    const parsedValue = +value[1]
    const startMonth = parsedValue * 3 - 3
    const endMonth = startMonth + 2

    return {startYear: year, startMonth: MONTHS[startMonth], endYear: year, endMonth: MONTHS[endMonth]}
  }
}

export interface IProps extends React.PropsWithChildren {
  defaultValue?: PeriodType.YEAR | PeriodType.MONTH
  setValue: (value: IValue) => void
}

const PeriodSelect: React.FC<IProps> = ({defaultValue, setValue}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const [selected, setSelected] = useAtom(periodAtom)
  const [year, setYear] = useAtom(yearAtom)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const isCustomPeriod = selected.period === PeriodType.CUSTOM

  const handleChange = (period: PeriodType, value?: string) => {
    setSelected({period, value})
    setAnchorEl(null)

    setValue(getValueByPeriod(period, year, value))
  }

  const handleYearClick = (year: number) => {
    setYear(year)
    setValue(getValueByPeriod(selected.period, year, selected.value))
  }

  return (
    <Box direction="row" align="center" height={4} margin={{vertical: 2}} gap={1} pad={{top: isCustomPeriod ? 2 : 0}}>
      {!isCustomPeriod && (
        <Box gap={1} align="center">
          <Text color={theme.colors.accent} size="small" uppercase font={theme.font.secondaryFont} nowrap>
            {translate('Choose a year')}
          </Text>
          {YEARS.map(item => (
            <YearChip onClick={() => handleYearClick(item)} key={item} label={`${item}`} selected={year === item} />
          ))}
        </Box>
      )}

      <Box direction="row" align="center">
        <Text
          color={theme.colors.accent}
          size="small"
          uppercase
          font={theme.font.secondaryFont}
          pad={{left: 2, right: 1}}
          nowrap
        >
          {translate('And period')}
        </Text>
        <Period
          open={open}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          selected={selected}
          handleChange={handleChange}
        />
      </Box>
      {isCustomPeriod && (
        <Box align="center" direction="row" margin={{left: 1, top: -0.75}}>
          <CustomPeriod setValue={setValue} />
        </Box>
      )}
    </Box>
  )
}

export default PeriodSelect
