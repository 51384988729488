import React from 'react'
import Text from './Text'
import useTheme from '../hooks/useTheme'
import styled from 'styled-components'

export interface IProps
  extends React.PropsWithChildren,
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  label?: any
  error?: string
  onChange?: (value: boolean) => void
}

const Label = styled.label`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 4px;
  align-items: center;
`

const StyledCheckbox = styled.input.attrs<any>({
  type: 'checkbox',
})`
  width: 16px;
  height: 16px;
`

// eslint-disable-next-line react/display-name
const Checkbox: React.FC<IProps> = React.forwardRef(
  ({label, error, checked, onChange, ...rest}, ref: React.Ref<any>) => {
    const theme = useTheme()

    return (
      <Label>
        <StyledCheckbox
          {...rest}
          checked={!!checked}
          onChange={e => {
            onChange && onChange(e.target.checked)
          }}
          ref={ref}
        />
        <Text inline size="small" color={error ? theme.colors.error : null} uppercase>
          {label}
        </Text>
      </Label>
    )
  },
)

export default Checkbox
