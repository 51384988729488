import React from 'react'
import ResourceGateway from 'components/ResourceGateway'
import AddMarketplaceListingPage from 'components/Platform/Portfolio/MarketplaceListing/AddMarketListingPage'
import MarketplaceListingsPage from 'components/Platform/Portfolio/MarketplaceListing/MarketListingsPage'
import EditMarketplaceListingPage from 'components/Platform/Portfolio/MarketplaceListing/EditMarketListingPage'
import MarketListingReport from 'components/Platform/Portfolio/MarketplaceListing/MarketListingReport'
import {Route, useRouteMatch} from 'react-router'

const MarketplaceListings = () => {
  const {url} = useRouteMatch()

  return (
    <ResourceGateway
      create={() => <AddMarketplaceListingPage />}
      single={routeProps => <EditMarketplaceListingPage id={routeProps.match.params.id} />}
      list={() => <MarketplaceListingsPage />}
      custom={
        <Route
          exact
          path={`${url}/:id/report`}
          render={routeProps => <MarketListingReport id={routeProps.match.params.id} />}
        />
      }
    />
  )
}

export default MarketplaceListings
