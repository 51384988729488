import React, {useState} from 'react'
import Select, {OptionTypeBase, Props} from 'react-select'
import {IWithLabelProps, withLabel} from './Label'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import useLocalization from '../hooks/useLocalization'
import {IOption} from './Select'
import dotsIcon from '../assets/icons/dots.svg'
import useTheme from 'hooks/useTheme'

const StyledSelect = styled(Select)<{
  hasError?: boolean
  hideIndicators?: boolean
  noMargin?: boolean
  isDisabled?: boolean
}>`
  font-size: 14px;
  margin-bottom: ${props => (props.noMargin ? '0px' : '16px')};
  z-index: 5;
  font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
  font-weight: 200;

  .wp {
    &__control {
      font-size: 14px;
      ${props =>
        props.hasError
          ? `border: 1px solid ${(props.theme as ITheme).colors.error}`
          : `border: 1px solid ${(props.theme as ITheme).colors.lightGrey}`};

      ${props =>
        props.isDisabled &&
        `
        background-color: ${(props.theme as ITheme).colors.nGrey0};
        cursor: not-allowed;
      `}

      &:hover {
        border: 1px solid
          ${props => (props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.primary)};
      }

      &--is-focused,
      &--is-focused:hover {
        border-color: ${props =>
          props.hasError ? (props.theme as ITheme).colors.error : (props.theme as ITheme).colors.primary};
        box-shadow: none;
      }
    }

    &__indicators {
      ${props => (props.hideIndicators ? 'display: none;' : '')}
    }

    &__input input {
      font-size: 14px;
      font-family: ${props => (props.theme as ITheme).font.secondaryFont}, sans-serif;
      font-weight: 200;
      color: ${props => (props.theme as ITheme).colors.secondary};
    }
  }
`

const StyledImageSelect = styled(Select)<{
  hideIndicators?: boolean
  pointer?: boolean
}>`
  .wp {
    &__control {
      all: unset;
      &:hover {
        ${props => (props.pointer ? 'cursor: pointer;' : '')}
      }
    }

    &__indicators {
      ${props => (props.hideIndicators ? 'display: none;' : '')}
    }

    &__input {
      caret-color: transparent;
    }

    &__input input {
      font-size: 12px;
      font-weight: 300;
    }

    &__menu {
      min-width: 80px;
    }
  }
`

const getColorStyles = (theme: ITheme) => ({
  option: (styles, {data, isFocused}) => {
    return {
      ...styles,
      color: data.color,
    }
  },
})

export const ReactSelect: React.FC<
  OptionTypeBase & {error?: string; hideIndicators?: boolean; isMulti?: boolean; isDisabled?: boolean}
> = ({error, hideIndicators, isDisabled, ...props}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <StyledSelect
      hasError={!!error}
      styles={getColorStyles(theme)}
      noOptionsMessage={() => translate('No options')}
      hideIndicators={hideIndicators}
      {...props}
      classNamePrefix="wp"
      isDisabled={isDisabled}
    />
  )
}

ReactSelect.displayName = 'ReactSelect'

export const ImageSelect: React.FC<Props & {hideIndicators?: boolean; pointer: boolean; noMargin?: boolean}> = ({
  hideIndicators,
  ...props
}) => {
  const {translate} = useLocalization()
  const [selectedValue, setSelectedValue] = useState([])

  return (
    <StyledImageSelect
      noOptionsMessage={() => translate('No options')}
      hideIndicators={hideIndicators}
      {...props}
      classNamePrefix="wp"
      placeholder={<img src={dotsIcon} alt="more actions" />}
      menuPosition="fixed"
      closeMenuOnScroll={e => e}
      value={selectedValue}
      onChange={() => setSelectedValue([])}
    />
  )
}

ImageSelect.defaultProps = {
  pointer: true,
}

export interface IProps extends React.PropsWithChildren, Props, IWithLabelProps {
  error?: string
  format?: (value: any) => IOption
  parse?: (value: IOption) => any
  data?: any[]
  noMargin?: boolean
}

const MultiSelect: React.FC<IProps> = React.forwardRef(
  ({label, error, onChange, value, format, parse, data, ...props}, ref: React.Ref<any>) => {
    const formattedvalue = Array.isArray(value) ? value : [value]

    return (
      <ReactSelect
        isMulti
        ref={ref}
        onChange={(value: any, action) => {
          onChange && onChange(format ? (value || []).map(format) : value, action)
        }}
        value={parse && value ? formattedvalue.map(parse) : value}
        options={data.map(parse)}
        {...props}
      />
    )
  },
)

MultiSelect.displayName = 'MultiSelect'

export default withLabel<IProps>(MultiSelect)
