import {PrimitiveAtom, atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {intervalAtom, rootServiceAtom} from './general'
import IParty from 'domain/IParty'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)
export const passedPartyAtom = atom<IParty>(null) as PrimitiveAtom<IParty>
const refetchAtom = atom(0)

export const refetchSitesAtom = atom(null, (get, set) => {
  set(refetchAtom, get(refetchAtom) + 1)
})

export const sitesAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)
    const passedParty = get(passedPartyAtom)
    const _ = get(refetchAtom)

    const data = await services.v2ContentService.getPortfolioSites(passedParty?.id || party?.id, page, pageSize)

    return data
  }),
)

export const dashboardAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const interval = get(intervalAtom)
    const passedParty = get(passedPartyAtom)
    const _ = get(refetchAtom)

    const data = await services.v2ContentService.getPortfolioSitesDashboard(passedParty?.id || party?.id, interval)

    return data
  }),
)

export const siteIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const buyerSiteAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const siteId = get(siteIdAtom)

    if (!siteId) {
      return null
    }

    const data = await services.v2ContentService.getBuyerSite(party?.id, siteId)

    return data
  }),
  {nullIsLoading: true},
)
