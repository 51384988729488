import React, {useEffect, useState} from 'react'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import Box from 'components/Box'
import useResource from 'hooks/useResource'
import {SiteProductionTechnology} from 'domain/ISite'
import useTheme from 'hooks/useTheme'
import Filter, {IChoice} from 'components/Filter'
import {capitalize} from 'lodash-es'
import SelectFilter from 'components/SelectFilter'
import ISellExcessRequest, {ExcessStatus} from 'domain/ISellExcessRequest'
import Table from 'components/Table'
import DottedIconButtonSelect from 'components/IconButton/DottedIconButtonSelect'
import SmartOption, {ISmartOption} from 'components/MultiSelect/SmartOption'
import {OptionsType} from 'react-select/src/types'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import useStore from 'hooks/useStore'
import {useRouteMatch} from 'react-router-dom'
import {formatDecimal} from 'helpers/format'
import {getCurrencySymbol} from 'helpers/price'
import {CurrencyCode} from 'domain/IPrice'
import TableSkeleton from 'components/TableSkeleton'
import {Skeleton} from '@mui/material'

const ALL_TECHNOLOGIES_SLUG = 'ALL'
const STATUS_FILTER_NAME = 'status'
const TECHNOLOGY_FILTER_NAME = 'filter'

interface IProps extends React.PropsWithChildren {
  sellRequests: ISellExcessRequest[]
  loading: boolean
  isAdminPage: boolean
  reloadSellRequests: () => Promise<void>
}

const SellRequests: React.FC<IProps> = ({sellRequests, loading, isAdminPage, reloadSellRequests}) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const {translate} = useLocalization()
  const allTechnologies: IChoice = {
    name: translate('All'),
    slug: ALL_TECHNOLOGIES_SLUG,
  }
  const [technology, setTechnology] = useState<IChoice>(allTechnologies)
  const theme = useTheme()
  const {adminStore, alertStore} = useStore()

  let filteredSellRequests = sellRequests
  const activeTechnologySlug = urlSearchParams.get(TECHNOLOGY_FILTER_NAME)
  const activeStatusSlug = urlSearchParams.getAll(STATUS_FILTER_NAME)

  if (activeTechnologySlug) {
    filteredSellRequests = filteredSellRequests?.filter(
      request => request.technologyKind === activeTechnologySlug || activeTechnologySlug === ALL_TECHNOLOGIES_SLUG,
    )
  }

  if (activeStatusSlug.length > 0) {
    filteredSellRequests = filteredSellRequests?.filter(request => activeStatusSlug.includes(request.status))
  }

  const technologies: IChoice[] = [
    allTechnologies,
    ...[SiteProductionTechnology.SOLAR, SiteProductionTechnology.WIND, SiteProductionTechnology.HYDRO].map(
      technology => ({
        name: translate(capitalize(SiteProductionTechnology[technology])).toUpperCase(),
        slug: technology,
      }),
    ),
  ]

  const statuses: IChoice[] = Object.keys(ExcessStatus).map(status => ({slug: status, name: translate(status)}))

  const handleApproveExcess = async (requestId: number) => {
    const result = await adminStore.acceptSellRequest(requestId)

    if (result) {
      alertStore.addSuccess(translate('Successfully sent request'))
      await reloadSellRequests()
    }
  }

  const handleDeclineExcess = async (requestId: number) => {
    const result = await adminStore.declineSellRequest(requestId)

    if (result) {
      alertStore.addSuccess(translate('Successfully sent request'))
      await reloadSellRequests()
    }
  }

  if (loading) {
    return (
      <Page title={translate('Sell requests')} description={translate('Manage excess sell requests')}>
        <Box direction="column">
          <Box direction="column" gap={1}>
            <Skeleton height={100} />
            <Skeleton height={70} />
          </Box>
          <TableSkeleton />
        </Box>
      </Page>
    )
  }

  return (
    <Page title={translate('Sell requests')} description={translate('Manage excess sell requests')}>
      <Box direction="column" gap={5}>
        <Box direction="row" align="center">
          <Filter
            selected={technology}
            choices={technologies}
            colored
            transparent
            skipDate
            technology
            setActiveChoice={choice => setTechnology(choice as IChoice)}
          />
          <Box>
            <SelectFilter
              label={translate('Choose status:')}
              multiselect
              choices={statuses}
              transparent
              name={STATUS_FILTER_NAME}
              choiceMaxWidth="180px"
            />
          </Box>
        </Box>
        <Table<ISellExcessRequest>
          search={{placeholder: translate('Search tokens')}}
          columns={[
            {
              title: translate('Technology'),
              accessor: item => item.technologyKind,
              render: (value: SiteProductionTechnology) => (
                <Box style={{maxWidth: 50}}>
                  <TechnologyIconAndName technology={value} />
                </Box>
              ),
            },
            {
              title: translate('Status'),
              accessor: item => capitalize(item.status.toLowerCase()),
            },
            isAdminPage && {
              title: translate('Party'),
              accessor: item => item.party.name,
            },
            {
              title: translate('Quantity'),
              accessor: item => item.quantity,
            },
            {
              title: translate('Certificate price'),
              accessor: item =>
                `${getCurrencySymbol(item.currency || CurrencyCode.GBP)}${formatDecimal(item.certPrice, 2)}`,
            },
            isAdminPage && {
              title: translate('Actions'),
              accessor: (offer: any) => offer,
              // eslint-disable-next-line react/display-name
              render: (item: ISellExcessRequest) => (
                <DottedIconButtonSelect
                  hideIndicators
                  components={{Option: SmartOption}}
                  options={
                    [
                      {
                        value: 'Approve',
                        label: translate('Approve'),
                        onClick: () => handleApproveExcess(item.id),
                      },
                      {
                        value: 'Decline',
                        color: theme.colors.error,
                        label: translate('Decline'),
                        onClick: () => handleDeclineExcess(item.id),
                      },
                    ] as OptionsType<ISmartOption>
                  }
                />
              ),
            },
          ].filter(Boolean)}
          data={filteredSellRequests}
        />
      </Box>
    </Page>
  )
}

const SellRequestsWrapper = () => {
  const {url} = useRouteMatch()
  const isAdminPage = url.includes('/admin')
  const {adminStore} = useStore()
  const {sellRequests, loading} = useResource(store => ({
    sellRequests: store.adminStore.sellRequests,
    loading: store.adminStore.loading,
  }))
  const loadSellRequests = () =>
    isAdminPage ? adminStore.loadAdminSellRequests() : adminStore.loadPublicSellRequests()

  useEffect(() => {
    loadSellRequests()
  }, [])

  return (
    <SellRequests
      isAdminPage={isAdminPage}
      loading={loading}
      sellRequests={sellRequests}
      reloadSellRequests={loadSellRequests}
    />
  )
}

export default SellRequestsWrapper
