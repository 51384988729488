import React, {useState} from 'react'
import {IProps as IInputProps} from '../Input'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import CertInput from 'components/CertInput'
import useLocalization from 'hooks/useLocalization'
import {CertError, getValidationErrMsg} from 'helpers/certificates'

export type IProps = IWrapperProps<IInputProps & {contrast?: boolean; staticValue?: string}>

const FormCertInput: React.FC<IProps> = props => {
  const [type, setType] = useState<CertError>(null)
  const {translate} = useLocalization()
  const {contrast, staticValue, ...rest} = props

  return (
    <FormInputWrapper
      {...rest}
      errorType={type}
      validate={(value, getValues) => {
        const error = getValidationErrMsg(value, translate)
        setType(error?.type)
        return error === null ? true : error.message
      }}
    >
      <CertInput contrast={props.contrast} staticValue={staticValue} />
    </FormInputWrapper>
  )
}

export default FormCertInput
