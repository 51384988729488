import React from 'react'
import Box from '../Box'
import Text, {IProps as ITextProps} from '../Text'
import {SiteProductionTechnology} from '../../domain/ISite'
import useLocalization from '../../hooks/useLocalization'
import TechnologyIcon from './TechnologyIcon'
import {getHumanReadableTechnologyName} from '../../helpers/site'

interface IProps extends React.PropsWithChildren, ITextProps {
  technology: SiteProductionTechnology
}

const TechnologyIconAndName: React.FC<IProps> = ({technology, ...textProps}) => {
  const {translate} = useLocalization()

  return (
    <Box align="center">
      <TechnologyIcon technology={technology} />
      <Text margin={{horizontal: 1}} {...textProps}>
        {getHumanReadableTechnologyName(technology, translate)}
      </Text>
    </Box>
  )
}

export default TechnologyIconAndName
