import React from 'react'
import Grid from '../../components/Grid'
import ForgotPasswordForm from '../../components/Platform/Onboarding/ForgotPasswordForm'
import Page from '../../components/Platform/Onboarding/Page'
import InnerScroll from '../../components/InnerScroll'

const ForgotPassword = () => {
  return (
    <Page>
      <InnerScroll>
        <Grid>
          <ForgotPasswordForm />
        </Grid>
      </InnerScroll>
    </Page>
  )
}

export default ForgotPassword
