import React from 'react'
import Box from 'components/Box'
import Modal from 'components/Modal'
import Text from 'components/Text'
import ModalHeader from 'components/Modal/ModalHeader'
import useLocalization from 'hooks/useLocalization'
import Link from 'components/Link'

interface IProps extends React.PropsWithChildren {
  setSigning(value: boolean): void
  setShowPhoneVerificationModal(value: boolean): void
}

const VerifyPhoneModal: React.FC<IProps> = ({setSigning, setShowPhoneVerificationModal}) => {
  const {translate} = useLocalization()

  const onClose = () => {
    setSigning(false)
    setShowPhoneVerificationModal(false)
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader title={translate('Verify your phone number')} />
      <Box style={{minWidth: '500px'}}>
        <Text margin={{top: 2, bottom: 4}} size="xxlarge" align="center">
          To sign the contract, you need to <br></br> verify your phone number from Personal Profile page.
        </Text>
        <Box justify="center">
          <Link to={`/consumer/company/personal-profile`}>Go to Personal Profile</Link>
        </Box>
      </Box>
    </Modal>
  )
}

export default VerifyPhoneModal
