export enum ProfileUploadKind {
  INVALID_KWH_COLUMN_IN_CSV = 'INVALID_KWH_COLUMN_IN_CSV',
  INVALID_TIMESTAMP_COLUMN_IN_CSV = 'INVALID_TIMESTAMP_COLUMN_IN_CSV',
  INVALID_COLUMN_LEN_IN_CSV = 'INVALID_COLUMN_LEN_IN_CSV',
  TIMESERIES_CSV_PROCESSING_ERROR = 'TIMESERIES_CSV_PROCESSING_ERROR',
}

export enum MeterRefKind {
  UPDATED_METER_REF_ALREADY_IN_USE = 'UPDATED_METER_REF_ALREADY_IN_USE',
  METER_REF_ALREADY_IN_USE = 'METER_REF_ALREADY_IN_USE',
}

export enum BaseUserKind {
  PRODUCT_ALREADY_ON_MARKET = 'PRODUCT_ALREADY_ON_MARKET',
}

export type UserError = ProfileUploadKind | MeterRefKind | BaseUserKind | string

interface ProfileUploadErrorPayload {
  attachmentId: number
  cause?: string
  fileHead?: string
  row: number
  actual?: string
  expected?: number
}

export type ErrorKind = {kind: UserError; payload?: any | ProfileUploadErrorPayload}

export default class V2ApiErrorMapper {
  public static getMessage(defaultMessage, error: ErrorKind): string {
    const kind = error?.kind

    return V2ApiErrorMapper.getUserErrorMessage(kind, error?.payload, defaultMessage)
  }

  private static getUserErrorMessage(kind: UserError, payload: ProfileUploadErrorPayload, defaultMsg: string): string {
    switch (kind) {
      case ProfileUploadKind.INVALID_COLUMN_LEN_IN_CSV:
        return `Your csv file contains ${payload.actual} columns, but the number of columns must be ${payload.expected}.`

      case ProfileUploadKind.INVALID_TIMESTAMP_COLUMN_IN_CSV:
        return `Your csv file contains invalid timestamp: ${payload.actual}. Please ensure the timestamp is in the format of "day/month/year hour:minute".`

      case ProfileUploadKind.INVALID_KWH_COLUMN_IN_CSV:
        return `Your csv file contains invalid kwh value: ${payload.actual}. Please ensure the kwh value is a valid number.`

      case ProfileUploadKind.TIMESERIES_CSV_PROCESSING_ERROR:
        return `The csv file is invalid. Please ensure every row has exactly same number of columns for each row.`
      case MeterRefKind.UPDATED_METER_REF_ALREADY_IN_USE:
      case MeterRefKind.METER_REF_ALREADY_IN_USE:
        return `Your meter reference is already in use. Please enter a different one.`
      case BaseUserKind.PRODUCT_ALREADY_ON_MARKET:
        return 'This product is already listed on the marketplace for the given period.'

      default:
        return defaultMsg
    }
  }
}
