import React from 'react'
import styled from 'styled-components'
import {NavLink, NavLinkProps} from 'react-router-dom'
import ITheme from '../../../theme/ITheme'
import round, {IRoundProps} from '../../styleProps/round'

const Wrap = styled.div`
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface IProps extends React.PropsWithChildren, IRoundProps, NavLinkProps {}

const BaseNavLink: React.FC<IProps> = ({round, ...props}) => {
  return <NavLink {...props} />
}

export const AsideNavigationLink = styled(BaseNavLink)<IProps>`
  font-family: ${props => (props.theme as ITheme).font.secondaryFont};
  display: block;
  margin-bottom: ${props => (props.theme as ITheme).tileSize * 0.5}px;
  padding: 8px;
  padding-right: 0px;
  color: #0e0e0e;
  font-weight: 200;
  line-height: 20px;
  width: 100%;
  ${round}

  &.active {
    background-color: ${props => (props.theme as ITheme).colors.toned};
  }
  &:hover {
    background-color: ${props => (props.theme as ITheme).colors.toned};
  }
`

AsideNavigationLink.defaultProps = {
  round: true,
}

const AsideNavigation = ({children}) => {
  return <Wrap>{children}</Wrap>
}

export default AsideNavigation
