import React, {useEffect} from 'react'
import ErrorInfo from 'components/ErrorInfo'
import TableSkeleton from 'components/TableSkeleton'
import useLocalization from 'hooks/useLocalization'
import Page from 'components/Page'
import {marketListingIdAtom, marketListingReportAtom} from 'atoms/marketListings'
import {useAtomValue, useSetAtom} from 'jotai'
import MatchedCertsTable from './MatchedCertsTable'

const MarketListingReport: React.FC<{id: string}> = ({id}) => {
  const {translate} = useLocalization()

  const {data, loading, error} = useAtomValue(marketListingReportAtom)
  const setMarketListingId = useSetAtom(marketListingIdAtom)

  useEffect(() => {
    if (id) {
      setMarketListingId(+id)
    }
  }, [id])

  if (loading) {
    return (
      <Page title={translate('Marketplace listing matched certificates breakdown')}>
        <TableSkeleton />
      </Page>
    )
  }

  return (
    <Page
      title={translate('Marketplace listing matched certificates breakdown')}
      error={error && <ErrorInfo error={error} title={translate('Failed to load certificates breakdown')} />}
    >
      <MatchedCertsTable data={data} />
    </Page>
  )
}

export default MarketListingReport
