import {useFormFieldName} from 'components/Form/FormFieldNamePrefixProvider'
import React, {ReactElement, useMemo} from 'react'
import ISite, {IBasicSite} from '../../../domain/ISite'
import useFormContext from '../../../hooks/useFormContext'
import MapWithHeader from '../../Map/MapWithHeader'
import SiteMapMarker from './SiteMapMarker'

interface IProps extends React.PropsWithChildren {
  title?: string
  description?: string
  sites?: ISite[] | IBasicSite[]
  urlPrefix?: string
  suffix?: string
  headerChildren?: ReactElement
  defaultContextSite?: {siteType: string}
}

const SiteMap: React.FC<IProps> = ({
  title,
  description,
  sites,
  urlPrefix,
  suffix,
  defaultContextSite,
  headerChildren,
}) => {
  const formContext = useFormContext()
  const lattitudeWithPrefix = useFormFieldName('location.coordinatesLat')
  const longtitudeWithPrefix = useFormFieldName('location.coordinatesLon')

  let lat
  let lng

  if (formContext) {
    lat = formContext.watch(lattitudeWithPrefix)
    lng = formContext.watch(longtitudeWithPrefix)
  }

  const centerCoordinates: [number, number] | null = lat && lng ? [50, 50] : null

  const defaultSites =
    formContext && defaultContextSite
      ? [{id: 'default', ...defaultContextSite, location: {coordinatesLat: lat, coordinatesLon: lng}}]
      : null

  const sitesLatitudes = []
  const sitesLongitudes = []
  let averageOfSitesLatitudes: number
  let averageOfSitesLongitudes: number

  if (!formContext) {
    sites.forEach(site => {
      sitesLatitudes.push(site.location?.coordinatesLat)
      sitesLongitudes.push(site.location?.coordinatesLon)
    })

    averageOfSitesLatitudes = sitesLatitudes.reduce((a, b) => a + b, 0) / sitesLatitudes.length
    averageOfSitesLongitudes = sitesLongitudes.reduce((a, b) => a + b, 0) / sitesLongitudes.length
  }

  return (
    <MapWithHeader
      title={title}
      description={description}
      headerChildren={headerChildren}
      defaultCenter={
        centerCoordinates
          ? centerCoordinates
          : !formContext && averageOfSitesLatitudes && averageOfSitesLongitudes
          ? [averageOfSitesLatitudes, averageOfSitesLongitudes]
          : null
      }
    >
      {useMemo(
        () =>
          (sites || defaultSites || []).map(site => {
            const location = site.location

            if (!location || !location.coordinatesLat || !location.coordinatesLon) {
              return null
            }

            return (
              <SiteMapMarker
                site={site}
                urlPrefix={urlPrefix}
                key={site.id}
                lat={lat ? lat : +location.coordinatesLat}
                lng={lng ? lng : +location.coordinatesLon}
                suffix={suffix}
              />
            )
          }),
        [lat, lng, defaultContextSite, sites],
      )}
    </MapWithHeader>
  )
}

export default SiteMap
