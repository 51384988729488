import React, {useEffect} from 'react'
import FormInputWrapper, {IProps as IWrapperProps} from './FormInputWrapper'
import Select, {IProps as ISelectProps} from '../Select'

export type IProps = IWrapperProps<ISelectProps>

const SelectWrap: React.FC<ISelectProps> = props => {
  useEffect(() => {
    if (props.value === undefined && props.options?.length > 0) {
      props.onChange(props.options[0].value as any)
    }
  }, [props.value, props.options])

  return <Select {...props} />
}

const FormSelect: React.FC<IProps> = ({children, ...props}) => {
  return (
    <FormInputWrapper {...props}>
      <SelectWrap />
    </FormInputWrapper>
  )
}

export default FormSelect
