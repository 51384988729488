import {partyAtom} from 'atoms/party'
import BuyerSiteCreatePage from 'components/Platform/Portfolio/Site/BuyerSiteCreatePage'
import BuyerSiteEditPage from 'components/Platform/Portfolio/Site/BuyerSiteEditPage'
import {useAtomValue} from 'jotai'
import React from 'react'
import SiteEditPage from '../../../components/Platform/Portfolio/Site/SiteEditPage'
import SiteListPageNew from '../../../components/Platform/Portfolio/Site/SiteListPageNew'
import SiteViewPage from '../../../components/Platform/Portfolio/Site/SiteViewPage'
import SiteResolver from '../../../components/Platform/Resolver/SiteResolver'
import ResourceGateway from '../../../components/ResourceGateway'
import {PartyRole} from '../../../domain/IParty'
import {SiteType} from '../../../domain/ISite'

interface IProps extends React.PropsWithChildren {
  partyId?: number
  type?: SiteType
}

const Sites: React.FC<IProps> = ({partyId, type}) => {
  const party = useAtomValue(partyAtom)
  const {partyRole} = party || {}
  const siteType = type ? type : partyRole === PartyRole.BUYER ? SiteType.CONSUMPTION : SiteType.PRODUCTION
  const isBuyer = partyRole === PartyRole.BUYER

  if (!party) {
    return null
  }

  return (
    <ResourceGateway
      create={() => (isBuyer ? <BuyerSiteCreatePage /> : <SiteEditPage siteType={siteType} />)}
      edit={routeProps =>
        isBuyer ? (
          <BuyerSiteEditPage siteId={routeProps.match.params.id} />
        ) : (
          <SiteResolver id={routeProps.match.params.id} component={SiteEditPage} />
        )
      }
      single={routeProps => <SiteResolver id={routeProps.match.params.id} component={SiteViewPage} />}
      list={() => <SiteListPageNew />}
    />
  )
}

export default Sites
