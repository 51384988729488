import {mockContractType1} from './mockContractTypes'
import IContract, {ContractState, ContractVolumeProfile} from '../../domain/IContract'

export const mockOfferContract1: IContract = {
  contractItems: [],
  contractType: mockContractType1,
  id: 1,
  signingDate: null,
  state: ContractState.DRAFT,
  summary: null,
  validFrom: '2018-12-31T22:00:00Z',
  validTo: '2023-01-30T22:00:00Z',
  volumeMwh: 1000,
  volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
}

export const mockOfferContract2: IContract = {
  contractItems: [],
  contractType: mockContractType1,
  id: 2,
  signingDate: null,
  state: ContractState.DRAFT,
  summary: null,
  validFrom: '2018-12-31T22:00:00Z',
  validTo: '2029-01-30T22:00:00Z',
  volumeMwh: 500,
  volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
}
