import React, {useEffect} from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import Page from '../../../Page'
import Box from '../../../Box'
import SiteMap from '../../Map/SiteMap'
import InnerScroll from '../../../InnerScroll'
import useResource from '../../../../hooks/useResource'
import {getSitesOfContracts} from '../../../../helpers/contract'
import ContractsSummaryByTechnology from './ContractsSummaryByTechnology'
import SalesContractList from './SalesContractList'
import useStore from 'hooks/useStore'

const SalesContractListPage: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const {purchaseContractStore} = useStore()
  const {contracts, isLoading, partyLoaded} = useResource(store => ({
    contracts: store.purchaseContractStore.partySalesContracts,
    isLoading: store.purchaseContractStore.isLoading,
    partyLoaded: store.profileStore.isLoaded,
  }))
  const sites = getSitesOfContracts(contracts)
  const loading = isLoading || !partyLoaded

  useEffect(() => {
    if (partyLoaded) {
      purchaseContractStore.loadPartySalesContracts()
    }
  }, [partyLoaded])

  return (
    <Page
      title={translate('Sales contracts')}
      description={translate('Overview of sales contracts')}
      aside={
        <SiteMap
          title={translate('Production sites')}
          description={translate('Overview of contracted production sites')}
          sites={sites || []}
        />
      }
    >
      <InnerScroll>
        <ContractsSummaryByTechnology contracts={contracts} loading={loading} />
        <Box margin={{vertical: 4}} />
        <SalesContractList contracts={contracts} loading={loading} />
      </InnerScroll>
    </Page>
  )
}

export default SalesContractListPage
