import ApiError from 'exceptions/ApiError'

export enum LederhosenApiErrorType {
  BAD_REQUEST = 'BAD_REQUEST',
  FORBIDDEN = 'FORBIDDEN',
  ELEMENT_NOT_FOUND = 'ELEMENT_NOT_FOUND',
  CONTRACT_ALREADY_IN_USE = 'CONTRACT_ALREADY_IN_USE',
  PRECONDITION_FAILED = 'PRECONDITION_FAILED',
  CONVERT_HTML_TO_PDF = 'CONVERT_HTML_TO_PDF',
  COMPILE_TEXT = 'COMPILE_TEXT',
  EMAIL_COULD_NOT_BE_SEND = 'EMAIL_COULD_NOT_BE_SEND',
  INVALID_AUTHENTICATION_MODE = 'INVALID_AUTHENTICATION_MODE',
  CONSTRAINT_VIOLATION_EXCEPTION = 'CONSTRAINT_VIOLATION_EXCEPTION',
  USER_INVITE_CANNOT_BE_CREATED = 'USER_INVITE_CANNOT_BE_CREATED',
  USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  PHONE_ALREADY_VERIFIED = 'PHONE_ALREADY_VERIFIED',
  VERIFICATIONS_LIMIT_EXCEEDED = 'VERIFICATIONS_LIMIT_EXCEEDED',
  VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID',
  NOT_ENOUGH_TOTAL_CERTIFICATES = 'NOT_ENOUGH_TOTAL_CERTIFICATES',
  NOT_ENOUGH_REMAINING_CERTIFICATES = 'NOT_ENOUGH_REMAINING_CERTIFICATES',
}

const DEFAULT_ERROR_MSG = 'Something went wrong.'
const INSUFFICIENT_PERMISSIONS_MSG = 'Insufficient permissions.'
const INTERNAL_SERVER_ERR_STATUS_CODE = 500
const CORRELATION_ID_HEADER = 'X-Correlation-Id'

export default class LederhosenApiErrorMapper {
  static getMessageByErrorType(response: any): ApiError {
    if (!response) {
      return new ApiError('Failed to load data. Please check your network connection.')
    }

    const data = response.data
    // UUID v4
    const correlationId = response.headers && response.headers[CORRELATION_ID_HEADER.toLowerCase()]

    // the detail field is the old Lederhosen API's msg(new exceptions use message field instead),
    // so detail will be removed after new version of Lederhosen is deployed to all envs.
    const responseErrorMsg = data?.detail || data?.message
    const mappedErrorType = LederhosenApiErrorType[(data?.errorType || data?.error?.kind) as string]
    const statusCode = response?.status
    let errorMsg

    if (mappedErrorType) {
      errorMsg = LederhosenApiErrorMapper.mapByType(responseErrorMsg, mappedErrorType)
    } else {
      errorMsg = statusCode === INTERNAL_SERVER_ERR_STATUS_CODE ? DEFAULT_ERROR_MSG : responseErrorMsg
    }

    return new ApiError(errorMsg, correlationId, null, mappedErrorType)
  }

  private static mapByType(responseErrorMsg: string, mappedErrorType: LederhosenApiErrorType): string {
    switch (mappedErrorType) {
      case LederhosenApiErrorType.CONTRACT_ALREADY_IN_USE:
        return 'Contract is already in use.'

      case LederhosenApiErrorType.USERNAME_ALREADY_EXISTS:
        return 'Username already exists.'

      case LederhosenApiErrorType.EMAIL_ALREADY_EXISTS:
        return 'Email already exists.'

      case LederhosenApiErrorType.USER_INVITE_CANNOT_BE_CREATED:
        return 'Failed to create invite.'

      case LederhosenApiErrorType.VERIFICATION_CODE_INVALID:
        return 'Invalid verification code.'

      case LederhosenApiErrorType.PHONE_ALREADY_VERIFIED:
        return 'Your phone number is already verified.'

      case LederhosenApiErrorType.PRECONDITION_FAILED:
        return responseErrorMsg || DEFAULT_ERROR_MSG

      case LederhosenApiErrorType.NOT_ENOUGH_REMAINING_CERTIFICATES:
        return 'Not enough remaining certificates.'

      case LederhosenApiErrorType.NOT_ENOUGH_TOTAL_CERTIFICATES:
        return 'Not enough total certificates.'

      case LederhosenApiErrorType.FORBIDDEN:
        // TODO: Should not rely on the server message,
        // but use a custom api error type for each insufficient permissions scenario
        return responseErrorMsg || INSUFFICIENT_PERMISSIONS_MSG

      default:
        return responseErrorMsg || DEFAULT_ERROR_MSG
    }
  }
}
