import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import Box from '../Box'
import useTheme from '../../hooks/useTheme'
import Grid from '../Grid'
import styled from 'styled-components'
import InnerScroll from '../InnerScroll'
import RouterModal from '../Modal/RouterModal'
import ModalHeader from '../Modal/ModalHeader'
import {ModalType} from '../Modal/IModal'
import Heading from '../Heading'
import ReactMarkdown from 'react-markdown'

const StyledGrid = styled(Grid)`
  overflow: hidden;
`

const SecurityModal: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <RouterModal name={ModalType.SECURITY} full>
      <ModalHeader
        title={translate('Responsible Disclosure Policy')}
        description={translate('You have found a potential security issue with our platform, please let us know.')}
      />
      <StyledGrid templateColumns="1fr 0.75fr" gap={3}>
        <InnerScroll noRightPad>
          <Box pad={2} border round>
            <ReactMarkdown>{translate('__SECURITY_POLICY__')}</ReactMarkdown>
          </Box>
        </InnerScroll>
        <InnerScroll noRightPad>
          <Box pad={2} color={theme.colors.surface} round>
            <Heading>{translate('Public PGP Key')}</Heading>
            <ReactMarkdown>{translate('__SECURITY_PGP__')}</ReactMarkdown>
          </Box>
        </InnerScroll>
      </StyledGrid>
    </RouterModal>
  )
}

export default SecurityModal
