import React from 'react'
import Box from 'components/Box'
import useTheme from 'hooks/useTheme'
import VerticalStack from 'components/VerticalStack'
import {SubHeading, Heading} from '../Heading'
import {buyerOnboardingAtom} from 'atoms/onboarding'
import {useAtomValue} from 'jotai'
import {OnboardingStatus, progress} from 'domain/IParty'
import NavItem from '../Layout/NavItem'
import useLocalization from 'hooks/useLocalization'

enum BuyerOnboardingStep {
  SETUP_SITES = 'SETUP_SITES',
  UPLOAD_CONSUMPTION = 'UPLOAD_CONSUMPTION',
  REVIEW_AND_PUBLISH = 'REVIEW_AND_PUBLISH',
}

interface IProps extends React.PropsWithChildren {
  step?: BuyerOnboardingStep
}

const BuyerOnboardingAside: React.FC<IProps> = step => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const {data: onboardResult} = useAtomValue(buyerOnboardingAtom)
  const currProgress = onboardResult && progress(onboardResult.onboardingStatus)
  const createSiteCompleted = currProgress >= progress(OnboardingStatus.BUYER_SITE_CREATED)
  const createProfileCompleted = currProgress >= progress(OnboardingStatus.BUYER_PROFILE_UPLOADED)
  const onboardCompleted = currProgress === progress(OnboardingStatus.COMPLETED)

  return (
    <Box color={theme.colors.surface}>
      <Box pad={2} color={theme.colors.primary} hoverColor={theme.colors.primaryDark}>
        <VerticalStack>
          <Heading>{translate('Get going in 3 easy steps')}</Heading>
          <SubHeading black>{translate('In few minutes you are good to go!')}</SubHeading>
        </VerticalStack>
      </Box>
      <NavItem to="/consumer/onboarding/setup-site" disabled $completed={createSiteCompleted}>
        <VerticalStack>
          <Heading>1. {translate('Set up site')}</Heading>
          <SubHeading>{translate('Where you consume energy')}</SubHeading>
        </VerticalStack>
      </NavItem>
      <NavItem to="/consumer/onboarding/upload-consumption" disabled $completed={createProfileCompleted}>
        <VerticalStack>
          <Heading>2. {translate('Upload your consumption')}</Heading>
          <SubHeading>{translate('to visualise your needs')}</SubHeading>
        </VerticalStack>
      </NavItem>
      <NavItem to={'/consumer/onboarding/review'} disabled $completed={onboardCompleted} $withBottomBorder>
        <VerticalStack>
          <Heading>3. {translate('Review and complete')}</Heading>
          <SubHeading>{translate('Finish Your Onboarding')}</SubHeading>
        </VerticalStack>
      </NavItem>
      <Box pad={2}>
        <VerticalStack>
          <Heading>{translate('Start now')}</Heading>
          <SubHeading>{translate("It's that easy!")}</SubHeading>
        </VerticalStack>
      </Box>
    </Box>
  )
}

export default BuyerOnboardingAside
