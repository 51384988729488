import React from 'react'
import styled from 'styled-components'
import ITheme from '../../theme/ITheme'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'

const StyledTab = styled(Link)<{$active: any}>`
  padding: 6px 12px;
  margin-right: ${props => (props.theme as ITheme).tileSize}px;
  color: ${props => (props.$active ? (props.theme as ITheme).colors.secondary : (props.theme as ITheme).colors.clay)};
  cursor: pointer;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  font-family: ${props => (props.theme as ITheme).font.secondaryFont};

  ${props => props.$active && `border-bottom-color: ${props.theme.colors.primary};`}

  &:hover {
    color: ${props => (props.theme as ITheme).colors.secondary};
  }
`

export interface IProps extends React.PropsWithChildren {
  label: string
  name?: string
  slug: string
  active?: boolean
  baseUrl?: string
}

const Tab: React.FC<IProps> = ({name, label, slug, baseUrl}) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const activeSlug = urlSearchParams.get(name) || ''

  urlSearchParams.set(name, slug)

  return (
    <>
      <StyledTab
        to={`${baseUrl ? baseUrl : ''}?${urlSearchParams.toString()}`}
        $active={activeSlug === slug}
        data-cy={`tab-${slug}`}
      >
        {label}
      </StyledTab>
    </>
  )
}

export default Tab
