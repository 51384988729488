import React from 'react'
import Table from '../../../Table'
import {
  getContractBuyer,
  getContractItemsByVolumeType,
  getContractYearlyVolumeMwh,
  getElectricityItemsAndFirmingItems,
  getWeightAverageForContractItems,
} from '../../../../helpers/contract'
import {getPeriod} from '../../../../helpers/date'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
import {getCurrencySymbolValueSlashEnergyUnit} from '../../../../helpers/price'
import {ProductTypeBehaviour} from '../../../../domain/IProductType'
import IPurchaseContract from 'domain/IPurchaseContract'
import {formatEnergy} from '../../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  contracts: IPurchaseContract[]
  loading?: boolean
}

const SalesContractList: React.FC<IProps> = ({contracts, loading}) => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <Table<IPurchaseContract>
      search
      data={contracts}
      isLoading={loading}
      linkTo={contract => `/broker/portfolio/sales-contracts/${contract?.offer?.id}`}
      columns={[
        {
          title: translate('Contract id'),
          accessor: contract => `#${contract.id}`,
        },
        {
          title: translate('Name'),
          accessor: contract => contract.name,
        },
        {
          title: translate('To'),
          accessor: contract => getContractBuyer(contract)?.name,
        },
        {
          title: translate('Volume'),
          accessor: contract => formatEnergy(getContractYearlyVolumeMwh(contract)),
          render: value => `${value} ${translate('MWh/y')}`,
        },
        {
          title: translate('Start and end date'),
          accessor: contract => getPeriod(contract.validFrom, contract.validTo),
        },
        // {
        //   title: translate('Electricity price'),
        //   accessor: contract => {
        //     return getCurrencySymbolValueSlashEnergyUnit(
        //       getWeightAverageForContractItems(getElectricityItemsAndFirmingItems(contract.contractItems)),
        //       theme,
        //     )
        //   },
        // },
        {
          title: translate('EAC price'),
          accessor: contract => {
            return getCurrencySymbolValueSlashEnergyUnit(
              getWeightAverageForContractItems(
                getContractItemsByVolumeType(contract.contractItems, ProductTypeBehaviour.LGC),
              ),
              theme,
            )
          },
        },
        {
          title: translate('Total price'),
          accessor: contract => {
            return getCurrencySymbolValueSlashEnergyUnit(contract.totalPrice, theme)
          },
        },
      ]}
    />
  )
}

export default SalesContractList
