import ITheme from '../ITheme'
import baseTheme from '../base'
import logo from './enery.svg'

// Theme to test out colors for diff party
const testTheme: ITheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    globalBackground: '#F5F0EB',
    secondary: '#232D32',
    primary: '#8CC896',
    primaryDark: '#A0D2A5',
    primaryLight: '#a3d3ab',
  },
  logo: {
    ...baseTheme.logo,
    height: 4,
    logo: logo,
  },
  poweredBy: true,
}

export default testTheme
