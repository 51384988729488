import ILocation from './ILocation'
import {OnboardingStatus} from './IParty'
import {SiteShape, SiteState} from './ISite'

export interface ICreateBuyerSite {
  name: string
  description: string
  shape: SiteShape
  state: SiteState
  placeId: string
}

export interface IBuyerUploadConsumption {
  file: File
}

export interface IBuyerOnboardSite {
  id: number
  name: string
  description: string
  shape: SiteShape
  state: SiteState
  location: ILocation
}

export interface UploadedConsumption {
  //TODO: add more fields here
  volumeMwh: number
}

export type IBuyerOnboardState =
  | {onboardingStatus: OnboardingStatus.NOT_STARTED}
  | {onboardingStatus: OnboardingStatus.BUYER_STARTED}
  | {onboardingStatus: OnboardingStatus.BUYER_SITE_CREATED; site: IBuyerOnboardSite}
  | {
      onboardingStatus: OnboardingStatus.BUYER_PROFILE_UPLOADED
      site: IBuyerOnboardSite
      uploadedProfile: UploadedConsumption
    }
  | {onboardingStatus: OnboardingStatus.COMPLETED; site: IBuyerOnboardSite; uploadedProfile: UploadedConsumption}

export const getOnboardSite = (state: IBuyerOnboardState): IBuyerOnboardSite | null => {
  switch (state.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
      return null
    case OnboardingStatus.BUYER_STARTED:
      return null
    case OnboardingStatus.BUYER_SITE_CREATED:
      return state.site
    case OnboardingStatus.BUYER_PROFILE_UPLOADED:
      return state.site
    case OnboardingStatus.COMPLETED:
      return state.site
  }
}

export const getUploadedConsumption = (state: IBuyerOnboardState): UploadedConsumption | null => {
  switch (state.onboardingStatus) {
    case OnboardingStatus.NOT_STARTED:
      return null
    case OnboardingStatus.BUYER_STARTED:
      return null
    case OnboardingStatus.BUYER_SITE_CREATED:
      return null
    case OnboardingStatus.BUYER_PROFILE_UPLOADED:
      return state.uploadedProfile
    case OnboardingStatus.COMPLETED:
      return state.uploadedProfile
  }
}
