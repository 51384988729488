import React from 'react'
import styled from 'styled-components'
import Text from '../Text'
import Box from '../Box'
import ITheme from '../../theme/ITheme'
import ModalLink from '../Modal/ModalLink'

export const FooterLink = styled(ModalLink)`
  display: block;
  margin-top: 4px;
  color: ${props => (props.theme as ITheme).colors.secondary};

  &:hover {
    text-decoration: underline;
  }
`

const Footer = ({children}) => {
  return (
    <Box data-c="Footer" margin={{left: 2.5, right: 4, vertical: 4}}>
      <Text uppercase size="small">
        {children}
      </Text>
    </Box>
  )
}

export default Footer
