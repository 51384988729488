import React from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'
import ITheme from '../theme/ITheme'

interface IStyleProps {
  decoration?: 'underline' | 'none'
  noColor?: boolean
  color?: string
}

interface IProps extends React.PropsWithChildren, IStyleProps {
  to?: string
  openInNewWindow?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  download?: boolean
}

const sharedStyle = css<IStyleProps>`
  cursor: pointer;
  text-decoration: ${props => props.decoration};
  color: ${props => (props.noColor ? 'unset' : props.color || (props.theme as ITheme).colors.primaryDark)};

  &:hover {
    text-decoration: underline;
  }
`

const StyledSpan = styled.span<IStyleProps>`
  ${sharedStyle}
`

const StyledLink = styled(Link)<IStyleProps>`
  ${sharedStyle}
`

const StyledAnchor = styled.a<IStyleProps>`
  ${sharedStyle}
`

const TextLink: React.FC<IProps> = ({children, to, openInNewWindow, download, ...props}) => {
  if (!to) {
    return <StyledSpan {...props}>{children}</StyledSpan>
  }

  if (download || openInNewWindow) {
    return (
      <StyledAnchor {...props} href={to} download={download} target="_blank">
        {children}
      </StyledAnchor>
    )
  }

  return (
    <StyledLink {...props} to={to}>
      {children}
    </StyledLink>
  )
}

export default TextLink
