import IManagedPartyMember from 'domain/IManagedPartyMember'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import IPartyMember, {RepresentationBasis} from '../domain/IPartyMember'
import ITranslate from '../interfaces/ITranslate'

export function isPartyAdmin(partyMember: IPartyMember | IManagedPartyMember): boolean {
  return partyMember?.partyMemberRoles?.includes(PartyMemberRole.MANAGER)
}

export function addDefaultMemberRoles(roles: PartyMemberRole[]): PartyMemberRole[] {
  const result = [...roles]

  if (!result.includes(PartyMemberRole.EMPLOYEE)) {
    result.push(PartyMemberRole.EMPLOYEE)
  }

  return result
}

export function stringifyRepresentationBasis(representationBasis: RepresentationBasis, translate: ITranslate): string {
  switch (representationBasis) {
    case RepresentationBasis.MEMBER_OF_BOARD:
      return translate('Member of board')

    case RepresentationBasis.POWER_OF_ATTORNEY:
      return translate('Power of attorney')

    case RepresentationBasis.CI_CUSTOMER_MANAGER:
      return translate('C&I Customer Manager')
  }

  return null
}

export function stringifyMemberRole(memberRole: PartyMemberRole, translate: ITranslate): string {
  switch (memberRole) {
    case PartyMemberRole.MANAGER:
      return translate('Manager')

    case PartyMemberRole.EMPLOYEE:
      return translate('Employee')
  }

  return null
}
