import React from 'react'
import styled from 'styled-components'
import Pagination from '@mui/material/Pagination'
import Select from 'components/Select'
import {getChangeEventValue} from 'helpers/misc'

interface Props {
  activePage: number
  resultsPerPage: number
  resultsPerPageOptions: number[]
  totalPages: number
  handlePageChange: (selected) => void
  handleResultsPerPageChange: (value) => void
}

const StyledSelectLabel = styled.p``

const StyledPagination = styled(Pagination)`
  & ul li::before {
    content: none;
  }
`

const PaginateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

export const PaginateWithSelection: React.FC<Props> = ({
  activePage,
  resultsPerPage,
  resultsPerPageOptions,
  totalPages,
  handlePageChange,
  handleResultsPerPageChange,
}) => {
  return (
    <PaginateContainer>
      <StyledSelectLabel>Results per page:</StyledSelectLabel>
      <Select
        value={resultsPerPage}
        options={resultsPerPageOptions.map(size => ({label: size.toString(), value: size}))}
        margin={{left: 1, right: 2}}
        onChange={e => {
          handleResultsPerPageChange(getChangeEventValue(e).toString())
        }}
      />
      <StyledPagination
        boundaryCount={2}
        count={totalPages}
        defaultPage={1}
        page={activePage}
        style={{listStyle: 'none'}}
        shape="rounded"
        onChange={(e, page) => handlePageChange(page)}
      />
    </PaginateContainer>
  )
}
