import QuickSignInForm from 'components/Platform/Onboarding/QuickSignInForm'
import IUser from 'domain/IUser'
import useServices from 'hooks/useServices'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Grid from '../../components/Grid'
import InnerScroll from '../../components/InnerScroll'
import Page from '../../components/Platform/Onboarding/Page'
import useLocalization from '../../hooks/useLocalization'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Loader from '../../components/Loader'
import useProfile from 'hooks/useProfile'
import {StyledReloadingButton} from 'components/Platform/Onboarding/Layout/FormComponents'

interface ParamTypes {
  accessCode: string
  inviteId: string
}

const QuickSignIn = () => {
  const {translate} = useLocalization()
  const {contentService} = useServices()
  const {user: loggedInUser, isLoaded: isLoggedInUserLoaded} = useProfile()
  const {accessCode} = useParams<ParamTypes>()
  const [user, setUser] = useState<IUser>({} as IUser)
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    contentService
      .getUserFromInvite(accessCode)
      .then(user => {
        setUser(user)
      })
      .catch(err => {
        setError(translate('Invite link does not exist, already used or has expired. Please contact an administrator.'))
      })
      .finally(() => {
        setIsLoading(false)
      })

    // eslint-disable-next-line
  }, [accessCode])

  if (!isLoggedInUserLoaded) {
    return (
      <Page>
        <Loader />
      </Page>
    )
  }

  if (loggedInUser) {
    return (
      <Page>
        <Box>
          <Text bold size="mlarge">
            You are already logged in as {loggedInUser?.email}.
          </Text>
          <Box justify="flex-end" margin={{top: 2}}>
            <StyledReloadingButton>{translate('Log out & continue with the invite')}</StyledReloadingButton>
          </Box>
        </Box>
      </Page>
    )
  }

  return (
    <Page hideNavigation={!error}>
      <InnerScroll>
        {(() => {
          if (isLoading) {
            return <Loader />
          }

          if (error.length > 0) {
            return <Text size={'xlarge'}>{error}</Text>
          }

          return (
            <Grid>
              <div>
                <div>
                  <Text size={'xlarge'} bold>
                    {translate('Hi, %s!', user.firstName)}
                  </Text>
                </div>
                <Box margin={{bottom: 4}}>
                  <Text size="large">
                    {translate(
                      'Thank you for accepting the invite. Your account is prepared and you only need to create a password so you can log in.',
                    )}
                  </Text>
                </Box>
                <QuickSignInForm accessCode={accessCode} user={user} />
              </div>
            </Grid>
          )
        })()}
      </InnerScroll>
    </Page>
  )
}

export default QuickSignIn
