import React from 'react'
import Text from '../../Text'
import SiteShapeMarker from '../../Map/SiteShapeMarker'
import TechnologyMarker, {IProps as IMarkerProps} from '../../Map/TechnologyMarker'
import ConnectedHoverable from '../../ConnectedHoverable'
import {getHoverKey} from '../../../helpers/site'
import ISite, {SiteType} from '../../../domain/ISite'

interface IProps extends React.PropsWithChildren, Omit<IMarkerProps, 'technology'> {
  site: ISite
  urlPrefix?: string
  suffix?: string
}

const SiteMapMarker: React.FC<IProps> = ({site, urlPrefix, suffix, ...props}) => {
  const linkTo = urlPrefix ? `${urlPrefix}/${site.id}${suffix ? suffix : ''}` : null
  const text = <Text size="small">{site.name}</Text>

  return (
    <ConnectedHoverable hoverKey={getHoverKey(site)}>
      {site.siteType === SiteType.CONSUMPTION ? (
        <SiteShapeMarker {...props} linkTo={linkTo} shape={site.siteShape}>
          {site.name && text}
        </SiteShapeMarker>
      ) : (
        <TechnologyMarker {...props} linkTo={linkTo} technology={site.productionTechnology}>
          {site.name && text}
        </TechnologyMarker>
      )}
    </ConnectedHoverable>
  )
}

export default SiteMapMarker
