import React, {useEffect} from 'react'
import useLocalization from 'hooks/useLocalization'
import {useAtomValue, useSetAtom} from 'jotai'
import Form from 'components/Form'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate} from 'helpers/date'
import Page from 'components/Page'
import Button from 'components/Button'
import useStore from 'hooks/useStore'
import {useHistory} from 'react-router'
import {tenderRespondAtom, tenderRespondIdAtom, tenderRespondsAtom} from 'atoms/tenderResponds'
import TenderRespondForm from './TenderRespondForm'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Box from 'components/Box'

interface IProps extends React.PropsWithChildren {
  id: number
}

const TenderRespondPage: React.FC<IProps> = ({id}) => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {alertStore} = useStore()
  const rootService = useAtomValue(rootServiceAtom)
  const setTenderRespondId = useSetAtom(tenderRespondIdAtom)
  const refetchTenderResponds = useSetAtom(tenderRespondsAtom)
  const party = useAtomValue(partyAtom)
  const {data: respond, loading} = useAtomValue(tenderRespondAtom)

  useEffect(() => {
    if (id) {
      setTenderRespondId(id)
    }

    return () => {
      setTenderRespondId(null)
    }
  }, [id])

  const handleSubmit = async data => {
    const {capacityMwac, operationsStart, ...rest} = data
    try {
      await rootService.v2ContentService.updateTenderRespond(party.id, id, {
        ...respond,
        ...rest,
        capacityMwac: +capacityMwac,
        operationsStart: formatDate(operationsStart, DateFormat.YEAR_MONTH_DAY),
      })
      alertStore.addSuccess(translate('Tender response updated'))
      refetchTenderResponds()
      history.push('/broker/sell/tender-responses')
    } catch (error) {
      alertStore.addError(translate('Failed to update tender response'), error?.correlationId, error?.message)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      fillHeight
      submissionFeedback={null}
      defaultValues={{...respond, operationsStart: formatDate(respond?.operationsStart, DateFormat.YEAR_MONTH_DAY)}}
    >
      <Page
        title={translate('Tender response')}
        description={translate('Edit your tender response information')}
        corner={<FormSubmitButton data-cy="save-button">{translate('Save')}</FormSubmitButton>}
        isLoading={loading}
      >
        <TenderRespondForm edit />
      </Page>
    </Form>
  )
}

export default TenderRespondPage
