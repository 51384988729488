import LocalStorageService from './LocalStorageService'
import {WithMeta} from '../types'
import {hasMeta} from '../helpers/misc'

export default class LocalStorageDataService<T extends {id?: any}> {
  private static sequenceStorage: LocalStorageService<number> = new LocalStorageService<number>('__sequence')
  private readonly localStorageService: LocalStorageService<T[]>
  protected readonly key: string

  constructor(key: string) {
    this.localStorageService = new LocalStorageService(key)
    this.key = key
  }

  public setItems(value: WithMeta<T, any>[]): void {
    this.localStorageService.setItem(value)
  }

  public setItem(value: T): void {
    this.localStorageService.setItem(value)
  }

  public saveItem(item: WithMeta<T, any>): T {
    if (item.id) {
      return this.updateItem(item)
    }

    return this.addItem(item)
  }

  public addItem(item: Omit<WithMeta<T, any>, 'id'>): T {
    const items = this.getItems()

    const newItem = {
      ...item,
      id: this.getNextId(),
    } as T
    this.setItems([...items, newItem])

    return newItem
  }

  public updateItem(item: WithMeta<T, any>): T {
    const items = this.getItems()

    const oldItem = items.find(i => i.id.toString() === item.id.toString()) || item

    Object.assign(oldItem, item)

    this.setItems(items)

    return oldItem
  }

  public getItems(): T[] {
    return this.localStorageService.getItem() || []
  }

  public getItemsWithMeta<K extends Record<string, any>>(predicate: (meta: K) => boolean): T[] {
    return this.getItems().filter((i: any) => hasMeta(i, predicate))
  }

  public getItem(id: any): T {
    const item = this.getItems().find(i => i.id.toString() === id.toString())

    if (!item) {
      throw new Error(`${this.key}: Item ${id} does not exist`)
    }

    return item
  }

  public removeItem(id: any): void {
    const items = this.getItems().filter(item => item.id.toString() !== id.toString())

    this.setItems(items)
  }

  public clear(): void {
    this.localStorageService.removeItem()
  }

  public getNextId(): number {
    const sequence = (LocalStorageDataService.sequenceStorage.getItem() || 10000) + 1
    LocalStorageDataService.sequenceStorage.setItem(sequence)

    return sequence
  }
}
