import React from 'react'
import {useHistory, useLocation} from 'react-router'
import Modal, {IProps as IModalProps} from './index'
import {ModalType} from './IModal'

export const RouterModalContext = React.createContext<{
  name: ModalType | string
  value: string | number
  handleClose: () => void
}>(null)

interface IProps extends React.PropsWithChildren, Omit<IModalProps, 'onClose'> {
  name: ModalType | string
}

const RouterModal: React.FC<IProps> = ({name, ...props}) => {
  const location = useLocation()
  const history = useHistory()
  const value = new URLSearchParams(location.search).get(name)
  const isOpen = value !== null

  if (!isOpen) {
    return null
  }

  const handleClose = () => {
    const urlSearchParams = new URLSearchParams(location.search)
    urlSearchParams.delete(name)

    history.push(`?${urlSearchParams.toString()}`)
  }

  return (
    <RouterModalContext.Provider value={{name, value, handleClose}}>
      <Modal {...props} onClose={handleClose} />
    </RouterModalContext.Provider>
  )
}

export default RouterModal
