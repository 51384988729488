import {PrimitiveAtom, atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const externalContractIdAtom = atom<string>(null) as PrimitiveAtom<string>

export const externalContractsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    const data = await services.v2ContentService.getExternalContracts(party?.id, page, pageSize)

    return data
  }),
)

export const externalContractAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const contractId = get(externalContractIdAtom)

    if (!contractId) {
      return null
    }

    const data = await services.v2ContentService.getExternalContract(party?.id, contractId)

    return data
  }),
  {nullIsLoading: true},
)
