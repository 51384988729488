import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {Card, Tooltip} from '@mui/material'
import {partyAtom} from 'atoms/party'
import {pageAtom, pageSizeAtom, subscriptionsAtom} from 'atoms/subscription'
import Box from 'components/Box'
import Button from 'components/Button'
import Grid from 'components/Grid'
import {PaginateWithSelection} from 'components/Paginate/Paginate'
import {ISubscriptionPlan} from 'domain/IParty'
import {SubscriptionStatus} from 'domain/ISubscription'
import useLocalization from 'hooks/useLocalization'
import {useAtom, useAtomValue} from 'jotai'
import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import InnerScroll from '../../../../components/InnerScroll'
import Page from '../../../../components/Page'
import SubscriptionList from './SubscriptionList'

const RESULT_PER_PAGE_OPTIONS = [5, 10, 20]

const SkeletonItem = styled.div<{width?: string; height?: string}>`
  background: #e0e0e0;
  height: ${props => props.height || '20px'};
  width: ${props => props.width || '100%'};
  margin-bottom: 8px;
  border-radius: 4px;
`

const SubscriptionPage: React.FC = () => {
  const {translate} = useLocalization()
  const history = useHistory()
  const {data: subscriptions, loading} = useAtomValue(subscriptionsAtom)
  const party = useAtomValue(partyAtom)
  const [page, setPage] = useAtom(pageAtom)
  const [pageSize, setPageSize] = useAtom(pageSizeAtom)
  const isBasic = party?.subscriptionPlan === ISubscriptionPlan.BASIC
  const hasActiveSubscriptions = subscriptions?.data?.some(s => s.status === SubscriptionStatus.Active) ?? false
  const canCreateSubscription = isBasic || (!isBasic && subscriptions?.data?.length === 0)
  const showButton = isBasic || !hasActiveSubscriptions

  return (
    <Page
      title={translate('Subscriptions')}
      description={translate('View your active and past subscriptions')}
      corner={
        showButton && (
          <Box direction="row" align="center" margin={{right: 4}}>
            <Button
              data-cy="create-subscription-button"
              onClick={() => {
                history.push('/consumer/company/subscription/add')
              }}
              small
              disabled={!canCreateSubscription}
            >
              {translate('Create subscription')}
            </Button>
            {!canCreateSubscription && (
              <Tooltip title={translate('You already have an active subscription')}>
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            )}
          </Box>
        )
      }
    >
      <InnerScroll padded>
        {loading ? (
          <Grid columns={1} gap={1} data-cy="contract-list-items">
            {[...Array(3)].map((_, index) => (
              <Card key={index} style={{padding: '16px', marginBottom: '16px', height: '222px'}}>
                <Grid columns={3} gap={2}>
                  <div>
                    <SkeletonItem width="80%" height="32px" style={{marginBottom: '32px'}} />
                    <SkeletonItem width="60%" height="24px" style={{marginTop: '8px'}} />
                  </div>
                  <div>
                    <SkeletonItem width="50%" height="24px" style={{marginTop: '64px'}} />
                  </div>
                  <div>
                    <SkeletonItem width="40%" height="24px" style={{marginTop: '64px'}} />
                  </div>
                </Grid>
                <Box margin={{top: 2}} justify="flex-end" width="100%">
                  <SkeletonItem width="120px" height="36px" style={{marginTop: '32px'}} />
                </Box>
              </Card>
            ))}
          </Grid>
        ) : (
          <SubscriptionList subscriptions={subscriptions?.data} />
        )}
      </InnerScroll>
      <Box align="flex-end" justify="center">
        <PaginateWithSelection
          activePage={page}
          resultsPerPage={pageSize}
          resultsPerPageOptions={RESULT_PER_PAGE_OPTIONS}
          totalPages={subscriptions?.totalPages || 1}
          handlePageChange={value => setPage(value)}
          handleResultsPerPageChange={value => {
            setPageSize(value)
            setPage(1)
          }}
        />
      </Box>
    </Page>
  )
}

export default SubscriptionPage
