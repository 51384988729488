import React from 'react'
import styled from 'styled-components'
import ITheme from '../../theme/ITheme'

const Button = styled.button<{size?: number; noHover?: boolean}>`
  padding: ${props => (props.theme as ITheme).tileSize * 0.5}px;
  width: ${props => (props.theme as ITheme).tileSize * props.size}px;
  height: ${props => (props.theme as ITheme).tileSize * props.size}px;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;

  ${props =>
    props.noHover &&
    `
    &:hover {
      background-color: ${props => (props.theme as ITheme).colors.light3};
    }
  `}

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

Button.defaultProps = {
  size: 3,
}

export type IProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  src: string
  alt?: string
  size?: number
  noHover?: boolean
}

const IconButton: React.FC<IProps> = ({src, alt, ...props}) => (
  <Button {...props}>
    <img src={src} alt={alt || 'x'} />
  </Button>
)

IconButton.defaultProps = {
  type: 'button',
}

export default IconButton
