import IContract from './IContract'
import IProduct from './IProduct'
import IPrice from './IPrice'

export default interface IContractItem {
  id: number
  volumeMwh: number
  contract?: IContract
  product: IProduct
  price: IPrice
  productPriceValue: number
}

export enum VolumeUnit {
  PERCENTAGE = '%',
  MWH_YEAR = 'MWh/year',
}
