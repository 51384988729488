import React from 'react'
import PortfolioKPISummary from './PortfolioKPISummary'
import Box from '../../../Box'
import SiteMap from '../../Map/SiteMap'
import useLocalization from 'hooks/useLocalization'
import {basicSitesAtom} from 'atoms/marketListings'
import {useAtomValue} from 'jotai'

interface IProps extends React.PropsWithChildren {}

const PortfolioOverviewAside: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {data: sites} = useAtomValue(basicSitesAtom)

  return (
    <Box direction="column" fillHeight>
      <PortfolioKPISummary />
      <Box grow>
        <SiteMap sites={sites || []} title={translate('Consumption points')} />
      </Box>
    </Box>
  )
}

export default PortfolioOverviewAside
