import React from 'react'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'

const Container = styled.div<{colored?: boolean}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${props =>
    props.colored ? (props.theme as ITheme).colors.common.black : (props.theme as ITheme).colors.common.white};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  border: ${props => (props.theme as ITheme).icon.borderSize}px solid white;

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

const Initials = styled.div`
  color: ${props => (props.theme as ITheme).colors.primary};
  font-size: 14px;
  font-weight: 700;
`

const getInitials = (name: string) => {
  let result = name
    .replace('-', ' ')
    .split(' ')
    .map(s => s.charAt(0))
    .join('')

  if (result.length < 2) {
    result = name
  }

  return result.substring(0, 2).toLocaleUpperCase()
}

const Avatar = ({image, name, colored}: {name: string; image?: string; colored?: boolean}) => {
  return (
    <Container colored={colored}>
      {image ? <img src={image} alt={name} /> : <Initials>{getInitials(name)}</Initials>}
    </Container>
  )
}

export default Avatar
