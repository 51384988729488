import React from 'react'
import Heading from '../../../Heading'
import KeyFeature from '../../KeyFeature'
import {getHumanReadableShape, getHumanReadableState, getCapacityUnitBasedOnTechnology} from '../../../../helpers/site'
import ISite, {SiteShape, SiteState, SiteType} from '../../../../domain/ISite'
import Grid from '../../../Grid'
import useLocalization from '../../../../hooks/useLocalization'
import {formatDecimal} from '../../../../helpers/format'

interface IProps extends React.PropsWithChildren {
  site?: ISite
}

const GeneralSiteData: React.FC<IProps> = ({site}) => {
  const {translate} = useLocalization()
  const isConsumptionSite = site?.siteType === SiteType.CONSUMPTION

  return (
    <Grid columns={1}>
      <Heading margin={{bottom: 2}}>{translate('General site data')}</Heading>
      <KeyFeature title={translate('NMI')} value={site?.siteNmi} autoHide />
      <KeyFeature
        title={translate('Profile')}
        value={getHumanReadableShape(site?.siteShape as SiteShape, translate)}
        autoHide
      />
      <KeyFeature
        title={translate('Status')}
        value={getHumanReadableState(site?.state as SiteState, translate)}
        autoHide
      />
      <KeyFeature
        title={translate('Description')}
        value={site?.description}
        valueParser={v =>
          v.split('\n').map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            )
          })
        }
        semiBoldValue={false}
        smallValue
        autoHide
      />
      {/* <KeyFeature
        title={translate(isConsumptionSite ? 'Main circuit breaker capacity' : 'Nameplate capacity')}
        value={isConsumptionSite ? site?.mainCircuitBreakerCapacityAmp : site?.productionNameplateCapacityMwac}
        valueParser={v => formatDecimal(v, 1)}
        unit={
          site?.siteType === SiteType.CONSUMPTION
            ? 'A'
            : getCapacityUnitBasedOnTechnology(site?.productionTechnology, 'MWac')
        }
        unitSize={'xlarge'}
        autoHide
      /> */}
      <KeyFeature
        title={translate(isConsumptionSite ? 'Est. annual consumption, MWh' : 'Est. annual production, MWh')}
        value={isConsumptionSite ? site?.avgYearlyConsumptionMwh : site?.avgYearlyProductionMwh}
        valueParser={v => formatDecimal(v)}
        unit={'MWh'}
        unitSize={'xlarge'}
        autoHide
      />
    </Grid>
  )
}

export default GeneralSiteData
