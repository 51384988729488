import {toManagedUser} from 'helpers/user'
import IManagedPartyMember from './IManagedPartyMember'
import IPartyMember from './IPartyMember'

export enum ManagedUserStatus {
  Active = 'Active',
  Invited = 'Invited',
  Suspended = 'Suspended',
}

export enum ManagedUserAction {
  ResendInvite = 'Resend Invite',
  Suspend = 'Suspend',
  Reactivate = 'Reactivate',
}

export interface IManagedUser {
  id?: string
  firstName: string
  lastName: string
  email: string
  status: ManagedUserStatus
}

export const partyMemberToManaged = (partyMember: IPartyMember): IManagedPartyMember => {
  const user = toManagedUser(partyMember.user)
  const managedPartyMember = {...partyMember, user} as IManagedPartyMember

  return managedPartyMember
}
