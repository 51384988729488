import React from 'react'
import useTheme from '../../../../hooks/useTheme'
import KeyFeature from '../../KeyFeature'
import Heading from '../../../Heading'
import {formatDecimal} from '../../../../helpers/format'
import Progressbar from '../../../Progressbar'
import Box from '../../../Box'
import {stringifyPriceWithSuffix} from '../../../../helpers/price'
import {Tooltip} from '@mui/material'

export interface IProps extends React.PropsWithChildren {
  title: string
  value: number
  color: string
  unit?: string
  max?: number
  icon?: any
  currency?: string
  suffix?: string
  tooltipText?: string
  showFilledProgress?: boolean
}

const ResourceSummaryItem: React.FC<IProps> = ({
  title,
  value,
  color,
  max,
  unit,
  icon,
  currency,
  suffix,
  tooltipText,
  showFilledProgress,
}) => {
  const theme = useTheme()

  return (
    <div>
      <KeyFeature
        title={title}
        icon={icon}
        value={
          <Tooltip title={tooltipText} arrow placement="top">
            <Heading size="xlarge" color={color} style={{whiteSpace: 'nowrap'}}>
              {currency && suffix ? stringifyPriceWithSuffix(value, suffix) : formatDecimal(value || 0, 0)}
            </Heading>
          </Tooltip>
        }
        unit={unit}
        currency={
          <Heading size="xlarge" color={color}>
            {currency}
          </Heading>
        }
      />
      {max > 0 && (
        <Box pad={{bottom: 1}}>
          <Progressbar size="xsmall" color={color} max={max} value={value} />
        </Box>
      )}

      {!max && showFilledProgress && (
        <Box pad={{bottom: 1}}>
          <Progressbar size="xsmall" color={color} max={100} value={100} />
        </Box>
      )}
    </div>
  )
}

export default ResourceSummaryItem
