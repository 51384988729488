import IParty, {OnboardingStatus, PartyRole, ISubscriptionPlan} from '../../domain/IParty'
import mockAttachments from './mockAttachments'
import mockLocations from './mockLocations'

export const mockBrokerParty: IParty = {
  id: 1,
  name: 'BROKER_PARTY_1',
  partyRole: PartyRole.BROKER,
  totalAvgYearlyConsumptionMwh: 3.281,
  description:
    'CleanCo is Queensland’s publicly owned clean energy generator, with a current trading portfolio of 1, 120 MW in the National Energy Market (NEM).\nCleanCo has a target to support 1, 000 MW of new renewable energy generation by 2025 and will achieve this by building, owning and operating renewable energy projects and by supporting investment in other new renewable energy projects.\nCleanCo’s foundation assets include the Wivenhoe pumped storage hydroelectric power station, Swanbank E gas-fired power station, and three hydroelectric power stations in Far North Queensland: Barron Gorge, Kareeya and Koombooloomba.',
  sites: null,
  offers: null,
  location: mockLocations[0],
  image: null,
  meta: {
    walletId: '123',
  },
  onboardingStatus: OnboardingStatus.COMPLETED,
  timeseriesId: 'YES',
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockProducerParty: IParty = {
  id: 2,
  partyRole: PartyRole.PRODUCER,
  name: 'PRODUCER_PARTY_2',
  totalAvgYearlyConsumptionMwh: null,
  description:
    'Whether in  ENERGY, MOBILITY OR COMMUNICATION: with our products and services, life becomes more sustainable, networked and more comfortable for everyone. 24 hours a day, 365 days a year.',
  location: null,
  image: null,
  timeseriesId: 'YES',
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockBuyerParty1: IParty = {
  id: 3,
  partyRole: PartyRole.BUYER,
  name: 'BUYER_PARTY_3',
  meteringPointCount: 20,
  description: 'BUYER_PARTY_3_DESCRIPTION',
  image: null,
  totalAvgYearlyConsumptionMwh: 1000,
  meta: {
    pricingParameterGroupId: 123,
  },
  timeseriesId: 'YES',
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

export const mockBuyerParty2: IParty = {
  id: 4,
  partyRole: PartyRole.BUYER,
  name: 'BUYER_PARTY_4',
  meteringPointCount: null,
  description: 'BUYER_PARTY_4_DESCRIPTION',
  image: null,
  totalAvgYearlyConsumptionMwh: 100.001,
  timeseriesId: 'YES',
  onboardingStatus: OnboardingStatus.COMPLETED,
  subscriptionPlan: ISubscriptionPlan.ENTERPRISE,
}

const mockParties: IParty[] = [mockBuyerParty1, mockBuyerParty2, mockBrokerParty, mockProducerParty]

export default mockParties
