import React from 'react'
import Form from '../../Form'
import Label, {LabelWithIcon} from '../../Label'
import useLocalization from '../../../hooks/useLocalization'
import Text from '../../Text'
import Box from '../../Box'
import useServices from '../../../hooks/useServices'
import IParty from '../../../domain/IParty'
import useStore from '../../../hooks/useStore'
import {RepresentationBasis} from '../../../domain/IPartyMember'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {StyledFormButton, StyledLocationSearch} from './Layout/FormComponents'
import {LocationBox} from '../Portfolio/Site/LocationSearch'
import FormInput from 'components/Form/FormInput'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {getAllowedCountries, getHumanReadableListOfCountries} from 'helpers/location'

// eslint-disable-next-line
interface IProps extends React.PropsWithChildren {}

const ContactInfoForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const {profileStore} = useStore()
  const {contentService} = useServices()

  const handleSubmit = async (party: IParty) => {
    await contentService.createPartyForCurrentUser({
      representationBasis: RepresentationBasis.CI_CUSTOMER_MANAGER,
      party,
      partyMemberRoles: [PartyMemberRole.MANAGER, PartyMemberRole.EMPLOYEE],
    })

    profileStore.load()
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null} fillHeight>
      <Box margin={{bottom: 3}}>
        <Label text={translate('Account type')}>
          <Text size="large" lineHeight="small">
            {translate('Corporate account')}
          </Text>
        </Label>
      </Box>

      <FormInput large label={translate('Company name')} name="name" required data-cy="name" />

      <FormInput large label={translate('Company reg. number')} name="registrationCode" data-cy="regNumber" required />

      <FormInput
        required
        validate={value => {
          if (!/^[a-z0-9]+$/i.test(value)) {
            return translate('Must contain only numbers and letters')
          }

          return true
        }}
        large
        label={translate('Company vat. number')}
        name="vatCode"
        data-cy="vatCode"
      />

      <LocationBox data-cy="location-select">
        <LabelWithIcon
          text={translate('Company address')}
          Icon={<InfoOutlinedIcon sx={{height: '16px', width: '16px'}} />}
          tooltip={translate(`We currently support following countries: ${getHumanReadableListOfCountries(translate)}`)}
        >
          <StyledLocationSearch location={null} variant="contrast" required allowedCountries={getAllowedCountries()} />
        </LabelWithIcon>
      </LocationBox>

      <Box justify="flex-end">
        <StyledFormButton variant="primary">{translate('Continue')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default ContactInfoForm
