import React, {useMemo} from 'react'
import {
  pink,
  purple,
  lightBlue,
  cyan,
  teal,
  lightGreen,
  lime,
  yellow,
  amber,
  blueGrey,
  red,
  deepPurple,
} from '@mui/material/colors'
import useTheme from './useTheme'

const muiVariations = ['A100', 'A200', 'A400'] // Add more variations if not enough colors
const muiColors = [pink, purple, lightBlue, cyan, teal, lightGreen, lime, yellow, amber, blueGrey, red, deepPurple]

// Currently total 57 different colors 21 base + 36 extended
const useNewChartColors = (startIndex?: number) => {
  const theme = useTheme()
  let currentIndex = startIndex || 0
  const totalPerColor = 7
  const baseChartColors = useMemo(
    () =>
      ['blue', 'green', 'yellow']
        .map(color => {
          return Array.from({length: totalPerColor}, (_, i) => `${color}${i}`)
        })
        .flat(),
    [],
  )
  let extendedIndex = startIndex >= baseChartColors.length ? startIndex - baseChartColors.length : 0
  const extendedChartColors = useMemo(
    () =>
      muiColors
        .map(color => {
          return muiVariations.map(variation => {
            return color[variation]
          })
        })
        .flat(),
    [],
  )

  return {
    getNext: (interval = 0) => {
      // In case we have still not enough colors, start again
      if (extendedIndex >= extendedChartColors.length) {
        extendedIndex = 0
      }

      return currentIndex < baseChartColors.length
        ? theme.colors[baseChartColors[currentIndex++ + interval]]
        : extendedChartColors[extendedIndex++ + interval]
    },
  }
}

export default useNewChartColors
