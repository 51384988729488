import React from 'react'
import useLocalization from '../hooks/useLocalization'
import Error from './Error'

const NotFound: React.FC<React.PropsWithChildren> = ({children}) => {
  const {translate} = useLocalization()

  return (
    <Error title={translate('Page not found')}>
      {children || translate("The page you are looking for doesn't exist or has been moved.")}
    </Error>
  )
}

export default NotFound
