import React from 'react'
import Box from 'components/Box'
import KeyFeature from 'components/Platform/KeyFeature'
import TechnologyIconAndName from 'components/Platform/TechnologyIconAndName'
import IProduct from 'domain/IProduct'
import useLocalization from 'hooks/useLocalization'
import {getPriceUnitSymbol} from 'helpers/price'
import ListItem, {Badge} from 'components/ListItem'
import {getHoverKey} from 'helpers/product'
import useServices from 'hooks/useServices'
import ConnectedHoverable from 'components/ConnectedHoverable'
import ProductCertProgress from './ProductCertProgress'
import useTheme from 'hooks/useTheme'
import {formatDecimal, formatPriceToAbbrevations} from 'helpers/format'
import {getProductCountry} from 'helpers/location'

interface IProductItem {
  product: IProduct
  to?: string
  disabled?: boolean
  onClick?: () => void
}

const ProductItem: React.FC<IProductItem> = ({product, to, disabled, onClick}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()
  const available = product.totalQuantity - (product.soldQuantity + product.reservedQuantity)
  const isSold = available < 50 // TODO: Get this from backend later on

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled || isSold) {
      e.preventDefault()
    }

    onClick && onClick()
  }

  return (
    <ConnectedHoverable hoverKey={getHoverKey(product)}>
      <ListItem
        key={product.id}
        minWidth="350px"
        to={to || ''}
        title={product.name}
        noHover={disabled || isSold}
        onClick={handleClick}
        defaultCoverColor={theme.colors.technology[product.site.productionTechnology]}
        country={getProductCountry(product, translate)}
        coverContent={
          <>
            <Badge>
              <TechnologyIconAndName technology={product.site.productionTechnology} />
            </Badge>
            {product.seller && <Badge>{product.seller.name}</Badge>}
            {isSold && <Badge variant="error">{translate('Sold out')}</Badge>}
          </>
        }
        coverImage={contentService.getAttachmentURL(product.image)}
      >
        <Box direction="row" justify="space-between" margin={{left: 2, right: 2}}>
          <KeyFeature unit="EACs" value={formatPriceToAbbrevations(product.totalQuantity)} />
          <KeyFeature
            currency={getPriceUnitSymbol(product?.price?.unit)}
            value={formatDecimal(product.price?.value, 2)}
            unit="per EAC"
          />
        </Box>
        <Box margin={{left: 2, right: 2}} style={{minHeight: 73}}>
          <ProductCertProgress
            total={isSold ? undefined : product.totalQuantity}
            totalSold={product.soldQuantity}
            reserved={product.reservedQuantity}
          />
        </Box>
      </ListItem>
    </ConnectedHoverable>
  )
}

export default ProductItem
