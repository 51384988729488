import LogoutButton from 'components/LogoutButton'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import React from 'react'
import styled from 'styled-components'
import Box from '../components/Box'
import Error from '../components/Error'
import Logo from '../components/Logo'
import Text from '../components/Text'

const StyledLogoutButton = styled(LogoutButton)`
  width: 200px;
`

interface IProps extends React.PropsWithChildren {
  showLogo?: boolean
  showUserMenu?: boolean
}

const NoAssociatedCompany: React.FC<IProps> = ({showLogo = true, showUserMenu = true}) => {
  const {translate} = useLocalization()
  const {user} = useProfile()

  return (
    <Box margin={{top: 10}} justify="center">
      <Box justify="center" direction="column" align="center" margin={{top: 4}}>
        {showLogo && <Logo to="/" />}

        <Box margin={{top: 10}} align="center" direction="column">
          <Error title={translate('No associated company')}>
            <Text size={'medium'} align="center">
              {translate('Your account is not associated with a company. Please contact an admin.')}
            </Text>

            {showUserMenu && user && (
              <Box margin={{top: 5}} justify="center" align="center" direction="column">
                <Text size={'large'}>{user.email}</Text>
                <Box width="150px;">
                  <StyledLogoutButton></StyledLogoutButton>
                </Box>
              </Box>
            )}
          </Error>
        </Box>
      </Box>
    </Box>
  )
}

export default NoAssociatedCompany
