import React from 'react'
import styled from 'styled-components'
import useLocalization from '../../../hooks/useLocalization'
import useTheme from '../../../hooks/useTheme'
import ITheme from '../../../theme/ITheme'
import Box from '../../Box'
import {ClearButton} from '../../Button'
import Heading, {ISize as IHeadingSize} from '../../Heading'
import Link from '../../Link'
import Thumbnail from '../../Thumbnail'

interface IItemProps extends React.PropsWithChildren {
  name: string
  image?: string
  linkTo?: string
  removable?: boolean
  onRemove?: () => void
  noImage?: boolean
  noBorder?: boolean
  headingSize?: IHeadingSize
  height?: number
  noPad?: boolean
  isExpired?: boolean
  style?: React.CSSProperties
  color?: string
}

const Container = styled(Box)`
  position: relative;
`

const RemoveButton = styled(ClearButton)<{expired: boolean}>`
  position: absolute;
  top: ${props => (props.theme as ITheme).tileSize}px;
  right: ${props => (props.theme as ITheme).tileSize}px;
  ${props =>
    props.expired &&
    `
    color: red;
  `}
`

export const Item: React.FC<IItemProps> = ({
  name,
  image,
  removable,
  onRemove,
  color,
  children,
  linkTo,
  noImage,
  noBorder,
  headingSize,
  height,
  noPad,
  isExpired,
  style,
}) => {
  const {colors} = useTheme()
  const {translate} = useLocalization()

  return (
    <Container
      data-c="Item"
      color={colors.common.white}
      round
      border={noBorder ? false : true}
      align="start"
      margin={{bottom: 2}}
      height={height}
      style={style}
    >
      {!noImage && <Thumbnail linkTo={linkTo} image={image} fallbackColor={color} />}
      <Box
        fillHeight
        pad={noPad ? null : {vertical: 1, horizontal: 2}}
        direction="column"
        justify="space-between"
        width={'100%'}
      >
        <Link to={linkTo}>
          <Heading size={headingSize} lineHeight="small" margin={{vertical: 1}}>
            {name}
          </Heading>
        </Link>
        <Box direction="row" width={'100%'}>
          {children}
        </Box>
      </Box>
      {removable && onRemove && (
        <RemoveButton pad={{horizontal: 1}} onClick={onRemove} expired={isExpired}>
          {isExpired && `${translate('Product is expired!')} `}
          {translate('Remove')}
        </RemoveButton>
      )}
    </Container>
  )
}

Item.defaultProps = {
  height: 16,
}
