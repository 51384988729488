import React from 'react'
import {Redirect} from 'react-router'
import SignInForm from '../../components/Platform/Onboarding/SignInForm'
import useProfile from '../../hooks/useProfile'
import Page from '../../components/Platform/Onboarding/Page'
import {getPlatformUrl} from '../../helpers/party'
import useTheme from '../../hooks/useTheme'

const SignIn = () => {
  const {user, isLoaded, party} = useProfile()
  const isLoggedIn = isLoaded && user
  const theme = useTheme()

  if (isLoggedIn && party) {
    return party.partyRole ? <Redirect to={getPlatformUrl(party?.partyRole, theme)} /> : <Redirect to="/register" />
  }

  if (isLoggedIn && !party) {
    return <Redirect to="/no-associated" />
  }

  return (
    <Page>
      <SignInForm />
    </Page>
  )
}

export default SignIn
