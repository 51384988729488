import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import Heading from '../../Heading'
import Text from '../../Text'
import useTheme from 'hooks/useTheme'

const OnboardingCompleted: React.FC<React.PropsWithChildren> = () => {
  const theme = useTheme()
  const {translate} = useLocalization()

  return (
    <>
      <div>
        <Heading size="medium" font={theme.font.headings}>
          {translate('Congratulations!')}
        </Heading>
        <Text margin={{vertical: 2}} size="large" lineHeight="msmall">
          {translate('Your account is now fully set up. You can always modify it on the account page.')}
        </Text>
        <Text size="large" lineHeight="msmall" pad={{top: 2}}>
          {translate(
            'Our team will verify the details you provided before granting you the access to the platform. We will get in touch with you shortly over the email.',
          )}
        </Text>

        <Text margin={{vertical: 3}} bold size="large" lineHeight="msmall">
          {translate('See you soon on the platform!')}
        </Text>
      </div>
    </>
  )
}

export default OnboardingCompleted
