import React, {useEffect, useState} from 'react'
import Box from 'components/Box'
import {InputWrap} from 'components/Input'
import Grid from 'components/Grid'
import Text from 'components/Text'
import useTheme from 'hooks/useTheme'
import {IconButton, Tooltip} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import PasteIcon from '@mui/icons-material/ContentPasteGoOutlined'
import styled from 'styled-components'
import Label from 'components/Label'
import {CertError, getCertTechKind, parseCertId, parseEndDate, parseStartDate} from 'helpers/certificates'
import useLocalization from 'hooks/useLocalization'
import {ISpacingProps} from './styleProps/spacing'

const DEFAULT_STATE = {accNum: '', certId: '', startDate: '', endDate: '', suffix: ''}

const StyledInput = styled.input`
  text-transform: uppercase;
  text-align: center;
`

const StyledInputWrap = styled(InputWrap)<{contrast?: boolean}>`
  background-color: ${props => (props.contrast ? props.theme.colors.contrastBackground : props.theme.colors.white)};
  border-color: ${props => props.contrast && props.theme.colors.secondary};

  input:disabled {
    color: ${props => props.theme.colors.secondary};
  }
`

const CertInputLabel = ({children, hasError}) => {
  const theme = useTheme()

  return (
    <Text uppercase color={hasError ? theme.colors.error : theme.colors.clay} semibold size="small" lineHeight="small">
      {children}
    </Text>
  )
}

const CertItem = ({name, value, ...props}) => {
  const theme = useTheme()

  return (
    <Box direction="column" align="center">
      <Text nowrap size="small" font={theme.font.secondaryFont} semibold color={theme.colors.clay} uppercase>
        {name}
      </Text>
      <Text size="small" font={theme.font.numbers} color={theme.colors.secondary} {...props}>
        {value ? value : '-'}
      </Text>
    </Box>
  )
}

interface IProps extends React.PropsWithChildren, ISpacingProps {
  onChange?: (value: string) => void
  label?: string
  error?: string
  isSubmitted?: boolean
  errorType?: CertError
  contrast?: boolean
  disabled?: boolean
  staticValue?: string
}

const CertInput: React.FC<IProps> = ({
  onChange,
  label,
  staticValue,
  disabled,
  error,
  margin,
  isSubmitted,
  errorType,
  contrast,
}) => {
  const theme = useTheme()
  const [dirty, setDirty] = useState(false)
  const {translate} = useLocalization()
  const [value, setValue] = useState(DEFAULT_STATE)
  const isEmpty = Object.values(value).every(val => val === '')
  const invalidLength = (value, length) => isSubmitted && value.length !== length
  const fullValue = Object.values(value).join('').toUpperCase()
  const showInfo = isSubmitted || dirty

  const handlePaste = async e => {
    const clipboardValue = await navigator.clipboard.readText()
    const value = e.clipboardData?.getData('text/plain') || clipboardValue
    setValue({
      accNum: value.slice(0, 10),
      certId: value.slice(10, 20),
      startDate: value.slice(20, 26),
      endDate: value.slice(26, 32),
      suffix: value.slice(32, 35),
    })
    e.preventDefault()
  }

  useEffect(() => {
    onChange && onChange(Object.values(value).join('').toUpperCase())
  }, [value])

  useEffect(() => {
    if (staticValue && staticValue.length === 35) {
      setValue({
        accNum: staticValue.slice(0, 10),
        certId: staticValue.slice(10, 20),
        startDate: staticValue.slice(20, 26),
        endDate: staticValue.slice(26, 32),
        suffix: staticValue.slice(32, 35),
      })
    }

    if (staticValue === '') {
      setValue(DEFAULT_STATE)
    }
  }, [staticValue])

  useEffect(() => {
    if (fullValue.length === 35) {
      setDirty(true)
    }
  }, [fullValue])

  return (
    <Box direction="column" margin={margin ? margin : {vertical: 2}}>
      <Label text={label}>
        <div onPaste={handlePaste}>
          <StyledInputWrap height={8} error={error} contrast={contrast}>
            <Grid columns={5} alignItems="center" style={{width: '100%', justifyItems: 'center'}}>
              <Box direction="column" align="center">
                <StyledInput
                  type="text"
                  value={value.accNum}
                  onChange={e => setValue({...value, accNum: e.target.value})}
                  placeholder="G02635NWSC"
                  disabled={disabled}
                  maxLength={10}
                  style={{maxWidth: 130}}
                />
                <CertInputLabel hasError={errorType === CertError.INVALID_ACC_NUM || invalidLength(value.accNum, 10)}>
                  {translate('acc num')}
                </CertInputLabel>
              </Box>
              <Box direction="column" align="center">
                <StyledInput
                  type="text"
                  value={value.certId}
                  disabled={disabled}
                  placeholder="0000000001"
                  onChange={e => setValue({...value, certId: e.target.value})}
                  maxLength={10}
                  style={{maxWidth: 130}}
                />
                <CertInputLabel hasError={errorType === CertError.INVALID_CERT_ID || invalidLength(value.certId, 10)}>
                  {translate('cert id')}
                </CertInputLabel>
              </Box>
              <Box direction="column" align="center">
                <StyledInput
                  type="text"
                  value={value.startDate}
                  disabled={disabled}
                  placeholder="010524"
                  onChange={e => setValue({...value, startDate: e.target.value})}
                  maxLength={6}
                  size={10}
                />
                <CertInputLabel
                  hasError={
                    [CertError.INVALID_START_DATE, CertError.INVALID_END_DATE_BEFORE_START_DATE].includes(errorType) ||
                    invalidLength(value.startDate, 6)
                  }
                >
                  {translate('start date')}
                </CertInputLabel>
              </Box>
              <Box direction="column" align="center">
                <StyledInput
                  type="text"
                  value={value.endDate}
                  disabled={disabled}
                  placeholder="010624"
                  onChange={e => setValue({...value, endDate: e.target.value})}
                  maxLength={6}
                  size={8}
                />
                <CertInputLabel
                  hasError={
                    [CertError.INVALID_END_DATE, CertError.INVALID_END_DATE_BEFORE_START_DATE].includes(errorType) ||
                    invalidLength(value.endDate, 6)
                  }
                >
                  {translate('end date')}
                </CertInputLabel>
              </Box>
              <Box direction="column" align="center">
                <StyledInput
                  type="text"
                  value={value.suffix}
                  disabled={disabled}
                  placeholder="GEN"
                  onChange={e => setValue({...value, suffix: e.target.value})}
                  maxLength={3}
                  size={4}
                />
                <CertInputLabel hasError={invalidLength(value.suffix, 3)}>{translate('suffix')}</CertInputLabel>
              </Box>
            </Grid>
            {!disabled && (
              <Tooltip title={isEmpty ? 'Paste' : 'Clear'}>
                <IconButton disabled={disabled} onClick={e => (isEmpty ? handlePaste(e) : setValue(DEFAULT_STATE))}>
                  {isEmpty ? <PasteIcon /> : <ClearIcon />}
                </IconButton>
              </Tooltip>
            )}
          </StyledInputWrap>
        </div>
      </Label>
      {showInfo && (
        <Box
          pad={2}
          round
          border
          borderColor={contrast ? theme.colors.secondary : theme.colors.lightGrey}
          color={contrast ? theme.colors.toned : theme.colors.outline}
        >
          <Grid columns={6}>
            <CertItem name={translate('Accredation number')} value={value.accNum.toUpperCase()} />
            <CertItem name={translate('Technology')} value={getCertTechKind(value.accNum.toUpperCase())} />
            <CertItem data-cy="cert-id" name={translate('Certificate id')} value={parseCertId(value.certId)} />
            <CertItem name={translate('Start date')} value={parseStartDate(value.startDate)} />
            <CertItem name={translate('End date')} value={parseEndDate(value.endDate)} />
            <CertItem name={translate('Suffix')} value={value.suffix.toUpperCase()} />
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default CertInput
