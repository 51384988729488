import styled from 'styled-components'
import ITheme from '../../theme/ITheme'
import React, {ReactElement} from 'react'
import Box from '../Box'
import Heading from '../Heading'
import Text from '../Text'
import Map, {IProps as IMapProps} from './index'

const Container = styled.div`
  position: relative;
  height: 100%;
`

const Header = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 192px;
  background-image: linear-gradient(180deg, rgba(20, 28, 46, 0.9), rgba(35, 48, 78, 0));
  color: ${props => (props.theme as ITheme).colors.common.white};
  z-index: 2;
  pointer-events: none;
`

export interface IProps extends React.PropsWithChildren, IMapProps {
  title?: string
  description?: string
  headerChildren?: ReactElement
}

export const MapWithHeader: React.FC<IProps> = ({title, description, children, headerChildren, ...mapProps}) => {
  const [loaded, setLoaded] = React.useState(false)

  return (
    <Container data-cy={`google-map-${loaded ? 'loaded' : 'loading'}`}>
      {title && (
        <Header>
          <Box margin={{horizontal: 3, top: 3}}>
            <Heading size={'large'}>{title}</Heading>
            {description && (
              <Text size="small" uppercase>
                {description}
              </Text>
            )}
          </Box>
          {headerChildren}
        </Header>
      )}
      <Map setLoaded={setLoaded} {...mapProps}>
        {children}
      </Map>
    </Container>
  )
}

export default MapWithHeader
