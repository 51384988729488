import React from 'react'
import NotFound from '../components/NotFound'
import Box from '../components/Box'
import Logo from '../components/Logo'
import LogoutButton from 'components/LogoutButton'
import useProfile from 'hooks/useProfile'
import PartySelect from 'components/Platform/Layout/PartySelect'

const PageNotFound: React.FC<React.PropsWithChildren> = ({children}) => {
  const {user} = useProfile()

  return (
    <Box margin={4} align="center" direction="column">
      <Logo to="/" />
      <Box margin={{top: 4}}>
        <NotFound>{children}</NotFound>
      </Box>
      {user && (
        <Box width="150px;" align="center" justify="center" direction="column" gap={2}>
          <PartySelect />
          <LogoutButton />
        </Box>
      )}
    </Box>
  )
}

export default PageNotFound
