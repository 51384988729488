import React from 'react'
import Input from '../Input'
import useLocalization from '../../hooks/useLocalization'
import {getChangeEventValue} from '../../helpers/misc'

interface IColumn<T> {
  accessor: (item: T) => string | number | boolean | Date | T[keyof T] | T
  searchable?: boolean
}

export interface IProps<T> extends React.PropsWithChildren {
  onFilter: (callback: (row: T) => boolean) => void
  columns: Array<IColumn<T>>
  placeholder?: string
}

const TableSearch = <T extends Record<string, unknown>>({columns, onFilter, placeholder}: IProps<T>) => {
  const {translate} = useLocalization()

  const handleFilter = (term: string) => {
    onFilter((row: T) => {
      if (!term) {
        return true
      }

      for (const column of columns) {
        if (column.searchable === false) {
          continue
        }

        const value = column.accessor(row)

        if (
          value &&
          ['number', 'string'].includes(typeof value) &&
          value.toString().toLocaleLowerCase().includes(term.toLocaleLowerCase())
        ) {
          return true
        }
      }

      return false
    })
  }

  return (
    <Input
      placeholder={placeholder || translate('Search..')}
      onChange={e => {
        handleFilter(getChangeEventValue(e))
      }}
    />
  )
}

export default TableSearch
