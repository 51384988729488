import React from 'react'
import Box from 'components/Box'
import Label from 'components/Label'
import Legend, {IProps as ILegendProps} from './Legend'
import SelectTimescale, {IProps as ISelectorProps} from './SelectTimescale'
import useLocalization from 'hooks/useLocalization'

export interface IProps extends React.PropsWithChildren, ILegendProps {
  timescale?: boolean | ISelectorProps
  customToolbar?: React.ReactNode
}

const VerticalToolbar: React.FC<IProps> = ({lines, timescale, selectedLegend, children, customToolbar}) => {
  const {translate} = useLocalization()

  return (
    <Box direction="row" data-cy="chart" gap={1}>
      {children}
      <Box $maxHeight="250px" overflow="auto" direction="column" width="130px" gap={1}>
        {timescale && <SelectTimescale {...(typeof timescale === 'object' ? timescale : {})} />}
        {customToolbar && <Box style={{minWidth: 120}}>{customToolbar}</Box>}
        <Box style={{textAlign: 'left'}} margin={{bottom: -0.5}}>
          <Label text={translate('Legend')} />
        </Box>
        <Legend vertical lines={lines} selectedLegend={selectedLegend} />
      </Box>
    </Box>
  )
}

const HorizontalToolbar: React.FC<IProps> = ({lines, timescale, selectedLegend, children, customToolbar}) => {
  const {translate} = useLocalization()

  return (
    <Box direction="column" data-cy="chart">
      <Box direction="row">
        {children}
        {timescale && <SelectTimescale {...(typeof timescale === 'object' ? timescale : {})} />}
        {customToolbar && <Box style={{minWidth: 120}}>{customToolbar}</Box>}
      </Box>
      <Box style={{textAlign: 'center'}}>
        <Label text={translate('Legend')} />
      </Box>
      <Box justify="center">
        <Box overflow="auto" direction="row">
          <Legend lines={lines} selectedLegend={selectedLegend} />
        </Box>
      </Box>
    </Box>
  )
}

const Toolbar: React.FC<IProps> = ({vertical, children, ...props}) => {
  if (vertical) {
    return <VerticalToolbar {...props}>{children}</VerticalToolbar>
  }

  return <HorizontalToolbar {...props}>{children}</HorizontalToolbar>
}

export default Toolbar
