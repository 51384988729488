import React from 'react'
import styled from 'styled-components'
import Link, {IProps as ILinkProps} from 'components/Link'
import checkIcon from 'assets/icons/check-icon.svg'
import {useLocation} from 'react-router-dom'

const StyledLink = styled(Link)<{
  $active?: boolean
  $completed?: boolean
  disabled?: boolean
  $withBottomBorder?: boolean
}>`
  ${props => props.disabled && `cursor: not-allowed;`}

  flex-direction: row;
  align-items: center;
  padding: 16px;
  max-height: 64px;
  display: flex;
  &:hover {
    background-color: ${props => props.theme.colors.outline};
  }

  ${props => props.$active && ` background-color: ${props.theme.colors.outline};`}

  ${props =>
    props.$withBottomBorder &&
    `
      border-bottom: 1px solid ${props.theme.colors.outline};
    `}

  ${props =>
    props.$completed &&
    `
      &:after {
        content: '';
        width: 35px;
        height: 35px;
        background-image: url('${checkIcon}');
        margin-left: auto;
        background-size: contain;
      }
    `}
`

interface INavItemProps extends ILinkProps {
  disabled?: boolean
  $completed?: boolean
  $withBottomBorder?: boolean
}

const NavItem: React.FC<INavItemProps> = ({to, disabled, children, ...rest}) => {
  const location = useLocation()

  return (
    <StyledLink to={disabled ? null : to} $active={location.pathname === to} {...rest} disabled={disabled}>
      {children}
    </StyledLink>
  )
}

export default NavItem
