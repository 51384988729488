import {ErrorKind} from 'services/V2ApiErrorMapper'

export default class V2ApiError extends Error {
  message: string
  kind: string
  error: ErrorKind
  correlationId?: string

  public constructor(message: string, error: ErrorKind, correlationId?: string) {
    super(message)

    this.correlationId = correlationId
    this.error = error
    this.message = message
  }
}
