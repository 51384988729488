import dayjs from 'dayjs'
import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import IMatchingScore from 'domain/IMatchingScore'
import {IPaginateResult, IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import IPurchaseContract from 'domain/IPurchaseContract'
import {
  IRecommendedExcessPrice,
  default as ISellExcessRequest,
  default as SellExcessRequest,
} from 'domain/ISellExcessRequest'
import {ISignedContractFile} from 'domain/ISignedContractFile'
import ISitesDashboard from 'domain/ISitesDashboard'
import ITextDocument from 'domain/ITextDocument'
import IUser from 'domain/IUser'
import {IContractedVolume, ICoverageOverview, ITimeseriesOverview} from 'domain/Portfolio'
import IAttachment from '../domain/IAttachment'
import IContract, {ContractState} from '../domain/IContract'
import IContractType from '../domain/IContractType'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IOfferTextGroup from '../domain/IOfferTextGroup'
import IParty from '../domain/IParty'
import IPartyMember, {RepresentationBasis} from '../domain/IPartyMember'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import IProduct from '../domain/IProduct'
import IProductType from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import IContentService, {Interval} from './IContentService'
import LocalStorageDataService from './LocalStorageDataService'
import LocalStorageRelationalDataService from './LocalStorageRelationalDataService'

export default class LocalContentService implements IContentService {
  private readonly attachments: LocalStorageDataService<IAttachment>
  private readonly contracts: LocalStorageDataService<IContract>
  private readonly purchaseContracts: LocalStorageDataService<IPurchaseContract>
  private readonly products: LocalStorageDataService<IProduct>
  private readonly mandatoryProducts: LocalStorageDataService<IProduct>
  private readonly productTypes: LocalStorageDataService<IProductType>
  private readonly sites: LocalStorageDataService<ISite>
  private readonly offers: LocalStorageDataService<IOffer>
  private readonly parties: LocalStorageDataService<IParty>
  private readonly partyMembers: LocalStorageDataService<IPartyMember>
  private readonly userPartyMemberships: LocalStorageDataService<IPartyMember>
  private readonly timeseries: LocalStorageDataService<ITimeseriesItem>

  public constructor() {
    const attachments = new LocalStorageRelationalDataService<IAttachment>('attachments')
    const contracts = new LocalStorageRelationalDataService<IContract>('contracts')
    const purchaseContracts = new LocalStorageRelationalDataService<IPurchaseContract>('purchaseContracts')
    const products = new LocalStorageRelationalDataService<IProduct>('products')
    const mandatoryProducts = new LocalStorageRelationalDataService<IProduct>('mandatoryProducts')
    const productTypes = new LocalStorageRelationalDataService<IProductType>('productTypes')
    const sites = new LocalStorageRelationalDataService<ISite>('sites')
    const offers = new LocalStorageRelationalDataService<IOffer>('offers')
    const parties = new LocalStorageRelationalDataService<IParty>('parties')
    const partyMembers = new LocalStorageRelationalDataService<IPartyMember>('partyMembers')
    const userPartyMemberships = new LocalStorageRelationalDataService<IPartyMember>('userPartyMemberships')
    const timeseries = new LocalStorageRelationalDataService<ITimeseriesItem>('timeseries')

    products.addManyToOneRelation<IAttachment>(attachments, 'image')
    products.addManyToOneRelation<ISite>(sites, 'site', 'products')
    products.addManyToOneRelation<IProductType>(productTypes, 'productType')

    this.attachments = attachments
    this.contracts = contracts
    this.purchaseContracts = purchaseContracts
    this.products = products
    this.mandatoryProducts = mandatoryProducts
    this.productTypes = productTypes
    this.sites = sites
    this.offers = offers
    this.parties = parties
    this.userPartyMemberships = userPartyMemberships
    this.timeseries = timeseries
    this.partyMembers = partyMembers
  }

  getPorfolioOverview(partyId: any, interval: Interval): Promise<ITimeseriesOverview> {
    throw new Error('Method not implemented.')
  }

  getPorfolioContractedVolumes(partyId: any, interval: Interval): Promise<IContractedVolume> {
    throw new Error('Method not implemented.')
  }

  getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    throw new Error('Method not implemented.')
  }

  getPortfolioSitesDashboard(partyId: any, interval: Interval): Promise<ISitesDashboard> {
    throw new Error('Method not implemented.')
  }

  sendContractVerificationCode(contractId: number) {
    throw new Error('Method not implemented.')
  }

  updateProfile(updatedUser: any) {
    throw new Error('Method not implemented.')
  }

  public async createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember> {
    return this.userPartyMemberships.addItem(partyMember)
  }

  public async getConsumptionData(partyId: any, interval: Interval): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItemsWithMeta<any>(meta => meta.partyId === partyId && meta.interval === interval)
  }

  public async getContractHtml(contractId: any): Promise<string> {
    return `<h1>contract ${contractId}</h1>`
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    throw new Error('Not implemented')
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    return []
  }

  public async getPortfolioMatchingScores(partyId: any): Promise<IMatchingScore> {
    throw new Error('Method not implemented.')
  }

  public async sellCertExcessRequest(partyId: any, payload: SellExcessPayload): Promise<SellExcessRequest> {
    return null
  }

  public async uploadTimeseriesData(siteId: any, csv: File): Promise<ISite> {
    return this.sites.getItem(siteId)
  }

  public async cloneTimeseriesData(siteId: any, cloneSiteId: any): Promise<ISite> {
    return this.sites.getItem(siteId)
  }

  public async getProductsVisibleToParty(): Promise<IProduct[]> {
    return this.products.getItems().map(p => this.extendProduct(p))
  }

  public async getProductVisibleToParty(partyId: any, id: any): Promise<IProduct> {
    return this.extendProduct(this.products.getItem(id))
  }

  public async getProductManagedByParty(partyId: any, id: any): Promise<IProduct> {
    return this.products.getItem(id)
  }

  public async getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return null
  }

  public async getRecommendedExcessPrice(
    partyId: any,
    technologyKind: SiteProductionTechnology,
  ): Promise<IRecommendedExcessPrice> {
    return null
  }

  public async acceptExcessSellRequest(sellRequestId: any): Promise<void> {
    return null
  }

  public async declineExcexsSellRequest(sellRequestId: any): Promise<void> {
    return null
  }

  public async editCertificateOffer(partyId: any, offerId: any, body: any): Promise<IOffer> {
    return this.offers.getItems()[0]
  }

  private extendProduct(product: IProduct): IProduct {
    return {
      ...product,
      totalContractedVolumeMwh:
        product.totalContractedVolumeMwh ||
        this.contracts.getItems().reduce((cPrev, cCurrent) => {
          return (
            cPrev +
            cCurrent.contractItems.reduce((ciPrev, ciCurrent) => {
              return (
                ciPrev + (ciCurrent.product?.id.toString() === product.id.toString() ? ciCurrent.volumeMwh || 0 : 0)
              )
            }, 0)
          )
        }, 0),
    }
  }

  public async getContractTypes(): Promise<IContractType[]> {
    const contractType: IContractType = {
      id: 1,
      name: 'Contract type 1',
      termsAndConditions: null,
    }

    return [contractType]
  }

  public async getProductTypes(): Promise<IProductType[]> {
    return this.productTypes.getItems()
  }

  public async savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember> {
    this.userPartyMemberships.setItems([partyMember])

    return partyMember
  }

  public async signContract(partyId: any, contractId: any): Promise<IContract> {
    const contract = this.contracts.getItem(contractId)

    if (!contract) {
      throw new Error('Contract not found')
    }

    contract.signingDate = dayjs().toISOString()
    contract.state = ContractState.SIGNED

    this.contracts.updateItem(contract)

    return contract
  }

  public async uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void> {
    const [partyMember] = await this.userPartyMemberships.getItems()

    partyMember.party.totalAvgYearlyConsumptionMwh = 1234

    this.userPartyMemberships.updateItem(partyMember)
  }

  public async createProduct(partyId: any, product: IProduct): Promise<IProduct> {
    return this.products.addItem(product)
  }

  public async updateProduct(partyId: any, product: IProduct): Promise<IProduct> {
    return this.products.updateItem(product)
  }

  public async createSite(partyId: any, site: ISite): Promise<ISite> {
    return this.sites.addItem(site)
  }

  public async updateSite(partyId: any, site: ISite): Promise<ISite> {
    return this.sites.updateItem(site)
  }

  public async softDeleteSite(partyId: any, siteId: any): Promise<ISite> {
    const site = this.sites.getItem(siteId)
    this.sites.removeItem(siteId)

    return site
  }

  public async confirmUserPhoneNumber(pin: string): Promise<void> {
    //TODO
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    //TODO
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    //TODO
  }

  public async getOffer(partyId: any, id: any): Promise<IOffer> {
    return this.offers.getItem(id)
  }

  public async createManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    return this.parties.addItem(party)
  }

  public async getPagedOffers(partyId: any): Promise<IPaginateResult<IOffer>> {
    return {
      content: this.offers.getItems(),
      totalPages: 0,
    }
  }

  public async getPagedCustomerOffers(partyId: any): Promise<IPaginateResult<IOffer>> {
    return {
      content: this.offers.getItems(),
      totalPages: 0,
    }
  }

  public async getAllOffers(partyId: any): Promise<IOffer[]> {
    return this.offers.getItems()
  }

  public async getManagedParties(asPartyId: any): Promise<IParty[]> {
    return this.parties.getItems()
  }

  public async getManagedParty(partyId: any): Promise<IParty> {
    return this.parties.getItem(partyId)
  }

  public async getPartyMembers(partyId: any): Promise<IPartyMember[]> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getUserFromInvite(partyId: any): Promise<IUser> {
    throw new Error('Not implemented')
  }

  public async getUserFromOfferInvite(accessCode: any): Promise<IOfferInvite> {
    throw new Error('Not implemented')
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async setPasswordFromOfferInvite(
    accessCode: string,
    user: {firstName: string; lastName: string; password: string},
  ): Promise<IOffer> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getPartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    return this.partyMembers.getItem(partyMemberId) as IPartyMember
  }

  public async createPartyMemberUser(
    partyId: number,
    createPartyMember: IAdminCreatePartyMember,
  ): Promise<IPartyMember> {
    const referencedParty = this.parties.getItem(partyId)
    const newUserID = this.userPartyMemberships.getItems().length + 1
    const newUser = {...createPartyMember.user, newUserID} as IUser
    return this.partyMembers.addItem({
      ...createPartyMember,
      user: newUser,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: referencedParty,
    })
  }

  public async updatePartyMember(partyId: number, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember> {
    const oldPartyMember = this.partyMembers.getItem(partyMember.id)
    const updated = {...oldPartyMember, partyMember}

    this.partyMembers.updateItem(updated)

    return updated
  }

  public async disablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const partyMember = this.partyMembers.getItem(partyMemberId)
    const updated = {...partyMember, user: {...partyMember.user, enabled: false}}

    this.partyMembers.updateItem(updated)

    return updated
  }

  public async enablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const partyMember = this.partyMembers.getItem(partyMemberId)
    const updated = {...partyMember, user: {...partyMember.user, enabled: true}}
    this.partyMembers.updateItem(updated)

    return updated
  }

  public async softDeleteManagedParty(partyId: any): Promise<IParty> {
    const party = this.getManagedParty(partyId)
    this.parties.removeItem(partyId)

    return party
  }

  public async getOfferTextGroups(partyId: any, offerId: any): Promise<IOfferTextGroup[]> {
    return []
  }

  public async updateManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    return this.parties.updateItem(party)
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument> {
    return {content: `DOCUMENT FOR ${documentType} with locale ${locale}`} as ITextDocument
  }

  public async getSites(partyId: any): Promise<ISite[]> {
    return this.sites.getItems()
  }

  public getAttachmentURL(attachment: IAttachment): string {
    return attachment ? attachment.fileName : ''
  }

  public getSignedContractFileURL(file: ISignedContractFile): string {
    return file ? file.fileName : ''
  }

  public async getSalesContracts(partyId: any): Promise<IPurchaseContract[]> {
    return this.purchaseContracts.getItems()
  }
}
