import {PrimitiveAtom, atom} from 'jotai'
import {loadable} from './utils'
import {partyAtom} from './party'
import {rootServiceAtom} from './general'
import {atomWithRefresh} from 'jotai/utils'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE = 1

export const pageAtom = atom(DEFAULT_PAGE)
export const pageSizeAtom = atom(DEFAULT_PAGE_SIZE)

export const tenderRespondsAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const page = get(pageAtom)
    const pageSize = get(pageSizeAtom)

    const data = await services.v2ContentService.getTenderResponds(party?.id, page, pageSize)

    return data
  }),
)

export const tenderRespondIdAtom = atom<number>(null) as PrimitiveAtom<number>

export const tenderRespondAtom = loadable(
  atom(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)
    const tenderRespondId = get(tenderRespondIdAtom)

    if (!tenderRespondId) {
      return null
    }

    const data = await services.v2ContentService.getTenderRespond(party?.id, tenderRespondId)

    return data
  }),
  {nullIsLoading: true},
)
