export enum ModalType {
  COOKIES = 'modal.cookies',
  TERMS = 'modal.terms',
  PRIVACY = 'modal.privacy',
  SECURITY = 'modal.security',
  OFFER_PDF = 'modal.offerpdf',
  CONTRACT = 'modal.contract',
  CERTIFICATE_REQUEST = 'modal.certificateRequest',
  CERTIFICATE_PREVIEW = 'modal.certificatePreview',
  EXCESS_CERTIFICATES = 'modal.excessCertificates',
}
