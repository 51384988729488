import React from 'react'
import PhoneNumberVerificationModal from 'components/Platform/Account/PhoneNumberVerificationModal'
import useStoreData from 'hooks/useStoreData'
import ChangePasswordForm from '../../../components/Platform/Account/ChangePasswordForm'
import PersonalInformationForm from '../../../components/Platform/Account/PersonalInformationForm'
import useLocalization from '../../../hooks/useLocalization'
import Page from 'components/Page'
import ResponsiveGrid from 'components/ResponsiveGrid'
import {ScreenBreakpoint} from 'hooks/useScreenSize'
import Box from 'components/Box'

const PersonalProfile = () => {
  const {translate} = useLocalization()
  const showPhoneVerificationModal = useStoreData(store => store.profileStore.showPhoneVerificationModal)

  return (
    <Page title={translate('Personal profile')} description={translate('Update your personal profile')}>
      <ResponsiveGrid gap={2} templateColumns={{[ScreenBreakpoint.Large]: '1fr', [ScreenBreakpoint.MLarge]: '1fr 1fr'}}>
        <Box pad={2} round border $maxHeight={60}>
          <PersonalInformationForm />
        </Box>
        <Box pad={2} round border $maxHeight={60}>
          <ChangePasswordForm />
        </Box>
      </ResponsiveGrid>
      {showPhoneVerificationModal && <PhoneNumberVerificationModal />}
    </Page>
  )
}

export default PersonalProfile
