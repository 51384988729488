import React, {useEffect, useState} from 'react'
import IContract, {ContractState} from '../../../domain/IContract'
import Box from '../../Box'
import {DownloadLink1, DownloadLink2} from '../../DownloadLink'
import useLocalization from '../../../hooks/useLocalization'
import useTheme from '../../../hooks/useTheme'
import useServices from '../../../hooks/useServices'
import {downloadFile} from '../../../helpers/misc'
import useStore from '../../../hooks/useStore'
import Loader from '../../Loader'
import useProfile from '../../../hooks/useProfile'
import IPurchaseContract from 'domain/IPurchaseContract'
import {ICertOfferContract} from 'domain/ICertificateOffer'

const DownloadContract: React.FC<{contract: IContract | IPurchaseContract | ICertOfferContract}> = ({contract}) => {
  const {translate} = useLocalization()
  const {party} = useProfile()
  const {alertStore} = useStore()
  const {contentService} = useServices()
  const [loading, setLoading] = useState<boolean>(false)
  const [templates, setTemplates] = useState<any>([])

  useEffect(() => {
    async function load() {
      setTemplates(await contentService.getContractContractTemplates(contract.id, party.id))
    }

    load()
    // eslint-disable-next-line
  }, [contract])

  const isSigned = contract.state === ContractState.SIGNED
  const title = isSigned ? translate('Contract') : translate('Contract draft')

  return (
    <>
      {loading && <Loader margin={{left: 1}} />}
      {templates.map(template => {
        return (
          <DownloadLink1
            key={template.id}
            title={title}
            name={template.name}
            onClick={async () => {
              setLoading(true)
              try {
                const pdf = await contentService.getContractContractTemplatePdf(contract.id, template.id, party.id)
                downloadFile(`contract-${contract.id}${isSigned ? '-signed' : '-draft'}-${template.name} .pdf`, pdf)
                setLoading(false)
              } catch (e) {
                console.error(e)
                alertStore.addError(translate('%s download failed', title), e?.correlationId, e?.message)
                setLoading(false)
              }
            }}
          />
        )
      })}
    </>
  )
}

interface IProps extends React.PropsWithChildren {
  contract: IContract | IPurchaseContract | ICertOfferContract
}

const ContractDownloadLinks: React.FC<IProps> = ({contract}) => {
  const {translate} = useLocalization()
  const theme = useTheme()
  const {contentService} = useServices()

  return (
    <Box color={theme.colors.light3} round pad={2} margin={{vertical: 3}} direction="row" gap={5}>
      <DownloadContract contract={contract} />
      {contract?.contractType?.termsAndConditions && (
        <DownloadLink2
          title={translate('General terms and conditions')}
          url={contentService.getAttachmentURL(contract?.contractType?.termsAndConditions)}
        />
      )}
    </Box>
  )
}

export default ContractDownloadLinks
