import {CertificateStatus} from './CertificateStatus'

export interface IRegoCertificate {
  id?: number
  productId: number
  accreditationNum: string
  certificateStatus: CertificateStatus
  numOfCertificates: number
  startCertificateNum: string
  endCertificateNum: string
  mwhPerCert: number
  outputStartDate: Date
  outputEndDate: Date
  issueDate: Date
  statusDate: Date
}

export enum CertificateTech {
  SOLAR = 'SOLAR',
  WIND = 'WIND',
  HYDRO = 'HYDRO',
}

export interface ITestCertificates {
  start: string
  end: string
}
