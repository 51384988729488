interface IValidation {
  key: string
  message: string
}

export default class ValidationError extends Error {
  public readonly validation: IValidation[]

  public constructor(message: string, validation: IValidation[]) {
    super(message)

    this.validation = validation
  }
}
