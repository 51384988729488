import Form from 'components/Form'
import FormInput from 'components/Form/FormInput'
import FormSelect from 'components/Form/FormSelect'
import FormSubmitButton from 'components/Form/FormSubmitButton'
import Heading from 'components/Heading'
import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {IManagedUser} from 'domain/IManagedUser'
import {PartyMemberRole} from 'domain/PartyMemberRole'
import {isPartyAdmin, stringifyMemberRole} from 'helpers/partyMember'
import useLocalization from 'hooks/useLocalization'
import useProfile from 'hooks/useProfile'
import useServices from 'hooks/useServices'
import useStore from 'hooks/useStore'
import React from 'react'
import {Redirect, useHistory} from 'react-router-dom'

interface IProps extends React.PropsWithChildren {
  urlPrefix: string
}

interface AddPartyMemberFormData extends Omit<IManagedUser, 'id'> {
  memberRole: PartyMemberRole
}

const AddPartyMemberForm: React.FC<IProps> = ({urlPrefix}) => {
  const {translate} = useLocalization()
  const {partyMembership, user} = useProfile()
  const {contentService} = useServices()
  const {profileStore, alertStore} = useStore()
  const history = useHistory()

  const handleSubmit = async (formData: AddPartyMemberFormData) => {
    const defaultPartyMemberRole = PartyMemberRole.EMPLOYEE
    const partyMemberRoles = [formData.memberRole]

    if (!partyMemberRoles.includes(defaultPartyMemberRole)) {
      partyMemberRoles.push(defaultPartyMemberRole)
    }

    const partyMember = {
      user: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      },
      partyMemberRoles: partyMemberRoles,
    } as IAdminCreatePartyMember

    const created = await contentService.createPartyMemberUser(
      profileStore.party.id,
      partyMember as IAdminCreatePartyMember,
    )
    history.push(`${urlPrefix}/company/members/edit/${created.id}`)
    alertStore.addSuccess(translate('User created'))
  }

  if (!isPartyAdmin(partyMembership)) {
    return <Redirect to={'/'} />
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <Heading>{translate('Personal information')}</Heading>
      <FormInput label={translate('First name')} name="firstName" placeholder={user.firstName} required />
      <FormInput label={translate('Surname')} name="lastName" placeholder={user.lastName} required />
      <FormInput placeholder={user.email} label={translate('Email address')} name="email" />
      <FormSelect
        label={translate('Role')}
        name="memberRole"
        defaultValue={PartyMemberRole.EMPLOYEE}
        options={Object.values(PartyMemberRole).map(role => ({
          value: role,
          label: stringifyMemberRole(role, translate),
        }))}
      ></FormSelect>

      <FormSubmitButton margin={{top: 1}}>{translate('Save')}</FormSubmitButton>
    </Form>
  )
}

export default AddPartyMemberForm
