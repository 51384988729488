import {useObserver} from 'mobx-react'
import useStore from './useStore'
import RootStore from '../store'

const useStoreData = <T>(selector: (store: RootStore) => T) => {
  const store = useStore()

  return useObserver(() => selector(store))
}

export default useStoreData
