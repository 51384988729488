import {atomWithCompare} from 'atoms/utils'
import {generateYears, getCurrentMonth, getCurrentYear, getMonths, getPrevMonth} from 'helpers/date'
import {atom} from 'jotai'

export enum PeriodType {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
}

export interface IValue {
  startMonth: string
  startYear: number
  endMonth: string
  endYear: number
}

export interface IPeriod {
  period: PeriodType
  value: string
}

export const MONTHS = getMonths()
export const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']
export const YEARS = generateYears(getCurrentYear(), 5)

export const periodAtom = atom<IPeriod>({
  period: PeriodType.MONTH,
  value: MONTHS[getCurrentMonth() - 1],
})

const defaultPeriod = {
  startMonth: getPrevMonth(),
  startYear: getCurrentYear(),
  endMonth: getPrevMonth(),
  endYear: getCurrentYear(),
}

export const customPeriodAtom = atomWithCompare(defaultPeriod)

export const yearAtom = atom(getCurrentYear())
