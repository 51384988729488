import React, {ReactElement, useState} from 'react'
import Select, {Props, OptionTypeBase, StylesConfig} from 'react-select'
import useLocalization from '../../hooks/useLocalization'
import styled from 'styled-components'
import DottedIconButton from './DottedIconButton'

const ThreeDotSelect = styled(Select)<{
  options?: {value: string; label: ReactElement}[]
}>`
  .wp {
    &__value-container {
      padding: 0;
    }

    &__control {
      all: unset;
    }

    &__indicators {
      display: none;
    }

    &__input {
      caret-color: transparent;
      padding: 5px;
    }

    &__menu {
      left: ${props => (props.options ? Math.max(...props.options.map(option => option.value.length)) * -7 : 0)}px;
      width: auto;
      white-space: nowrap;
      border-radius: 0;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      padding: 10px 20px;
    }

    &__option--is-focused {
      background-color: #f1f2f5;
    }

    &__placeholder {
      margin: 0;
      left: -10px;
    }
  }
`

export const DottedIconButtonSelect: React.FC<Props & {styles?: StylesConfig<OptionTypeBase, boolean>}> = ({
  styles,
  ...props
}) => {
  const {translate} = useLocalization()
  const [selectedValue, setSelectedValue] = useState([])

  const defaultStyles = {
    option: (styles, {data, isDisabled}) => {
      return {
        ...styles,
        color: data.color,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }
    },
  }

  return (
    <ThreeDotSelect
      styles={styles ? styles : defaultStyles}
      noOptionsMessage={() => translate('No options')}
      {...props}
      menuPlacement={'auto'}
      placeholder={<DottedIconButton />}
      classNamePrefix="wp"
      menuPosition="fixed"
      closeMenuOnScroll={e => e}
      value={selectedValue}
      onChange={() => setSelectedValue([])}
    />
  )
}

export default DottedIconButtonSelect
