import React from 'react'
import Heading from '../Heading'
import Text from '../Text'
import InnerScroll from '../InnerScroll'
import Box from '../Box'
import useTheme from 'hooks/useTheme'

interface IProps extends React.PropsWithChildren {
  title: string | React.ReactNode
  description?: any
  fullWidth?: boolean
  width?: string | number
  fullHeight?: boolean
  noBorder?: boolean
  noMargin?: boolean
}

const ActionBox: React.FC<IProps> = ({
  title,
  description,
  children,
  fullWidth,
  fullHeight,
  width,
  noBorder,
  noMargin,
}) => {
  const theme = useTheme()

  return (
    <Box
      width={fullWidth ? null : width || 41.25}
      height={fullHeight ? '100%' : null}
      border={noBorder ? false : true}
      round
      direction="row"
    >
      <InnerScroll noRightPad>
        <Box pad={2}>
          <Box margin={{bottom: noMargin ? 0 : 2}}>
            <Heading>{title}</Heading>
            {description && (
              <Text lineHeight="medium" size="small" uppercase>
                {description}
              </Text>
            )}
          </Box>
          {children}
        </Box>
      </InnerScroll>
    </Box>
  )
}

export default ActionBox
