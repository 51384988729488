import styled from 'styled-components'
import React from 'react'
import ITheme from '../theme/ITheme'
import {IWithLabelProps, withLabel} from './Label'
import spacing, {ISpacingProps} from './styleProps/spacing'

export interface IProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    IWithLabelProps,
    ISpacingProps {
  contrast?: boolean
}

const Range = styled.input.attrs({type: 'range'})<IProps>`
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 8px; /* Specified height */
  background: ${props => (props.theme as ITheme).colors.light3}; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  cursor: default;
  padding: initial;
  border: initial;
  border-radius: 4px;
  ${spacing}

  /* Mouse-over effects */
  &:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 24px; /* Set a specific slider handle width */
    height: 24px; /* Slider handle height */
    background: ${props =>
      props.contrast
        ? (props.theme as ITheme).colors.info
        : (props.theme as ITheme).colors.primaryDark}; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 12px;
  }

  &::-moz-range-thumb {
    width: 24px; /* Set a specific slider handle width */
    height: 24px; /* Slider handle height */
    background: ${props =>
      props.contrast
        ? (props.theme as ITheme).colors.info
        : (props.theme as ITheme).colors.primaryDark}; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 12px;
  }
`

export default withLabel<IProps>(Range)
