import ILocation, {Country} from '../../domain/ILocation'

const mockLocations: ILocation[] = [
  {
    id: 1451,
    addressStreet: '12 Creek Street',
    addressCity: ' Brisbane',
    addressPostCode: '4000',
    addressCountry: Country.AUT,
    addressSubdivision: 'Queensland',
    coordinatesLat: -24.9922916,
    coordinatesLon: 115.224928,
    details: 'room nr 1',
    placeId: 'abc',
  },
  {
    id: 1604,
    addressStreet: 'SALZACH',
    addressCity: 'ST. JOHANN',
    addressPostCode: '',
    addressCountry: Country.AUT,
    addressSubdivision: 'Salzburg',
    coordinatesLat: 47.33303,
    coordinatesLon: 13.220278,
    details: 'room nr 1',
    placeId: 'def',
  },
  {
    id: 9501,
    addressStreet: null,
    addressCity: 'London',
    addressPostCode: null,
    addressCountry: Country.GBR,
    coordinatesLat: 51.5072178,
    coordinatesLon: -0.1275862,
    addressSubdivision: 'England',
    details: null,
    placeId: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
  },
]

export default mockLocations
