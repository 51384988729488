import React, {useState} from 'react'
import ConfirmationDialog from 'components/ConfirmationDialog'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import {useAtom} from 'jotai'
import {kpiIntervalAtom} from 'atoms/portfolioOverview'
import Select from 'components/Select'
import {getMonths, getPastYears} from 'helpers/date'
import {getChangeEventValue} from 'helpers/misc'

const IntervalModal = ({setModalType}) => {
  const {translate} = useLocalization()
  const [interval, setInterval] = useAtom(kpiIntervalAtom)
  const [month, setMonth] = useState<string>(interval.month)
  const [year, setYear] = useState<number>(interval.year)

  const handleAccept = () => {
    setInterval({month, year})
    setModalType(undefined)
  }

  return (
    <ConfirmationDialog
      title={translate('Set KPI interval')}
      content={
        <Box direction="row" gap={4}>
          <Select
            value={month}
            label="Month"
            options={getMonths().map(month => ({value: month, label: month}))}
            onChange={e => setMonth(getChangeEventValue(e))}
            data-cy="kpi-interval-month"
          />
          <Select
            value={year}
            label="Year"
            options={getPastYears(2).map(year => ({value: year, label: `${year}`}))}
            onChange={e => setYear(getChangeEventValue(e))}
            data-cy="kpi-interval-year"
          />
        </Box>
      }
      open
      setOpen={() => setModalType(undefined)}
      acceptText={translate('Set')}
      declineText={translate('Close')}
      onAccept={handleAccept}
    />
  )
}

export default IntervalModal
