import IUser from '../domain/IUser'
import useStoreData from './useStoreData'
import IParty from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'

interface IProfile {
  user: IUser
  party: IParty
  partyMembership: IPartyMember
  isLoaded: boolean
}

const useProfile = (): IProfile => {
  const {user, party, isLoaded, partyMembership} = useStoreData(store => ({
    user: store.profileStore.user,
    party: store.profileStore.party,
    partyMembership: store.profileStore.partyMembership,
    isLoaded: store.profileStore.isLoaded,
  }))

  return {user, party, partyMembership, isLoaded}
}

export default useProfile
