import React from 'react'
import IProduct from '../../../domain/IProduct'
import Text from '../../Text'
import TechnologyMarker, {IProps as IMarkerProps} from '../../Map/TechnologyMarker'
import ConnectedHoverable from '../../ConnectedHoverable'
import {getHoverKey} from '../../../helpers/product'

interface IProps extends React.PropsWithChildren, Omit<IMarkerProps, 'technology'> {
  product: IProduct
  urlPrefix: string
  onClick?: () => void
}

const ProductMapMarker: React.FC<IProps> = ({product, urlPrefix, onClick, ...props}) => {
  return (
    <ConnectedHoverable hoverKey={getHoverKey(product)}>
      <TechnologyMarker
        {...props}
        linkTo={urlPrefix && `${urlPrefix}/${product.id}`}
        technology={product?.site?.productionTechnology}
        onClick={onClick}
      >
        <Text size="small">{product.name}</Text>
      </TechnologyMarker>
    </ConnectedHoverable>
  )
}

export default ProductMapMarker
