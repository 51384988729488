import React from 'react'
import useLocalization from '../../hooks/useLocalization'
import Label from '../Label'
import Box from '../Box'
import LegendItem from './LegendItem'

export interface IProps extends React.PropsWithChildren {
  lines: Array<{key: string; color: string; label: string; onClick?: () => void; isSelected?: boolean}>
  vertical?: boolean
  selectedLegend?: string
}

const Legend: React.FC<IProps> = ({lines, vertical, selectedLegend}) => {
  return (
    <Box gap={vertical ? 0.5 : 1} margin={{bottom: vertical ? 0 : 2}} direction={vertical ? 'column' : 'row'}>
      {lines.map(line => (
        <LegendItem
          vertical={vertical}
          onClick={line?.onClick}
          selected={line?.isSelected || selectedLegend === line.key}
          key={line.color}
          color={line.color}
        >
          {line.label}
        </LegendItem>
      ))}
    </Box>
  )
}

export default Legend
