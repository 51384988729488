import IPurchaseContract from 'domain/IPurchaseContract'
import {action, computed} from 'mobx'
import IParty from '../domain/IParty'
import {ProductState} from '../domain/IProduct'
import {VolumeType} from '../domain/IProductType'
import {hasMeta, withMeta} from '../helpers/misc'
import IContentService from '../services/IContentService'
import {ResourceId, WithMeta} from '../types'
import RootStore from './index'
import ResourceStore, {RelationType} from './ResourceStore'

interface IMeta {
  partyId?: ResourceId
  purchase?: boolean
}

export default class PurchaseContractStore extends ResourceStore<WithMeta<IPurchaseContract, IMeta>> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super([])
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadPartySalesContracts() {
    await this.setManyEventually(
      this.contentService
        .getSalesContracts(this.party.id)
        .then(contracts => contracts.map(contract => withMeta(contract, {partyId: this.party.id, purchase: false}))),
    )
  }

  @computed
  public get partySalesContracts(): IPurchaseContract[] {
    return this.items.filter(contract => hasMeta(contract, meta => meta.partyId === this.party.id && !meta.purchase))
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
