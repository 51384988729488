import React, {useContext} from 'react'
import {FormContextValues as ReactHookFormContextValues} from 'react-hook-form'

export interface IMeta {
  submitButtonId?: string | number
}

type LocalContextValues = {
  meta: IMeta
  setMeta: (meta: IMeta) => void
}

type FormContextValues = ReactHookFormContextValues & LocalContextValues

export const FormContext = React.createContext<FormContextValues>(null)

const useFormContext = (): FormContextValues => {
  return useContext(FormContext)
}

export default useFormContext
