import IProduct, {ProductState} from '../../domain/IProduct'
import {PriceUnit} from '../../domain/IPrice'
import mockAttachments from './mockAttachments'
import {mockHydroSite, mockSolarSite, mockWindSite} from './mockSites'
import {mockAutofillProductType, mockUserSelectProductType} from './mockProductTypes'

export const mockProducts: IProduct[] = [
  {
    id: 1,
    name: 'SEED_PRODUCT_1',
    state: ProductState.IN_OPERATION,
    description:
      'Beim Gemeinschaftskraftwerk von Salzburg AG und VERBUND (je 50%) haben wir 2011 die Fischwanderhilfe auf den aktuellsten Stand der Technik gebracht. Im 1,8 Kilometer langen Rückstauraum wurde auf Dämme verzichtet, die Ufer wurden durch Aufschüttungen erhöht.',
    totalVolumeForSaleMwh: 71200,
    salesStart: '2018-12-31T22:00:00Z',
    salesEnd: '2023-01-30T22:00:00Z',
    image: mockAttachments[0],
    factSheet: null,
    site: mockHydroSite,
    totalContractedVolumeMwh: 0,
    price: {
      unit: PriceUnit.EUR_MWH,
      value: 14,
    },
    productType: mockUserSelectProductType,
    emissionFactor: 1,
  },
  {
    id: 2,
    name: 'SEED_PRODUCT_2',
    state: ProductState.IN_OPERATION,
    description:
      "After three years of construction, the system went online in 2013. With an output of 13.7 megawatts, it generates around 81 million kilowatt hours of electricity annually. That's enough for nearly 23,000 households in the districts of Lehen, Liefering and Itzling.  With the construction of the flood protection was improved. The continuity of the Salzach for fish is guaranteed and on the Glanspitzareal was a recreational area.",
    totalVolumeForSaleMwh: 40000,
    salesStart: '2018-12-31T22:00:00Z',
    salesEnd: '2030-01-30T22:00:00Z',
    image: null,
    factSheet: null,
    site: mockWindSite,
    totalContractedVolumeMwh: 2002,
    price: {
      unit: PriceUnit.EUR_MWH,
      value: 23,
    },
    productType: mockUserSelectProductType,
    emissionFactor: 1,
  },
  {
    id: 3,
    name: 'SEED_PRODUCT_3',
    state: ProductState.IN_OPERATION,
    description:
      'In 2003, the community Munderfing created an energy concept. The aim was to make the energy supply environmentally friendly and independent. An important step was the generation of electricity from wind power.',
    totalVolumeForSaleMwh: 32000,
    salesStart: '2018-12-31T22:00:00Z',
    salesEnd: '2030-01-30T22:00:00Z',
    image: mockAttachments[0],
    factSheet: mockAttachments[1],
    site: mockHydroSite,
    totalContractedVolumeMwh: 0,
    price: {
      unit: PriceUnit.EUR_MWH,
      value: 3,
    },
    productType: mockUserSelectProductType,
    emissionFactor: 1,
  },
  {
    id: 4,
    name: 'SEED_PRODUCT_4',
    state: ProductState.IN_OPERATION,
    description: 'SEED_PRODUCT_4',
    totalVolumeForSaleMwh: 32000,
    salesStart: null,
    salesEnd: null,
    image: mockAttachments[0],
    factSheet: null,
    site: mockSolarSite,
    totalContractedVolumeMwh: 0,
    price: {
      unit: PriceUnit.EUR_MWH,
      value: 15,
    },
    productType: mockAutofillProductType,
    emissionFactor: 1,
  },
]

export const mockProductsWithSite = mockProducts.filter(product => product.site !== null)

export default mockProducts
