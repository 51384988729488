import React from 'react'
import ResourceGateway from 'components/ResourceGateway'
import {Route, useRouteMatch} from 'react-router-dom'
import CertDesignerProduct from 'components/Platform/Buy/CertificateDesigner/CertDesignerProduct'
import CertDesignerOffer from 'components/Platform/Buy/CertificateDesigner/CertDesignerOffer'

const CertificateDesigner: React.FC<React.PropsWithChildren> = () => {
  const {url} = useRouteMatch()

  return (
    <ResourceGateway
      custom={
        <>
          <Route
            exact
            path={`${url}/offer/:id/product`}
            render={routeProps => <CertDesignerOffer id={routeProps.match.params.id} />}
          />
          <Route
            exact
            path={`${url}/product/:id`}
            render={routeProps => <CertDesignerProduct id={routeProps.match.params.id} />}
          />
        </>
      }
    />
  )
}

export default CertificateDesigner
