import React, {useState} from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useStore from '../../../../hooks/useStore'
import Form from '../../../Form'
import {useHistory} from 'react-router'
import Page from '../../../Page'
import FormSubmitButton from '../../../Form/FormSubmitButton'
import InnerScroll from '../../../InnerScroll'
import Box from '../../../Box'
import {useAtomValue, useSetAtom} from 'jotai'
import {externalContractsAtom} from 'atoms/externalContracts'
import {rootServiceAtom} from 'atoms/general'
import {partyAtom} from 'atoms/party'
import {DateFormat, formatDate} from 'helpers/date'
import {ProfileUploadKind} from 'services/V2ApiErrorMapper'
import ExternalContractAddForm from './ExternalContractAddForm'
import {TimeseriesKind} from 'domain/ISite'
import {IExternalContractPayload} from 'domain/IExternalContract'

interface IProps extends React.PropsWithChildren {}

const AddExternalContractPage: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const [uploadExpectedType, setUploadExpectedType] = useState(TimeseriesKind.BUILDER)
  const [uploadActualType, setUploadActualType] = useState(TimeseriesKind.BUILDER)
  const {alertStore} = useStore()
  const party = useAtomValue(partyAtom)
  const history = useHistory()
  const refetchContracts = useSetAtom(externalContractsAtom)
  const rootService = useAtomValue(rootServiceAtom)

  const handleSubmit = async (contract, {setError}) => {
    try {
      const {expectedBuilder, expectedFile, actualBuilder, actualFile, startDate, endDate, interval, ...rest} = contract
      const contractStartDate = formatDate(startDate, DateFormat.YEAR_MONTH_DAY)
      const contractEndDate = formatDate(endDate, DateFormat.YEAR_MONTH_DAY)

      const payload: IExternalContractPayload = {
        ...rest,
        startDate: contractStartDate,
        endDate: contractEndDate,
        interval: {
          month: interval.month,
          year: +interval.year,
        },
        predictedTimeseries: {
          kind: uploadExpectedType,
          payload: {
            hourlyVolumes: uploadExpectedType === TimeseriesKind.BUILDER ? expectedBuilder.hourlyVolumes : undefined,
            attachmentId: uploadExpectedType === TimeseriesKind.FILE_UPLOAD ? expectedFile?.id : undefined,
          },
        },
        actualTimeseries: {
          kind: uploadActualType,
          payload: {
            hourlyVolumes: uploadActualType === TimeseriesKind.BUILDER ? actualBuilder.hourlyVolumes : undefined,
            attachmentId: uploadActualType === TimeseriesKind.FILE_UPLOAD ? actualFile?.id : undefined,
          },
        },
      }

      await rootService.v2ContentService.saveExternalContract(party.id, payload)
      alertStore.addSuccess(translate('Successfully saved %s', 'contract'))
      refetchContracts()
      history.push('/consumer/portfolio/external-contracts')
    } catch (e) {
      if (e?.error?.kind in ProfileUploadKind) {
        const attachmentId = e.error.payload.attachmentId
        setError(
          contract.predictedProfileAttachment.id === attachmentId
            ? 'predictedProfileAttachment'
            : 'actualProfileAttachment',
          'file',
          e.message,
        )
        alertStore.addError(e.message)
      } else {
        alertStore.addError(translate('Failed to save external contract'), e?.correlationId, e?.message)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight submissionFeedback={null}>
      <Page
        title={translate('Add external contract')}
        corner={
          <Box gap={2}>
            <FormSubmitButton>{translate('Save')}</FormSubmitButton>
          </Box>
        }
      >
        <InnerScroll>
          <ExternalContractAddForm
            uploadActualType={uploadActualType}
            setUploadActualType={setUploadActualType}
            uploadExpectedType={uploadExpectedType}
            setUploadExpectedType={setUploadExpectedType}
          />
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default AddExternalContractPage
