import {SiteState} from '../../../../../domain/ISite'
import {getHumanReadableState} from '../../../../../helpers/site'
import FormSelect, {IProps} from '../../../../Form/FormSelect'
import React from 'react'
import useLocalization from '../../../../../hooks/useLocalization'

const SiteStateSelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()
  return (
    <FormSelect
      label={translate('Status')}
      {...props}
      options={Object.keys(SiteState).map(state => ({
        value: state,
        label: getHumanReadableState(state as SiteState, translate),
      }))}
    />
  )
}

export default SiteStateSelect
