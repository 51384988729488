import {SiteProductionTechnology} from '../../../../../domain/ISite'
import {getHumanReadableTechnologyName} from '../../../../../helpers/site'
import FormSelect, {IProps} from '../../../../Form/FormSelect'
import React from 'react'
import useLocalization from '../../../../../hooks/useLocalization'

const ProductionTechnologySelect: React.FC<IProps> = props => {
  const {translate} = useLocalization()

  return (
    <FormSelect
      label={translate('Technology')}
      {...props}
      options={Object.keys(SiteProductionTechnology).map(technology => ({
        value: technology,
        label: getHumanReadableTechnologyName(technology as SiteProductionTechnology, translate),
      }))}
    />
  )
}

export default ProductionTechnologySelect
