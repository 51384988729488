import React from 'react'
import styled from 'styled-components'
import ITheme from 'theme/ITheme'
import {IProps as IDropdownProps} from './'

const DropdownMenuList = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
`
const StyledDropdown = styled.div`
  all: unset;
  position: absolute;
  top: 100%;
  z-index: 999;
  min-width: 80px;
  font-size: 14px;
  font-weight: 300;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: ${props => props.theme.tileSize}px;
  margin-top: ${props => props.theme.tileSize}px;

  color: ${props => (props.theme as ITheme).colors.secondary};
`

const DropdownListMenuItem = styled.div`
  position: relative;
  background-color: transparent;
  display: flex;
  color: inherit;
  cursor: default;
  font-size: inherit;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
`

const DropdownList: React.FC<IDropdownProps> = ({menuItems}) => {
  return (
    <StyledDropdown>
      <DropdownMenuList>
        {menuItems.map((item, idx) => (
          <DropdownListMenuItem key={idx}>{item}</DropdownListMenuItem>
        ))}
      </DropdownMenuList>
    </StyledDropdown>
  )
}
export default DropdownList
