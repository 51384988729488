import React from 'react'
import useTheme from '../hooks/useTheme'
import styled from 'styled-components'
import ITheme from '../theme/ITheme'
import {useAtomValue} from 'jotai'
import {partyAtom} from 'atoms/party'
import Box from './Box'
import Text from './Text'

interface IProps extends React.PropsWithChildren {
  to?: string
}

const StyledLogoImage = styled.img`
  height: 20px;
  display: block;
  filter: contrast(0.9);
`

const PoweredBy: React.FC<IProps> = ({to}) => {
  const theme = useTheme()
  const party = useAtomValue(partyAtom)

  if (!theme.poweredBy) {
    return null
  }

  return (
    <Box pad={{horizontal: 2.5}}>
      <Text font={theme.font.headings} size="small">
        Powered By
      </Text>
      <StyledLogoImage src={theme.renewablLogo} alt="poweredBy" />
    </Box>
  )
}

export default PoweredBy
