import React from 'react'
import styled from 'styled-components'
import useLocalization from '../../../hooks/useLocalization'
import HorizStack from 'components/HorizStack'
import {CheckIcon, StyledSmallArrowImage} from './Layout/FormComponents'
import Box from 'components/Box'

const OnboardingStep = styled.div<{
  index: number
  active?: boolean
  completed?: boolean
}>`
  text-transform: uppercase;
  display: flex;
  font-size: 10px;
  align-items: center;
  color: black;
  font-weight: 300;

  ${props =>
    (props.active || props.completed) &&
    `
    font-weight: 800;
  `}
`

export enum Step {
  LOGIN_INFO = 1,
  EMAIL_VERIFICATION = 2,
  CONTACT_INFO = 3,
  CONSUMPTION_ESTIMATE = 4,
  PARTY_VERIFICATION = 5,
  COMPLETED = 6,
}

interface IProps extends React.PropsWithChildren {
  current: Step
}

const OnboardingSteps: React.FC<IProps> = ({current}) => {
  const {translate} = useLocalization()
  const stepTexts = {
    [Step.LOGIN_INFO]: translate('Credentials'),
    [Step.EMAIL_VERIFICATION]: translate('Verify email'),
    [Step.CONTACT_INFO]: translate('Contact info'),
  }
  const steps: Step[] = Object.keys(stepTexts).map(key => +key)

  return (
    <HorizStack margin={{bottom: 4}}>
      {steps.map((step: Step) => (
        <OnboardingStep key={step} completed={current > step} active={current === step} index={step}>
          {current > step && <CheckIcon />}
          <Box margin={{right: 1}}>{stepTexts[step]}</Box>
          {step !== steps.length && <StyledSmallArrowImage />}
        </OnboardingStep>
      ))}
    </HorizStack>
  )
}

export default OnboardingSteps
