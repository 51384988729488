import {ISubscriptionPlan} from './IParty'
import {CurrencyCode} from './IPrice'

export default interface ISubscription {
  id: number
  billingMode: BillingMode
  externalId: string
  status: SubscriptionStatus
  // payment_provider: PaymentProvider;
  paymentProvider: string
  // payment_method: PaymentMethod;
  paymentMethod: string
  partyId: string
  createdOn: string
  startsOn: string
  endsOn: string | null
  canceledOn: string | null
  pausedOn: string | null
  firstBillOn: string | null
  nextBillOn: string | null
  amount: string
  isAutoCollect: boolean
  currencyCode: CurrencyCode
  plan: ISubscriptionPlan
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Paused = 'PAUSED',
  PendingCancellation = 'PENDING_CANCELLATION',
  PastDue = 'PastDue',
}

export enum BillingMode {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}
