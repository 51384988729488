import React from 'react'
import useLocalization from '../../../hooks/useLocalization'
import ResourceGateway from '../../../components/ResourceGateway'
import ContractListPage from '../../../components/Platform/Portfolio/ContractListPage'
import CertificateContractPage from 'components/Platform/Portfolio/CertificateContractPage/CertificateContractPage'

const CurrentContract: React.FC<React.PropsWithChildren> = () => {
  const {translate} = useLocalization()

  return (
    <ResourceGateway
      single={routeProps => <CertificateContractPage offerId={routeProps.match.params.id} />}
      list={() => (
        <ContractListPage
          title={translate('Contracts')}
          description={translate('List of currently active contracts')}
        />
      )}
    />
  )
}

export default CurrentContract
