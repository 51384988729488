import dayjs from 'dayjs'
import IPurchaseContract, {IPurchaseParty} from 'domain/IPurchaseContract'
import IContract from '../domain/IContract'
import IContractItem from '../domain/IContractItem'
import IParty from '../domain/IParty'
import IProduct, {ProductState} from '../domain/IProduct'
import {VolumeType} from '../domain/IProductType'
import ITranslate from '../interfaces/ITranslate'
import {getContractItemVolumeMwh} from './contract'
import {detectBestPossibleEnergyUnit} from './conversion'
import {getPeriod} from './date'
import {getStringifiedSiteLocation} from './site'

export function getHumanReadableState(productOrState: IProduct | ProductState, translate: ITranslate): string {
  const state: ProductState = typeof productOrState === 'string' ? productOrState : productOrState.state
  switch (state) {
    case ProductState.DRAFT:
      return translate('Upcoming')

    case ProductState.IN_OPERATION:
      return translate('Operational')

    case ProductState.RETIRED:
      return translate('Retired')
  }

  return null
}

export function getStringifiedLocation(product: IProduct, translate: ITranslate): string {
  if (!product?.site) {
    return null
  }

  return getStringifiedSiteLocation(product.site, translate)
}

export function getAvailableEnergy(product: IProduct): number {
  return product.totalVolumeForSaleMwh - product.totalContractedVolumeMwh
}

export function getTotalForSalePercentageOfSiteProduction(product: IProduct) {
  return product.totalVolumeForSaleMwh / product.site.avgYearlyProductionMwh
}

export function getAvailablePercentageOfSiteProduction(product: IProduct) {
  return getAvailableEnergy(product) / product.site.avgYearlyProductionMwh
}

export function getProductMaxReservationVolumeMwh(product: IProduct) {
  if (product.totalContractedVolumeMwh === undefined) {
    return 0
  }

  return +Math.max(product.totalVolumeForSaleMwh - product.totalContractedVolumeMwh, 0).toFixed(3)
}

export function getProductMaxReservationVolumeMwhForParty(
  product: IProduct,
  contract: IContract | IPurchaseContract,
  partyOrConsumptionMwh: IParty | IPurchaseParty | number,
): number {
  if (!partyOrConsumptionMwh) {
    return 0
  }

  if (contract?.volumeMwh && product.productType?.volumeType === VolumeType.STANDALONE_USER_SELECT) {
    return contract.volumeMwh
  }

  let dynamicContractItemsVolumeMwh = 0

  if (contract) {
    const contractItemsWithDynamicProducts = contract.contractItems.filter(
      item => item.product.productType?.volumeType === VolumeType.USER_SELECT && item.product.id !== product.id,
    )
    dynamicContractItemsVolumeMwh = contractItemsWithDynamicProducts.reduce(
      (acc: number, item: IContractItem) => acc + getContractItemVolumeMwh(item),
      0,
    )
  }
  const consumptionMwh =
    typeof partyOrConsumptionMwh === 'object'
      ? partyOrConsumptionMwh.totalAvgYearlyConsumptionMwh
      : partyOrConsumptionMwh
  const maxTotalVolumeMwh = consumptionMwh - dynamicContractItemsVolumeMwh

  return +Math.max(Math.min(maxTotalVolumeMwh, getProductMaxReservationVolumeMwh(product)), 0).toFixed(3)
}

export function getSalesPeriod(product: IProduct) {
  return getPeriod(product.salesStart, product.salesEnd)
}

export function getHoverKey(product: IProduct) {
  return `PRODUCT_${product.id}`
}

export function isProductOperational(product: IProduct) {
  if (product.salesStart && !dayjs().isAfter(product.salesStart)) {
    return false
  }

  if (product.salesEnd && !dayjs().isBefore(product.salesEnd)) {
    return false
  }

  return product.state === ProductState.IN_OPERATION
}

export function isProductOperationalBroker(product: IProduct) {
  if (product.salesStart && !dayjs().isAfter(product.salesStart)) {
    return false
  }

  if (product.salesEnd && !dayjs().isBefore(product.salesEnd)) {
    return false
  }

  if (product.state === ProductState.IN_OPERATION) {
    return true
  }

  return product.state === ProductState.DRAFT
}

export function isProductOnSale(product: IProduct) {
  if (product.salesStart && !dayjs().isAfter(product.salesStart)) {
    return false
  }

  if (product.salesEnd && !dayjs().isBefore(product.salesEnd)) {
    return false
  }

  if (product.state !== ProductState.IN_OPERATION) {
    return false
  }

  return true
}

export function getProductNotOnSaleReason(product: IProduct, translate: ITranslate) {
  if (product.salesStart && !dayjs().isAfter(product.salesStart)) {
    return translate('Sales not begun')
  }

  if (product.salesEnd && !dayjs().isBefore(product.salesEnd)) {
    return translate('Sales ended')
  }
}

export function isProductUserSelectable(product: IProduct): boolean {
  return [VolumeType.USER_SELECT, VolumeType.STANDALONE_USER_SELECT].includes(product.productType?.volumeType)
}

export function isPriceVisibleToCustomer(product: IProduct): boolean {
  return product.productType?.priceVisibleToCustomer
}

export function isSoldOut(product: IProduct) {
  return product.totalVolumeForSaleMwh && product.totalContractedVolumeMwh >= product.totalVolumeForSaleMwh
}

export function isNotSoldOut(product: IProduct) {
  return !isSoldOut(product)
}

export function getBestPossibleEnergyUnit(product: IProduct): string {
  return detectBestPossibleEnergyUnit([
    product.totalVolumeForSaleMwh,
    product.totalContractedVolumeMwh,
    product.totalOfferedVolumeMwh,
  ])
}

export function isUserSelectable(product: IProduct): boolean {
  return [VolumeType.USER_SELECT, VolumeType.STANDALONE_USER_SELECT].includes(product?.productType?.volumeType)
}
