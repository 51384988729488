import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'

const OffersSkeleton: React.FC<React.PropsWithChildren> = () => {
  return (
    <Box direction="column">
      <Box direction="row" margin={{top: 3}} gap={4}>
        <Skeleton variant="rectangular" height="90px" width="100%" />
        <Skeleton variant="rectangular" height="90px" width="100%" />
        <Skeleton variant="rectangular" height="90px" width="100%" />
      </Box>

      <Box direction="column" pad={{top: 3}} gap={1}>
        {[...Array(20)].map((_, index) => (
          <Skeleton key={index} height={40} width="100%" variant="rectangular" />
        ))}
      </Box>
    </Box>
  )
}

export default OffersSkeleton
