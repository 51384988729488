import React, {useState, useEffect} from 'react'
import Text from 'components/Text'
import Box from 'components/Box'
import Table from 'components/Table'
import {DateFormat, formatDate, getHourlyInterval} from 'helpers/date'
import {getAccreditationNum, getDateAndSuffix, getId, getNumCertificates} from 'helpers/certificates'
import {Chip} from '@mui/material'
import Checkbox from 'components/Checkbox'
import useLocalization from 'hooks/useLocalization'
import InnerScroll from 'components/InnerScroll'
import useTheme from 'hooks/useTheme'
import {IMarketListingReport} from 'domain/IMarketListing'

export const TableCertValue = ({value, showAccr, showDates}) => {
  const theme = useTheme()
  if (!value) return '-'

  const getCertValue = cert => {
    const accrNum = showAccr ? getAccreditationNum(cert) : ''
    const certId = getId(cert)
    const datesAndSuffix = showDates ? getDateAndSuffix(cert) : ''

    return `${accrNum}${certId}${datesAndSuffix}`
  }

  return (
    <Box direction="row" align="center" gap={1}>
      <Box>
        <Box direction="row" align="center" justify="start">
          <Text bold size="msmall" style={{minWidth: 32}}>
            start:
          </Text>
          <Text size="medium" font={theme.font.primaryFont}>
            {getCertValue(value.start)}
          </Text>
        </Box>

        <Box direction="row" align="center" justify="start">
          <Text bold size="msmall" style={{minWidth: 32}}>
            end:
          </Text>
          <Text size="medium" font={theme.font.primaryFont}>
            {getCertValue(value.end)}
          </Text>
        </Box>
      </Box>
      <Box>
        <Chip color="primary" variant="outlined" label={`${getNumCertificates(value.start, value.end)} EAC`} />
      </Box>
    </Box>
  )
}

const MatchedCertsTable: React.FC<{data: IMarketListingReport}> = ({data}) => {
  const {translate} = useLocalization()
  const [showAccr, setShowAccr] = useState(true)
  const [showDates, setShowDates] = useState(true)

  const contracts = data?.hourly[0]?.contracts.map(contract => contract.id)

  return (
    <InnerScroll>
      <Box gap={3} direction="row" pad={{horizontal: 1}}>
        <Checkbox
          label={translate('Show accredation numbers')}
          checked={showAccr}
          onChange={() => setShowAccr(!showAccr)}
        />
        <Checkbox
          label={translate('Show dates and suffixes')}
          checked={showDates}
          onChange={() => setShowDates(!showDates)}
        />
      </Box>
      <Table
        tableName={translate('Certificates breakdown')}
        data={data?.hourly}
        sortable={false}
        columns={[
          {
            title: translate('Date'),
            accessor: report => report.timestamp,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {formatDate(date, DateFormat.MONTH_DAY_YEAR)}
              </Text>
            ),
          },
          {
            title: translate('Hour'),
            accessor: report => report.timestamp,
            render: (date: string) => (
              <Text size="msmall" nowrap>
                {getHourlyInterval(date)}
              </Text>
            ),
          },
          {
            title: translate('Total'),
            accessor: report => report.total,
            render: value => <TableCertValue value={value} showAccr={showAccr} showDates={showDates} />,
          },
          {
            title: translate('Gap'),
            accessor: report => report.gap,
            render: value => <TableCertValue value={value} showAccr={showAccr} showDates={showDates} />,
          },
          ...contracts.map(contract => ({
            title: translate(`Contract %s`, contract),
            accessor: report => {
              const contractData = report.contracts.find(c => c.id === contract)
              return contractData?.certs
            },
            render: value => <TableCertValue value={value} showAccr={showAccr} showDates={showDates} />,
          })),
        ].filter(Boolean)}
      />
    </InnerScroll>
  )
}

export default MatchedCertsTable
