import React from 'react'
import expandSvg from '../../assets/icons/expand.svg'
import IconButton, {IProps as IIconButtonProps} from './index'
import styled from 'styled-components'

type IProps = Omit<IIconButtonProps, 'src'> & {
  expanded?: boolean
  size?: number
}

const StyledIconButton = styled(IconButton)<{rotated?: boolean}>`
  transition: transform 0.2s;
  ${props => props.rotated && `transform: rotate(180deg);`}
`

const ExpandButton: React.FC<IProps> = ({expanded, size, ...props}) => {
  return <StyledIconButton rotated={expanded} src={expandSvg} size={size || 3} {...props} />
}

export default ExpandButton
