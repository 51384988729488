import styled from 'styled-components'
import React from 'react'
import Box from './Box'
import Text from './Text'
import useTheme from '../hooks/useTheme'
import {ProgressContainer, ProgressFill, IProps as IProgressProps} from './Progressbar'
import {formatDecimal} from '../helpers/format'

const Indicator = styled.div<{color: string; opacity?: number}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  ${props =>
    props.color.includes('gradient') ? `background-image: ${props.color};` : `background-color: ${props.color};`}
  ${props => props.opacity && `opacity: ${props.opacity};`}
`

const LegendItem: React.FC<{
  color: string
  text: string
  value: string | number
  opacity?: number
  unit?: string
}> = ({color, text, value, unit, opacity}) => {
  const theme = useTheme()

  return (
    <Box align="start">
      <Indicator color={color} opacity={opacity} />
      <Box margin={{left: 1}}>
        <Text size="small" uppercase>
          {text}
        </Text>
        <Text nowrap semibold size="small" uppercase font={theme.font.numbers}>
          {value}&nbsp;&nbsp;&nbsp;{unit}
        </Text>
      </Box>
    </Box>
  )
}

interface IFill {
  color: string
  text: string
  value: number
  opacity?: number
}

export interface IProps extends React.PropsWithChildren, IProgressProps {
  fills: IFill[]
  max?: number
  unit?: string
  containerColor?: string
  remaining?: Partial<IFill>
}

const ProgressWithLegend: React.FC<IProps> = ({fills, max, unit, remaining, ...rest}) => {
  const theme = useTheme()

  return (
    <div>
      <ProgressContainer size="small" containerColor={remaining && remaining.color} {...rest}>
        {fills
          .filter(({value}) => value > 0)
          .map(({color, value, opacity}) => (
            <ProgressFill key={color} color={color} value={value} max={max} opacity={opacity} />
          ))}
      </ProgressContainer>

      <Box justify="space-between" margin={{top: 1}} gap={3}>
        {[
          ...fills,
          remaining && {
            color: remaining.color || theme.colors.light3,
            text: remaining.text,
            value: remaining.value || max,
            opacity: remaining.opacity,
            unit,
          },
        ]
          .filter(Boolean)
          .map(({color, text, value, opacity}, index) => (
            <Box key={color} grow={!remaining && index === fills.length - 1} justify="flex-end">
              <LegendItem color={color} text={text} value={formatDecimal(value, 0)} unit={unit} opacity={opacity} />
            </Box>
          ))}
      </Box>
    </div>
  )
}

ProgressWithLegend.defaultProps = {
  max: 100,
}

export default ProgressWithLegend
