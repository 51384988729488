import ITimeseriesItem from '../../domain/ITimeseriesItem'

export const timeseriesMonthly: ITimeseriesItem[] = [
  {id: 'id1', name: 'jan', value: 1},
  {id: 'id2', name: 'feb', value: 20},
  {id: 'id3', name: 'mar', value: 22},
  {id: 'id4', name: 'apr', value: 13},
  {id: 'id5', name: 'may', value: 240},
  {id: 'id6', name: 'jun', value: 124},
  {id: 'id7', name: 'jul', value: 10},
  {id: 'id8', name: 'aug', value: 40},
  {id: 'id9', name: 'sept', value: 41},
  {id: 'id10', name: 'oct', value: 5},
  {id: 'id11', name: 'nov', value: 41},
  {id: 'id12', name: 'dec', value: 5},
]

export default timeseriesMonthly
