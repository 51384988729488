import ISite, {SiteModel, SiteProductionTechnology, SiteState, SiteType} from '../../domain/ISite'
import mockParties from './mockParties'
import mockLocations from './mockLocations'

export const mockWindSite: ISite = {
  id: 1,
  name: 'WIND_SITE_1',
  state: SiteState.IN_OPERATION,
  siteModel: SiteModel.PHYSICAL,
  siteType: SiteType.PRODUCTION,
  description: 'SITE_1_DESCRIPTION',
  operationsStart: '2007-12-31T22:00:00Z',
  operationsEnd: '2049-12-31T22:00:00Z',
  avgYearlyConsumptionMwh: null,
  productionTechnology: SiteProductionTechnology.WIND,
  avgYearlyProductionMwh: 71200,
  productionNameplateCapacityMwac: 52,
  timeseriesId: 'YES',
  location: mockLocations[1],
  party: mockParties[1],
}

export const mockHydroSite: ISite = {
  id: 2,
  name: 'HYDRO_SITE_2',
  state: SiteState.IN_OPERATION,
  siteModel: SiteModel.PHYSICAL,
  siteType: SiteType.PRODUCTION,
  description: 'SITE_2_DESCRIPTION',
  operationsStart: '2007-12-31T22:00:00Z',
  operationsEnd: '2049-12-31T22:00:00Z',
  avgYearlyConsumptionMwh: null,
  productionTechnology: SiteProductionTechnology.HYDRO,
  avgYearlyProductionMwh: 71200,
  productionNameplateCapacityMwac: 165,
  timeseriesId: null,
  location: mockLocations[1],
  party: mockParties[1],
}

export const mockSolarSite: ISite = {
  id: 5,
  name: 'SOLAR_SITE_3',
  state: SiteState.IN_OPERATION,
  siteModel: SiteModel.PHYSICAL,
  siteType: SiteType.PRODUCTION,
  description: 'SITE_#_DESCRIPTION',
  operationsStart: '2007-12-31T22:00:00Z',
  operationsEnd: '2049-12-31T22:00:00Z',
  avgYearlyConsumptionMwh: null,
  productionTechnology: SiteProductionTechnology.SOLAR,
  avgYearlyProductionMwh: 71200,
  productionNameplateCapacityMwac: 165,
  timeseriesId: null,
  location: mockLocations[1],
  party: mockParties[1],
}

export const mockProductionSites: ISite[] = [mockWindSite, mockHydroSite, mockSolarSite]

export const mockConsumptionSites: ISite[] = [
  {
    id: 3,
    name: 'CONSUMPTION_SITE_2',
    state: SiteState.IN_OPERATION,
    siteModel: SiteModel.VIRTUAL,
    siteType: SiteType.CONSUMPTION,
    description: 'SITE_2_DESCRIPTION',
    operationsStart: null,
    operationsEnd: null,
    avgYearlyConsumptionMwh: 1450,
    productionTechnology: null,
    avgYearlyProductionMwh: null,
    productionNameplateCapacityMwac: null,
    mainCircuitBreakerCapacityAmp: 150,
    timeseriesId: 'YES',
    location: mockLocations[0],
    party: mockParties[1],
  },
]

export const mockConsumptionTemplateSites: ISite[] = [
  {
    id: 4,
    name: 'CONSUMPTION_TEMPLATE_SITE_1',
    state: SiteState.IN_OPERATION,
    siteModel: SiteModel.VIRTUAL,
    siteType: SiteType.CONSUMPTION,
    description: 'CONSUMPTION_TEMPLATE_SITE_1_DESCRIPTION',
    operationsStart: null,
    operationsEnd: null,
    avgYearlyConsumptionMwh: 1450,
    productionTechnology: null,
    avgYearlyProductionMwh: null,
    productionNameplateCapacityMwac: null,
    timeseriesId: 'YUP',
    location: null,
    isTemplate: true,
    party: null,
  },
]

const mockSites: ISite[] = [...mockProductionSites, ...mockConsumptionSites, ...mockConsumptionTemplateSites]

export default mockSites
