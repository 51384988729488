import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from 'components/Box'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 10%;
  min-width: 100px;
  max-width: 140px;
`

const LoadingSkeleton: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <Box direction={'row'} height={'150px'} margin={{top: -3}}>
        <Skeleton width={'17%'} />
        <Box width={'10px'}></Box>
        <Skeleton width={'17%'} />
        <Box width={'10px'}></Box>
        <Skeleton width={'17%'} />
        <Box width={'10px'}></Box>
        <Skeleton width={'17%'} />
        <Box width={'10px'}></Box>
        <Skeleton width={'17%'} />
        <Box width={'10px'}></Box>
        <Skeleton width={'17%'} />
      </Box>
      <Box direction="row" height={12} justify="space-between" margin={{top: -3}}>
        <Skeleton width="40%" />
        <Skeleton width="25%" />
      </Box>

      <Box direction="row" height="256px">
        <Box width="87%">
          <Skeleton height={256} variant="rectangular"></Skeleton>
        </Box>
        <StyledBox pad={{top: 2}} margin={{left: 3}}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </StyledBox>
      </Box>

      <Box margin={2} align="center" direction="column" gap={3}>
        <Skeleton height={55} width="15%" />
        <Box direction="row" gap={3}>
          <Skeleton height={137} width={400} variant="rectangular" />
          <Skeleton height={137} width={400} variant="rectangular" />
        </Box>
      </Box>
    </>
  )
}

export default LoadingSkeleton
