import ResourceStore from './ResourceStore'
import IContentService from '../services/IContentService'
import RootStore from './index'
import IProductType from '../domain/IProductType'

export default class ProductTypeStore extends ResourceStore<IProductType> {
  private contentService: IContentService
  private rootStore: RootStore

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super()
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadProductTypes() {
    await this.setManyEventually(this.contentService.getProductTypes(this.rootStore.profileStore.party.id))
  }
}
